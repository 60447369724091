import { Button, Typography } from '@material-ui/core'
import React, { useReducer, useState } from 'react'
import "./style.scss"
import logo from "../../../assets/images/onlywhitelogo.svg";
import { baseURL } from '../../../api';
import AnyOneImg from "../../../assets/images/connection.svg";
import * as contactActions from "../../../redux/actions/contactActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useForm from "./useForm";
import validate from './Validateform';
let imagePath = baseURL + "uploads/homepagecms/image/";


function StayConnectComponent(props) {
    const pageDetails = props.pageDetails;
    const [full_name, setFullname] = useState("");
    const [email, setEmail] = useState("");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(login, validate);

    function login() {
       // props.contactActions.newsletterSubscription()
       console.log('dgdg')
    }


    return (
        <>
            <section className="stayConnected">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <img src={AnyOneImg} alt="" className="img-fluid d-flex ml-auto" />
                        </div>
                        <div className="col-xl-6">
                            <h1>Stay Connected</h1>
                            <h5>Get Notified Once The Platform Is Live Subscribe To Our Email Newsletter</h5>

                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-4">
                                    <input type="text" className="form-control" placeholder="Your name" name={'full_name'}  onChange={handleChange} value={values.full_name || ''} />
                                    {errors.full_name && (
                                        <p className="text-danger">{errors.full_name}</p>
                                    )}
                                </div>
                                <div class="form-group mb-4">
                                    <input type="text" className="form-control" placeholder="Your email address" name={'email'} onChange={handleChange} value={values.email || ''} />
                                    {errors.email && (
                                        <p className="text-danger">{errors.email}</p>
                                    )}
                                </div>
                                <button type="submit" className="btn btn-subs rounded-pill mb-4">Subscribe</button>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        contactReducerData: state.contactReducer,
    };
};

const mapDispatchToProps = (dispatch) => ({
    contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StayConnectComponent);



