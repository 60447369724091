import axios from "axios";
//export const baseURL = 'http://104.211.229.156:1469/';

//Devlopment server
export const baseURL = "https://admin-forgetmenot.dedicateddevelopers.us/";

//Live server
//export const baseURL = "https://beta-admin.getforgetmenot.com/";

export const stripe_publishable_key =
  "pk_test_51Hya9cF2x3aozwVHIBe6UJX52vsQXCCn6WEUpcNJXehgltTtvAoIWfAQLe5sGZiQEhEpy8hvOSeHWJg0CCmetsGQ00dle5U52m";

let axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("auth_token");
    if (token !== null) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export default axiosInstance;
