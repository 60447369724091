import React, { Component } from "react";
import { Link } from "react-router-dom";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import TextField from "@material-ui/core/TextField";

import fingerIcon from "../assets/images/icon6.svg";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import { Helmet } from "react-helmet";

class SignInPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",

      isShowPassword: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      loading: false,

      isForgotPassword: false,
      loginUserType: "",

      cms_title: "",
      cms_sub_title: "",
      cms_description: "",
    };

    /* Bind the function */
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user_info");
    localStorage.removeItem("secondary_user_token");
    if (auth_token === "" || auth_token === undefined || auth_token === null) {
    } else {
      this.props.history.push("/");
    }
    this.props.miscActions.fetchSignCms();
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      this.setState({ loading: false });
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";

      /* Redirect to dashboard */

      if (this.state.isForgotPassword === true) {
        this.setState({ isForgotPassword: false, loading: false });
      } else {
        if (this.state.loginUserType === "user") {
          this.props.history.push("/dashboard");
        } else {
          this.props.history.push("/secondary-user-dashboard");
        }
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.USER_LOGIN_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          var token =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.token !== undefined
              ? miscReducerData.responce.data.token
              : "";
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";

          var role =
            userInfo !== undefined &&
            userInfo !== null &&
            userInfo !== "" &&
            userInfo.role !== undefined &&
            userInfo.role !== "" &&
            userInfo.role !== "" &&
            userInfo.role.role !== undefined &&
            userInfo.role.role !== "" &&
            userInfo.role.role !== null
              ? userInfo.role.role
              : "";

          props.miscActions.clearState();
          if (token !== "") {
            localStorage.setItem("auth_token", token);
            localStorage.setItem("user_info", JSON.stringify(userInfo));
            return {
              apiErrorMessage: "",
              apiSuccessMessage: message,
              loginUserType: role,
            };
          } else {
            return {
              apiErrorMessage: "Something went wrong",
              apiSuccessMessage: "",
            };
          }
        case miscActions.USER_LOGIN_FAILED:
          props.miscActions.clearState();
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined &&
            miscReducerData.responce.data.message !== ""
              ? miscReducerData.responce.data.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
          };

        case miscActions.FORGOT_PASSWORD_SUCCESS:
          props.miscActions.clearState();
          var forgotPasswordSuccess = miscReducerData.responce.data;
          return {
            apiErrorMessage: "",
            apiSuccessMessage: forgotPasswordSuccess.message,
            loading: false,
          };
        case miscActions.FORGOT_PASSWORD_FAILED:
          props.miscActions.clearState();
          var forgotPasswordFailure = miscReducerData.responce.data;
          return {
            apiErrorMessage: forgotPasswordFailure.message,
            apiSuccessMessage: "",
            loading: false,
          };
        case miscActions.FETCH_SIGN_CMS_SUCCESS:
          var cmsInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          return {
            cms_title: cmsInfo.title,
            cms_sub_title: cmsInfo.sub_title,
            cms_description: cmsInfo.description,
          };
        default:
          return {};
      }
    }

    return {};
  }

  handleChange(event) {
    let stateValue = this.state;
    if (event.target.name === "email") {
      this.setState({ email: event.target.value });
    } else {
      this.setState({ password: event.target.value });
    }
  }

  validateEmail(mail) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });

    if (
      this.state.email === "" ||
      this.state.email === undefined ||
      this.state.email === null
    ) {
      toast.error("Please enter your email address");
    } else if (this.validateEmail(this.state.email) === false) {
      toast.error("Please enter a valid email address");
    } else if (!this.state.password && this.state.isForgotPassword === false) {
      toast.error("Please enter your password");
    } else {
      this.setState({ loading: true });

      let fromData = {
        email: this.state.email,
        password: this.state.password,
        deviceToken: "",
        deviceType: "",
      };
      if (!this.state.isForgotPassword) {
        /* Hit the ajax */
        this.props.miscActions.userLogin(fromData);
      } else {
        let ForgotPasswordFromData = {
          email: this.state.email,
        };
        /* Hit the ajax */
        this.props.miscActions.forgotPassword(ForgotPasswordFromData);
      }
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Forget Me Not - Sign in</title>
        </Helmet>
        <PrimaryHeader
          position={"absoluteHeader"}
          otherMenuNotVisible={"login"}
        />
        <section className="registerWrap">
          <div className="container pb-5">
            <div className="row mx-auto registerInwrap   ">
              <div className="col-lg-6 bg-white leftBox">
                <div className="card border-0 h-100 justify-content-center">
                  <div>
                    <h3
                      className="fs-36 font-weight-Bold"
                      style={{ color: "#212121" }}
                    >
                      {this.state.isForgotPassword === true
                        ? "Forgot Password"
                        : "Sign In"}
                    </h3>
                    {this.state.isForgotPassword === false ? (
                      <h5
                        className="fs-16 fs-lg-18 mar-b-30 font-weight-Regular"
                        style={{ color: "#A3A3A3" }}
                      >
                        Don’t have an account yet?{" "}
                        <Link to="/sign-up" className="font-weight-Bold">
                          Sign Up Now
                        </Link>
                      </h5>
                    ) : (
                      <h5
                        className="fs-16 fs-lg-18 mar-b-30 font-weight-Regular"
                        style={{ color: "#A3A3A3" }}
                      ></h5>
                    )}

                    <form
                      onSubmit={this.handleSubmit}
                      className={"custom_form"}
                    >
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <TextField
                            name="email"
                            label="Email Address"
                            variant="outlined"
                            className="w-100"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        {this.state.isForgotPassword === false && (
                          <div className="col-md-12 mb-4">
                            <div className="position-relative passwordWrap">
                              <TextField
                                type={
                                  this.state.isShowPassword === false
                                    ? "password"
                                    : "text"
                                }
                                name="password"
                                label="Password"
                                variant="outlined"
                                className="w-100"
                                onChange={this.handleChange}
                                style={{ color: "red" }}
                                required
                              />
                              <div
                                className="position-absolute icoSufix"
                                onClick={(e) =>
                                  this.setState({
                                    isShowPassword: !this.state.isShowPassword,
                                  })
                                }
                              >
                                <IconButton>
                                  <i className="material-icons">
                                    {this.state.isShowPassword === true
                                      ? "visibility"
                                      : "visibility_off"}{" "}
                                  </i>
                                </IconButton>
                              </div>
                            </div>
                          

                            {this.state.isForgotPassword !== true && (
                              <a
                                className="text-color1 font-weight-Medium"
                                onClick={() =>
                                  this.setState({
                                    isForgotPassword:
                                      !this.state.isForgotPassword,
                                  })
                                }
                                href="javascript:void(0)"
                                style={{ float: "right", marginTop: "5px" }}
                              >
                                {this.state.isForgotPassword === true
                                  ? "Sign in"
                                  : "Forgot password?"}
                              </a>
                            )}
                            {this.state.isForgotPassword === true && (
                              <Button
                                className="text-color1 font-weight-Medium"
                                onClick={() =>
                                  this.setState({
                                    isForgotPassword:
                                      !this.state.isForgotPassword,
                                  })
                                }
                                style={{ float: "right", marginTop: "5px" }}
                              >
                                {this.state.isForgotPassword === true
                                  ? "Sign in"
                                  : "Forgot password?"}
                              </Button>
                            )}
                          </div>
                        )}

                        {this.state.loading === true && (
                          <div className="col-md-6 mar-t-n15 ">
                            <CircularProgress />
                          </div>
                        )}
                        {this.state.loading === false && (
                          <div className="col-md-6 mar-t-n24 ">
                            <Button
                              type="submit"
                              className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize mt-3"
                            >
                              {this.state.isForgotPassword === true
                                ? "Reset Now"
                                : "Sign In"}
                            </Button>
                          </div>
                        )}

                        <div className="col-md-6 text-right sign_in_text">
                          <a
                            className="text-color1 font-weight-Medium"
                            onClick={() =>
                              this.setState({
                                isForgotPassword: !this.state.isForgotPassword,
                              })
                            }
                            href="javascript:void(0)"
                          >
                            {this.state.isForgotPassword === true
                              ? "Sign in"
                              : ""}
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  ">
                <div className="rightBox">
                  <img
                    src={fingerIcon}
                    alt=""
                    className="w-70px w-xxxl-100px"
                  />
                  <div
                    className="text-white fs-34 fs-xxxl-48 font-weight-Bold pad-t-20 pad-t-xxxl-35 line-height-1"
                    dangerouslySetInnerHTML={{
                      __html: this?.state?.cms_title
                        ? this?.state?.cms_title
                        : "Be there at 2025",
                    }}
                  />

                  <div
                    className="text-white fs-26 fs-xxxl-36"
                    style={{ opacity: "0.6" }}
                    dangerouslySetInnerHTML={{
                      __html: this?.state?.cms_sub_title
                        ? this?.state?.cms_sub_title
                        : "With Never Forget Me",
                    }}
                  />
                  <div
                    className="text-white line-height-normal fs-18 fs-xxxl-24 pad-t-30"
                    dangerouslySetInnerHTML={{
                      __html: this?.state?.cms_description,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
