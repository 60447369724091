import { combineReducers } from 'redux';

import miscReducer from './miscReducer';
import contactReducer from './contactReducer';
import paymentReducer from './paymentReducer';
import memoryReducer from './memoryReducer';

export default combineReducers({
    miscReducer,
    contactReducer,
    paymentReducer,
    memoryReducer
});
