import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Modal,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import PlayBtn from "../assets/images/icon23.svg";
import Image from "../assets/images/img6.jpg";

import TextField from "@material-ui/core/TextField";
import Image7 from "../assets/images/img7.jpg";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadURLS from "../assets/settings.js";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import audioIcon from "../assets/images/audio.png";
import VideoRecorder from "react-video-recorder";
import CloseIcon from "@material-ui/icons/Close";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";

import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import Timer from "../Components/timer";

class EditMemories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: null,
      formData: {
        title: "",
        message: "",
        uploadedFiles: [],
      },
      eventDetails: {},
      singleMemoriData: {},
      loading: false,
      isDisabled: true,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      removeFileSuccessApiMessage: "",
      removeFileErrorApiMessage: "",
      flag: "",
      imageFiles: [],
      videoFiles: [],
      audioFiles: [],
      audioOpen: false,
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      },
      recordState: null,
      recordAudio: null,
      recordAudioData: null,
      isStart: false,
      isStop: false,
      isPause: false,
      isUpload: false,
      audioCount: 0,
      videoCount: 0,
      isVideoCloseClick: false,
      uploadingPer: 0,

      isVideoStart: false,
      isVideoStop: false,
      isVideoPause: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleOpen = (event) => {
    this.setState({ setOpen: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ setOpen: false, isVideoCloseClick: true }, () => {
      setTimeout(() => {
        this.setState({ isVideoCloseClick: false });
      }, 1000);
    });
    this.setState({
      currentVideourl: "",
      currentBloburl: "",
      videoOpen: false,
      videocloseClick: true,
      setOpen: false,
    });
  };

  componentDidMount() {
    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");

    console.log("details", this.props.location.state.detail);
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null
    ) {
      this.props.history.push("/");
    } else {
      if (this.props.location.state) {
        this.setState(
          {
            eventDetails: this.props.location.state.eventDetails,
            singleMemoriData: this.props.location.state.detail,
            flag: this.props.location.state.flag,
          },
          () => {
            if (
              this.state.singleMemoriData &&
              this.state.singleMemoriData &&
              this.state.singleMemoriData.files.length > 0
            ) {
              let imageArr = this.state.singleMemoriData.files.filter(
                (file) => {
                  return (
                    file.includes("jpg") ||
                    file.includes("JPG") ||
                    file.includes("png") ||
                    file.includes("PNG") ||
                    file.includes("gif") ||
                    file.includes("GIF") ||
                    file.includes("jpeg") ||
                    file.includes("JPEG")
                  );
                }
              );
              this.setState({ imageFiles: imageArr });

              let videoArr = this.state.singleMemoriData.files.filter(
                (file) => {
                  return (
                    file.includes("mp4") ||
                    file.includes("MP4") ||
                    file.includes("mov") ||
                    file.includes("MOV") ||
                    file.includes("wmv") ||
                    file.includes("WMV") ||
                    file.includes("flv") ||
                    file.includes("FLV") ||
                    file.includes("video") ||
                    file.includes("VIDEO") ||
                    file.includes("webm") ||
                    file.includes("WEBM")
                  );
                }
              );
              this.setState({ videoFiles: videoArr });

              let audioArr = this.state.singleMemoriData.files.filter(
                (file) => {
                  return (
                    file.includes("mp3") ||
                    file.includes("wav") ||
                    file.includes("ogg")
                  );
                }
              );
              this.setState({ audioFiles: audioArr });
            }
          }
        );

        let obj = { ...this.state.formData };
        obj.title = this.props.location.state.detail?.title;
        obj.message = this.props.location.state.detail?.message;

        this.setState({ formData: obj });
      }
    }
  }

  componentDidUpdate() {
    if (this.state.apiErrorMessage) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.setState({ loading: false });
    } else if (this.state.apiSuccessMessage) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiSuccessMessage = "";
      this.setState({ loading: false });
      this.props.history.replace("/view-event/" + this.state.eventDetails._id);
    }
    if (this.state.removeFileErrorApiMessage) {
      toast.error(this.state.removeFileErrorApiMessage);
      this.state.removeFileErrorApiMessage = "";
    } else if (this.state.removeFileSuccessApiMessage) {
      toast.success(this.state.removeFileSuccessApiMessage);
      this.state.removeFileSuccessApiMessage = "";
    }
  }

  static getDerivedStateFromProps(props) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        case contactActions.UPDATE_USER_MEMORY_SUCCESS:
          props.contactActions.clearState();
          var updateEventSuccess = contactReducerData.responce.data;
          return {
            apiErrorMessage: "",
            apiSuccessMessage: updateEventSuccess.message,
          };
        case contactActions.UPDATE_USER_MEMORY_FAILED:
          props.contactActions.clearState();
          return {
            apiErrorMessage: "Something Went Wrong! please try again",
            apiSuccessMessage: "",
          };
        case contactActions.REMOVE_FILE_SUCCESS:
          props.contactActions.clearState();
          var removeFileSuccess = contactReducerData.responce.data;
          return {
            removeFileErrorApiMessage: "",
            removeFileSuccessApiMessage: removeFileSuccess.message,
          };
        case contactActions.REMOVE_FILE_FAILED:
          props.contactActions.clearState();
          var removeFileSuccess = contactReducerData.responce.data;
          return {
            removeFileErrorApiMessage: "Something Went Wrong! please try again",
            removeFileSuccessApiMessage: "",
          };
        case contactActions.FILE_UPLOAD_PROGRESS:
          // props.contactActions.clearState();
          let per = contactReducerData.responce;
          return {
            uploadingPer: per,
          };
        default:
          return {};
      }

      return {};
    }
  }

  handleChange = (event) => {
    let x = { [event.target.name]: event.target.value };

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ...x,
      },
    }));
  };

  handleSubmit = () => {
    if (!this.state.formData.title) {
      toast.error("Please enter title.");
    } else if (!this.state.formData.message) {
      toast.error("Please enter message");
    } else {
      var formData = new FormData();
      formData.append("title", this.state.formData.title);
      formData.append("message", this.state.formData.message);
      formData.append("scheduled", true);
      formData.append("event_id", this.state.eventDetails._id);
      formData.append("memoryId", this.props.match.params.id);
      formData.append(
        "user_contact_id",
        this.state.eventDetails.user_contact_id._id
      );
      formData.append("year", this.state.singleMemoriData.year);

      if (this.state.formData.uploadedFiles.length > 0) {
        /* Delete the previewImage data from array */
        this.state.formData.uploadedFiles.map((item) => {
          delete item.previewImage;
          return item;
        });
        /* Append new selected files of an array */
        this.state.formData.uploadedFiles.forEach(function (item) {
          formData.append("files", item.mainFile);
        });
      }

      /* Append old files */
      let oldFileArr = [
        ...this.state.imageFiles,
        ...this.state.videoFiles,
        ...this.state.audioFiles,
      ];
      if (oldFileArr.length > 0) {
        oldFileArr.forEach(function (item) {
          formData.append("oldFiles[]", item);
        });
      } else {
        formData.append("oldFiles[]", "");
      }

      this.setState({ loading: true });
      this.props.contactActions.updateUserMemory(formData);
    }
  };

  fileUploadChange(e) {
    let memoryFiles = e.target.files;

    let previewImage = "";
    let fileType = "";
    if (memoryFiles.length > 0) {
      memoryFiles = memoryFiles[0];
      previewImage = URL.createObjectURL(memoryFiles);
      fileType = memoryFiles["type"].split("/")[0];
    }

    if (previewImage !== "" && memoryFiles !== "" && fileType !== "") {
      let formData = this.state.formData;
      let preUploadedImage = formData.uploadedFiles; // fetch pre upload files and push new file

      let tempImg = {
        mainFile: memoryFiles,
        previewFile: previewImage,
        fileType: fileType,
      };
      preUploadedImage.push(tempImg);

      formData["uploadedFiles"] = preUploadedImage;
      this.setState({
        formData: formData,
        isDisabled: false,
      });

      e.target.value = ""; // reset input type file
    }
  }

  removeFileConfirm(removeIndx) {
    let formData = this.state.formData;
    let preUploadedImage = formData.uploadedFiles;

    preUploadedImage.splice(removeIndx, 1); // remove file form array
    formData["uploadedFiles"] = preUploadedImage;

    this.setState({
      formData: formData,
    });
  }

  removeFile(removeIndx) {
    var THIS = this;
    confirmAlert({
      title: "Confirm to remove!",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => THIS.removeFileConfirm(removeIndx),
        },
        {
          label: "No",
        },
      ],
    });
  }

  removeUploadedFile(file, type) {
    var THIS = this;
    confirmAlert({
      title: "Confirm to remove!",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => THIS.removeUploadedFileConfirm(file, type),
        },
        {
          label: "No",
        },
      ],
    });
  }

  removeUploadedFileConfirm(file, type) {
    let data = {
      fileName: file,
      memoryId: this.state.singleMemoriData?._id,
    };
    if (type === "image") {
      let updatedImageArr = this.state.imageFiles.filter((item) => {
        return item !== file;
      });
      //this.props.contactActions.removeFile(data);
      this.setState({ imageFiles: updatedImageArr });
    } else if (type === "video") {
      let updatedVideoArr = this.state.videoFiles.filter((item) => {
        return item !== file;
      });
      //this.props.contactActions.removeFile(data);
      this.setState({ videoFiles: updatedVideoArr });
    } else if (type === "audio") {
      let updatedAudioArr = this.state.audioFiles.filter((item) => {
        return item !== file;
      });
      //this.props.contactActions.removeFile(data);
      this.setState({ audioFiles: updatedAudioArr });
    }
  }

  resetForm() {
    var resetValues = {
      title: "",
      message: "",
      uploadedFiles: [],
    };

    this.setState(
      {
        formData: resetValues,
      },
      () => {
        this.props.history.replace({
          pathname: "/view-memory/" + this.state.singleMemoriData._id,
          state: {
            detail: this.state.singleMemoriData,
            eventDetails: this.state.eventDetails,
          },
        });
      }
    );
  }

  openvideorecorder = () => {
    this.setState({ setOpen: true });
  };

  handleopenAudiorecorder = () => {
    var THIS = this;
    let audioIN = { audio: true };
    navigator.mediaDevices
      .getUserMedia(audioIN)

      // 'then()' method returns a Promise
      .then(function (mediaStreamObj) {
        THIS.setState({ audioOpen: true });
      })
      // If any error occurs then handles the error
      .catch(function (err) {
        toast.error(`${err.name} ${err.message}`);
        // console.log(err.name, err.message);
      });
  };

  handleCloseAudiorecorder = () => {
    this.stop();
    this.setState({
      audioOpen: false,
      recordState: null,
      recordAudio: null,
      recordAudioData: null,
      isStart: false,
      isStop: false,
      isPause: false,
      isUpload: false,
      audioCount: 0,
    });
  };

  handleAudioStop(data) {
    console.log(data);
    this.setState({ audioDetails: data });
  }
  // handleAudioUpload= ()=> {
  //   const url = URL.createObjectURL(file);
  //   this.updateaudoformdata(file, url);
  //   console.log("videoBlob1233", url);

  //   console.log(file);
  // }

  handleAudioUpload = () => {
    console.log(this.state.recordAudioData);
    //const url = URL.createObjectURL(this.state.recordAudioData);
    // const url = URL.createObjectURL(this.state.recordAudioData);
    this.updateaudoformdata(
      this.state.recordAudioData,
      this.state.recordAudioData.url
    );

    this.setState({
      recordState: RecordState.STOP,
      isStart: false,
      isStop: false,
      isPause: false,
      isUpload: false,
      audioCount: 0,
    });
    // console.log("videoBlob1233", url);
  };

  handleRest() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      },
    };
    this.setState({ audioDetails: reset });
  }

  //Update fordata after viderecord..
  updateformdata(blobfile1, bloburl) {
    console.log("test-------------------------", blobfile1);

    var blobfile = new Blob([blobfile1], { type: blobfile1.type });
    // var url = window.URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.style.display = 'none';
    // a.href = url;
    // a.download = 'test.mp4';
    // document.body.appendChild(a);
    // a.click();
    // setTimeout(() => {
    //   document.body.removeChild(a);
    //   window.URL.revokeObjectURL(url);
    // }, 100);

    // console.log(blobfile1)

    // var blobfile = new Blob([blobfile1], {"type" : "video\/mp4"});

    // console.log('Blobfile',blobfile)

    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 25; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    let memoryFiles = blobfile;

    var blob = blobfile.slice(0, blobfile.size, "video/*");
    var mimetype = blobfile.type;
    console.log(mimetype);
    var extention = mimetype.substring(6, mimetype.lastIndexOf(";"));
    //console.log(extention);
    var newFile = new File(
      [blobfile],
      `${result}.${extention == "webm" ? "webm" : extention}`,
      { lastModified: new Date().getTime(), type: blobfile.type }
    );

    //let memoryFiles = blobfile;

    let previewImage = "";
    let fileType = "";

    memoryFiles = memoryFiles;
    previewImage = bloburl;
    fileType = "video";

    console.log(memoryFiles);
    console.log(previewImage);
    console.log(fileType);

    if (this.state.isVideoCloseClick) {
      this.setState({
        isVideoCloseClick: false,
      });
    } else {
      if (previewImage !== "" && memoryFiles !== "" && fileType !== "") {
        let formData = this.state.formData;
        let preUploadedImage = formData.uploadedFiles; // fetch pre upload files and push new file

        let tempImg = {
          mainFile: newFile,
          previewFile: previewImage,
          fileType: fileType,
        };

        preUploadedImage.push(tempImg);
        console.log(preUploadedImage);

        formData["uploadedFiles"] = preUploadedImage;
        this.setState({
          formData: formData,
          isDisabled: false,
          isVideoCloseClick: false,
        });
        this.handleClose();

        //   e.target.value = ""; // reset input type file
      }
    }
  }
  updateaudoformdata(blobfile1, bloburl) {
    console.log(blobfile1);
    var blobfile = new Blob([blobfile1.blob], { type: "audio/wav" });
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 25; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    let memoryFiles = blobfile;

    var mimetype = blobfile.type;
    var extention = mimetype.substring(6, mimetype.lastIndexOf(";"));
    console.log(extention);

    //var blob = blobfile.slice(0, blobfile.size, "audio/*");
    var newFile = new File([blobfile], `${result}.wav`, { type: mimetype });

    let previewImage = "";
    let fileType = "";

    memoryFiles = memoryFiles;
    previewImage = bloburl;
    fileType = "audio";

    // console.log(memoryFiles);
    // console.log(previewImage);
    // console.log(fileType);

    if (previewImage !== "" && memoryFiles !== "" && fileType !== "") {
      let formData = this.state.formData;
      let preUploadedImage = formData.uploadedFiles; // fetch pre upload files and push new file

      let tempImg = {
        mainFile: newFile,
        previewFile: previewImage,
        fileType: fileType,
      };

      preUploadedImage.push(tempImg);
      console.log(preUploadedImage);

      formData["uploadedFiles"] = preUploadedImage;
      this.setState({
        formData: formData,
        isDisabled: false,
      });

      this.handleRest();
      this.handleCloseAudiorecorder();

      //   e.target.value = ""; // reset input type file
    }
  }
  triggerInputFile = () => this.fileInput.click();

  /*
  /****************************Audio recording Start ************************ *
  */

  changeTime1 = () => {
    this.setState({ videoCount: this.state.videoCount + 1 });
  };

  start1 = () => {
    // if (!this.state.isVideoStop) {
    //   this.setState({
    //     videoCount: 0,
    //   });
    // }

    const intervalID1 = setInterval(this.changeTime1, 1000);
    this.setState({
      // recordState: RecordState.START,
      // isStop: true,
      // isPause: true,
      // isUpload: false,
      intervalID1,
    });
  };

  stop1 = () => {
    clearInterval(this.state.intervalID1);
    // this.setState({
    //   recordState: RecordState.STOP,
    //   isUpload: true,
    //   isStop: false,
    //   isPause: false,
    // });
  };

  changeTime = () => {
    this.setState({ audioCount: this.state.audioCount + 1 });
  };

  start = () => {
    if (!this.state.isStop) {
      this.setState({
        audioCount: 0,
      });
    }

    const intervalID = setInterval(this.changeTime, 1000);
    this.setState({
      recordState: RecordState.START,
      isStop: true,
      isPause: true,
      isUpload: false,
      intervalID,
    });
  };

  stop = () => {
    clearInterval(this.state.intervalID);
    this.setState({
      recordState: RecordState.STOP,
      isUpload: true,
      isStop: false,
      isPause: false,
    });
  };

  //audioData contains blob and blobUrl
  onStop = (audioData) => {
    this.setState({
      recordAudio: audioData.url,
      recordAudioData: audioData,
    });
    console.log("audioData", audioData);
  };

  pause = () => {
    clearInterval(this.state.intervalID);
    this.setState({
      recordState: RecordState.PAUSE,
      isUpload: false,
      isPause: false,
    });
  };

  /*
  /****************************Audio recording END    ************************ *
  */

  render() {
    let formData = this.state.formData;
    const modal = "d-flex align-items-center";
    //console.log(this.state.audioCount);

    return (
      <>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
            <div className="container">
              <div className="card border-0 border-radius-15 min-vh-75 overflow-hidden">
                <div className="card-header pad-x-20 pad-x-xl-46 pad-y-20 bg-white">
                  <div className="d-lg-flex justify-content-between align-items-center">
                    <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                      <i className="material-icons text-color1 align-middle fs-30">
                        mms
                      </i>
                      <span className="pl-2">
                        {/* {this.state.eventDetails &&
                          this.state.eventDetails.title}{" "} */}
                        {this.state.eventDetails.title !== undefined &&
                        this.state.eventDetails.title !== null &&
                        this.state.eventDetails.title !== ""
                          ? this.state.eventDetails.title.includes("Birthday")
                            ? this.state.eventDetails.user_contact_id
                                .firstName !== undefined
                              ? this.state.eventDetails.user_contact_id
                                  .firstName +
                                "'s " +
                                this.state.eventDetails.title
                              : ""
                            : this.state.eventDetails.title
                          : ""}{" "}
                        |{" "}
                        {this.state.singleMemoriData &&
                          this.state.singleMemoriData.year}
                      </span>
                      {/*<span className="pl-5 pl-md-2 fs-19 d-block d-md-inline opa-60 text-black font-weight-Regular">
                        Draft saved
                      </span>*/}
                    </h3>

                    <div className="pt-3 pt-lg-0 d-flex justify-content-between align-items-center">
                      {this.state.loading === false && (
                        <div>
                          <Button
                            onClick={() => this.resetForm()}
                            className="text-color1 border text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-15"
                          >
                            <i className="material-icons fs-17 fs-md-24">
                              close
                            </i>{" "}
                            Cancel
                          </Button>
                        </div>
                      )}

                      <div>
                        {this.state.loading === true ? (
                          <>
                            <Box
                              style={{
                                position: "relative",
                                display: "inline-flex",
                              }}
                            >
                              <CircularProgress />
                              <Box
                                style={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="text.secondary"
                                >
                                  {`${Math.round(this.state.uploadingPer)}%`}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <Button
                            className={
                              "bg-color1 text-white text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-25 mar-l-20 "
                            }
                            onClick={this.handleSubmit}
                          >
                            <i className="material-icons fs-17 fs-md-24">
                              send
                            </i>{" "}
                            Edit memory
                          </Button>
                        )}
                      </div>

                      {/*<div>
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={this.handleOpen.bind(this)}
                        >
                          <i className="material-icons fs-36">more_vert</i>
                        </IconButton>
                      </div> */}
                      {/* <Menu
                        anchorEl={this.state.setOpen}
                        keepMounted
                        open={this.state.setOpen}
                        onClose={this.handleClose.bind(this)}
                      >
                        <MenuItem
                          className="fs-18 font-weight-Medium"
                          onClick={() => this.handleClose}
                        >
                          <i className="material-icons text-color1 align-middle mr-2">
                            delete
                          </i>{" "}
                          Discard memory
                        </MenuItem>
                      </Menu> */}
                    </div>
                  </div>

                  <div></div>
                </div>

                <div className="card-body">
                  <div className="col-md-11 col-xl-8 mx-auto pt-5">
                    <div className="w-100 mb-4">
                      <TextField
                        id="demo-simple-select-outlined"
                        label="Add a title"
                        variant="outlined"
                        className="w-100"
                        name="title"
                        onChange={this.handleChange}
                        value={formData.title}
                        required
                      />
                    </div>

                    <div className="w-100 mb-4">
                      <TextField
                        id="outlined-multiline-static"
                        label="Write your message here"
                        multiline
                        rows={5}
                        variant="outlined"
                        className="w-100"
                        name="message"
                        onChange={this.handleChange}
                        value={formData.message}
                        required
                      />
                    </div>

                    <div className="w-100 mb-4">
                      <input
                        ref={(fileInput) => (this.fileInput = fileInput)}
                        type="file"
                        hidden
                        accept="image/*,video/mp4,video/x-m4v,video/*,audio/*"
                        onChange={(e) => this.fileUploadChange(e)}
                      />
                      <Button
                        className="text-color1 border  fs-19 pad-y-7 pad-x-15 mr-2"
                        onClick={this.triggerInputFile}
                      >
                        <i className="material-icons">insert_photo</i>
                        <span className="pl-1">
                          Add Video or Images or Audio
                        </span>
                      </Button>

                      <Button
                        className="text-color1 border  fs-19 pad-y-7 pad-x-15 mr-2"
                        onClick={this.openvideorecorder}
                      >
                        <i className="material-icons">videocam</i>
                        <span className="pl-1">Record Video</span>
                      </Button>

                      <Button
                        className="text-color1 border  fs-19 pad-y-7 pad-x-15 mr-2"
                        onClick={this.handleopenAudiorecorder}
                      >
                        <i className="material-icons">mic</i>
                        <span className="pl-1">Record Audio</span>
                      </Button>
                    </div>
                    {/* {this.state.uploadingPer > 0 &&
                      this.state.uploadingPer < 100 && (
                        <div className="my-3">
                          <LinearProgress
                            variant="determinate"
                            value={this.state.uploadingPer}
                          />
                          {this.state.uploadingPer}%
                        </div>
                      )} */}

                    <div className="row addVideoImage">
                      {formData.uploadedFiles.map((list, index) => (
                        <div className="col-md-4 mb-4" key={index}>
                          {list.fileType === "video" && (
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                <video
                                  id="video"
                                  src={list.previewFile}
                                  className="w-100"
                                  controls
                                  playsInline
                                ></video>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() => this.removeFile(index)}
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          )}
                          {list.fileType === "audio" && (
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                <audio
                                  id="video"
                                  src={list.previewFile}
                                  className="w-100"
                                  controls
                                  style={{
                                    background: `url(${audioIcon})`,
                                    height: "205px",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                ></audio>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() => this.removeFile(index)}
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          )}
                          {list.fileType === "image" && (
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                <a data-fancybox="gallery" href={Image7}>
                                  <img
                                    src={list.previewFile}
                                    alt=""
                                    className="w-100"
                                  />
                                </a>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() => this.removeFile(index)}
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          )}
                        </div>
                      ))}

                      {this.state.videoFiles &&
                        this.state.videoFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={index}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                {/*<img src={Image} alt="" className="w-100" />
                                <div className="position-absolute play">
                                  <a
                                    data-fancybox="gallery"
                                    href={UploadURLS.user_memories + list}
                                  >
                                    <img src={PlayBtn} alt="" />
                                  </a>
                                </div>*/}

                                {/*<video
                                  id="video"
                                  src={UploadURLS.user_memories + list}
                                  className="w-100"
                                  controls
                                ></video>*/}

                                <video
                                  className="w-100"
                                  controls
                                  playsInline
                                  style={{ zoom: 1 }}
                                >
                                  <source src={list} type="video/mp4" />
                                  <source src={list} type="video/webm" />
                                </video>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() =>
                                  this.removeUploadedFile(list, "video")
                                }
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          </div>
                        ))}

                      {this.state.audioFiles &&
                        this.state.audioFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={index}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                <audio
                                  id="video"
                                  src={list}
                                  className="w-100"
                                  controls
                                  style={{
                                    background: `url(${audioIcon})`,
                                    height: "205px",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                ></audio>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() =>
                                  this.removeUploadedFile(list, "audio")
                                }
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          </div>
                        ))}

                      {this.state.imageFiles &&
                        this.state.imageFiles.length > 0 &&
                        this.state.imageFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={index}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                {/*<a
                                  data-fancybox="gallery"
                                  href={UploadURLS.user_memories + list}
                                >
                                  <img
                                    src={UploadURLS.user_memories + list}
                                    alt=""
                                    className="w-100"
                                  />
                                </a>*/}

                                <a data-fancybox="gallery" href={list}>
                                  <img src={list} alt="" className="w-100" />
                                </a>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() =>
                                  this.removeUploadedFile(list, "image")
                                }
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>

                    <Dialog
                      fullScreen
                      open={this.state.setOpen}
                      style={{
                        background: "#ffffff7a",
                        width: "60%",
                        margin: "auto",
                      }}
                      className={"videorecorderDialouge"}
                    >
                      <IconButton
                        style={{
                          background: "transparent",
                          position: "absolute",
                          zIndex: 5,
                        }}
                      >
                        <CloseIcon
                          className={"crossIcon"}
                          onClick={this.handleClose}
                        />
                      </IconButton>

                      {this.state.videoCount === 0 &&
                        this.state.isVideoStart && (
                          <div className="waitingtext">Wait.....</div>
                        )}

                      <VideoRecorder
                        className="videorecorderCls"
                        onRecordingComplete={(videoBlob) => {
                          // Do something with the video...
                          const url = URL.createObjectURL(videoBlob);
                          this.setState(
                            {
                              isVideoPause: false,
                              isVideoStart: false,
                              isVideoStop: false,
                            },
                            () => {
                              this.updateformdata(videoBlob, url);
                            }
                          );
                        }}
                        isFlipped={false}
                        style={{
                          margin: "auto",
                          borderRadius: "0px",
                        }}
                        renderActions={({
                          onStartRecording,
                          onStopRecording,
                          isCameraOn,
                          streamIsReady,
                          isRecording,
                          isVideoInputSupported,
                          isInlineRecordingSupported,
                          thereWasAnError,
                          isConnecting,
                          isRunningCountdown,
                          isReplayingVideo,
                          onTurnOnCamera,
                          onPauseRecording,
                          onResumeRecording,
                          countdownTime,
                          timeLimit,
                        }) => {
                          // console.log({ isReplayingVideo });
                          if (
                            (!isInlineRecordingSupported &&
                              !isVideoInputSupported) ||
                            thereWasAnError ||
                            isConnecting ||
                            isRunningCountdown ||
                            isReplayingVideo
                          ) {
                            return null;
                          }

                          return (
                            <div
                              style={{ position: "absolute", bottom: "10%" }}
                            >
                              {!isCameraOn && (
                                <button
                                  onClick={() => {
                                    this.setState(
                                      {
                                        videoCount: 0,
                                      },
                                      () => {
                                        onTurnOnCamera();
                                      }
                                    );
                                  }}
                                  className="btn btn-primary ml-2"
                                >
                                  Start Camera
                                </button>
                              )}

                              {isRecording && this.state.videoCount > 0 && (
                                <div className="timerVideoCount">
                                  {Math.round(this.state.videoCount)}
                                </div>
                              )}

                              {!this.state.isVideoStart && isCameraOn && (
                                <button
                                  onClick={() => {
                                    this.setState({
                                      isVideoStart: true,
                                    });
                                    if (isCameraOn && streamIsReady) {
                                      console.log("dshhj");
                                      this.setState(
                                        {
                                          isVideoStart: true,
                                        },
                                        () => {
                                          setTimeout(() => {
                                            this.start1();
                                          }, 3000);

                                          onStartRecording();
                                        }
                                      );
                                    }
                                  }}
                                  className="btn btn-success ml-2"
                                >
                                  Start Record
                                </button>
                              )}

                              {!this.state.isVideoPause &&
                                isCameraOn &&
                                this.state.isVideoStart &&
                                isRecording && (
                                  <button
                                    onClick={() => {
                                      this.setState(
                                        {
                                          isVideoPause: true,
                                        },
                                        () => {
                                          this.stop1();
                                          onPauseRecording();
                                        }
                                      );
                                    }}
                                    className="btn btn-info ml-2"
                                  >
                                    Pause
                                  </button>
                                )}

                              {this.state.isVideoPause &&
                                isCameraOn &&
                                this.state.isVideoStart &&
                                isRecording && (
                                  <button
                                    onClick={() => {
                                      this.setState(
                                        {
                                          isVideoPause: false,
                                        },
                                        () => {
                                          this.start1();
                                          onResumeRecording();
                                        }
                                      );
                                    }}
                                    className="btn btn-info ml-2"
                                  >
                                    Resume
                                  </button>
                                )}

                              {this.state.isVideoStart &&
                                isCameraOn &&
                                isRecording && (
                                  <button
                                    onClick={() => {
                                      this.setState(
                                        {
                                          videoCount: 0,
                                        },
                                        () => {
                                          this.stop1();
                                          onStopRecording();
                                        }
                                      );
                                    }}
                                    className="btn btn-danger ml-2"
                                  >
                                    Stop
                                  </button>
                                )}
                            </div>
                          );
                        }}
                      />
                    </Dialog>

                    <Dialog
                      open={this.state.audioOpen}
                      style={{ background: "#ffffff7a" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <IconButton style={{ background: "transparent" }}>
                          <CloseIcon
                            className={"crossIcon"}
                            onClick={this.handleCloseAudiorecorder}
                          />
                        </IconButton>
                        {!this.state.isUpload && (
                          <span
                            style={{
                              background: "#eeecec",
                              padding: "0px 30px",
                              fontSize: "2rem",
                            }}
                          >
                            {this.state.audioCount}
                          </span>
                        )}
                      </div>

                      <div style={{ textAlign: "center", padding: "" }}>
                        <div style={{ background: "#c8c8c8" }}>
                          <AudioReactRecorder
                            state={this.state.recordState}
                            onStop={this.onStop}
                            foregroundColor={"#eee"}
                            type="audio/wav"
                          />
                        </div>

                        {this.state.isUpload && !this.state.isPause ? (
                          <audio
                            id="video"
                            src={this.state.recordAudio}
                            classname="w-100"
                            controls
                            style={{ width: "100%", marginTop: "10px" }}
                          ></audio>
                        ) : (
                          ""
                        )}
                        {!this.state.isPause && (
                          <button
                            class="btn btn-primary waves-effect waves-light"
                            type="submit"
                            name="action"
                            onClick={this.start}
                            disabled={this.state.isPause ? true : false}
                            style={{ margin: "5px" }}
                          >
                            Start
                          </button>
                        )}
                        {this.state.isPause && (
                          <button
                            class="btn btn-primary waves-effect waves-light"
                            onClick={this.pause}
                            disabled={this.state.isPause ? false : true}
                            style={{ margin: "5px" }}
                          >
                            Pause
                          </button>
                        )}

                        <button
                          class="btn btn-primary waves-effect waves-light"
                          onClick={this.stop}
                          disabled={this.state.isStop ? false : true}
                          style={{ margin: "5px" }}
                        >
                          Stop
                        </button>
                        <button
                          class="btn btn-primary waves-effect waves-light"
                          onClick={this.handleAudioUpload}
                          disabled={this.state.isUpload ? false : true}
                          style={{ margin: "5px" }}
                        >
                          Upload
                        </button>
                      </div>

                      {/* <Recorder
                        record={true}
                        title={"Please record your audio and then upload"}
                        audioURL={this.state.audioDetails.url}
                        showUIAudio
                        handleAudioStop={(data) => this.handleAudioStop(data)}
                        startRecording={(data) => {
                          alert("sss");
                        }}
                        handleOnChange={(value) => {
                          alert("test");
                        }}
                        handleAudioUpload={(data) => {
                          if (data) {
                            this.handleAudioUpload(data);
                          } else if (!this.state.audioDetails.url) {
                            this.setState({
                              audioOpen: false,
                            });
                            var THIS = this;
                            confirmAlert({
                              title: "Alert",
                              message: "Please start or stop your recording",
                              buttons: [
                                {
                                  label: "OK",
                                  onClick: () => {
                                    THIS.setState({
                                      audioOpen: true,
                                    });
                                  },
                                },
                              ],
                              overlayClassName: "overlay-custom-class-name",
                            });
                          } else {
                            confirmAlert({
                              title: "Alert",
                              message: "Please start or stop your recording",
                              buttons: [
                                {
                                  label: "OK",
                                  onClick: () => {
                                    THIS.setState({
                                      audioOpen: true,
                                    });
                                  },
                                },
                              ],
                              overlayClassName: "overlay-custom-class-name",
                            });
                          }
                        }}
                        handleopenAudiorecorder={() => {
                          alert("cfhsfhs");
                        }}
                        handleRest={() => this.handleRest()}
                        className={"recorderModal"}
                        style={{ width: "80%", marginBottom: "10px" }}
                      /> */}
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMemories);
