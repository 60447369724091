import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import UserIcon from "../assets/images/icon21.svg";
import EventIcon from "../assets/images/icon22.svg";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import UploadURLS from "../assets/settings";

class PrimaryUserListFromEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      eventDetails: "",
      userListList: [],
      isLoading: true,

      isDisabledSubmit: false,
      isSubmitForm: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      isRedirect: false,
      isrequestmemoryopenmodal: false,
      currentEventDetails: "",
      canUpdateEvent: false,
      formData: {
        title: "",
        recuringEvent: "",
        eventDay: "",
        eventMonth: "",
        eventYear: "",
        requestMemory: true,
        dateUnknown: false,
        user_id: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        /* FETCH ALL CONTACT INFO */
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_SUCCESS:
          var eventDetails =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";

          if (eventDetails) {
            props.contactActions.clearState();
            return {
              eventDetails: eventDetails,
              isLoading: false,
            };
          } else {
            props.contactActions.clearState();
            return {
              eventDetails: "",
              isLoading: false,
            };
          }

          break;
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_FAILED:
          return {
            eventDetails: "",
            isLoading: false,
          };
          break;

        /* Fetch memory */
        case contactActions.FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_SUCCESS:
          var userList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];

          props.contactActions.clearState();
          return {
            userListList: userList,
          };
          break;
        case contactActions.FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_FAILED:
          props.contactActions.clearState();
          return {
            userListList: [],
          };
        case contactActions.CREATE_NEW_EVENT_SUCCESS:
          eventDetails =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";

          if (eventDetails) {
            props.contactActions.clearState();

            return {
              //eventDetails: eventDetails,
              isLoading: false,
              apiSuccessMessage: contactReducerData.responce.data.message,
              isrequestmemoryopenmodal:false
            };
          } else {
            props.contactActions.clearState();
            return {
             ///eventDetails: "",
              isLoading: false,
            };
          }

        case contactActions.CREATE_NEW_EVENT_FAILED:
          props.contactActions.clearState();
          //toast.error(contactReducerData.responce.data.message)
          return {
            eventDetails: "",
            isLoading: false,
            apiErrorMessage: contactReducerData.responce.data.message,
          };

        default:
          return;
      }
    }
    return {};
  }

  componentDidMount() {
    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null
    ) {
      this.props.history.push("/");
    } else {
      var currentEventId =
        this.props.match !== undefined &&
        this.props.match.params !== undefined &&
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "";

      if (
        currentEventId === "" ||
        currentEventId === null ||
        currentEventId === undefined
      ) {
        this.props.history.push("/");
      } else {
        this.props.contactActions.fetchSingleEventDetails(currentEventId);
        this.props.contactActions.fetchPrimaryUserListWithMemoryFromEventId(
          currentEventId
        );
      }
    }
  }

  componentDidUpdate() {
    if (this.state.apiErrorMessage) {
      toast.error(this.state.apiErrorMessage);
      this.setState({
        apiErrorMessage: "",
      });
      this.setState({ loading: false });
    } else if (this.state.apiSuccessMessage) {
      toast.success(this.state.apiSuccessMessage);
      this.setState({
        apiSuccessMessage: "",
      });
    }
  }

  handleOpenRequestMemoryModal = (list) => {
    console.log(list);
    if (
      list.eventDay !== null &&
      list.eventDay !== undefined &&
      list.eventDay !== "" &&
      list.eventMonth !== undefined &&
      list.eventMonth !== null &&
      list.eventMonth !== "" &&
      list.eventYear !== "" &&
      list.eventYear !== undefined &&
      list.eventYear !== null
    ) {
      let formData = this.state.formData;
      formData["eventDay"] = list.eventDay;
      formData["eventMonth"] = list.eventMonth;
      formData["eventYear"] = list.eventYear;
      formData["recuringEvent"] = list.recuringEvent;
      formData["title"] = list.title;
      formData["user_id"] = list.user_id?._id;
      this.setState({
        formData: formData,
        canUpdateEvent: false,
      });
    } else {
      let formData = this.state.formData;
      formData["eventDay"] = "";
      formData["eventMonth"] = "";
      formData["eventYear"] = "";
      formData["title"] = list.title;
      formData["user_id"] = list.user_id?._id;
      this.setState({
        formData: formData,
        canUpdateEvent: true,
      });
    }
    this.setState({
      isrequestmemoryopenmodal: true,
      currentEventDetails: list,
    });
  };

  handleCloseRequestMemoryModal = () => {
    this.setState({
      isrequestmemoryopenmodal: false,
    });
  };

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    if (fieldName === "eventMonth") {
      if (fieldValue === "2") {
        if (formData.eventDay > 29) {
          formData["eventDay"] = 29;
        } else if (formData.eventDay < 1) {
          formData["eventDay"] = 1;
        }
      }
    }

    if (fieldName === "recuringEvent" && fieldValue === "true") {
      fieldValue = true;
    } else if (fieldName === "recuringEvent" && fieldValue === "false") {
      fieldValue = false;
    }

    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
      isDisabledSubmit: false,
    });
  }

  handleSubmit = () => {
    let formData = this.state.formData;
    // this.setState({
    //   formData: formData,
    // });

    if (
      this.state.formData.title === "" ||
      this.state.formData.title === null ||
      this.state.formData.title === undefined
    ) {
      toast.error("Please select an event");
    } else if (
      (this.state.canUpdateEvent && this.state.formData.recuringEvent === "") ||
      this.state.formData.recuringEvent === undefined ||
      this.state.formData.recuringEvent === null
    ) {
      toast.error("Please select 'Is this a recurring event or not'");
    } else if (
      (this.state.canUpdateEvent && this.state.formData.eventDay === "") ||
      this.state.formData.eventDay === null ||
      this.state.formData.eventDay === undefined
    ) {
      toast.error("Please enter event date");
    } else if (
      (this.state.canUpdateEvent && this.state.formData.eventMonth === "") ||
      this.state.formData.eventMonth === null ||
      this.state.formData.eventMonth === undefined
    ) {
      toast.error("Please select an event month");
    } else if (
      (this.state.canUpdateEvent && this.state.formData.eventYear === "") ||
      this.state.formData.eventYear === null ||
      this.state.formData.eventYear === undefined
    ) {
      toast.error("Please enter event year");
    } else if (
      !moment(
        `${this.state.formData.eventYear}-${this.state.formData.eventMonth}-${this.state.formData.eventDay}`
      ).isValid()
    ) {
      toast.error("Please change event day");
    } else {
      let formData = this.state.formData;
      formData.requestMemory = true;
      formData.dateUnknown = false;
      this.setState({
        formData: formData,
        //isSubmitForm: true,
      });

      console.log(this.state.formData);
      this.props.contactActions.createPrimaryNewEvent(this.state.formData);
    }
  };

  render() {
    var formData = this.state.formData;
    var eventDetails = this.state.eventDetails;
    var isLoading = this.state.isLoading;
    var userListList = this.state.userListList;
    //Year for loop
    let d = new Date();
    let rows = [];
    for (let i = 1900; i <= d.getFullYear(); i++) {
      rows.push(i);
    }

    return (
      <>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          {userListList !== undefined &&
            userListList !== null &&
            userListList !== "" &&
            userListList.length > 0 &&
            isLoading === false && (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="card border-0 border-radius-10 min-vh-75 overflow-hidden">
                    <div className="card-header pad-x-20 pad-x-md-46 pad-y-15 bg-white">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                          <i className="material-icons text-color1 align-middle fs-30">
                            event
                          </i>{" "}
                          <span
                            className="pl-2"
                            style={{ overflowWrap: "anywhere" }}
                          >
                            {eventDetails.title !== undefined &&
                            eventDetails.title !== null &&
                            eventDetails.title !== ""
                              ? eventDetails.title
                              : ""}
                          </span>
                        </h3>

                        <div className="position-relative"></div>
                      </div>
                    </div>
                    <div className="card-body pad-x-20 pad-x-md-46 pad-y-25 ">
                      <div className="row">
                        {this.state.userListList &&
                          this.state.userListList.length > 0 &&
                          this.state.userListList.map((list, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                              <div className="shadow-sm px-4 py-3 border rounded">
                                <div className="d-flex align-items-center  justify-content-between mb-3">
                                  <div>
                                    <img
                                      src={
                                        list.user_id !== undefined &&
                                        list.user_id.profile_image !==
                                          undefined &&
                                        list.user_id.profile_image !== null &&
                                        list.user_id.profile_image !== ""
                                          ? UploadURLS.contact_profile_pic +
                                            list.user_id.profile_image
                                          : UserIcon
                                      }
                                      className="object-fit-cover w-55px h-55px  rounded-circle"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="fs-20 fs-xl-26 fs-xxxl-36 text-black opa-87 text-left">
                                  {list.user_id !== undefined &&
                                  list.user_id.first_name !== undefined
                                    ? list.user_id.first_name
                                    : ""}{" "}
                                  {list.user_id !== undefined &&
                                  list.user_id.last_name !== undefined
                                    ? list.user_id.last_name
                                    : ""}
                                </div>
                                <div className="fs-14 fs-xl-18 text-black opa-60 text-left mb-4">
                                  {list.user_id !== undefined &&
                                  list.user_id.email !== undefined
                                    ? list.user_id.email
                                    : ""}
                                </div>

                                <div className="d-flex justify-content-between">
                                  <Button
                                    className="border text-color1"
                                    onClick={(e) =>
                                      this.props.history.push(
                                        "/primary-user-memory-shared-list/" +
                                          list._id +
                                          "/" +
                                          list.user_contact_id
                                      )
                                    }
                                  >
                                    <i className="material-icons mr-1">mms</i>{" "}
                                    View memories
                                  </Button>

                                  <Button
                                    className="border text-color"
                                    onClick={() =>
                                      this.handleOpenRequestMemoryModal(list)
                                    }
                                  >
                                    Request A Memory
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {(userListList === undefined ||
            userListList === null ||
            userListList === "" ||
            userListList.length === 0) &&
            isLoading === false && (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    Sorry, no data found
                  </div>
                </div>
              </div>
            )}
        </div>

        <Modal
          className={"d-flex align-items-center"}
          open={this.state.isrequestmemoryopenmodal}
          onClose={this.handleCloseRequestMemoryModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="myCusTomModal d-flex">
            <div className="card   ">
              <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 ">
                  <i className="material-icons text-color1 align-middle">
                    event
                  </i>{" "}
                  Request a memory
                </h3>
              </div>
              <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  1. For which event are you requesting this memory?
                </h3>
                <div className="row">
                  <div className="mar-b-40 col-md-12">
                    <FormControl variant="outlined" className="w-100 bg-white">
                      <TextField
                        value={
                          eventDetails.title !== undefined &&
                          eventDetails.title !== null &&
                          eventDetails.title !== ""
                            ? eventDetails.title
                            : ""
                        }
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </div>
                {this.state.canUpdateEvent && (
                  <>
                    <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                      2. Is this a recurring event?
                    </h3>
                    <div className="mar-b-40 ">
                      <RadioGroup
                        name="recuringEvent"
                        onChange={this.handleChange}
                        value={
                          formData.recuringEvent !== undefined
                            ? formData.recuringEvent
                            : ""
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes, this event recurs every year"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No, this event occurs for once"
                        />
                      </RadioGroup>
                    </div>
                  </>
                )}
                {this.state.canUpdateEvent && (
                  <>
                    <div className="d-md-flex justify-content-between mar-b-22 align-items-center">
                      <h3 className="text-black mb-0 fs-18 font-weight-Regular">
                        3. Event date
                      </h3>
                    </div>

                    <div className="row">
                      <div className="mar-b-40 col-md-4">
                        <FormControl
                          variant="outlined"
                          className="w-100 bg-white"
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Month
                          </InputLabel>

                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Month"
                            onChange={this.handleChange}
                            name="eventMonth"
                            value={
                              formData.eventMonth !== undefined
                                ? formData.eventMonth
                                : ""
                            }
                          >
                            <MenuItem value="1">January</MenuItem>
                            <MenuItem value="2">February</MenuItem>
                            <MenuItem value="3">March</MenuItem>
                            <MenuItem value="4">April</MenuItem>
                            <MenuItem value="5">May</MenuItem>
                            <MenuItem value="6">June</MenuItem>
                            <MenuItem value="7">July</MenuItem>
                            <MenuItem value="8">August</MenuItem>
                            <MenuItem value="9">September</MenuItem>
                            <MenuItem value="10">October</MenuItem>
                            <MenuItem value="11">November</MenuItem>
                            <MenuItem value="12">December</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="mar-b-40 col-md-4">
                        <TextField
                          type="number"
                          label="Day"
                          name="eventDay"
                          value={
                            formData.eventDay !== undefined
                              ? formData.eventDay
                              : ""
                          }
                          onChange={this.handleChange}
                          variant="outlined"
                          className="w-100"
                          onInput={(e) => {
                            var value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 2);
                            if (formData.eventMonth === "2") {
                              if (value > 29) {
                                value = 29;
                              } else if (value < 1) {
                                value = 1;
                              }
                            } else if (value > 31) {
                              value = 31;
                            } else if (value < 1) {
                              value = 1;
                            }
                            e.target.value = value;
                          }}
                        />
                      </div>
                      <div className="mar-b-40 col-md-4">
                        <FormControl
                          variant="outlined"
                          className="w-100 bg-white"
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Year
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Year"
                            onChange={this.handleChange}
                            name="eventYear"
                            value={
                              formData.eventYear !== undefined
                                ? formData.eventYear
                                : ""
                            }
                          >
                            {rows.map((value, index) => {
                              return (
                                <MenuItem value={value} key={index}>
                                  {value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="card-footer bg-white text-right pad-x-40 ">
                {this.state.isSubmitForm === true && <CircularProgress />}

                {this.state.isSubmitForm === false && (
                  <>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize"
                      onClick={this.handleCloseRequestMemoryModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize background_color"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrimaryUserListFromEvent);
