import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";
import Icon from "../assets/images/icon7.svg";
import TextField from "@material-ui/core/TextField";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";

class SecondaryUserNoOfContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfContacts: "",
      noOfContacts_err: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.miscActions.getProfileDetails();
    var currentUserRole = "";
    var no_of_contacts = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");

    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }

    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
      no_of_contacts = user_info.no_of_contacts;
    }

    // if (auth_token === "" || auth_token === undefined || auth_token === null || currentUserRole === "" || currentUserRole === undefined || currentUserRole === null || currentUserRole !=="sub-user" || (no_of_contacts !== "" && no_of_contacts !== null && no_of_contacts !== undefined)) {
    //   this.props.history.push("/");
    // }
  }

  handleChange(event) {
    // let fieldValue = event.target.value;
    // this.setState({
    //     noOfContacts : fieldValue
    // })
    let fieldValue = event.target.value;
    var reg = new RegExp("^[0-9]");
    if (fieldValue < 1 || !reg.test(fieldValue)) {
      this.setState({
        noOfContacts_err: "Atleast 1 contact required",
        noOfContacts: "",
      });
    } else {
      this.setState({
        noOfContacts: event.target.value,
        noOfContacts_err: "",
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.GET_PROFILE_DETAILS_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";

          props.miscActions.clearState();
          localStorage.setItem("user_info", JSON.stringify(userInfo));
          return {
            noOfContacts: userInfo?.no_of_contacts,
          };

        case miscActions.USER_PROFILE_UPDATE_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";

          props.miscActions.clearState();
          localStorage.setItem("user_info", JSON.stringify(userInfo));
          //props.history.push("/user-plan");
          props.history.push("/sec-user-event-care-about");

          return {};
          break;
      }
    }

    return {};
  }

  handleSubmit(event) {
    event.preventDefault();

    if (
      this.state.noOfContacts === "" ||
      this.state.noOfContacts === undefined ||
      this.state.noOfContacts === null ||
      this.state.noOfContacts <= 0
    ) {
      toast.error("Please select number of contacts limit");
    } else {
      var formData = new FormData();
      formData.append("no_of_contacts", this.state.noOfContacts);
      this.props.miscActions.userProfileUpdate(formData);
    }
  }

  render() {
    return (
      <>
        <div className="card bg-transparent border-0 vh-100 overflow-auto">
          <PrimaryHeader
            position={"innerHeader"}
            otherMenuNotVisible={"stepTwo"}
          />
          <section className=" registerStepWrap h-100 overflow-auto bluebgsection">
            <div className="container  ">
              <div className="row">
                <div className="card border-0  mx-auto">
                  <div className=" ">
                    <div className="iconBox mar-b-15 mar-b-md-35">
                      <img src={Icon} alt="" className="w-50px w-md-96px" />
                    </div>
                    <div className="fs-22 fs-md-36 font-weight-Bold  ">
                      Number of Contacts
                    </div>
                    <div className="subHead fs-14 fs-md-18 mar-b-30">
                      How many people would you like to share memories with?
                    </div>
                    <div className="info">
                      Don’t worry! You can add more people,
                      <br />
                      later on.
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      {/* <div className="pad-t-30">
                                                <FormControl variant="outlined" className={'w-100'}>
                                                    <InputLabel id="demo-simple-select-label">Select your limit</InputLabel>
                                                    <Select labelId="demo-simple-select-label" label="Select your limit" onChange={this.handleChange}>
                                                        <MenuItem value={5}>Less than 5 people</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div> */}

                      <div className="pad-t-30">
                        {/* <h3 className="text-black col-md-12 mb-0 fs-18 font-weight-Regular">
                          Please add contact limit *
                        </h3> */}
                        <div className="col-md-12 pt-3">
                          <TextField
                            type="number"
                            value={this.state.noOfContacts}
                            inputProps={{ inputMode: "numeric" }}
                            variant="outlined"
                            className="w-100"
                            onChange={this.handleChange}
                            error={Boolean(this.state.noOfContacts_err)}
                            helperText={this.state.noOfContacts_err}
                            required
                            label={'Select number'}
                          />
                        </div>
                      </div>
                      <div className="w-100 pad-t-50">
                        <Button
                          type="submit"
                          className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                        >
                          Next
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryUserNoOfContacts);
