import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomePage from "./PagesModule/HomePage";
import RegisterFinalPage from "./PagesModule/RegisterFinalPage";
import RegisterPage from "./PagesModule/RegisterPage";
import RegisterStepFive from "./PagesModule/RegisterStepFive";
import RegisterStepFour from "./PagesModule/RegisterStepFour";
import RegisterStepThreePage from "./PagesModule/RegisterStepThreePage";
import RegisterStepTwoPage from "./PagesModule/RegisterStepTwoPage";
import Dashboard from "./PagesModule/Dashboard";
import ViewContact from "./PagesModule/ViewContact";
import ViewEvent from "./PagesModule/ViewEvent";
import CreateMemory from "./PagesModule/CreateMemory";
import ViewMemory from "./PagesModule/ViewMemory";
import EditMemories from "./PagesModule/EditMemories";
import SettingsPage from "./PagesModule/settingsPage";
import UserPlan from "./PagesModule/userPlan";
import HelpCenterPage from "./PagesModule/HelpCenterPage";
import NotificationPage from "./PagesModule/NotificationPage";
import SignInPage from "./PagesModule/SignInPage";
import DashboardAfter from "./PagesModule/AfterLoginRegister/DashboardAfter";
import Footer from "./Widgets/PrimaryFooter";

/* Primary user list form event id */
import PrimaryUserListFromEvent from "./PagesModule/PrimaryUserListFromEvent";
import PrimaryUserMemorySharedList from "./PagesModule/PrimaryUserMemorySharedList";

/* Static page */
import TermsOfUse from "./PagesModule/TermsOfUse";
import PrivacyPolicy from "./PagesModule/PrivacyPolicy";
import ShareTheFuture from "./PagesModule/ShareTheFuture";
import AnyoneAnytime from "./PagesModule/AnyoneAnytime";

/* Secondary User */
import SecondaryUserRegistration from "./PagesModule/SecondaryUserRegistration";
import SecondaryUserRegStepTwo from "./PagesModule/SecondaryUserRegStepTwo";
import SecondaryUserStepThree from "./PagesModule/SecondaryUserStepThree";
import SecondaryUserDashboard from "./PagesModule/SecondaryUserDashboard";
import SecondaryUserMemoryList from "./PagesModule/SecondaryUserMemoryList";

import SecondaryUserNoOfContacts from "./PagesModule/SecondaryUserNoOfContacts";
import SecondaryUserEventCareAbout from "./PagesModule/SecondaryUserEventCareAbout";

import SecondaryUserViewMemory from "./PagesModule/SecondaryUserViewMemory";

import PaypalPaymentConfirmation from "./PagesModule/PaypalPaymentConfirmation";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { stripe_publishable_key } from "./api";
import NavigatorOnline from "react-navigator-online";
import { useState } from "react";

const stripePromise = loadStripe(stripe_publishable_key, {
  apiVersion: "2020-08-27",
});

function App() {
  const [isOffline, setIsOffline] = useState(2);
  /* get query string token value */
  var name = "token";
  var url = window.location.href; // current location
  var path = window.location.pathname; // current path
  var currentPage = path.split("/").pop(); // current page name

  if (
    currentPage !== undefined ||
    currentPage !== null ||
    (currentPage !== "" && currentPage === "sign-up")
  ) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);

    if (
      results !== null &&
      results !== undefined &&
      results !== "" &&
      results[2] !== null &&
      results[2] !== undefined &&
      results[2] !== ""
    ) {
      var tokenValue = decodeURIComponent(results[2].replace(/\+/g, " ")); // token value
      if (
        tokenValue !== undefined &&
        tokenValue !== null &&
        tokenValue !== ""
      ) {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('user_info')
        localStorage.setItem("secondary_user_token", tokenValue);

        //window.location.href = "/sign-up-secondary-user";

      }
    }
  }

  const showMessage = (status) => {
    if (status) {
      setIsOffline(1);
    } else {
      setIsOffline(0);
    }
    setTimeout(() => {
      setIsOffline(2);
    }, 2000);
  };

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/sign-in" component={SignInPage} />
          <Route exact path="/sign-up" component={RegisterPage} />
          <Route
            exact
            path="/sign-up-step-two"
            component={RegisterStepTwoPage}
          />
          <Route
            exact
            path="/sign-up-step-three"
            component={RegisterStepThreePage}
          />
          <Route exact path="/sign-up-step-four" component={RegisterStepFour} />
          <Route exact path="/sign-up-step-five" component={RegisterStepFive} />
          <Route
            exact
            path="/sign-up-final-step"
            component={RegisterFinalPage}
          />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/view-contact/:id" component={ViewContact} />
          <Route exact path="/view-event/:id" component={ViewEvent} />
          <Route exact path="/create-memory/:id" component={CreateMemory} />
          <Route exact path="/view-memory/:id" component={ViewMemory} />
          <Route exact path="/edit-memory/:id" component={EditMemories} />
          <Route
            exact
            path="/primary-userlist-from-event/:id"
            component={PrimaryUserListFromEvent}
          />
          <Route
            exact
            path="/primary-user-memory-shared-list/:eventId/:contactId"
            component={PrimaryUserMemorySharedList}
          />
          { /* User Profile Update */}
          <Route exact path="/settings" component={SettingsPage} />
          <Route exact path="/user-plan" component={UserPlan} />
          <Route exact path="/help" component={HelpCenterPage} />
          <Route exact path="/notification" component={NotificationPage} />
          {/*<Route exact path="/dashboard-after" component={DashboardAfter} />*/}
          {/* Static Page */}
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/share-the-future" component={ShareTheFuture} />
          <Route exact path="/anyone-anytime" component={AnyoneAnytime} />
          {/* Secondary User */}
          <Route
            exact
            path="/sign-up-secondary-user"
            component={SecondaryUserRegistration}
          />
          <Route
            exact
            path="/sign-up-secondary-user-step-two"
            component={SecondaryUserRegStepTwo}
          />
          <Route
            exact
            path="/secondary-user-step-three"
            component={SecondaryUserStepThree}
          />
          <Route
            exact
            path="/secondary-user-dashboard"
            component={SecondaryUserDashboard}
          />
          <Route
            exact
            path="/secondary-user-memorylist/:id"
            component={SecondaryUserMemoryList}
          />
          <Route
            exact
            path="/sec-user-no-of-contacts/"
            component={SecondaryUserNoOfContacts}
          />
          <Route
            exact
            path="/sec-user-event-care-about/"
            component={SecondaryUserEventCareAbout}
          />
          <Route
            exact
            path="/secondary-user-view-memory/:id"
            component={SecondaryUserViewMemory}
          />
          {/* /* Paypal Confirmation page */}
          <Route
            exact
            path="/paypal-payment-confirmation/"
            component={PaypalPaymentConfirmation}
          />
        </Switch>
      </Router>
      {/* <Footer /> */}

      <NavigatorOnline
        onChange={(status) => {
          showMessage(status);
        }}
      />
      {isOffline === 0 ? (
        <div className="offlineCls">No connection</div>
      ) : isOffline === 1 ? (
        <div className="offlineCls">Connection back</div>
      ) : (
        ""
      )}
      {/* <div className="offlineCls">No connection</div> */}
      <ToastContainer />
    </Elements>
  );
}

export default App;
