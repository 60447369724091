import React, { Component } from 'react'
import PrimaryHeader from '../Widgets/PrimaryHeader'
import bg from '../assets/images/registerBg.png'
import icon24 from '../assets/images/icon24.svg'
import { Button } from '@material-ui/core'
import PlayBtn from '../assets/images/icon23.svg';
import Image from '../assets/images/img6.png';

import CircularProgress from '@material-ui/core/CircularProgress';

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as miscActions from '../redux/actions/miscActions';

import UploadURLS from '../assets/settings.js';
import { Helmet } from 'react-helmet'

class TermsOfUse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: 'panel0',
            cmsInfo : "",
            faqList : [],
            isLoading : false
        }
    }

    componentDidMount(){
        /* fetch help information */
        this.setState({isLoading : true});
        this.props.miscActions.fetchCmsData("terms");
    }

    static getDerivedStateFromProps(props, state) {
        if (Object.keys(props.miscReducerData).length > 0) {
            let { miscReducerData } = props;
            switch (miscReducerData.type) {
                case miscActions.FETCH_CMS_DATA_SUCCESS:
                    var cmsInfo = miscReducerData !== undefined && miscReducerData.responce !== undefined && miscReducerData.responce.data !== undefined && miscReducerData.responce.data.data !== undefined ? miscReducerData.responce.data.data : '';
                    props.miscActions.clearState();
                    return {
                        isLoading : false,
                        cmsInfo : cmsInfo,
                    };
                    break;

                case miscActions.FETCH_CMS_DATA_FAILED:
                    props.miscActions.clearState();
                    return {
                        isLoading : false,
                        cmsInfo : "",
                    };
                    break;

            }
        }

        return {};
    }




    handleChange = (panel) => (event, newExpanded) => {
        this.setState({ expanded: panel });
    };

    render() {
        var cmsInfo = this.state.cmsInfo;
        var faqList = this.state.faqList;
        if(this.state.isLoading === true){
            return (
                <div className="page-center"><CircularProgress /></div>
            )
        }else{
            return (
                <>
                <Helmet>
                    <title>Forget Me Not - Terms of Use</title>
                </Helmet>
                    <div className="d-flex flex-column vh-100 helpCenterWrap">
                        <div className="w-100">
                            <PrimaryHeader position={'innerHeader'} />
                        </div>
                        <div className="h-100 pt-5 overflow-auto" style={{ backgroundImage: 'url(' + bg + ')', backgroundPosition: 'center', backgroundSize:'cover' }}>
                            <div className=" col-11 col-lg-8 col-xxl-6 mx-auto pt-5">
                                <div className="card ">
                                    <div className="text-center py-4 my-3">
                                        <h3 className="fs-24 fs-md-36 text-black m-0 py-3">{cmsInfo.title !== undefined && cmsInfo.title !== null && cmsInfo.title !== "" ? cmsInfo.title : "Terms Of Use"}</h3>
                                    </div>
                                    <div className="  pt-3  py-xxxl-4  text-center  " style={{ background: '#F6F6F6' }}>
                                        <div className="fs-18 fs-md-20  fs-xxxl-24 opa-60 text-black px-3 px-md-4" dangerouslySetInnerHTML={{ __html: cmsInfo.content !== undefined && cmsInfo.content !== null && cmsInfo.content !== "" ? cmsInfo.content : "" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
      miscReducerData : state.miscReducer
    }
}

const mapDispatchToProps = dispatch => ({
 miscActions: bindActionCreators(miscActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUse);
