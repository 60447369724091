import { CLEAR_STATE, FETCH_VIEWMEMORY_FAILED, FETCH_VIEWMEMORY_REQUEST, FETCH_VIEWMEMORY_SUCCESS } from "../actions/memoryActions";
const intialState = {
    pending: false,
    step: "",
};

const memoryReducer = (state = intialState, action) => {
    switch (action.type) {
        /* FETCH PARENT USER LIST  */
        case FETCH_VIEWMEMORY_REQUEST:
            return { type: FETCH_VIEWMEMORY_REQUEST, pending: true };
        case FETCH_VIEWMEMORY_SUCCESS:
            return { type: FETCH_VIEWMEMORY_SUCCESS, responce: action.data, pending: false };
        case FETCH_VIEWMEMORY_FAILED:
            return { type: FETCH_VIEWMEMORY_FAILED, responce: action.data, pending: false };
        case CLEAR_STATE:
            return {};
        default:
            return {}
    }
}
export default memoryReducer;