import React, { Component, Fragment } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import Icon from "../assets/images/icon11.svg";
import card1 from "../assets/images/card1.svg";
import card2 from "../assets/images/card2.svg";
import card3 from "../assets/images/card3.svg";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";

import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import Modal from "@material-ui/core/Modal";
import pusherChannel from "../assets/pusherSettings.js";

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import { confirmAlert } from "react-confirm-alert";

class RegisterFinalPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        card_holder_firstName: "",
        card_holder_lastName: "",
        card_number: "",
        exp_date_month: new Date(),
        exp_month: "",
        exp_year: "",
        cvv: "",
      },
      isSubmit: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      is_redirect_to_url: "",
    };

    /* Bind the function */
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");
    let registration_current_step = localStorage.getItem(
      "registration_current_step"
    );
    if (parseInt(registration_current_step) !== 6) {
      this.props.history.push("/sign-up");
    } else {
      if (
        auth_token === "" ||
        auth_token === undefined ||
        auth_token === null
      ) {
      } else {
        this.props.history.push("/");
      }
    }

    pusherChannel.bind("card-payment-status", (data) => {
      var user_info1 = localStorage.getItem("user_info");
      console.log("cardPayment", data);
      console.log(
        "userinfo",
        data?.data?.user_id +
          "-------" +
          JSON.parse(user_info1)?._id +
          "---------" +
          user_info1
      );
      if (data?.data?.user_id === JSON.parse(user_info1)?._id) {
        this.setState({
          is_redirect_to_url: "",
        });
        //localStorage.clear();

        confirmAlert({
          title: data?.message,
          message: "",
          closeOnClickOutside: false,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.props.history.push("/dashboard");
              },
            },
          ],
        });
      }
    });
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.setState({
        apiErrorMessage: "",
        apiSuccessMessage: "",
      });
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.setState({
        apiErrorMessage: "",
        apiSuccessMessage: "",
      });
      localStorage.setItem("firstLogin", 1);

      /* Redirect to dashboard */
      if(this.state.is_redirect_to_url === ""){
        this.props.history.push("/dashboard");
      }
      //this.props.history.push("/dashboard");
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.USER_REGISTRATION_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          var token =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.token !== undefined
              ? miscReducerData.responce.data.token
              : "";
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";

          var is_redirect_to_url =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.redirect_to_url !== undefined
              ? miscReducerData.responce.data.redirect_to_url
              : "";

          props.miscActions.clearState();

          if (token !== "") {
            localStorage.setItem("auth_token", token);
            localStorage.setItem("user_info", JSON.stringify(userInfo));
            localStorage.removeItem("registration_info");
            localStorage.removeItem("registration_current_step");

            return {
              apiErrorMessage: "",
              apiSuccessMessage: message,
              isSubmit: false,
              is_redirect_to_url: is_redirect_to_url,
            };
          } else {
            return {
              apiErrorMessage: "Something went wrong",
              apiSuccessMessage: "",
              isSubmit: false,
            };
          }
          return {};
          break;
        case miscActions.USER_REGISTRATION_FAILED:
          props.miscActions.clearState();
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined &&
            miscReducerData.responce.data.message !== ""
              ? miscReducerData.responce.data.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmit: false,
          };
          break;
        case miscActions.USER_REGISTRATION_FAILED_CARD:
          props.miscActions.clearState();
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.message !== undefined &&
            miscReducerData.responce.message !== ""
              ? miscReducerData.responce.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmit: false,
          };
          break;
        default:
          return {};
      }
    }

    return {};
  }

  handleDateChange(date) {
    var formData = this.state.formData;
    formData["exp_date_month"] = date;
    this.setState({
      formData: formData,
    });
  }

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
    });
  }

  handleEmailChange = (event) => {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    const setValue = fieldValue.length <= 19 ? fieldValue : formData[fieldName];
    formData[fieldName] = setValue;
    this.setState({
      formData: formData,
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    //dashboard
    var formData = this.state.formData;

    if (this.state.isSubmit === false) {
      if (
        formData.card_holder_firstName === "" ||
        formData.card_holder_firstName === null ||
        formData.card_holder_firstName === undefined
      ) {
        toast.error("Please enter your first name");
      } else if (
        formData.card_holder_lastName === "" ||
        formData.card_holder_lastName === null ||
        formData.card_holder_lastName === undefined
      ) {
        toast.error("Please enter your last name");
      } else if (
        formData.card_number === "" ||
        formData.card_number === null ||
        formData.card_number === undefined
      ) {
        toast.error("Please enter your card number");
      } else if (
        formData.exp_date_month === "" ||
        formData.exp_date_month === null ||
        formData.exp_date_month === undefined
      ) {
        toast.error("Please enter your expiration date");
      } else if (
        formData.cvv === "" ||
        formData.cvv === null ||
        formData.cvv === undefined
      ) {
        toast.error("Please enter your cvv");
      } else {
        var formData = this.state.formData;
        var exp_date_month = formData.exp_date_month;
        var newdate = moment(exp_date_month)
          //.tz('America/New_York')
          .format("MM/YYYY");
        var res = newdate.split("/");
        var exp_month = "";
        var exp_year = "";
        if (res.length > 0) {
          exp_month = res[0];
          exp_year = res[1];
        }

        let registerData = localStorage.getItem("registration_info");
        if (
          registerData === "" ||
          registerData === undefined ||
          registerData === null
        ) {
          toast.error("Something went wrong! please try again");
          this.props.history.push("/sign-up");
        } else {
          let registerObj = JSON.parse(registerData);

          var event_memories_id = registerObj.event_memories_id;
          delete registerObj.event_memories_id;
          delete registerObj.confirm_password;

          registerObj["card_holder_firstName"] = formData.card_holder_firstName;
          registerObj["card_holder_lastName"] = formData.card_holder_lastName;
          registerObj["card_number"] = formData.card_number;
          registerObj["exp_month"] = exp_month;
          registerObj["exp_year"] = exp_year;
          registerObj["cvv"] = formData.cvv;
          registerObj["code"] = registerObj.coupon_code
            ? registerObj.coupon_code
            : "";

          var form_data = new FormData();

          for (var key in registerObj) {
            form_data.append(key, registerObj[key]);
          }

          if (event_memories_id.length > 0) {
            event_memories_id.forEach(function (value, indx) {
              form_data.append("event_memories_id[" + indx + "]", value);
            });
          }

          this.setState({ isSubmit: true });

          this.props.miscActions.userRegistration(form_data);
        }
      }
    }
  }

  render() {
    console.log("this.state.isSubmit", this.state.isSubmit);

    return (
      <>
        <Modal
          className={"d-flex align-items-center"}
          open={this.state.is_redirect_to_url !== "" ? true : false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {this.state.is_redirect_to_url !== "" && (
            <iframe
              src={this.state.is_redirect_to_url}
              height="100%"
              width="100%"
              title="hsdhha"
              style={{ background: "#fff" }}
            />
          )}
        </Modal>

        <div className="card bg-transparent border-0 vh-100 ">
          <PrimaryHeader position={"innerHeader"} />
          <section className=" registerStepWrap h-100 overflow-auto bluebgsection">
            <div className="container">
              <div className="row">
                <div className="card  pad-b-82 border-0  mx-auto">
                  <div className="head text-right mr-md-n4">
                    <h5 className="mb-0">Final step</h5>
                  </div>

                  <div className=" ">
                    <div className="iconBox mar-b-15 mar-b-md-35">
                      <img src={Icon} alt="" className="w-50px w-md-90px" />
                    </div>
                    <div className="fs-22 fs-md-36 font-weight-Bold  ">
                      Add Card Details
                    </div>
                    <div className="subHead fs-14 fs-md-18 mar-b-30">
                      Your membership starts as soon as you set up payment.
                    </div>
                    <div className="d-flex  pb-4">
                      <div className="px-2">
                        <img src={card1} alt="" />
                      </div>
                      <div className="px-2">
                        <img src={card2} alt="" />
                      </div>
                      <div className="px-2">
                        <img src={card3} alt="" />
                      </div>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 pt-4">
                          <TextField
                            label="First Name"
                            name="card_holder_firstName"
                            onChange={this.handleChange}
                            variant="outlined"
                            className="w-100"
                          />
                        </div>
                        <div className="col-md-6 pt-4">
                          <TextField
                            label="Last Name"
                            name="card_holder_lastName"
                            onChange={this.handleChange}
                            variant="outlined"
                            className="w-100"
                          />
                        </div>
                        <div className="col-md-12 pt-4">
                          <TextField
                            // onInput = {(e) =>{
                            //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,19)
                            // }}
                            // onInput={(e) => {
                            //   e.target.value = Math.max(
                            //     0,
                            //     parseInt(e.target.value)
                            //   )
                            //     .toString()
                            //     .slice(0, 19);
                            // }}
                            //type="number"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            type="number"
                            label="Card Number"
                            name="card_number"
                            onChange={this.handleEmailChange}
                            variant="outlined"
                            className="w-100"
                            size="10"
                            maxlength="2"
                            value={this.state.formData.card_number}
                          />
                        </div>
                        <div className="col-md-12 pt-4">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              variant="outlined"
                              inputVariant="outlined"
                              openTo="year"
                              views={["year", "month"]}
                              label="Expiration Date (MM/YYYY)"
                              value={
                                this.state.formData.exp_date_month === ""
                                  ? new Date()
                                  : this.state.formData.exp_date_month
                              }
                              format="MM/yyyy"
                              onChange={(date) => this.handleDateChange(date)}
                              className="w-100"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-12 pt-4">
                          <TextField
                            type="password"
                            /*onInput = {(e) =>{
                                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                                    }}*/
                            label="Security Code (CVV)"
                            name="cvv"
                            onChange={this.handleChange}
                            inputProps={{ inputMode: "numeric" }}
                            variant="outlined"
                            className="w-100"
                          />
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="col-3">
                          <Button
                            className="bg-color4 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                            onClick={() => {
                              localStorage.setItem(
                                "registration_current_step",
                                5
                              );
                              this.props.history.push("/sign-up-step-five");
                            }}
                          >
                            Back
                          </Button>
                        </div>
                        <div className="col-9 text-right">
                          <div>
                            {this.state.isSubmit === true && (
                              <CircularProgress />
                            )}
                            {this.state.isSubmit === false && (
                              <Button
                                type="submit"
                                className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                              >
                                Start Membership
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFinalPage);
