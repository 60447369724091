import React, { Component, useState, useEffect } from "react";

import { Button, Typography } from "@material-ui/core";
import Icon from "../assets/images/icon9.svg";
import payIco1 from "../assets/images/payIco1.svg";
import payIco2 from "../assets/images/payIco2.svg";
import payIco3 from "../assets/images/payIco3.svg";
import PrimaryHeader from "../Widgets/PrimaryHeader";

import card1 from "../assets/images/card1.svg";
import card2 from "../assets/images/card2.svg";
import card3 from "../assets/images/card3.svg";
import TextField from "@material-ui/core/TextField";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Modal from "@material-ui/core/Modal";
import CancelIcon from '@material-ui/icons/Cancel';


/* Stripe */
//import {Elements} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
//import {stripe_publishable_key} from "../api";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as paymentActions from "../redux/actions/paymentActions";

import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import pusherChannel from "../assets/pusherSettings.js";


const modal = "d-flex align-items-center";

//const stripePromise = loadStripe(stripe_publishable_key, {apiVersion: "2020-08-27"});

const anualPlanPrice = [
  { plan: "Free", price: 0, contact: 1, space: 0.5 },
  { plan: "Basic", price: 30.99, contact: 5, space: 3 },
  { plan: "Advanced", price: 70.99, contact: 15, space: 30 },
];

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const applePayInfo = props.applePayInfo;
  const clientSecret =
    applePayInfo !== undefined &&
      applePayInfo !== "" &&
      applePayInfo !== null &&
      applePayInfo.client_secret !== undefined &&
      applePayInfo.client_secret !== null &&
      applePayInfo.client_secret !== ""
      ? applePayInfo.client_secret
      : "";

  useEffect(() => {
    if (stripe) {
      var selected_plan_details = localStorage.getItem("selected_plan_details");
      selected_plan_details = JSON.parse(selected_plan_details);

      var amount =
        selected_plan_details.value !== undefined &&
          selected_plan_details.value !== null &&
          selected_plan_details.value !== ""
          ? selected_plan_details.value
          : 0.0;
      var stripAmount = Math.round(amount * 100);
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label:
            selected_plan_details.header !== undefined &&
              selected_plan_details.header !== null &&
              selected_plan_details.header !== ""
              ? selected_plan_details.header
              : "",
          amount: stripAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        // console.log("result", result);
        if (
          result &&
          result.applePay !== undefined &&
          result.applePay !== null &&
          result.applePay !== "" &&
          result.applePay !== false
        ) {
          //if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", async (ev) => {
      const {
        paymentIntent,
        error: confirmError,
      } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        ev.complete("fail");
      } else {
        ev.complete("success");
        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
          } else {
            console.log("succeeded1", paymentIntent);
            props.applePaySubmit(paymentIntent);
            // The payment has succeeded.
          }
        } else {
          console.log("succeeded2", paymentIntent);
          props.applePaySubmit(paymentIntent);
          // The payment has succeeded.
        }
      }
    });

    return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  }

  return "Your browser or device does not support Apple Pay on the web";
};

class UserPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      updatePlanCurrentStep: "select_plan",
      //updatePlanCurrentStep : "select_card",
      planType: "annual",

      isAnualPlanListFetched: false,
      anualPlanList: [],
      monthlyPlanList: [],
      userInfo: "",

      apiErrorMessage: "",
      apiSuccessMessage: "",

      formData: {
        card_holder_firstName: "",
        card_holder_lastName: "",
        card_number: "",
        exp_date_month: null,
        exp_month: "",
        exp_year: "",
        cvv: "",
      },
      isSubmit: false,
      userSavedCardDetails: [],

      selectedPlan: "",
      selectedPlanDetails: "",
      applePayInfo: "",

      isPaypalPrepaymentSuccess: false,
      isExistingcardchose: false,
      selectedCardid: "",
      currentPlaninfo: [],
      is_redirect_to_url: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(date) {
    var formData = this.state.formData;
    formData["exp_date_month"] = date;
    this.setState({
      formData: formData,
      isExistingcardchose: false
    });
  }

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
      isExistingcardchose: false
    });

  }

  handleEmailChange = (event) => {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    const setValue = fieldValue.length <= 19 ? fieldValue : formData[fieldName];
    formData[fieldName] = setValue;
    this.setState({
      formData: formData,
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    //dashboard
    var formData = this.state.formData;
    if (this.state.isExistingcardchose) {
      this.setState({ isSubmit: true }); /* Show loader */
      var form_data = new FormData();
      form_data.append("plan_id", this.state.selectedPlan);
      form_data.append("userCardId", this.state.selectedCardid);
      form_data.append("paymentMethod", "card");

      this.props.miscActions.userPlanUpdate(form_data);

    } else {
      if (this.state.isSubmit === false) {
        if (
          formData.card_holder_firstName === "" ||
          formData.card_holder_firstName === null ||
          formData.card_holder_firstName === undefined
        ) {
          toast.error("Please enter your first name");
        } else if (
          formData.card_holder_lastName === "" ||
          formData.card_holder_lastName === null ||
          formData.card_holder_lastName === undefined
        ) {
          toast.error("Please enter your last name");
        } else if (
          formData.card_number === "" ||
          formData.card_number === null ||
          formData.card_number === undefined
        ) {
          toast.error("Please enter your card number");
        } else if (
          formData.exp_date_month === "" ||
          formData.exp_date_month === null ||
          formData.exp_date_month === undefined
        ) {
          toast.error("Please enter your expiration date");
        } else if (
          formData.cvv === "" ||
          formData.cvv === null ||
          formData.cvv === undefined
        ) {
          toast.error("Please enter your cvv");
        } else {
          var selectedPlan = this.state.selectedPlan;
          if (selectedPlan === "") {
            toast.error("Something went wrong! please try again");
          } else {
            var formData = this.state.formData;
            var exp_date_month = formData.exp_date_month;
            var newdate = moment(exp_date_month)
              //.tz('America/New_York')
              .format("MM/YYYY");
            var res = newdate.split("/");
            var exp_month = "";
            var exp_year = "";
            if (res.length > 0) {
              exp_month = res[0];
              exp_year = res[1];
            }

            let registerObj = {};
            registerObj["card_holder_firstName"] = formData.card_holder_firstName;
            registerObj["card_holder_lastName"] = formData.card_holder_lastName;
            registerObj["card_number"] = formData.card_number;
            registerObj["exp_month"] = exp_month;
            registerObj["exp_year"] = exp_year;
            registerObj["cvv"] = formData.cvv;

            var form_data = new FormData();
            for (var key in registerObj) {
              form_data.append(key, registerObj[key]);
            }

            form_data.append("plan_id", selectedPlan);
            form_data.append("paymentMethod", "card");

            this.setState({ isSubmit: true });
            this.props.miscActions.userPlanUpdate(form_data);
          }
        }
      }

    }



  }

  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");
    if (auth_token === "" || auth_token === undefined || auth_token === null) {
      this.props.history.push("/");
    } else {
      /*fetch plans list */
      this.props.miscActions.getProfileDetails();
      this.props.miscActions.fetchUserSavedCard();
    }

    pusherChannel.bind("upgrade-card-payment-status", (data) => {
      var user_info1 = localStorage.getItem("user_info");
      console.log("cardPayment", data);
      console.log(
        "userinfo",
        data?.data?.user_id +
        "-------" +
        JSON.parse(user_info1)?._id +
        "---------" +
        user_info1
      );
      if (data?.data?.user_id === JSON.parse(user_info1)?._id) {
        this.setState({
          is_redirect_to_url: "",
        });
        //localStorage.clear();

        confirmAlert({
          title: data?.message,
          message: "",
          closeOnClickOutside: false,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.props.history.push("/settings");
              },
            },
          ],
        });
      }
    });




  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.setState({
        apiErrorMessage: "",
        apiSuccessMessage: "",
      });

    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.setState({
        apiErrorMessage: "",
        apiSuccessMessage: "",
      });
      //this.props.history.push("/settings");
    }

    if (this.state.isPaypalPrepaymentSuccess === true) {
      var form_data = new FormData();
      var plan_id = this.state.selectedPlan;
      form_data.append("plan_id", plan_id);
      form_data.append("paymentMethod", "paypal");
      this.props.miscActions.userPlanUpdate(form_data);
      this.setState({
        isPaypalPrepaymentSuccess: false
      });
    }
  }

  applePaySubmit = (value) => {
    console.log("valuevaluevaluevaluevaluevalue", value);
    this.setState({ setOpen: false, isSubmit: true });
    var form_data = new FormData();
    form_data.append("plan_id", this.state.selectedPlan);
    form_data.append("paymentMethod", "apple-pay");
    form_data.append("paymentDetails", JSON.stringify(value));
    this.props.miscActions.userPlanUpdate(form_data);
  };

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        /* Fetch All plans */
        case miscActions.FETCH_PLANS_SUCCESS:
          let planList =
            miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : [];
          if (state.isAnualPlanListFetched === false) {
            setTimeout(function () {
              props.miscActions.fetchUserFlans({
                no_of_contacts: userInfo?.no_of_contacts,
                type: "Annual",
              });
            }, 2000);

            return {
              monthlyPlanList: planList,
              isAnualPlanListFetched: true,
            };
          } else {
            props.miscActions.clearState();
            return {
              anualPlanList: planList,
            };
          }

          break;
        case miscActions.FETCH_PLANS_FAILED:
          props.miscActions.clearState();
          return {};
          break;

        /* Fetch User Profile */
        case miscActions.GET_PROFILE_DETAILS_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          props.miscActions.clearState();
          props.miscActions.fetchUserFlans({
            no_of_contacts: userInfo?.no_of_contacts,
            type: "Monthly",
          });
          return {
            userInfo: userInfo,
          };
          break;
        case miscActions.GET_PROFILE_DETAILS_FAILED:
          props.miscActions.clearState();
          return {
            userInfo: "",
          };
          break;

        /* User Profile Update */
        case miscActions.USER_PROFILE_UPDATE_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          var message =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          props.miscActions.clearState();
          localStorage.setItem("user_info", JSON.stringify(userInfo));
          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            userInfo: userInfo,
          };
          break;
        case miscActions.USER_PROFILE_UPDATE_FAILED:
          props.miscActions.clearState();
          return {
            apiErrorMessage: "Something went wrong! Please try again",
            apiSuccessMessage: "",
          };
          break;

        /* Fetch Saved Card */
        case miscActions.FETCH_USER_CARD_SUCCESS:
          var cardInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          props.miscActions.clearState();
          return {
            userSavedCardDetails: cardInfo,
          };
          break;
        case miscActions.FETCH_USER_CARD_FAILED:
          props.miscActions.clearState();
          return {
            userSavedCardDetails: [],
          };
          break;

        /* Plan Update */
        case miscActions.USER_PLAN_UPDATE_SUCCESS:
          var is_redirect_to_url =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.redirect_to_url !== undefined
              ? miscReducerData.responce.data.redirect_to_url
              : "";
          var userInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          if (is_redirect_to_url === "") {

            localStorage.setItem("user_info", JSON.stringify(userInfo));
          }

          localStorage.removeItem("selected_plan_details");

          var message =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";


          props.miscActions.clearState();

          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            isSubmit: false,
            is_redirect_to_url: is_redirect_to_url
          };
          break;
        case miscActions.USER_PLAN_UPDATE_FAILED:
          props.miscActions.clearState();
          return {
            apiErrorMessage: "Something went wrong! Please try again",
            apiSuccessMessage: "",
            isSubmit: false,
          };
          break;

        case miscActions.USER_PLAN_UPDATE_FAILED_CARD:
          props.miscActions.clearState();
          var message =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.message !== undefined &&
              miscReducerData.responce.message !== ""
              ? miscReducerData.responce.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmit: false,
          };
          break;

        /* Apple Payment */
        case miscActions.GET_APPLE_PAYMENT_INTENT_SUCCESS:
          var applePayInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          props.miscActions.clearState();
          return {
            applePayInfo: applePayInfo,
          };
          break;
        case miscActions.GET_APPLE_PAYMENT_INTENT_FAILED:
          return {
            applePayInfo: "",
          };
          break;

        /* Paypal Payment get redirect URL */
        case miscActions.GET_PAYPAL_PAY_REDIRECT_URL_SUCCESS:
          var redirectURL =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.redirectUrl !== undefined &&
              miscReducerData.responce.data.redirectUrl !== "" &&
              miscReducerData.responce.data.redirectUrl !== null
              ? miscReducerData.responce.data.redirectUrl
              : "";
          props.miscActions.clearState();
          if (redirectURL !== "") {
            window.location.href = redirectURL;
          }

          return {
            //isSubmit : false,
          };
          break;

        case miscActions.GET_PAYPAL_PAY_REDIRECT_URL_FAILED:
          return {
            apiErrorMessage: "Something went wrong ! please try again",
            apiSuccessMessage: "",
            isSubmit: false,
          };
          break;

        case miscActions.CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_SUCCESS:
          props.miscActions.clearState();
          return {
            isPaypalPrepaymentSuccess: true,
          };
          break;
        case miscActions.CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_FAILED:
          props.miscActions.clearState();
          return {
            isPaypalPrepaymentSuccess: false,
          };

        /* Fetch All plans */
        case miscActions.DELETE_CARD_SUCCESS:
          let availableCards =
            miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.availableCards !== undefined
              ? miscReducerData.responce.data.availableCards
              : [];

          message =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";

          props.miscActions.clearState();
          return {
            userSavedCardDetails: availableCards,
            apiSuccessMessage: message,
          };
        case miscActions.DELETE_CARD_FAILED:
          message =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          props.miscActions.clearState();
          return {
            apiErrorMessage: message,
          };



        //break;
        default:
          return {}
      }
    }



    return {};
  }

  changePlan(data) {
    this.setState({ planType: data });
  }

  selectPlan(plan) {
    var userInfo = this.state.userInfo;
    var THIS = this;
    var plan_id =
      plan._id !== undefined && plan._id !== null && plan._id !== ""
        ? plan._id
        : "";
    if (plan_id === "") {
      toast.error("Something went wrong! please try again");
    } else {
      this.setState({
        updatePlanCurrentStep: "select_payment_type",
        selectedPlan: plan_id,
        selectedPlanDetails: plan,
      });

      var formdata = {
        plan_id: plan_id,
      };
      this.props.miscActions.getApplePayIntent(formdata);

      setTimeout(function () {
        if (
          userInfo !== "" &&
          userInfo !== null &&
          userInfo !== undefined &&
          userInfo.email !== undefined &&
          userInfo.email !== null &&
          userInfo.email !== ""
        ) {
          var formdata = {
            email: userInfo.email,
          };
          THIS.props.miscActions.checkPaypalAlreadyPaymentOrNot(formdata);
        }
      }, 1000);

      localStorage.setItem("selected_plan_details", JSON.stringify(plan));
    }
  }

  selectPaymentMethod(methodName) {
    if (methodName === "") {
      toast.error("Something went wrong! please try again");
    } else {
      if (methodName === "paypal") {
        var user_info = this.state.userInfo;
        var plan_id = this.state.selectedPlan;

        if (
          plan_id !== null &&
          plan_id !== "" &&
          plan_id !== undefined &&
          user_info !== undefined &&
          user_info !== "" &&
          user_info !== null &&
          user_info.email !== "" &&
          user_info.email !== null &&
          user_info.email !== undefined
        ) {
          var formData = {
            plan_id: plan_id,
            email: user_info.email,
          };

          this.setState({
            isSubmit: true,
          });
          this.props.miscActions.getPaypalRedirectUrl(formData);
        }
      } else {
        this.setState({
          updatePlanCurrentStep: "select_card",
        });
      }
    }
  }

  selectCard(cardId, cardData) {
    var plan_id = this.state.selectedPlan;

    if (this.state.selectedCardid === cardId) {
      this.setState({
        selectedCardid: "",
        isExistingcardchose: false,
        formData: {
          card_holder_firstName: "",
          card_holder_lastName: "",
          cvv: "",
          card_number: "",
          exp_date_month: null
        }
      })
    } else {
      if (cardId === "" || plan_id === "") {
        toast.error("Something went wrong! please try again");
      } else {
        let formData = this.state.formData;
        formData["exp_date_month"] = cardData.exp_month + "/" + cardData.exp_month + '/' + cardData.exp_year;
        formData["card_holder_firstName"] = cardData.card_holder_firstName;
        formData["card_holder_lastName"] = cardData.card_holder_lastName;
        formData["card_number"] = `************${cardData.last_four_digit}`;

        formData["cvv"] = "***";

        this.setState({
          selectedCardid: cardId,
          isExistingcardchose: true,
          formData: formData,
        })
        //this.setState({ isSubmit: true }); /* Show loader */
        // var form_data = new FormData();
        // form_data.append("plan_id", plan_id);
        // form_data.append("userCardId", cardId);
        // form_data.append("paymentMethod", "card");

        //this.props.miscActions.userPlanUpdate(form_data);
      }
    }




  }

  removeCard = (cardid, carddetails) => {
    confirmAlert({
      title: "Are you sure to delete this card?",
      //message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var form_data = new FormData();
            form_data.append("card_id", cardid);
            this.props.miscActions.deleteCard(form_data);
          }
        },
        {
          label: "No",
        },
      ],
    });

  }

  handleOpen = () => {
    this.setState({ setOpen: true });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  render() {
    var anualPlans = this.state.anualPlanList;
    var monthlyPlans = this.state.monthlyPlanList;
    var userCurrentPlanId =
      this.state.userInfo !== "" &&
        this.state.userInfo !== undefined &&
        this.state.userInfo !== null &&
        this.state.userInfo.plan_id !== undefined &&
        this.state.userInfo.plan_id !== null &&
        this.state.userInfo.plan_id !== "" &&
        this.state.userInfo.plan_id._id !== undefined
        ? this.state.userInfo.plan_id._id
        : "";
    var curentPlaninfo = this.state.userInfo !== "" &&
      this.state.userInfo !== undefined &&
      this.state.userInfo !== null &&
      this.state.userInfo.plan_id !== undefined &&
      this.state.userInfo.plan_id !== null &&
      this.state.userInfo.plan_id !== "" &&
      this.state.userInfo.plan_id._id !== undefined
      ? this.state.userInfo.plan_id
      : "";

    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    //console.log('this.state.userSavedCardDetails',this.state.userSavedCardDetails)

    var selected_plan_details = localStorage.getItem("selected_plan_details");
    selected_plan_details = JSON.parse(selected_plan_details);

    var planName =
      selected_plan_details !== undefined &&
        selected_plan_details !== null &&
        selected_plan_details !== "" &&
        selected_plan_details.header !== undefined &&
        selected_plan_details.header !== "" &&
        selected_plan_details.header !== null
        ? selected_plan_details.header
        : "";
    var subheader =
      selected_plan_details !== undefined &&
        selected_plan_details !== null &&
        selected_plan_details !== "" &&
        selected_plan_details.subheader !== undefined &&
        selected_plan_details.subheader !== "" &&
        selected_plan_details.subheader !== null
        ? selected_plan_details.subheader
        : "";
    var value =
      selected_plan_details !== undefined &&
        selected_plan_details !== null &&
        selected_plan_details !== "" &&
        selected_plan_details.value !== undefined &&
        selected_plan_details.value !== "" &&
        selected_plan_details.value !== null
        ? selected_plan_details.value
        : 0.0;
    var type =
      selected_plan_details !== undefined &&
        selected_plan_details !== null &&
        selected_plan_details !== "" &&
        selected_plan_details.type !== undefined &&
        selected_plan_details.type !== "" &&
        selected_plan_details.type !== null
        ? selected_plan_details.type
        : "";

    //console.log("anualPlans", this.state.formData.exp_date_month);


    return (
      <>
        <Modal
          className={"d-flex align-items-center"}
          open={this.state.is_redirect_to_url !== "" ? true : false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {this.state.is_redirect_to_url !== "" && (
            <iframe
              src={this.state.is_redirect_to_url}
              height="100%"
              width="100%"
              title="hsdhha"
              style={{ background: "#fff" }}
            />
          )}
        </Modal>
        <div className="card bg-transparent border-0 vh-100 overflow-auto">
          <PrimaryHeader position={'innerHeader'} />
          <section className=" registerStepWrap h-100 overflow-auto bluebgsection">
            <div className="container">
              <div className="row">
                <div className="card bigCard border-0  mx-auto">
                  <div className="head text-right mr-md-n4">
                    {/*<h5 className="mb-0">Step 4 of 5</h5>*/}
                  </div>
                  {this.state.updatePlanCurrentStep === "select_plan" &&
                    <div className=" ">
                      <div className="iconBox mar-b-15 mar-b-md-35">
                        <img src={Icon} alt="" className="w-50px w-md-72px" />
                      </div>
                      <div className="fs-22 fs-md-36 font-weight-Bold ">
                        Select plan
                      </div>
                      <div className="subHead fs-14 fs-md-18 mar-b-18">
                        Choose the plan that suits you.
                      </div>


                      <div className="w-100">
                        <div className="border-bottom mb-4 ">
                          <div className="">
                            <div className="d-inline-block mr-2 mar-r-sm-70">
                              <Button
                                className={"btn px-0 fs-14 fs-xs-18 shadow-none  " + (this.state.planType === 'annual' ? 'text-primary font-weight-Bold' : 'text-secondary')}
                                onClick={() => this.changePlan('annual')}
                              >Annual (Save 10%)</Button>
                              <div
                                className={"h-7px " + (this.state.planType === 'annual' ? 'bg-primary' : '')}
                                style={{ borderRadius: '5px 5px 0px 0px' }}></div>
                            </div>
                            <div className="d-inline-block">
                              <Button
                                className={"btn px-0 fs-14 fs-xs-18 shadow-none  " + (this.state.planType === 'monthly' ? 'text-primary font-weight-Bold' : 'text-secondary')}
                                onClick={() => this.changePlan('monthly')}
                              >Monthly</Button>
                              <div
                                className={"h-7px " + (this.state.planType === 'monthly' ? 'bg-primary' : '')}
                                style={{ borderRadius: '5px 5px 0px 0px' }}></div>
                            </div>
                          </div>
                        </div>
                        {this.state.planType === 'annual' &&
                          <div className="row animate__animated animate__fadeIn">
                            {anualPlans.map((list, index) =>
                              <div className="col-md-4 mb-4" key={index}>
                                <div className={`d-flex flex-column p-4 planBox ${list._id === userCurrentPlanId ? "selected-plan" : ""}`}>
                                  <div className="mb-3">
                                    <span className="rounded-pill border d-inline-block px-4 py-1">{list.header}</span>
                                  </div>
                                  <div className="mb-3">
                                    <h4 className="line-height-1">US$ {list.value} <sub className="fs-14" style={{ bottom: '0' }}>/ Year</sub></h4>
                                    <h5 className="text-color4 fs-18 font-weight-Regular">{list.subheader}</h5>
                                  </div>
                                  <div>
                                    <ul className="list-unstyled text-color5">
                                      {list.line_items.map((single_line_itm, single_line_indx) =>
                                        <li key={single_line_indx}>✓ {single_line_itm}</li>
                                      )}
                                    </ul>
                                  </div>
                                  <div>
                                    {/*<Button
                                                                            className="bg-primary pad-y-10 text-white w-100"
                                                                            onClick={() => window.location.href = "/sign-up-step-five"}
                                                                        >Select Plan</Button>*/}

                                    <Button
                                      className="bg-primary pad-y-10 text-white w-100"
                                      onClick={(e) => {
                                        if (list._id === userCurrentPlanId) {
                                          confirmAlert({
                                            title: "You have already purchased this plan.",
                                            message: "Are you sure to do this?",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => this.selectPlan(list),
                                              },
                                              {
                                                label: "No",
                                              },
                                            ],
                                          });

                                        } else if (list.value < curentPlaninfo.value) {
                                          confirmAlert({
                                            title: "If you degrade your plan, your memory size and create contact limit will be reduced.",
                                            message: "Are you sure to do this?",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => this.selectPlan(list),
                                              },
                                              {
                                                label: "No",
                                              },
                                            ],
                                          });

                                        } else {
                                          this.selectPlan(list)
                                        }


                                      }}
                                    >Select Plan</Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        {this.state.planType === 'monthly' &&
                          <div className="row animate__animated animate__fadeIn">
                            {monthlyPlans.map((list, index) =>
                              <div className="col-md-4 mb-4" key={index}>
                                <div className={`d-flex flex-column p-4 planBox ${list._id === userCurrentPlanId ? "selected-plan" : ""}`}>
                                  <div className="mb-3">
                                    <span className="rounded-pill border d-inline-block px-4 py-1">{list.header}</span>
                                  </div>
                                  <div className="mb-3">
                                    <h4 className="line-height-1">US$ {list.value} <sub className="fs-14" style={{ bottom: '0' }}>/ Month</sub></h4>
                                    <h5 className="text-color4 fs-18 font-weight-Regular">{list.subheader}</h5>
                                  </div>
                                  <div>
                                    <ul className="list-unstyled text-color5">
                                      {list.line_items.map((single_line_itm, single_line_indx) =>
                                        <li key={single_line_indx}>✓ {single_line_itm}</li>
                                      )}
                                    </ul>
                                  </div>
                                  <div>
                                    <Button
                                      className="bg-primary pad-y-10 text-white w-100"
                                      onClick={(e) => {
                                        if (list._id === userCurrentPlanId) {
                                          confirmAlert({
                                            title: "You have already purchased this plan.",
                                            message: "Are you sure to do this?",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => this.selectPlan(list),
                                              },
                                              {
                                                label: "No",
                                              },
                                            ],
                                          });

                                        } else if (list.value < curentPlaninfo.value) {
                                          confirmAlert({
                                            title: "If you gegrade your plan, your memory size and create contact limit will be reduced.",
                                            message: "Are you sure to do this?",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => this.selectPlan(list),
                                              },
                                              {
                                                label: "No",
                                              },
                                            ],
                                          });

                                        } else {
                                          this.selectPlan(list)
                                        }


                                      }}
                                    >Select Plan</Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        }
                      </div>
                      <div className="info">
                        We’ll be billing from your account only. Your contacts wont   have to pay.
                      </div>
                      <div className="w-100 pad-t-50">
                      </div>
                    </div>
                  }

                  {this.state.updatePlanCurrentStep === "select_payment_type" &&
                    <div className="custom-sm-padding">
                      <div className="iconBox mar-b-15 mar-b-md-35">
                        <img src={Icon} alt="" className="w-50px w-md-89px" />
                      </div>
                      <div className="fs-22 fs-md-36 font-weight-Bold ">
                        Select Payment Method
                      </div>
                      <div className="subHead fs-14 fs-md-18 mar-b-30">
                        Set up your payment method.
                      </div>
                      {this.state.isSubmit === true &&
                        <div className="text-center"><CircularProgress /></div>
                      }
                      {this.state.isSubmit === false &&
                        <div className="pad-t-30">
                          <div className="row">
                            <div className="col-md-4 mb-5 mb-md-0">
                              <div className="d-flex flex-column p-4 payBox text-center cursor-pointer justify-content-between rounded" onClick={(e) => this.handleOpen()}>
                                <div className=" my-auto "><img src={payIco1} alt="" /></div>
                                <div className="text-color4">Pay with Apple Pay</div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-5 mb-md-0">
                              <div className="d-flex flex-column p-4 payBox text-center cursor-pointer justify-content-between rounded" onClick={(e) => this.selectPaymentMethod('paypal')}>
                                <div className=" my-auto "><img src={payIco2} alt="" /></div>
                                <div className="text-color4">Pay with PayPal</div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-5 mb-md-0">
                              <div className="d-flex flex-column p-4 payBox text-center cursor-pointer justify-content-between rounded" onClick={(e) => this.selectPaymentMethod('card')}>
                                <div className=" my-auto "><img src={payIco3} alt="" /></div>
                                <div className="text-color4">Pay with Card</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  }

                  {this.state.updatePlanCurrentStep === "select_card" &&
                    <div className="row custom-sm-padding-card">
                      <div className="iconBox mar-b-15 mar-b-md-35">
                        <img src={Icon} alt="" className="w-50px w-md-90px" />
                      </div>
                      <div className="fs-22 fs-md-36 font-weight-Bold" style={{ width: '100%' }}>
                        Add Card details
                      </div>
                      <div className="subHead fs-14 fs-md-18 mar-b-30">
                        Your membership starts as soon as you set up payment.
                      </div>
                      <div className="d-flex  pb-4 w-100">
                        <div className="px-2">
                          <img src={card1} alt="" />
                        </div>
                        <div className="px-2">
                          <img src={card2} alt="" />
                        </div>
                        <div className="px-2">
                          <img src={card3} alt="" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        {this.state.userSavedCardDetails.length > 0 && this.state.isSubmit === false &&
                          <ul className="list-unstyled row">
                            {this.state.userSavedCardDetails.map((cardlist, cardindex) =>
                              <li className={this.state.selectedCardid === cardlist._id ? "mb-3 col-lg-6 active" : "mb-3 col-lg-6"} key={cardindex} >
                                <CancelIcon style={{ position: 'absolute', right: '0px', fontSize: '25px', top: '-15px', cursor: 'pointer' }}
                                  onClick={(e) => this.removeCard(cardlist._id, cardlist)} />
                                <div className="border rounded p-2 shadow-sm over-active" onClick={(e) => this.selectCard(cardlist._id, cardlist)}>
                                  <div className="d-flex align-items-center">
                                    <div className="mr-auto">
                                      {cardlist.card_holder_name}
                                    </div>
                                    <div className="">
                                      <h5 className="mb-0 fs-16">**** **** **** {cardlist.last_four_digit}</h5>
                                      <h5 className="mb-0 fs-14 text-right text-uppercase opa-70">{cardlist.exp_month} / {cardlist.exp_year} - {cardlist.cardBrand}</h5>
                                    </div>
                                  </div>

                                </div>
                              </li>
                            )}
                          </ul>
                        }

                        {this.state.isSubmit === true &&
                          <CircularProgress />
                        }

                        {this.state.userSavedCardDetails.length === 0 && currentUserRole === "user" &&
                          <div className="alert alert-primary" role="alert">
                            Sorry, no cards are available
                          </div>
                        }
                      </div>
                      <div className="col-md-12">
                        <form onSubmit={this.handleSubmit}>
                          {!this.state.isExistingcardchose && <div className="row">
                            <div className="col-md-6 pt-4">
                              <TextField label="First Name" name="card_holder_firstName" onChange={this.handleChange} variant="outlined" className="w-100" value={this.state.formData.card_holder_firstName} />
                            </div>
                            <div className="col-md-6 pt-4">
                              <TextField label="Last Name" name="card_holder_lastName" onChange={this.handleChange} variant="outlined" className="w-100" value={this.state.formData.card_holder_lastName} />
                            </div>
                            {!this.state.isExistingcardchose && <div className="col-md-12 pt-4">
                              <TextField
                                onInput={(e) => {
                                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 16)
                                }}
                                type="number" label="Card Number" name="card_number" onChange={this.handleChange} variant="outlined" className="w-100" value={this.state.formData.card_number} />
                            </div>
                            }
                            {this.state.isExistingcardchose && <div className="col-md-12 pt-4">
                              <TextField
                                onInput={(e) => {
                                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 16)
                                }}
                                type="text" label="Card Number" name="card_number" onChange={this.handleChange} variant="outlined" className="w-100" value={this.state.formData.card_number} />
                            </div>
                            }
                            <div className="col-md-12 pt-4">

                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  clearable={true}
                                  variant="outlined"
                                  inputVariant="outlined"
                                  openTo="year"
                                  views={["year", "month"]}
                                  label="Expiration Date (MM/YYYY)"
                                  value={this.state.formData.exp_date_month}
                                  format="MM/yyyy"
                                  onChange={date => this.handleDateChange(date)}
                                  className="w-100"
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-12 pt-4">
                              <TextField
                                type="password"
                                /*onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                }}*/
                                label="Security Code (CVV)" name="cvv" onChange={this.handleChange} variant="outlined" className="w-100" value={this.state.formData.cvv} />
                            </div>
                          </div>}



                          <div className="row mt-5">
                            <div className="col-3">
                              <Button
                                className="bg-color4 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                                onClick={() => {
                                  // localStorage.setItem('registration_current_step', 5)
                                  // this.props.history.push("/sign-up-step-five");
                                  this.setState({
                                    updatePlanCurrentStep: "select_payment_type"
                                  })
                                }}
                              >
                                Back
                              </Button>
                            </div>
                            <div className="col-9 text-right">
                              <div>
                                {this.state.isSubmit === true &&
                                  <CircularProgress />
                                }
                                {this.state.isSubmit === false &&
                                  <>
                                    {this.state.isExistingcardchose && <Button type="submit" className="MuiButtonBase-root MuiButton-root MuiButton-text pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize startmemebershipbtn">
                                      {'Start Membership'}
                                    </Button>}
                                    {!this.state.isExistingcardchose && <Button type="submit" className="MuiButtonBase-root MuiButton-root MuiButton-text bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize">
                                      {'Start Membership'}
                                    </Button>}
                                  </>
                                }
                              </div>
                            </div>
                          </div>











                          {/* <div className="pt-5">
                            {this.state.isSubmit === true &&
                              <CircularProgress />
                            }
                            {this.state.isSubmit === false &&
                              <Button type="submit" className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize">Start Membership</Button>
                            }
                          </div> */}
                        </form>
                      </div>
                    </div>
                  }


                </div>
              </div>
            </div>
          </section>
        </div>
        <Modal
          className={modal}
          open={this.state.setOpen}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="myCusTomModal d-flex">
            <div className="card">
              <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 ">
                  <i className="material-icons text-color1 align-middle">
                    payment
                  </i>{" "}
                  Payment Now
                </h3>
              </div>
              <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                <h3 className="tsubHead fs-14 fs-md-18 mar-b-30">
                  You have selected the <span className="font-weight-Bold fs-24">{planName}</span> plan now for just <span className="font-weight-Bold fs-24">${value}</span>
                </h3>
              </div>
              <div className="card-footer bg-white text-right pad-x-40 ">
                {/*<Elements stripe={stripePromise}>
                          <CheckoutForm applePayInfo={this.state.applePayInfo} applePaySubmit = {this.applePaySubmit}/>
                        </Elements>*/}
                <CheckoutForm applePayInfo={this.state.applePayInfo} applePaySubmit={this.applePaySubmit} />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    paymentReducerData: state.paymentReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  paymentActions: bindActionCreators(paymentActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPlan);
