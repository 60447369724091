import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import UserIcon from "../assets/images/icon21.svg";
import EventIcon from "../assets/images/icon22.svg";
import { Button, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import moment from "moment";

const modal = "d-flex align-items-center";

class ViewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      setModalOpen: false,
      eventDetails: "",
      memoriesList: [],
      isLoading: true,
      formData: {
        title: "",
        recuringEvent: "",
        eventDay: "",
        eventMonth: "",
        eventYear: "",
        dateUnknown: "",
      },

      isDisabledSubmit: false,
      isSubmitForm: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      isRedirect: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    if (fieldName === "eventMonth") {
      if (fieldValue === "2") {
        if (formData.eventDay > 29) {
          formData["eventDay"] = 29;
        } else if (formData.eventDay < 1) {
          formData["eventDay"] = 1;
        }
      }
    }

    if (fieldName === "dateUnknown") {
      fieldValue = event.target.checked;
    }

    if (fieldName === "recuringEvent" && fieldValue === "true") {
      fieldValue = true;
    } else if (fieldName === "recuringEvent" && fieldValue === "false") {
      fieldValue = false;
    }

    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
      isDisabledSubmit: false,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    var formData = this.state.formData;
    var eventmonth =
      formData.eventMonth && formData.eventMonth.toString().length === 1
        ? `0${formData.eventMonth}`
        : formData.eventMonth;

    var eventDetails = this.state.eventDetails;
    if (
      formData.title === "" ||
      formData.title === undefined ||
      formData.title === null
    ) {
      toast.error("Event name can not be blank");
    } else if (
      formData.recuringEvent === "" ||
      formData.recuringEvent === undefined ||
      formData.recuringEvent === null
    ) {
      toast.error("Please select 'Is this a recurring event or not'");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventDay === "" ||
        formData.eventDay === null ||
        formData.eventDay === undefined)
    ) {
      toast.error("Please enter event date");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventMonth === "" ||
        formData.eventMonth === null ||
        formData.eventMonth === undefined)
    ) {
      toast.error("Please select the event month");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventYear === "" ||
        formData.eventYear === null ||
        formData.eventYear === undefined)
    ) {
      toast.error("Please enter the event year");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      !moment(
        `${formData.eventYear}-${eventmonth}-${formData.eventDay}`
      ).isValid()
    ) {
      toast.error("Please change the event day");
    } else {
      var event_id =
        eventDetails._id !== undefined &&
        eventDetails._id !== "" &&
        eventDetails._id !== null
          ? eventDetails._id
          : "";
      var user_contact_id =
        eventDetails.user_contact_id !== undefined &&
        eventDetails.user_contact_id !== "" &&
        eventDetails.user_contact_id !== null &&
        eventDetails.user_contact_id._id !== null &&
        eventDetails.user_contact_id._id !== "" &&
        eventDetails.user_contact_id._id !== undefined
          ? eventDetails.user_contact_id._id
          : "";

      if (event_id === "" || user_contact_id === "") {
        toast.error("Something went wrong ! please try again");
      } else {
        formData["eventId"] = event_id;
        formData["user_contact_id"] = user_contact_id;
        this.setState({
          isSubmitForm: true,
        });
        this.props.contactActions.updateEvent(formData);
      }
    }
  }

  handleOpen = (event) => {
    this.setState({ setOpen: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  handleCloseModal = () => {
    this.setState({ setModalOpen: false });
  };

  handleEditEvent() {
    this.setState({ setModalOpen: true, setOpen: false });
  }

  handleDeleteEvent() {
    var THIS = this;
    this.setState({ setOpen: false });
    var eventDetails = this.state.eventDetails;
    var event_id =
      eventDetails._id !== undefined &&
      eventDetails._id !== "" &&
      eventDetails._id !== null
        ? eventDetails._id
        : "";
    var user_contact_id =
      eventDetails.user_contact_id !== undefined &&
      eventDetails.user_contact_id !== "" &&
      eventDetails.user_contact_id !== null &&
      eventDetails.user_contact_id._id !== null &&
      eventDetails.user_contact_id._id !== "" &&
      eventDetails.user_contact_id._id !== undefined
        ? eventDetails.user_contact_id._id
        : "";

    if (event_id === "" || user_contact_id === "") {
      toast.error("Something went wrong ! please try again");
    } else {
      var formData = {};
      formData["id"] = event_id;
      formData["user_contact_id"] = user_contact_id;

      confirmAlert({
        title: "Confirm to delete event!",
        message: "Are you sure to do this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => THIS.props.contactActions.deleteEvent(formData),
          },
          {
            label: "No",
          },
        ],
      });
    }
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    }

    var eventDetails = this.state.eventDetails;
    var user_contact_id =
      eventDetails !== undefined &&
      eventDetails !== "" &&
      eventDetails !== null &&
      eventDetails.user_contact_id !== undefined &&
      eventDetails.user_contact_id !== null &&
      eventDetails.user_contact_id !== "" &&
      eventDetails.user_contact_id._id !== undefined &&
      eventDetails.user_contact_id._id !== null &&
      eventDetails.user_contact_id._id !== ""
        ? eventDetails.user_contact_id._id
        : "";

    if (this.state.isRedirect === true && user_contact_id !== "") {
      this.state.isRedirect = false;
      this.props.history.push("/view-contact/" + user_contact_id);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        /* FETCH ALL CONTACT INFO */
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_SUCCESS:
          var eventDetails =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";
          if (eventDetails) {
            props.contactActions.fetchUserMemoriesByEvent(
              eventDetails._id,
              //eventDetails.user_contact_id.user_id
              eventDetails.user_contact_id._id
            );

            var formData = {
              title:
                eventDetails.title !== undefined &&
                eventDetails.title !== "" &&
                eventDetails.title !== null
                  ? eventDetails.title
                  : "",
              recuringEvent:
                eventDetails.recuringEvent !== undefined &&
                eventDetails.recuringEvent !== "" &&
                eventDetails.recuringEvent !== null
                  ? eventDetails.recuringEvent
                  : "",
              eventDay:
                eventDetails.eventDay !== undefined &&
                eventDetails.eventDay !== "" &&
                eventDetails.eventDay !== null
                  ? eventDetails.eventDay
                  : "",
              eventMonth:
                eventDetails.eventMonth !== undefined &&
                eventDetails.eventMonth !== "" &&
                eventDetails.eventMonth !== null
                  ? eventDetails.eventMonth
                  : "",
              eventYear:
                eventDetails.eventYear !== undefined &&
                eventDetails.eventYear !== "" &&
                eventDetails.eventYear !== null
                  ? eventDetails.eventYear
                  : "",
              dateUnknown:
                eventDetails.dateUnknown !== undefined &&
                eventDetails.dateUnknown !== "" &&
                eventDetails.dateUnknown !== null
                  ? eventDetails.dateUnknown
                  : "",
            };
            props.contactActions.clearState();
            return {
              eventDetails: eventDetails,
              formData: formData,
            };
          } else {
            props.contactActions.clearState();
            return {
              eventDetails: "",
              isLoading: false,
            };
          }

          break;
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_FAILED:
          return {
            eventDetails: "",
            isLoading: false,
          };
          break;

        /* UPDATE EVENT */
        case contactActions.UPDATE_EVENT_SUCCESS:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          var data =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined &&
            contactReducerData.responce.data.data !== ""
              ? contactReducerData.responce.data.data
              : "";
          props.contactActions.clearState();

          var currentEventId =
            props.match !== undefined &&
            props.match.params !== undefined &&
            props.match.params.id !== undefined
              ? props.match.params.id
              : "";
          if (currentEventId !== "") {
            props.contactActions.fetchSingleEventDetails(currentEventId);
          }

          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            setModalOpen: false,
            isSubmitForm: false,
          };
          break;
        case contactActions.UPDATE_EVENT_FAILED:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          props.contactActions.clearState();
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmitForm: false,
          };
          break;

        /* Delete event */
        case contactActions.DELETE_EVENT_SUCCESS:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          props.contactActions.clearState();
          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            isRedirect: true,
          };
          break;
        case contactActions.DELETE_EVENT_FAILED:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          props.contactActions.clearState();
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
          };
          break;

        /* Fetch memory */
        case contactActions.FETCH_MEMORIES_BY_EVENT_SUCCESS:
          let memories = contactReducerData.responce.data
            ? contactReducerData.responce.data
            : [];

          props.contactActions.clearState();
          return {
            memoriesList: memories,
            isLoading: false,
          };
        default:
          return {};
      }
    }

    return {};
  }

  componentDidMount() {
    var currentEventId =
      this.props.match !== undefined &&
      this.props.match.params !== undefined &&
      this.props.match.params.id !== undefined
        ? this.props.match.params.id
        : "";

    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null ||
      currentEventId === "" ||
      currentEventId === null ||
      currentEventId === undefined
    ) {
      this.props.history.push("/");
    } else {
      this.props.contactActions.fetchSingleEventDetails(currentEventId);
    }
  }

  handleClick = (data, eventDetails) => {
    if (
      data.memories &&
      Object.keys(data.memories).length > 0 &&
      data.memories.scheduled === true
    ) {
      this.props.history.push({
        pathname: "/view-memory/" + data.memories._id,
        state: {
          detail: data,
          eventDetails: eventDetails,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/create-memory/" + eventDetails._id,
        state: {
          detail: data,
          eventDetails: eventDetails,
        },
      });
    }
  };

  render() {
    var formData = this.state.formData;
    var eventDetails = this.state.eventDetails;
    var isLoading = this.state.isLoading;
    // console.log("Event Details: ",eventDetails);
    // console.log("Memory Details: ",this.state.memoriesList);

    //Year for loop
    let d = new Date();
    let rows = [];
    for (let i = 1900; i <= d.getFullYear(); i++) {
      rows.push(i);
    }

    return (
      <>
        <Helmet>
          <title>Forget Me Not - View Events</title>
        </Helmet>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          {eventDetails !== undefined &&
            eventDetails !== null &&
            eventDetails !== "" &&
            (isLoading === true ? (
              <div className="text-center mt-5">
                <CircularProgress />
              </div>
            ) : (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="card border-0 border-radius-15 min-vh-75 overflow-hidden">
                    <div className="card-header pad-x-20 pad-x-md-46 pad-y-15 bg-white">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                          <i className="material-icons text-color1 align-middle fs-30">
                            event
                          </i>{" "}
                          <span className="pl-2">
                            {eventDetails.title !== undefined &&
                            eventDetails.title !== null &&
                            eventDetails.title !== ""
                              ? eventDetails.title.includes("Birthday")
                                ? eventDetails.user_contact_id.firstName !==
                                  undefined
                                  ? eventDetails.user_contact_id.firstName +
                                    "'s " +
                                    eventDetails.title
                                  : ""
                                : eventDetails.title
                              : ""}

                            {eventDetails.eventDate !== undefined &&
                            eventDetails.eventDate !== null &&
                            eventDetails.eventDate !== ""
                              ? ` | ${moment(eventDetails.eventDate).format(
                                  "Do MMMM"
                                )}`
                              : ""}
                          </span>
                        </h3>

                        <div className="position-relative">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={this.handleOpen.bind(this)}
                          >
                            <i className="material-icons fs-36">more_vert</i>
                          </IconButton>

                          <Menu
                            anchorEl={this.state.setOpen}
                            keepMounted
                            open={this.state.setOpen}
                            onClose={this.handleClose.bind(this)}
                          >
                            {eventDetails.canUpdateEvent && (
                              <MenuItem
                                className="fs-18 font-weight-Medium"
                                onClick={(e) => this.handleEditEvent()}
                              >
                                <i className="material-icons text-color1 align-middle mr-2">
                                  edit
                                </i>{" "}
                                Edit event
                              </MenuItem>
                            )}

                            {!eventDetails.canUpdateEvent && (
                              <Tooltip title="You are not authorized to edit this event">
                                <MenuItem className="fs-18 font-weight-Medium">
                                  <i className="material-icons text-color1 align-middle mr-2">
                                    edit
                                  </i>{" "}
                                  Edit event
                                </MenuItem>
                              </Tooltip>
                            )}
                            {eventDetails.canUpdateEvent && (
                              <MenuItem
                                className="fs-18 font-weight-Medium"
                                onClick={(e) => this.handleDeleteEvent()}
                              >
                                <i className="material-icons text-color1 align-middle mr-2">
                                  delete
                                </i>{" "}
                                Delete event
                              </MenuItem>
                            )}
                            {!eventDetails.canUpdateEvent && (
                              <Tooltip title="You are not authorized to edit this event">
                                <MenuItem className="fs-18 font-weight-Medium">
                                  <i className="material-icons text-color1 align-middle mr-2">
                                    delete
                                  </i>{" "}
                                  Delete event
                                </MenuItem>
                              </Tooltip>
                            )}
                          </Menu>
                        </div>
                      </div>
                    </div>

                    <div className="card-body pad-x-20 pad-x-md-46 pad-y-25 ">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb bg-white text-nowrap flex-nowrap overflow-auto p-0">
                          <li className="breadcrumb-item text-color1">
                            <Link to={"/dashboard"}>
                              {/* <i className="material-icons">home</i> */}
                              <HomeOutlinedIcon
                                style={{
                                  fontSize: "22px",
                                  marginTop: "-4px",
                                }}
                              />
                            </Link>
                          </li>
                          <li className="breadcrumb-item text-color1">
                            <Link
                              to={
                                "/view-contact/" +
                                eventDetails.user_contact_id._id
                              }
                            >
                              {eventDetails.user_contact_id.firstName !==
                              undefined
                                ? eventDetails.user_contact_id.firstName
                                : ""}{" "}
                              {eventDetails.user_contact_id.lastName !==
                              undefined
                                ? eventDetails.user_contact_id.lastName
                                : ""}
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item text-color1 active"
                            aria-current="page"
                          >
                            {eventDetails.title !== undefined &&
                            eventDetails.title !== null &&
                            eventDetails.title !== ""
                              ? eventDetails.title.includes("Birthday")
                                ? eventDetails.user_contact_id.firstName !==
                                  undefined
                                  ? eventDetails.user_contact_id.firstName +
                                    "'s " +
                                    eventDetails.title
                                  : ""
                                : eventDetails.title
                              : ""}

                            {console.log(eventDetails)}
                          </li>
                        </ol>
                      </nav>
                      <div className="row">
                        {this.state.memoriesList &&
                          this.state.memoriesList.length > 0 &&
                          this.state.memoriesList.map((list, index) => (
                            <div
                              className="col-sm-6 col-lg-4 mar-b-30 cursor-pointer"
                              key={index}
                              onClick={() =>
                                this.handleClick(list, eventDetails)
                              }
                            >
                              <div
                                className={`shadow-md pad-15 pad-xxxl-30 rounded ${
                                  list.memories &&
                                  Object.keys(list.memories).length > 0 &&
                                  list.memories.scheduled === true
                                    ? "light_grey"
                                    : ""
                                }`}
                              >
                                <div className="fs-28 fs-xxxl-36 font-weight-light text-black">
                                  <i className="material-icons align-middle text-color1">
                                    {list.memories &&
                                    Object.keys(list.memories).length > 0 &&
                                    list.memories.scheduled === true
                                      ? "hourglass_full"
                                      : "add_circle_outline"}
                                  </i>{" "}
                                  {list.year}
                                </div>
                                <div className="">
                                  <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                    {list.memories &&
                                    Object.keys(list.memories).length > 0 &&
                                    list.memories.scheduled === true
                                      ? "Memory placed in this year"
                                      : list.memories?.scheduled === false
                                      ? "Memory in draft"
                                      : "No memory shared"}
                                  </span>
                                  {list.memories &&
                                  Object.keys(list.memories).length > 0 &&
                                  list.memories.scheduled === true ? (
                                    ""
                                  ) : (
                                    <i
                                      className="w-10px h-10px rounded-circle  d-inline-flex ml-2"
                                      style={{ background: "#FF1469" }}
                                    ></i>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {(eventDetails === undefined ||
            eventDetails === null ||
            eventDetails === "") &&
            isLoading === false && (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    Sorry, no data found
                  </div>
                </div>
              </div>
            )}
        </div>

        <Modal
          className={modal}
          open={this.state.setModalOpen}
          onClose={this.handleCloseModal.bind(this)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="myCusTomModal d-flex">
            <div className="card   ">
              <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 ">
                  <i className="material-icons text-color1 align-middle">
                    event
                  </i>{" "}
                  Event details
                </h3>
              </div>
              <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  1. For which occasion are you creating this event?
                </h3>
                <div className="row">
                  <div className="mar-b-40 col-md-12">
                    <TextField
                      inputProps={{ maxLength: 25 }}
                      label="Event name"
                      variant="outlined"
                      className="w-100"
                      name="title"
                      value={formData.title !== undefined ? formData.title : ""}
                      onChange={this.handleChange}
                      required
                    />
                    <div className="fs-12 opa-60 text-black text-right">
                      {this.state.formData.title.length}/25
                    </div>
                  </div>
                </div>
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  2. Is this a recurring event? *
                </h3>
                <div className="mar-b-40 ">
                  <RadioGroup
                    name="recuringEvent"
                    onChange={this.handleChange}
                    value={
                      formData.recuringEvent !== undefined
                        ? formData.recuringEvent
                        : ""
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes, this event recurs every year"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No, this event occurs for once"
                    />
                  </RadioGroup>
                </div>

                <div className="d-md-flex justify-content-between mar-b-22 align-items-center">
                  <h3 className="text-black mb-0 fs-18 font-weight-Regular">
                    3. Event date *
                  </h3>
                  <div className="checkLabelCustom ml-n2 ml-md-auto">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="dateUnknown"
                          checked={
                            formData.dateUnknown !== undefined
                              ? formData.dateUnknown
                              : false
                          }
                          value={
                            formData.dateUnknown !== undefined
                              ? formData.dateUnknown
                              : ""
                          }
                          onChange={this.handleChange}
                        />
                      }
                      label="This event’s date is unknown"
                    />
                  </div>
                </div>

                {formData.dateUnknown !== true && (
                  <div className="row">
                    <div className="mar-b-40 col-md-4">
                      <FormControl
                        variant="outlined"
                        className="w-100 bg-white"
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Month
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Month"
                          onChange={this.handleChange}
                          name="eventMonth"
                          value={
                            formData.eventMonth !== undefined
                              ? formData.eventMonth
                              : ""
                          }
                        >
                          <MenuItem value="1">January</MenuItem>
                          <MenuItem value="2">February</MenuItem>
                          <MenuItem value="3">March</MenuItem>
                          <MenuItem value="4">April</MenuItem>
                          <MenuItem value="5">May</MenuItem>
                          <MenuItem value="6">June</MenuItem>
                          <MenuItem value="7">July</MenuItem>
                          <MenuItem value="8">August</MenuItem>
                          <MenuItem value="9">September</MenuItem>
                          <MenuItem value="10">October</MenuItem>
                          <MenuItem value="11">November</MenuItem>
                          <MenuItem value="12">December</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mar-b-40 col-md-4">
                      <TextField
                        type="number"
                        label="Day"
                        name="eventDay"
                        value={
                          formData.eventDay !== undefined
                            ? formData.eventDay
                            : ""
                        }
                        onChange={this.handleChange}
                        variant="outlined"
                        className="w-100"
                        onInput={(e) => {
                          var value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 2);
                          if (formData.eventMonth === "2") {
                            if (value > 29) {
                              value = 29;
                            } else if (value < 1) {
                              value = 1;
                            }
                          } else if (value > 31) {
                            value = 31;
                          } else if (value < 1) {
                            value = 1;
                          }
                          e.target.value = value;
                        }}
                      />
                    </div>
                    <div className="mar-b-40 col-md-4">
                      <FormControl
                        variant="outlined"
                        className="w-100 bg-white"
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Year
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Year"
                          onChange={this.handleChange}
                          name="eventYear"
                          value={
                            formData.eventYear !== undefined
                              ? formData.eventYear
                              : ""
                          }
                        >
                          {rows.map((value, index) => {
                            return (
                              <MenuItem value={value} key={index}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {/* <TextField
                        type="number"
                        label="Year"
                        variant="outlined"
                        className="w-100"
                        name="eventYear"
                        value={
                          formData.eventYear !== undefined
                            ? formData.eventYear
                            : ""
                        }
                        onChange={this.handleChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 4);
                        }}
                      /> */}
                    </div>
                  </div>
                )}

                {formData.dateUnknown === true && (
                  <div className="bg-color3 py-3 px-4 border-radius-10 fs-18 ">
                    <div className="opa-60 text-black">
                      Create memories for future events without a certain date.{" "}
                      {eventDetails?.user_contact_id?.firstName !== undefined &&
                      eventDetails?.user_contact_id?.firstName !== "" &&
                      eventDetails?.user_contact_id?.firstName !== null
                        ? eventDetails?.user_contact_id?.firstName
                        : ""}{" "}
                      will be able to see this memory anytime when it’s
                      appropriate by the event name.
                    </div>
                  </div>
                )}
              </div>
              <div className="card-footer bg-white text-right pad-x-40 ">
                {this.state.isSubmitForm === true && <CircularProgress />}

                {this.state.isSubmitForm === false && (
                  <>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize"
                      onClick={this.handleCloseModal.bind(this)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize background_color"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEvent);

//export default ViewEvent;
