import { Button } from "@material-ui/core";
import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Link, NavLink } from "react-router-dom";
/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";
import * as memoryActions from "../redux/actions/memoryActions";

import Image from "../assets/images/img6.jpg";
import Image7 from "../assets/images/img7.jpg";
import PlayBtn from "../assets/images/icon23.svg";
import UploadURLS from "../assets/settings.js";
import UserIcon from "../assets/images/icon21.svg";
import moment from "moment-timezone";
import audioIcon from "../assets/images/audio.png";
import { Helmet } from "react-helmet";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

class ViewMemory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: null,
      title: "",
      memoryDetails: {},
      eventDetails: {},
      videoFiles: [],
      imageFiles: [],
      audioFiles: [],
      apiErrorMessage: "",
      apiSuccessMessage: "",
      userDetails: "",
    };
  }
  handleOpen = (event) => {
    this.setState({ setOpen: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };
  handleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  handleDelete = () => {
    this.setState({ setOpen: false });
    var THIS = this;
    confirmAlert({
      title: "Confirm to delete!",
      message: "Are you sure want to delete this memory ?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.contactActions.deleteMemories(
              this.props.match.params?.id
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  handleCancelSchedule = () => {
    this.setState({ setOpen: false });
    var THIS = this;
    confirmAlert({
      title: "Confirm to cancel schedule!",
      message: "Are you sure want to cancel this schedule ?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.contactActions.cancelSchedule(
              this.state.memoryDetails.memories._id
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  componentDidMount() {
    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (this.props.match.params?.id) {
      /*GET MEMORY  DETAILS*/
      this.props.memoryActions.fetchMemorydetails(this.props.match.params?.id);
    }

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null
    ) {
      this.props.history.push("/");
    } else {
      if (this.props.location.state) {
        this.setState(
          {
            memoryDetails: this.props.location.state.detail,
            eventDetails: this.props.location.state.eventDetails,
          },
          () => {
            if (
              this.state.memoryDetails &&
              this.state.memoryDetails.memories &&
              this.state.memoryDetails.memories.files.length > 0
            ) {
              let imageArr = this.state.memoryDetails.memories.files.filter(
                (file) => {
                  return (
                    file.includes("jpg") ||
                    file.includes("JPG") ||
                    file.includes("png") ||
                    file.includes("PNG") ||
                    file.includes("gif") ||
                    file.includes("GIF") ||
                    file.includes("jpeg") ||
                    file.includes("JPEG")
                  );
                }
              );
              this.setState({ imageFiles: imageArr });

              let videoArr = this.state.memoryDetails.memories.files.filter(
                (file) => {
                  return (
                    file.includes("mp4") ||
                    file.includes("MP4") ||
                    file.includes("mov") ||
                    file.includes("MOV") ||
                    file.includes("wmv") ||
                    file.includes("WMV") ||
                    file.includes("flv") ||
                    file.includes("FLV") ||
                    file.includes("video") ||
                    file.includes("VIDEO") ||
                    file.includes("webm") ||
                    file.includes("WEBM")
                  );
                }
              );
              this.setState({ videoFiles: videoArr });

              let audioArr = this.state.memoryDetails.memories.files.filter(
                (file) => {
                  return (
                    file.includes("mp3") ||
                    file.includes("wav") ||
                    file.includes("ogg") ||
                    file.includes("audio")
                  );
                }
              );
              this.setState({ audioFiles: audioArr });
            }
          }
        );

        /*GET PROFILE  DETAILS*/
        this.props.miscActions.getProfileDetails();
      }
    }
  }

  componentDidUpdate() {
    if (this.state.apiErrorMessage) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
    } else if (this.state.apiSuccessMessage) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiSuccessMessage = "";
      this.props.history.push("/view-event/" + this.state.eventDetails._id);
    }
  }

  static getDerivedStateFromProps(props) {
    if (Object.keys(props.memoryReducerData).length > 0) {
      let { memoryReducerData } = props;
      // eslint-disable-next-line default-case
      switch (memoryReducerData.type) {
        /* Fetch User Profile */
        case memoryActions.FETCH_VIEWMEMORY_SUCCESS:
          var memorydetails = memoryReducerData.responce.data.data
            ? memoryReducerData.responce.data.data
            : "";
            console.log('memorydetails',memorydetails)

          let imageArr = memorydetails.files.filter((file) => {
            return (
              file.includes("jpg") ||
              file.includes("JPG") ||
              file.includes("png") ||
              file.includes("PNG") ||
              file.includes("gif") ||
              file.includes("GIF") ||
              file.includes("jpeg") ||
              file.includes("JPEG")
            );
          });

          let videoArr = memorydetails.files.filter((file) => {
            return (
              file.includes("mp4") ||
              file.includes("MP4") ||
              file.includes("mov") ||
              file.includes("MOV") ||
              file.includes("wmv") ||
              file.includes("WMV") ||
              file.includes("flv") ||
              file.includes("FLV") ||
              file.includes("video") ||
              file.includes("VIDEO") ||
              file.includes("webm") ||
              file.includes("WEBM")
            );
          });

          let audioArr = memorydetails.files.filter((file) => {
            return (
              file.includes("mp3") ||
              file.includes("wav") ||
              file.includes("ogg") ||
              file.includes("audio")
            );
          });

          props.memoryActions.clearState();
          return {
            memoryDetails: memorydetails,
            audioFiles: audioArr,
            videoFiles: videoArr,
            imageFiles: imageArr,
          };

        case memoryActions.FETCH_VIEWMEMORY_FAILED:
          props.miscActions.clearState();
          return {
            memoryDetails: "",
          };
      }
    }

    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        case contactActions.DELETE_USER_MEMORIES_SUCCESS:
          props.contactActions.clearState();
          var deleteEventSuccess = contactReducerData.responce.data;
          return {
            apiErrorMessage: "",
            apiSuccessMessage: deleteEventSuccess.message,
          };
        case contactActions.DELETE_USER_MEMORIES_FAILED:
          props.contactActions.clearState();
          return {
            apiErrorMessage: "Something Went Wrong! please try again",
            apiSuccessMessage: "",
          };
        case contactActions.CANCEL_SCHEDULE_FAILED:
          props.contactActions.clearState();
          return {
            apiErrorMessage: "Something Went Wrong! please try again",
            apiSuccessMessage: "",
          };
        case contactActions.CANCEL_SCHEDULE_SUCCESS:
          props.contactActions.clearState();
          var deleteEventSuccess = contactReducerData.responce.data;
          return {
            apiErrorMessage: "",
            apiSuccessMessage: deleteEventSuccess.message,
          };
          break;
        //Fetch user details
        case contactActions.FETCH_USER_DETAILS_SUCCESS:
          let userdetails = contactReducerData.responce.data
            ? contactReducerData.responce.data.data
            : [];

          props.contactActions.clearState();
          return {
            userDetails: { invitedBy: userdetails },
          };
        //break;
        default:
          return {};
      }

      //return {};
    }

    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        /* Fetch User Profile */
        case miscActions.GET_PROFILE_DETAILS_SUCCESS:
          var userInfo = miscReducerData.responce.data.data
            ? miscReducerData.responce.data.data
            : "";
          props.contactActions.fetchUserdetails(
            props.location.state.eventDetails.user_id
          );
          props.miscActions.clearState();
          return {
            userDetails: userInfo,
          };
          break;
        case miscActions.GET_PROFILE_DETAILS_FAILED:
          props.miscActions.clearState();
          return {
            userDetails: "",
          };
        //break;

        default:
          return {};
      }
    }

    
  }

  render() {
    var userDetails = this.state.userDetails;
    var currentUserRole = "";
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }
    //console.log(this.props.location.state.eventDetails);

    //console.log("this.props.location.state.eventDetails",this.props.location.state.eventDetails);
    // console.log("sam : ",this.state.memoryDetails.memories);

    return (
      <>
        <Helmet>
          <title>Forget Me Not - View Memory</title>
        </Helmet>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
            <div className="container">
              <div className="card border-0 border-radius-15 min-vh-75 overflow-hidden">
                <div className="card-header pad-x-20 pad-x-md-46 pad-y-20 bg-white">
                  <div className="d-md-flex justify-content-between align-items-center">
                    {currentUserRole === "user" && (
                      <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                        <i className="material-icons text-color1 align-middle fs-30">
                          event
                        </i>
                        <span className="pl-2">
                          {this.state.eventDetails.title !== undefined &&
                          this.state.eventDetails.title !== null &&
                          this.state.eventDetails.title !== ""
                            ? this.state.eventDetails.title.includes("Birthday")
                              ? this.state.eventDetails.user_contact_id
                                  .firstName !== undefined
                                ? this.state.eventDetails.user_contact_id
                                    .firstName +
                                  "'s " +
                                  this.state.eventDetails.title
                                : ""
                              : this.state.eventDetails.title
                            : ""}
                          {/* {this.state.eventDetails &&
                            this.state.eventDetails.title}{" "}
                          {this.state.memoryDetails &&
                            this.state.memoryDetails.year} */}
                        </span>
                      </h3>
                    )}

                    {currentUserRole === "sub-user" &&
                      this.state.userDetails !== "" &&
                      this.state.userDetails.invitedBy !== undefined && (
                        <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                          <i className="material-icons text-color1 align-middle fs-30">
                            event
                          </i>
                          <span className="pl-2">
                            Memory from{" "}
                            {this.state.userDetails.invitedBy.first_name}
                          </span>
                        </h3>
                      )}

                    {currentUserRole === "user" && (
                      <div className="pt-4 pt-md-0 d-flex justify-content-between align-items-center">
                        <div>
                          <Button
                            className="text-color1 border text-capitalize fs-19 pad-y-5 pad-x-20"
                            onClick={() =>
                              this.props.history.push({
                                pathname:
                                  "/edit-memory/" +
                                  this.state.memoryDetails._id,
                                state: {
                                  detail: this.state.memoryDetails,
                                  eventDetails: this.state.eventDetails,
                                  flag: "edit",
                                },
                              })
                            }
                          >
                            <i className="material-icons mr-2">edit</i> Edit
                          </Button>
                        </div>

                        <div>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={this.handleOpen.bind(this)}
                          >
                            <i className="material-icons fs-36">more_vert</i>
                          </IconButton>
                          <Menu
                            anchorEl={this.state.setOpen}
                            keepMounted
                            open={this.state.setOpen}
                            onClose={this.handleClose.bind(this)}
                          >
                            <MenuItem
                              className="fs-18 font-weight-Medium py-3"
                              onClick={() => this.handleCancelSchedule()}
                            >
                              <i className="material-icons text-color1 align-middle mr-2">
                                cancel_schedule_send
                              </i>{" "}
                              Cancel schedule
                            </MenuItem>
                            <MenuItem
                              className="fs-18 font-weight-Medium py-3"
                              onClick={this.handleDelete}
                            >
                              <i className="material-icons text-color1 align-middle mr-2">
                                delete
                              </i>{" "}
                              Delete memory
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    )}
                  </div>

                  <div></div>
                </div>

                {this.state.eventDetails.eventDate &&
                  currentUserRole === "user" && (
                    <div className="card-extra text-center bg-color1 text-white fs-18 py-3 px-3">
                      {this.state.memoryDetails !== undefined &&
                      this.state.memoryDetails !== null &&
                      this.state.memoryDetails !== "" &&
                      this.state.memoryDetails.memories !== undefined &&
                      this.state.memoryDetails.memories !== null &&
                      this.state.memoryDetails.memories !== "" &&
                      this.state.memoryDetails.memories.dispatched !==
                        undefined &&
                      this.state.memoryDetails.memories.dispatched !== null &&
                      this.state.memoryDetails.memories.dispatched !== "" &&
                      this.state.memoryDetails.memories.dispatched === true
                        ? "Delivered at"
                        : "This memory is scheduled to be delivered on"}{" "}
                      {/*{moment(
                      this.state.eventDetails &&
                        this.state.eventDetails.eventDate
                    ).format("MMMM Do YYYY")}*/}
                      {moment(
                        this.state.memoryDetails &&
                          this.state.memoryDetails.memories &&
                          this.state.memoryDetails.memories.eventDate
                      )
                        //.tz("America/New_York")
                        .format("Do MMMM YYYY")}
                      .
                    </div>
                  )}

                <div className="card-body">
                  <div className="col-md-11 px-0 px-md-3 mx-auto pt-4 pt-md-1">
                    <div className="w-100 mb-4 border-bottom pb-4">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                          {currentUserRole === "sub-user" && (
                            <nav aria-label="breadcrumb">
                              <ol className="breadcrumb bg-white text-nowrap flex-nowrap overflow-auto p-0">
                                <li className="breadcrumb-item text-color1">
                                  <Link to={"/secondary-user-dashboard"}>
                                    {/* <i className="material-icons">home</i> */}
                                    <HomeOutlinedIcon
                                      style={{
                                        fontSize: "22px",
                                        marginTop: "-4px",
                                      }}
                                    />
                                  </Link>
                                </li>

                                {this.state.userDetails !== "" &&
                                  this.state.userDetails !== undefined &&
                                  this.state.userDetails !== null &&
                                  this.state.userDetails.invitedBy !==
                                    undefined &&
                                  this.state.userDetails.invitedBy !== null &&
                                  this.state.userDetails.invitedBy !== "" && (
                                    <li className="breadcrumb-item text-color1">
                                      <Link to={"/secondary-user-dashboard/"}>
                                        {
                                          this.state.userDetails.invitedBy
                                            .first_name
                                        }{" "}
                                        {
                                          this.state.userDetails.invitedBy
                                            .last_name
                                        }
                                      </Link>
                                    </li>
                                  )}

                                {this.props.location.state !== undefined &&
                                  this.props.location.state.eventDetails !==
                                    undefined &&
                                  this.props.location.state.eventDetails !==
                                    "" &&
                                  this.props.location.state.eventDetails !==
                                    null && (
                                    <li className="breadcrumb-item text-color1">
                                      <Link
                                        to={
                                          "/secondary-user-memorylist/" +
                                          this.props.location.state.eventDetails
                                            ._id
                                        }
                                      >
                                        {this.props.location.state
                                          .eventDetails !== undefined &&
                                        this.props.location.state
                                          .eventDetails !== null &&
                                        this.props.location.state
                                          .eventDetails !== ""
                                          ? this.props.location.state.eventDetails.title.includes(
                                              "Birthday"
                                            )
                                            ? `Your ${this.props.location.state.eventDetails.title}`
                                            : this.props.location.state
                                                .eventDetails.title
                                          : ""}
                                        {/* {
                                          this.props.location.state.eventDetails
                                            .title
                                        } */}
                                      </Link>
                                    </li>
                                  )}

                                {this.props.location.state !== undefined &&
                                  this.props.location.state.detail !==
                                    undefined &&
                                  this.props.location.state.detail !== "" &&
                                  this.props.location.state.detail !== null && (
                                    <li className="breadcrumb-item text-color1">
                                      {this.props.location.state.detail.year}
                                    </li>
                                  )}
                              </ol>
                            </nav>
                          )}
                        </div>
                        <div className="col-md-auto">
                          <h3 className="mb-0 fs-24 fs-md-36">
                            {this.state.memoryDetails &&
                              this.state.memoryDetails &&
                              this.state.memoryDetails?.title}
                          </h3>
                        </div>
                        <div className="col-md-auto pt-2 pt-md-0">
                          {currentUserRole === "user" && (
                            <h5 className="mb-0 fs-18 opa-60 font-weight-Regular">
                              Written on{" "}
                              {moment(
                                this.state.memoryDetails &&
                                  this.state.memoryDetails &&
                                  this.state.memoryDetails?.createdAt
                              )
                                .tz("America/New_York")
                                .format("Do MMMM YYYY")}
                            </h5>
                          )}

                          {currentUserRole === "sub-user" &&
                            this.props.location.state !== undefined &&
                            this.props.location.state !== null &&
                            this.props.location.state !== "" &&
                            this.props.location.state.eventDetails !==
                              undefined &&
                            this.props.location.state.eventDetails !== null &&
                            this.props.location.state.eventDetails !== "" &&
                            this.props.location.state.eventDetails.eventDate !==
                              "" &&
                            this.props.location.state.eventDetails.eventDate !==
                              undefined &&
                            this.props.location.state.eventDetails.eventDate !==
                              null && (
                              <h5 className="mb-0 fs-18 opa-60 font-weight-Regular">
                                Arrived on{" "}
                                {moment(
                                  this.state.memoryDetails &&
                                    this.state.memoryDetails.memories &&
                                    this.state.memoryDetails.memories.unlockedOn
                                )
                                  //.tz("America/New_York")
                                  .format("Do MMMM YYYY")}
                              </h5>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="row addVideoImage mb-2">
                      {this.state.videoFiles &&
                        this.state.videoFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={index}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                {/*<img src={Image} alt="" className="w-100" />
                                <div className="position-absolute play">
                                  <a
                                    data-fancybox="gallery"
                                    href={UploadURLS.user_memories + list}
                                  >
                                    <img src={PlayBtn} alt="" />
                                  </a>
                                </div>*/}

                                {/*<video
                                  id="video"
                                  src={UploadURLS.user_memories + list}
                                  className="w-100"
                                  controls
                                ></video>*/}
                                <video
                                  className="w-100 viewvideo"
                                  controls
                                  playsInline
                                  onClick={(e) => e.target.requestFullscreen()}
                                  style={{ zoom: 1 }}
                                >
                                  <source
                                    src={list}
                                    type='video/webm;codecs="vp8, vorbis"'
                                  />
                                  <source
                                    src={list}
                                    type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                                  />
                                </video>

                                {/* <video className="w-100" controls playsInline>
                                  <source src={list} type="video/mp4" />
                                  <source src={list} type="video/webm" />
                                  <p>Does not support</p>
                                </video> */}
                              </figure>
                            </div>
                          </div>
                        ))}

                      {this.state.audioFiles &&
                        this.state.audioFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={index}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                {/*<img src={Image} alt="" className="w-100" />
                                <div className="position-absolute play">
                                  <a
                                    data-fancybox="gallery"
                                    href={UploadURLS.user_memories + list}
                                  >
                                    <img src={PlayBtn} alt="" />
                                  </a>
                                </div>*/}

                                {/*<video
                                  id="video"
                                  src={UploadURLS.user_memories + list}
                                  className="w-100"
                                  controls
                                ></video>*/}

                                <audio
                                  id="video"
                                  src={list}
                                  className="w-100"
                                  controls
                                  style={{
                                    background: `url(${audioIcon})`,
                                    height: "205px",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                ></audio>
                              </figure>
                            </div>
                          </div>
                        ))}

                      {this.state.imageFiles &&
                        this.state.imageFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={index}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                {/*<a
                                  data-fancybox="gallery"
                                  href={UploadURLS.user_memories + list}
                                >
                                  <img
                                    src={UploadURLS.user_memories + list}
                                    alt=""
                                    className="w-100"
                                  />
                                </a>*/}

                                <a data-fancybox="gallery" href={list}>
                                  <img src={list} alt="" className="w-100" />
                                </a>
                              </figure>
                            </div>
                          </div>
                        ))}
                    </div>

                    <div className="fs-16 fs-md-24 lh-28 lh-md-36 font-weight-Light text-black mb-5">
                      {this.state.memoryDetails &&
                        this.state.memoryDetails.memories &&
                        this.state.memoryDetails.memories.message}
                    </div>

                    {currentUserRole === "sub-user" &&
                      userDetails !== undefined &&
                      userDetails !== null &&
                      userDetails !== "" &&
                      userDetails.invitedBy !== undefined &&
                      userDetails.invitedBy !== null &&
                      userDetails.invitedBy !== "" && (
                        <div>
                          <span className="opa-60 fs-18">Yours,</span>
                          <h3 className="text-black fs-20 font-weight-Medium mb-0">
                            <img
                              className="object-fit-cover w-38px h-38px  rounded-circle"
                              src={
                                userDetails &&
                                userDetails.invitedBy &&
                                userDetails.invitedBy.profile_image
                                  ? UploadURLS.profile_image_path +
                                    userDetails.invitedBy.profile_image
                                  : UserIcon
                              }
                              alt={""}
                            />

                            {/*<img
                              className="object-fit-cover w-38px h-38px  rounded-circle"
                              src={
                                userDetails &&
                                userDetails.invitedBy &&
                                userDetails.invitedBy.profile_image
                                  ? userDetails.invitedBy.profile_image
                                  : UserIcon
                              }
                            />*/}

                            <span className="pl-2">
                              {userDetails &&
                                userDetails.invitedBy &&
                                userDetails.invitedBy.first_name}{" "}
                              {userDetails &&
                                userDetails.invitedBy &&
                                userDetails.invitedBy.last_name}
                            </span>
                          </h3>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
    memoryReducerData: state.memoryReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
  memoryActions: bindActionCreators(memoryActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMemory);
