import { Button, Typography } from '@material-ui/core'
import React from 'react'
import "./style.scss"
import logo from "../../../assets/images/homeFooter2.svg";
import footerFb from "../../../assets/images/footer-facebook.png"
import footerInsta from "../../../assets/images/footer-instra.png"
import footerTwitter from "../../../assets/images/footer-twitter.png"
import footerLinkedin from "../../../assets/images/footer-linkedin.png"

import { baseURL } from '../../../api';
import AnyOneImg from "../../../assets/images/connection.svg";
import foothome from "../../../assets/images/foothome.svg";
let imagePath = baseURL + "uploads/homepagecms/image/";


export default function HomeFooterComponent(props) {
    const pageDetails = props.pageDetails;
    const socialLink = props.socialLink;
    return (
        <>
            <section className="homeFooter">
                <div className="container-fluid">
                    <div className="row">
                        <img src={logo} alt="" className="d-block mx-auto" height="31" />
                        <h6 className="text-white text-center w-100 footer-text-logo">Forget Me Not</h6>
                        <p className="text-center w-100 mb-4">
                            <svg height="17px" viewBox="0 0 384 384">
                                <g>
                                    <g>
                                        <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
                                        c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
                                        c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
                                        C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z" fill="#898989"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <a href="tel:(631) 672-1260" className="pl-2 ">{socialLink?.socialurls?.phone}</a>
                            <br />

                            <p className="mt-3 d-inline-block" style={{color:'#898989'}}>
                                <img src={foothome} alt="" width="17" style={{ marginTop: '-7px' }} /> &nbsp;
                                {socialLink?.socialurls?.address}
                            </p>
                        </p>

                        <div className="socialName">
                            <p className="text-center">
                                <span className="pt-1">Stay In Touch: </span>

                                <a href={socialLink?.socialurls?.fb}>
                                    <img src={footerFb} style={{ margin: '8px' }} />
                                    {/* <svg height="22px" viewBox="0 0 512 512" ><path d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h151v-181h-60v-90h60v-61c0-49.628906 40.371094-90 90-90h91v90h-91v61h91l-15 90h-76v181h121c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm0 0" fill="#fff" /></svg> */}
                                </a>
                                <a href={socialLink?.socialurls?.insta}>
                                    <img src={footerInsta} style={{ margin: '8px' }} />


                                </a>
                                <a href={socialLink?.socialurls?.twitter}>

                                <img src={footerTwitter} style={{ margin: '8px' }} />
                                </a>
                                <a href={socialLink?.socialurls?.linkedin}>
                                <img src={footerLinkedin} style={{ margin: '8px' }} />
                                </a>
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
