import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

//import UserImage from '../assets/images/user2.png';
import UserImage from "../assets/images/usericon.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import UploadURLS from "../assets/settings.js";
import countryCode from "../assets/countryCode";
import { Helmet } from "react-helmet";
import moment from "moment";
import BillingInfoComponent from "../Components/Billinginfomadal/BillingInfoComponent";
import validator from "validator";

const modal = "d-flex align-items-center";
class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: null,
      title: "",
      resModal: false,
      userInfo: "",

      profilePreviewImage: "",
      profileMainImage: "",
      first_name: "",
      last_name: "",
      email: "",
      currentCountryCode: "+1",
      phone: "",
      memoryConsumption: "",
      isSubscribed: "",

      apiErrorMessage: "",
      apiSuccessMessage: "",
      isUploadNewImage: false,
      isEmailExistsValidate: false,
      isSubmitForm: false,
      old_password: "",
      new_password: "",
      conf_password: "",
      newPassError: "",
      confPassError: "",
      isError: false,
      isShowOldPassword: false,
      isShowNewPassword: false,
      isShowConfPassword: false,
      pending: false,
      isinfomdal: false,
    };
  }
  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");
    if (auth_token === "" || auth_token === undefined || auth_token === null) {
      this.props.history.push("/");
    } else {
      this.props.miscActions.getProfileDetails();
    }
  }
  handleOpen = (event) => {
    this.setState({ setOpen: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };
  handleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  handleModalChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  handleResClose = () => {
    this.setState({
      resModal: false,
      old_password: "",
      new_password: "",
      conf_password: "",
      newPassError: "",
      confPassError: "",
    });
  };
  validate = () => {
    let isError = false;
    this.setState({ confPassError: "", newPassError: "" });
    if (this.state.old_password === this.state.new_password) {
      isError = true;
      this.setState({
        newPassError: "Password can not be same as Old Password",
      });
    }
    if (this.state.new_password !== this.state.conf_password) {
      isError = true;
      this.setState({ confPassError: "Password doesn't match." });
    }
    return isError;
  };
  handleResSubmit = () => {
    // this.setState({ isSubmitForm: true })
    let err = this.validate();
    if (!err) {
      this.setState({ isSubmitForm: true });
      let new_data = {
        new_password: this.state.new_password,
        old_password: this.state.old_password,
      };
      console.log(new_data);
      this.props.miscActions.changeUserPassword(new_data);
    }
  };
  triggerInputFile = () => this.fileInput.click();

  fileUploadChange(e) {
    let profileImage = e.target.files;
    let profilePreviewImage = "";
    let profileMainImage = "";

    if (
      profileImage !== undefined &&
      profileImage !== null &&
      profileImage !== "" &&
      profileImage.length > 0
    ) {
      profileMainImage = profileImage[0];
      profilePreviewImage = URL.createObjectURL(profileMainImage);
    }

    if (profilePreviewImage !== "" && profileMainImage !== "") {
      this.setState({
        profilePreviewImage: profilePreviewImage,
        profileMainImage: profileMainImage,
        isUploadNewImage: true,
      });
    }
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.setState({
        apiErrorMessage: "",
        apiSuccessMessage: "",
      });
      //this.state.apiErrorMessage = "";
      //this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.setState({
        apiErrorMessage: "",
        apiSuccessMessage: "",
      });
      // this.state.apiErrorMessage = "";
      // this.state.apiSuccessMessage = "";
    }

    /* Update the user profile */
    if (this.state.isEmailExistsValidate === true) {
      var formData = new FormData();
      formData.append("first_name", this.state.first_name);
      formData.append("last_name", this.state.last_name);
      formData.append("email", this.state.email);
      if (this.state.profileMainImage !== "") {
        formData.append("profile_image", this.state.profileMainImage);
      }
      this.props.miscActions.userProfileUpdate(formData);
      this.setState({
        isEmailExistsValidate: false,
      });
      //this.state.isEmailExistsValidate = false;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        /* Fetch User Profile */
        case miscActions.GET_PROFILE_DETAILS_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          console.log("userInfo", userInfo);
          localStorage.setItem("user_info", JSON.stringify(userInfo));
          props.miscActions.clearState();
          return {
            userInfo: userInfo,
            profilePreviewImage:
              userInfo.profile_image !== undefined &&
              userInfo.profile_image !== null &&
              userInfo.profile_image !== ""
                ? UploadURLS.profile_image_path + userInfo.profile_image
                : "",
            profileMainImage: "",
            first_name:
              userInfo.first_name !== undefined &&
              userInfo.first_name !== null &&
              userInfo.first_name !== ""
                ? userInfo.first_name
                : "",
            last_name:
              userInfo.last_name !== undefined &&
              userInfo.last_name !== null &&
              userInfo.last_name !== ""
                ? userInfo.last_name
                : "",
            email:
              userInfo.email !== undefined &&
              userInfo.email !== null &&
              userInfo.email !== ""
                ? userInfo.email
                : "",

            currentCountryCode:
              userInfo.countryCode !== undefined &&
              userInfo.countryCode !== null &&
              userInfo.countryCode !== ""
                ? userInfo.countryCode
                : "+1",

            phone:
              userInfo.phoneNumber !== undefined &&
              userInfo.phoneNumber !== null &&
              userInfo.phoneNumber !== ""
                ? userInfo.phoneNumber
                : "",

            memoryConsumption:
              userInfo.memoryConsumption !== undefined &&
              userInfo.memoryConsumption !== null &&
              userInfo.memoryConsumption !== ""
                ? userInfo.memoryConsumption
                : "",

            isSubscribed:
              userInfo.isSubscribed !== undefined &&
              userInfo.isSubscribed !== null &&
              userInfo.isSubscribed !== ""
                ? userInfo.isSubscribed
                : "",
          };
        //break;
        case miscActions.GET_PROFILE_DETAILS_FAILED:
          props.miscActions.clearState();
          return {
            userInfo: "",
          };
        //break;

        /* check email already exists or not */
        case miscActions.CHECK_USER_EXISTS_FAILED:
          props.miscActions.clearState();
          return {
            apiErrorMessage:
              "Email is already exists! Please try another email",
          };
        //break;
        case miscActions.CHECK_USER_EXISTS_SUCCESS:
          props.miscActions.clearState();

          return {
            apiErrorMessage: "",
            apiSuccessMessage: "",
            isEmailExistsValidate: true,
          };
        //break;

        case miscActions.USER_PROFILE_UPDATE_REQUEST:
          return {
            pending: true,
          };

        case miscActions.USER_PROFILE_UPDATE_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";

          props.miscActions.clearState();
          localStorage.setItem("user_info", JSON.stringify(userInfo));
          props.history.push("/settings");
          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            userInfo: userInfo,
            isUploadNewImage: false,
            isEmailExistsValidate: false,
            pending: false,
          };
        //break;
        case miscActions.USER_PROFILE_UPDATE_FAILED:
          props.miscActions.clearState();
          message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";

          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            pending: false,
          };
        //break;

        case miscActions.LOGOUT_USER_PROFILE_SUCCESS:
          props.miscActions.clearState();
          localStorage.removeItem("auth_token");
          localStorage.removeItem("user_info");
          localStorage.removeItem("firstLogin");
          localStorage.clear();
          window.location.href = "/";
          //props.history.push("/");
          return {};
        //break;
        case miscActions.LOGOUT_USER_PROFILE_FAILED:
          props.miscActions.clearState();
          return {};
          break;

        case miscActions.USER_ACCOUNT_DEACTIVATE_SUCCESS:
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          console.log("message", message);
          props.miscActions.clearState();
          localStorage.removeItem("auth_token");
          localStorage.removeItem("user_info");
          props.history.push("/");
          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
          };
          break;
        case miscActions.USER_ACCOUNT_DEACTIVATE_FAILED:
          props.miscActions.clearState();
          return {};
          break;
        case miscActions.CHANGE_USER_PASSWORD_SUCCESS:
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          props.miscActions.clearState();
          // toast.success(message);
          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            resModal: false,
            isSubmitForm: false,
            old_password: "",
            new_password: "",
            conf_password: "",
            newPassError: "",
            confPassError: "",
          };
        case miscActions.CHANGE_USER_PASSWORD_FAILED:
          message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          props.miscActions.clearState();
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmitForm: false,
            old_password: "",
            new_password: "",
            conf_password: "",
            newPassError: "",
            confPassError: "",
          };
        default:
          return {};
      }
    }
    return {};
  }

  validateEmail(mail) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  updateProfile() {
    if (
      this.state.first_name === "" ||
      this.state.first_name === undefined ||
      this.state.first_name === null
    ) {
      toast.error("Please enter your first name");
    } else if (
      this.state.last_name === "" ||
      this.state.last_name === undefined ||
      this.state.last_name === null
    ) {
      toast.error("Please enter your last name");
    } else if (
      this.state.email === "" ||
      this.state.email === undefined ||
      this.state.email === null
    ) {
      toast.error("Please enter your email");
    } else if (this.validateEmail(this.state.email) === false) {
      toast.error("Please enter a valid email address");
    } else if (
      this.state.phone !== "" &&
      !validator.isMobilePhone(
        this.state.currentCountryCode + this.state.phone ||
          this.state.phone.trim().includes("+")
      ) &&
      !/^\s/.test(this.state.phone)
    ) {
      toast.error("Please enter a valid phone number");
    } else if (
      (this.state.currentCountryCode === "" ||
        this.state.currentCountryCode === null ||
        this.state.currentCountryCode === undefined) &&
      this.state.phone !== "" &&
      this.state.phone !== null &&
      this.state.phone !== undefined
    ) {
      toast.error("Please select a country code");
    }

    // else if ((this.state.phone === "" || this.state.phone === undefined || this.state.phone === null) && (this.state.currentCountryCode !== "" && this.state.currentCountryCode !== null && this.state.currentCountryCode !== undefined)) {
    //   toast.error("Please enter your phone no");z
    // }
    else {
      /* if user change the email address then check email adredy exixts or not */
      if (this.state.userInfo.email !== this.state.email) {
        this.props.miscActions.checkUserEmailExists(this.state.email);
      } else {
        var phone = this.state.phone;
        var formData = new FormData();
        formData.append("first_name", this.state.first_name);
        formData.append("last_name", this.state.last_name);
        formData.append("email", this.state.email);
        formData.append("phone", phone);
        formData.append("phoneNumber", this.state.phone);
        formData.append("countryCode", this.state.currentCountryCode);
        if (this.state.profileMainImage !== "") {
          formData.append("profile_image", this.state.profileMainImage);
        }
        this.props.miscActions.userProfileUpdate(formData);
      }
    }
  }

  profileLogout() {
    this.props.miscActions.logoutUserProfile();
  }

  deactivateAccountConfirmation = () => {
    var THIS = this;
    confirmAlert({
      title: "Confirm to deactivate account!",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => THIS.props.miscActions.userAccDeactivate(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  closeInfoModal = () => {
    this.setState({
      isinfomdal: false,
    });
  };

  render() {
    var isEnableSaveButton = false;
    /* active the save button when change the form value */
    if (
      this.state.userInfo.first_name !== this.state.first_name ||
      this.state.userInfo.last_name !== this.state.last_name ||
      this.state.userInfo.email !== this.state.email ||
      this.state.userInfo.countryCode !== this.state.currentCountryCode ||
      this.state.userInfo.phoneNumber !== this.state.phone ||
      this.state.isUploadNewImage === true
    ) {
      isEnableSaveButton = true;
    }

    var userCurrentPlanName =
      this.state.userInfo !== undefined &&
      this.state.userInfo !== "" &&
      this.state.userInfo !== null &&
      this.state.userInfo.plan_id !== undefined &&
      this.state.userInfo.plan_id !== "" &&
      this.state.userInfo.plan_id !== null &&
      this.state.userInfo.plan_id.header !== undefined &&
      this.state.userInfo.plan_id.header !== "" &&
      this.state.userInfo.plan_id.header !== null
        ? this.state.userInfo.plan_id.header
        : "";

    var userSubscriptionenddate =
      this.state.userInfo !== undefined &&
      this.state.userInfo !== "" &&
      this.state.userInfo !== null &&
      this.state.userInfo?.subscriptionData !== undefined &&
      this.state.userInfo?.subscriptionData !== "" &&
      this.state.userInfo?.subscriptionData !== null &&
      this.state.userInfo?.subscriptionData.endDate !== undefined &&
      this.state.userInfo?.subscriptionData.endDate !== "" &&
      this.state.userInfo?.subscriptionData.endDate !== null
        ? this.state.userInfo?.subscriptionData.endDate
        : "";

    // alert(userSubscriptionenddate)

    var userSubscriptionnewenddate =
      this.state.userInfo !== undefined &&
      this.state.userInfo !== "" &&
      this.state.userInfo !== null &&
      this.state.userInfo.subscriptionData !== undefined &&
      this.state.userInfo.subscriptionData !== "" &&
      this.state.userInfo.subscriptionData !== null &&
      this.state.userInfo.subscriptionData.endDate !== undefined &&
      this.state.userInfo.subscriptionData.endDate !== "" &&
      this.state.userInfo.subscriptionData.endDate !== null
        ? this.state.userInfo.subscriptionData.endDate
        : "";

    /* Fetch Current user role */

    var currentUserRole = "";
    var no_of_contacts = "";
    var event_memories_id = [];

    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
      no_of_contacts = user_info.no_of_contacts;
      event_memories_id = user_info.event_memories_id;
    }

    // var planUrl = "/user-plan";
    // if (currentUserRole === 'sub-user') {
    //   if (no_of_contacts === "" || no_of_contacts === null || no_of_contacts === undefined) {
    //     planUrl = "/sec-user-no-of-contacts"
    //   } else if (event_memories_id.length === 0) {
    //     planUrl = "/sec-user-event-care-about"
    //   }
    // }

    var memoryConsumption = this.state.memoryConsumption;
    var isSubscribed = this.state.isSubscribed;
    return (
      <>
        <Helmet>
          <title>Forget Me Not - Settings</title>
        </Helmet>

        <Modal
          className={modal}
          open={this.state.resModal}
          onClose={this.handleResClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="myCusTomModal d-flex">
            <div className="card">
              <div className="card-header border-bottom bg-white pad-x-20 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 ">
                  {/* <i className="material-icons text-color1 align-middle">
                    test
                  </i>{" "} */}
                  <span
                    className="float-left mr-2"
                    style={{ marginTop: "-2px " }}
                  >
                    <LockOpenIcon className="material-icons text-color1 align-middle " />{" "}
                  </span>
                  Reset Password
                </h3>
              </div>
              <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                <div className="row">
                  <h3 className="text-black mar-b-10 fs-18 font-weight-Regular">
                    Please enter Old Password
                  </h3>
                  <div className="mar-b-40 w-100 position-relative">
                    <TextField
                      placeholder="Old Password"
                      type={
                        this.state.isShowOldPassword === false
                          ? "password"
                          : "text"
                      }
                      variant="outlined"
                      className="w-100"
                      name="old_password"
                      value={this.state.old_password}
                      onChange={this.handleModalChange}
                      autoComplete="off"
                      required
                    />
                    <div
                      className="position-absolute "
                      style={{ top: "4px", right: "5px" }}
                      onClick={(e) =>
                        this.setState({
                          isShowOldPassword: !this.state.isShowOldPassword,
                        })
                      }
                    >
                      <IconButton>
                        <i className="material-icons">
                          {this.state.isShowOldPassword === true
                            ? "visibility"
                            : "visibility_off"}{" "}
                        </i>
                      </IconButton>
                    </div>
                  </div>
                  <h3 className="text-black mar-b-10 fs-18 font-weight-Regular">
                    Please enter New Password
                  </h3>
                  <div className="mar-b-40 w-100 position-relative">
                    <TextField
                      placeholder="New Password"
                      type={
                        this.state.isShowNewPassword === false
                          ? "password"
                          : "text"
                      }
                      variant="outlined"
                      className="w-100"
                      name="new_password"
                      value={this.state.new_password}
                      onChange={this.handleModalChange}
                      autoComplete="off"
                      error={Boolean(this.state.newPassError)}
                      helperText={this.state.newPassError}
                      required
                    />
                    <div
                      className="position-absolute "
                      style={{ top: "4px", right: "5px" }}
                      onClick={(e) =>
                        this.setState({
                          isShowNewPassword: !this.state.isShowNewPassword,
                        })
                      }
                    >
                      <IconButton>
                        <i className="material-icons">
                          {this.state.isShowNewPassword === true
                            ? "visibility"
                            : "visibility_off"}{" "}
                        </i>
                      </IconButton>
                    </div>
                  </div>
                  <h3 className="text-black mar-b-10 fs-18 font-weight-Regular">
                    Please confirm the New password
                  </h3>
                  <div className="mar-b-40 w-100 position-relative">
                    <TextField
                      placeholder="Confirm Password"
                      type={
                        this.state.isShowConfPassword === false
                          ? "password"
                          : "text"
                      }
                      variant="outlined"
                      className="w-100"
                      name="conf_password"
                      value={this.state.conf_password}
                      onChange={this.handleModalChange}
                      autoComplete="off"
                      error={Boolean(this.state.confPassError)}
                      helperText={this.state.confPassError}
                      required
                    />
                    <div
                      className="position-absolute "
                      style={{ top: "4px", right: "5px" }}
                      onClick={(e) =>
                        this.setState({
                          isShowConfPassword: !this.state.isShowConfPassword,
                        })
                      }
                    >
                      <IconButton>
                        <i className="material-icons">
                          {this.state.isShowConfPassword === true
                            ? "visibility"
                            : "visibility_off"}{" "}
                        </i>
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white text-right pad-x-40 ">
                {this.state.isSubmitForm === true && <CircularProgress />}

                {this.state.isSubmitForm === false && (
                  <>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize"
                      onClick={this.handleResClose.bind(this)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={
                        this.state.old_password &&
                        this.state.new_password &&
                        this.state.conf_password
                          ? "ml-3 bg-color1 text-white text-capitalize fs-19 font-weight-Bold"
                          : "ml-3 fs-19 font-weight-Bold text-capitalize"
                      }
                      disabled={
                        !(
                          this.state.old_password &&
                          this.state.new_password &&
                          this.state.conf_password
                        )
                      }
                      onClick={this.handleResSubmit}
                    >
                      Reset
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>

        {this.state.userInfo === "" && (
          <div className="page-center">
            <CircularProgress />
          </div>
        )}

        {this.state.userInfo !== "" && (
          <div className="d-flex vh-100 flex-column ">
            <div>
              <PrimaryHeader position={"innerHeader"} />
            </div>
            <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
              <div className="container">
                <div className="card border-0 border-radius-15 min-vh-75 overflow-hidden">
                  <div className="card-header pad-x-20 pad-x-lg-46 pad-y-20 bg-white">
                    <div className="d-md-flex justify-content-between align-items-center">
                      <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                        <i className="material-icons text-color1 align-middle fs-30">
                          settings
                        </i>
                        <span className="pl-2">Account settings</span>
                      </h3>

                      <div className="pt-4 pt-md-0">
                        {isEnableSaveButton === true && (
                          <Button
                            className="bg-color1 text-white text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-25"
                            onClick={(e) => this.updateProfile()}
                          >
                            <i className="material-icons pr-1">save</i>
                            {currentUserRole === "user" && (
                              <span>
                                {this.props.miscReducerData?.pending ? (
                                  <CircularProgress color="inherit" />
                                ) : (
                                  "Save"
                                )}
                              </span>
                            )}
                            {currentUserRole === "sub-user" && (
                              <span>
                                {this.props.miscReducerData?.pending ? (
                                  <CircularProgress color="inherit" />
                                ) : (
                                  "Save Changes"
                                )}
                              </span>
                            )}
                          </Button>
                        )}

                        {isEnableSaveButton === false && (
                          <Button
                            className="bg-color1 text-white text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-25   opa-50"
                            disabled
                          >
                            <i className="material-icons pr-1">save</i> Save
                            changes
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="col-lg-7 px-0 px-md-3 mx-auto pt-md-5">
                      <div>
                        <div className="w-100   ">
                          {/* {userSubscriptionenddate !== "" &&
                            new Date(userSubscriptionenddate) < new Date() && (
                              <div className="bg-light pad-x-15 pad-y-7 text-center d-md-flex align-items-center justify-content-between border border-radius-5 mb-2">
                                <div className="mr-2"></div>
                                <div className="mr-auto py-2 py-md-2">
                                  <h5 className="mb-0 text-red fs-14 fs-sm-18 opa-60">
                                    Your subscription has ended.
                                  </h5>
                                </div>
                                <div className="overflow-hidden position-relative"></div>
                              </div>
                            )} */}

                          <div className="bg-light pad-x-15 pad-y-7 text-center d-md-flex align-items-center justify-content-between border border-radius-5">
                            <div className="mr-2">
                              <i className="material-icons text-color1 align-middle fs-44 fs-md-24">
                                fingerprint
                              </i>
                            </div>

                            <div className="mr-auto py-2 py-md-0">
                              {userSubscriptionenddate !== "" &&
                                new Date(userSubscriptionenddate) <
                                  new Date() && (
                                  <h5 className="mb-0 text-red fs-14 fs-sm-18 opa-60">
                                    Your subscription has ended.
                                  </h5>
                                )}

                              <h5 className="mb-0 text-black fs-14 fs-sm-18 opa-60">
                                {userCurrentPlanName !== "" &&
                                userSubscriptionenddate !== "" &&
                                new Date(userSubscriptionenddate) > new Date()
                                  ? "You’re subscribed to the " +
                                    userCurrentPlanName +
                                    " plan." +
                                    "(End: " +
                                    moment(userSubscriptionnewenddate)
                                      .tz("America/New_York")
                                      .format("MMMM Do YYYY") +
                                    ")"
                                  : ""}{" "}
                              </h5>

                              {(userCurrentPlanName === "" ||
                                userSubscriptionenddate === "") && (
                                <h5 className="mb-0 text-black fs-14 fs-sm-18 opa-60">
                                  {"Subscribe & start sharing memories"}
                                </h5>
                              )}
                            </div>

                            <div className="overflow-hidden position-relative">
                              {currentUserRole === "user" &&
                                userSubscriptionenddate !== "" && (
                                  <Button
                                    data-title="hgghfhg"
                                    className="text-color1 font-weight-Mediam fs-16"
                                    onClick={(e) =>
                                      this.props.history.push("/user-plan")
                                    }
                                  >
                                    Upgrade
                                  </Button>
                                )}

                              {currentUserRole === "user" &&
                                userSubscriptionenddate === "" && (
                                  <Button
                                    data-title="hgghfhg"
                                    className="text-color1 font-weight-Mediam fs-16"
                                    onClick={(e) =>
                                      this.props.history.push("/user-plan")
                                    }
                                  >
                                    Upgrade
                                  </Button>
                                )}

                              {currentUserRole === "sub-user" && (
                                <Button
                                  className="text-color1 font-weight-Mediam fs-16"
                                  onClick={(e) =>
                                    this.props.history.push(
                                      "/sec-user-no-of-contacts"
                                    )
                                  }
                                >
                                  Upgrade now
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          className={`w-100 ${
                            currentUserRole === "user" ? "border-bottom" : ""
                          } py-3 mb-4`}
                        >
                          {currentUserRole === "user" &&
                            userSubscriptionenddate !== "" && (
                              <h6
                                className="fs-16 text-center text-color1 font-weight-Mediaum mb-0 cursor-pointer"
                                onClick={() => {
                                  this.setState({ isinfomdal: true });
                                }}
                              >
                                Billing Info
                              </h6>
                            )}
                        </div>
                      </div>

                      <div className="w-100 d-flex align-items-center mb-4">
                        <div className="rounded-circle mr-3">
                          <img
                            src={
                              this.state.profilePreviewImage !== "" &&
                              this.state.profilePreviewImage !== null &&
                              this.state.profilePreviewImage !== undefined
                                ? this.state.profilePreviewImage
                                : UserImage
                            }
                            alt=""
                            className="w-72px h-72px d-block object-fit-cover rounded-circle"
                            onClick={this.triggerInputFile}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div>
                          <h3 className=" fs-20 font-weight-Medium">
                            {this.state.first_name} {this.state.last_name}
                          </h3>

                          <Button
                            className="text-color1 font-weight-Mediaum p-0 fs-16 changeProfilepicturebtn"
                            onClick={this.triggerInputFile}
                            disabled={this.state.pending}
                          >
                            Change Profile Picture
                          </Button>

                          <input
                            hidden
                            ref={(fileInput) => (this.fileInput = fileInput)}
                            type="file"
                            accept="image/*"
                            onChange={(e) => this.fileUploadChange(e)}
                          />
                        </div>
                      </div>

                      <div className=" pt-2 row mb-4">
                        <div className="col-md-6 mb-3">
                          <TextField
                            label="First Name"
                            variant="outlined"
                            value={this.state.first_name}
                            onChange={(e) =>
                              this.setState({ first_name: e.target.value })
                            }
                            className="w-100"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <TextField
                            label="Last Name"
                            variant="outlined"
                            value={this.state.last_name}
                            onChange={(e) =>
                              this.setState({ last_name: e.target.value })
                            }
                            className="w-100"
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <TextField
                            label="Email Address"
                            variant="outlined"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                            className="w-100"
                          />
                        </div>
                        <div className="col-md-4">
                          <FormControl variant="outlined" className={"w-100"}>
                            <InputLabel id="demo-simple-select-label">
                              Country Code
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              name="country_code"
                              label="Country Code"
                              value={this.state.currentCountryCode}
                              onChange={(e) =>
                                this.setState({
                                  currentCountryCode: e.target.value,
                                })
                              }
                            >
                              {countryCode.map(
                                (singleCode, singleCodeIndex) => (
                                  <MenuItem
                                    value={singleCode.code}
                                    key={singleCodeIndex}
                                  >
                                    {singleCode.code} - {singleCode.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-8 mb-3 mobile_no">
                          <TextField
                            label="Phone No"
                            type={"Number"}
                            placeholder="Phone No"
                            variant="outlined"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                            className="w-100"
                            inputProps={{ maxLength: 12 }}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 12);
                            }}
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <Button
                            className="text-color1 font-weight-Mediaum p-0 fs-19 w-100 border py-2"
                            onClick={(e) => this.setState({ resModal: true })}
                          >
                            Reset Password
                          </Button>
                        </div>
                        <div className="col-md-12 mb-5">
                          <Button
                            className="text-color1 font-weight-Mediaum p-0 fs-19 w-100 border py-2 signoutbtn"
                            onClick={(e) => this.profileLogout()}
                            disabled={this.state.pending}
                          >
                            {currentUserRole === "user" && (
                              <span>Sign Out</span>
                            )}
                            {currentUserRole === "sub-user" && (
                              <span>Sign Out</span>
                            )}
                          </Button>
                        </div>
                      </div>

                      <div className="pb-3 mb-3 border-bottom text-center">
                        {memoryConsumption !== "" &&
                          memoryConsumption !== undefined &&
                          memoryConsumption !== null &&
                          memoryConsumption.used !== "" &&
                          memoryConsumption.used !== null &&
                          memoryConsumption.used !== undefined &&
                          memoryConsumption.limit !== "" &&
                          memoryConsumption.limit !== null &&
                          memoryConsumption.limit !== undefined &&
                          isSubscribed === true && (
                            <div className="text-center">
                              <h3 className=" mb-0 text-black fs-14 fs-sm-18 opa-60">
                                Memory Used{" "}
                                <span className="fs-20 font-weight-Medium">
                                  {memoryConsumption.used}/
                                  {memoryConsumption.limit}
                                </span>
                              </h3>
                            </div>
                          )}

                        <Button
                          className="text-red1 font-weight-Mediaum  fs-16  "
                          onClick={(e) => this.deactivateAccountConfirmation()}
                        >
                          {currentUserRole === "user" && (
                            <span>Deactivate my account</span>
                          )}
                          {currentUserRole === "sub-user" && (
                            <span>Deactivate my account</span>
                          )}
                        </Button>
                      </div>
                      {currentUserRole === "user" && (
                        <p className="text-center opa-60 text-black  fs-14">
                          Deleting the account will remove all your memories
                          permanently.
                        </p>
                      )}
                      {currentUserRole === "sub-user" && (
                        <p className="text-center opa-60 text-black  fs-14">
                          Deactivating your account will remove all your
                          memories from our database
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <BillingInfoComponent
          isinfomdal={this.state.isinfomdal}
          closeInfoModal={() => this.closeInfoModal()}
          userInfo={this.state.userInfo}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
