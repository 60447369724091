import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Button, Slider } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Icon from "../assets/images/icon7.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";

class RegisterStepTwoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfContacts: "",
      noOfContacts_err : ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.valuetext = this.valuetext.bind(this);
  }

  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");
    let registration_current_step = localStorage.getItem(
      "registration_current_step"
    );
    console.log("registration_current_step", registration_current_step);
    let registration_info = JSON.parse(localStorage?.getItem(
      "registration_info"
    ));    
    if (this.props.miscReducerData.step != "" && localStorage.getItem(
      "registration_info") && registration_info?.no_of_contacts !== undefined ) {
      // alert(JSON.parse(localStorage.getItem("registration_info")).no_of_contacts)
      this.setState({
        noOfContacts: registration_info?.no_of_contacts
      })

    } else {
      if (parseInt(registration_current_step) !== 2) {
        this.props.history.push("/sign-up");
      } else {
        if (
          auth_token === "" ||
          auth_token === undefined ||
          auth_token === null
        ) {
        } else {
          this.props.history.push("/");
        }
      }
    }
  }

  handleChange(event) {
    let fieldValue = event.target.value;
        var reg = new RegExp('^[0-9]');
          if(fieldValue < 1 || !reg.test(fieldValue)) {
                this.setState({
                    noOfContacts_err : "Atleast 1 contact required",
                    noOfContacts : "",
                })
              } else {
                    this.setState({
                      noOfContacts : event.target.value,
                      noOfContacts_err : ""
                    })
                }
    // this.setState(
    //   {
    //     noOfContacts: fieldValue,
    //   },
    //   () => {
    //     console.log(this.state.noOfContacts);
    //   }
    // );
  }
  // updateState12(value) {
  //   console.log(value);
  //   // this.setState({
  //   //   noOfContacts: value,
  //   // },()=>{
  //   //   console.log(this.state.noOfContacts)
  //   // });
  // }

  // valuetext= (event, value)=> {
  //   //console.log(value)
  //   //this.updateState12(value);
  //   this.setState({
  //     noOfContacts: value
  //   })
  // }

  handleSubmit(event) {
    event.preventDefault();

    if (
      this.state.noOfContacts === "" ||
      this.state.noOfContacts === undefined ||
      this.state.noOfContacts === null ||
      this.state.noOfContacts <= 0
    ) {
      toast.error("Please select number of contacts limit");
    } else {
      let registerData = localStorage.getItem("registration_info");
      if (
        registerData === "" ||
        registerData === undefined ||
        registerData === null
      ) {
        toast.error("Something went wrong! please try again");
        this.props.history.push("/sign-up");
      } else {
        let registerObj = JSON.parse(registerData);
        registerObj["no_of_contacts"] = this.state.noOfContacts;
        localStorage.setItem("registration_info", JSON.stringify(registerObj));
        localStorage.setItem("registration_current_step", 3);
        this.props.miscActions.Updatestep(3);
        this.props.history.push("/sign-up-step-three");
      }
    }
  }

  render() {
    // const marks = [
    //   {
    //     value: 5,
    //     label: "Less than 5 people",
    //   },
    //   {
    //     value: 20,
    //     label: "Twenty",
    //   },
    //   {
    //     value: 30,
    //     label: "Thirty",
    //   },
    // ];
    // console.log("check:",this.state.noOfContacts);
    return (
      <>
        <div className="card bg-transparent border-0 vh-100 overflow-auto">
          <PrimaryHeader
            position={"innerHeader"}
            otherMenuNotVisible={"stepTwo"}
          />
          <section className=" registerStepWrap h-100   overflow-auto bluebgsection">
            <div className="container  ">
              <div className="row">
                <div className="card border-0  mx-auto">
                  <div className="head text-right mr-md-n4">
                    <h5 className="mb-0">Step 2 of 5</h5>
                  </div>

                  <div className=" ">
                    <div className="iconBox mar-b-15 mar-b-md-35">
                      <img src={Icon} alt="" className="w-50px w-md-96px" />
                    </div>
                    <div className="fs-22 fs-md-36 font-weight-Bold  ">
                    Number of Contacts
                    </div>
                    <div className="subHead fs-14 fs-md-18 mar-b-30">
                      How many people would you like to share memories with?
                    </div>
                    <div className="info">
                      Don’t worry! You can add more people,<br />
                      later on.
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      {/* <div className="pad-t-30">
                        <FormControl variant="outlined" className={"w-100"}>
                          <InputLabel id="demo-simple-select-label">
                            Select your limit
                          </InputLabel>

                          <Slider
                            value={this.state.noOfContacts}
                            aria-labelledby="discrete-slider-restrict"
                            step={null}
                            valueLabelDisplay="auto"
                            marks={marks}
                            min="5"
                            max="30"
                            style={{ marginTop: "50px" }}
                            onChange={this.valuetext}
                            //getAriaValueText={()=>this.valuetext}
                          />
                        </FormControl>
                      </div> */}

                      <div className="pad-t-30">
                        {/* <h3 className="text-black col-md-12 mb-0 fs-18 font-weight-Regular">
                          Please add contact limit *
                        </h3> */}
                        <div className="col-md-12 pt-3">
                            <TextField 
                            type="number" 
                            value={this.state.noOfContacts}
                            inputProps={
                              { inputMode: 'numeric',
                              }
                            }  
                            variant="outlined" 
                            className="w-100" 
                            onChange={this.handleChange}
                            error={Boolean(this.state.noOfContacts_err)}
                            helperText={this.state.noOfContacts_err}
                            required
                            label={'Select number'}
                            />
                        </div>
                      </div>

                      <div
                        className="w-100 pad-t-50"
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          type="submit"
                          className="bg-color4 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                          onClick={() => {
                            this.props.history.push("/sign-up");
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                        >
                          Next
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStepTwoPage);
