import React, { Component } from 'react'
import PrimaryHeader from '../../Widgets/PrimaryHeader'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import UploadIcon from '../../assets/images/upload.png';
import AddUser from '../../assets/images/icon20.svg';
import userSmall from '../../assets/images/u1.png';
import UserPhoto from '../../assets/images/user1.png';
import Tip from '../../assets/images/icon19.svg';
import userAfter from '../../assets/images/userAfter.png';
import { Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
const modal= 'd-flex align-items-center';

const userName = ['Amanda', 'Oliver', 'George', 'Arthur', 'Leo', 'Jack']
export default class DashboardAfter extends Component {
    constructor(props){
        super(props);
        this.state={
            setOpen:false,
        }
    }
    handleOpen = () => {
       this.setState({setOpen:true});
      };
    
       handleClose = () => {
        this.setState({setOpen:false});
      };

    render() {
        var settings = {
            fade:true,
            dots: true,
            arrows: false,
            autoplay: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
   
        return (
            <>
                <div className="d-flex vh-100 flex-column ">
                    <div>
                        <PrimaryHeader position={'innerHeader'} />
                    </div>
                    <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-xxxl-9">

                                    <div className="userSlider">
                                        <Slider {...settings}>
                                            {userName.map((list, index) =>
                                                <div className="list" key={index}>
                                                    <h3 className="fs-24 font-weight-bold mar-b-22">Welcome back, {list} !</h3>
                                                    <div className="border-radius-10  bg-white d-md-flex pad-y-20  pad-y-md-40 pad-x-20 pad-x-md-50">
                                                        <div className="uploadImage mb-3 mb-md-0">
                                                            {/* <input type="file" /> */}
                                                            <div>
                                                            <img src={userAfter} alt="" className="w-100px h-100 rounded-circle object-fit-cover" />
                                                            </div>
                                                        </div>
                                                        <div className="pad-l-md-40">
                                                            <h6 className="text-uppercase font-weight-bold fs-14"><img src={Tip} alt="" className="d-inline-flex" /> pro tips</h6>
                                                            <h4 className="text-black fs-16 fs-xl-24 ">
                                                            <b className="font-weight-Bold">{list} </b> 
                                                            <span className="opa-80 font-weight-Regular">has added </span> 
                                                            <b className="font-weight-Bold"> “Marriage Anniversary”</b> 
                                                            <span className="opa-80 font-weight-Regular">as a significant event. </span> 
                                                            </h4>
   

                                                            <div className="pt-2">
                                                                <Button className="bg-color1 text-white pad-y-8 pad-x-20 text-capitalize fs-14 fs-xl-19"><i className="material-icons">add_circle</i> <span className="pl-2">Create memory for Tracey</span></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Slider>
                                    </div>

                                    <div className=" text-center bg-white border-radius-10  ">
                                        <div className="card-header border-0 pad-x-15 pad-x-md-46 pad-t-20 bg-white">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h3 className="text-black fs-16 fs-md-24 font-weight-Bold mb-0 text-left">
                                                    <i className="material-icons text-color1 align-middle fs-30">supervisor_account</i>
                                                    <span className="pl-2">Your contacts</span>
                                                </h3>

                                                <div>
                                                    <Button className="bg-color1 text-white text-capitalize fs-12 fs-md-16 pad-y-7 pad-x-15 pad-x-md-25 mar-l-20  "  ><i className="material-icons pr-1 fs-18 fs-md-24">person_add</i> Add contact</Button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body pad-x-15 pad-x-md-46">
                                            <div className="row">
                                               {[0,1,2,3].map((list,index)=>
                                                <div className="col-md-6 mb-4" key={index}>
                                                    <div className="shadow-sm px-4 py-3 border rounded">                                                  
                                                        <div className="d-flex align-items-center  justify-content-between mb-3">
                                                            <div>
                                                                <img src={userSmall} className="object-fit-cover w-55px h-55px  rounded-circle" />
                                                            </div>
                                                            <div className="border rounded-pill fs-14 text-secondary py-1 px-2">
                                                               Husband
                                                            </div>
                                                        </div>
                                                        <div className="fs-20 fs-xl-26 fs-xxxl-36 text-black opa-87 text-left">
                                                            Tracey Bachman
                                                        </div>
                                                        <div className="fs-14 fs-xl-18 text-black opa-60 text-left mb-4">
                                                            3 Memories created
                                                        </div>
                                                        <div className="text-left">
                                                           <Button className="border text-color1"><i className="material-icons mr-1">mms</i> Manage memories</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                               )}
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div className="col-lg-4 col-xxxl-3 pt-5 pt-lg-0">
                                    <div className="h-100 pad-y-34 pad-x-15 pad-x-md-55 border-radius-10 text-center bg-white">
                                        <div className="userImg mar-b-22">
                                            <img src={UserPhoto} alt="" className="mx-auto w-157px h-157px object-fit-cover rounded-circle" alt="" />
                                        </div>
                                        <h4 className="fs-22 font-weight-Regular lh-36 px-xxxl-5 text-black">I might not be there at 2020 with my family. But my memories will be.</h4>
                                        <h4 className="fs-22 font-weight-Bold mar-b-20">-Frank Underwood</h4>
                                        <p className="fs-14 text-black opa-40 font-weight-Regular">
                                            We said goodbye  to him at
                                            12 July 2019

                                            
                                        </p>
                                        <p className="fs-14 text-black opa-40 font-weight-Regular">

                                            Left 43 memories for 4 of his family members
                                        </p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


               <Modal
               className={modal}
                open={this.state.setOpen}
                onClose={this.handleClose.bind(this)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
        <div className="myCusTomModal d-flex">
        <div className="card   ">
            <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 "><i className="material-icons text-color1 align-middle">person_add</i> Create a contact</h3>
            </div>
            <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">1. What is the name of this person?</h3>
                <div className="row">
                    <div className="mar-b-40 col-md-6">
                    <TextField   label="First Name" variant="outlined" className="w-100" />
                    </div>
                    <div className="mar-b-40 col-md-6">
                    <TextField   label="Last Name" variant="outlined" className="w-100" />
                    </div>
                </div>
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">2. What is your relationship with this person? (Wife, Brother etc)</h3>
                <div className="row">
                    <div className="mar-b-40 col-md-12">
                    <TextField   label="Relationship" variant="outlined" className="w-100" />
                    </div>                     
                </div>
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">3. Add an email address or phone number -</h3>
                <div className="row">
                    <div className="  col-md-12">
                    <TextField   label="Email Address" variant="outlined" className="w-100" />
                    </div>                     
                    <div className=" col-md-12 py-2">
                        or
                    </div>                     
                    <div className="mar-b-40 col-md-12">
                    <TextField   label="Phone Number" variant="outlined" className="w-100" />
                    </div>                     
                </div>
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">4. Create a customised invitation (optional) -</h3>
                <div className="row">
                    <div className="mar-b-40 col-md-12">
                    <TextField   label="Write a message" variant="outlined" className="w-100" />
                    </div>                     
                </div>

            </div>
            <div className="card-footer bg-white text-right pad-x-40 ">
                    <Button className="text-color1 fs-19 font-weight-Medium text-capitalize"  onClick={this.handleClose.bind(this)}>Cancel</Button>
                    <Button className="text-color1 fs-19 font-weight-Medium text-capitalize opa-70"  disabled>Save</Button>
            </div>
        </div>
        </div>
      </Modal>
            </>
        )
    }
}


// img1.svg