import {
  /* fetch all relationship type */
  FETCH_ALL_RELATIONSHIP_TYPE_SUCCESS,
  FETCH_ALL_RELATIONSHIP_TYPE_FAILED,

  /* Save contact information */
  SAVE_CONTACT_INFO_SUCCESS,
  SAVE_CONTACT_INFO_FAILED,

  /* fetch user contact list */
  GET_USER_CONTACT_LIST_SUCCESS,
  GET_USER_CONTACT_LIST_FAILED,

  /* fetch user event list */
  FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS,
  FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED,

  /* Create new event */
  CREATE_NEW_EVENT_SUCCESS,
  CREATE_NEW_EVENT_FAILED,

  /* Update event */
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILED,

  /* Delete event */
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILED,

  /* Fetch Single event details */
  FETCH_SINGLE_EVENT_DETAILS_SUCCESS,
  FETCH_SINGLE_EVENT_DETAILS_FAILED,

  /* FETCH MEMORIES BY EVENT */
  FETCH_MEMORIES_BY_EVENT_SUCCESS,
  FETCH_MEMORIES_BY_EVENT_FAILED,

  /* CREATE USER MEMORY */
  CREATE_USER_MEMORY_SUCCESS,
  CREATE_USER_MEMORY_FAILED,

  /* UPDATE USER MEMORY */
  UPDATE_USER_MEMORY_SUCCESS,
  UPDATE_USER_MEMORY_FAILED,
  FILE_UPLOAD_PROGRESS,

  /* REMOVE FILE */
  REMOVE_FILE_SUCCESS,
  REMOVE_FILE_FAILED,

  /* DELETE MEMORIES BY EVENT */
  DELETE_USER_MEMORIES_SUCCESS,
  DELETE_USER_MEMORIES_FAILED,

  /* CANCEL SCHEDULED */
  CANCEL_SCHEDULE_SUCCESS,
  CANCEL_SCHEDULE_FAILED,

  /* FETCH EVENT DATES */
  FETCH_EVENT_DATES_SUCCESS,
  FETCH_EVENT_DATES_FAILED,

  /* NOTIFICATION PRO TIPS */
  NOTIFICATION_PRO_TIPS_LIST_SUCCESS,
  NOTIFICATION_PRO_TIPS_LIST_FAILED,

  /* SECONDARY USER EVENT SELECTION */
  SECONDARY_USER_EVENT_SELECTION_SUCCESS,
  SECONDARY_USER_EVENT_SELECTION_FAILED,

  /* SECONDARY USER SELECTION LIST */
  SECONDARY_USER_SELECTION_LIST_SUCCESS,
  SECONDARY_USER_SELECTION_LIST_FAILED,

  /* FETCH SECONDARY USER EVENTS */
  FETCH_SECONDARY_USER_EVENT_SUCCESS,
  FETCH_SECONDARY_USER_EVENT_FAILED,
  FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_SUCCESS,
  FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_FAILED,
  FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_SUCCESS,
  FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_FAILED,
  PRIMARY_USER_MEMORY_SHARED_LIST_SUCCESS,
  PRIMARY_USER_MEMORY_SHARED_LIST_FAILED,
  UPDATE_EVENT_TYPES_DATE_SUCCESS,
  UPDATE_EVENT_TYPES_DATE_FAILED,
  FETCH_PARENT_USER_LIST_SUCCESS,
  FETCH_PARENT_USER_LIST_FAILED,
  FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_SUCCESS,
  FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_FAILED,
  FETCH_USER_DETAILS_FAILED,
  FETCH_USER_DETAILS_SUCCESS,
  CLEAR_STATE,
  FORCE_USER_LOGIN_FAILED,
  FORCE_USER_LOGIN_SUCCESS,
  NEWSLETTER_SUBSCRIPTION_SUCCESS,
  NEWSLETTER_SUBSCRIPTION_FAILED,
} from "../actions/contactActions";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    /* FETCH PARENT USER LIST  */
    case FETCH_PARENT_USER_LIST_SUCCESS:
      return { type: FETCH_PARENT_USER_LIST_SUCCESS, responce: action.data };
    case FETCH_PARENT_USER_LIST_FAILED:
      return { type: FETCH_PARENT_USER_LIST_FAILED, responce: action.data };

    /* FETCH PARENT USER LIST  */
    case FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_SUCCESS:
      return {
        type: FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_SUCCESS,
        responce: action.data,
      };
    case FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_FAILED:
      return {
        type: FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_FAILED,
        responce: action.data,
      };

    /* FETCH USER DETAILS  */
    case FETCH_USER_DETAILS_SUCCESS:
      return { type: FETCH_USER_DETAILS_SUCCESS, responce: action.data };
    case FETCH_USER_DETAILS_FAILED:
      return { type: FETCH_USER_DETAILS_FAILED, responce: action.data };

    /* FETCH ALL RELATIONSHIP  */
    case FETCH_ALL_RELATIONSHIP_TYPE_SUCCESS:
      return {
        type: FETCH_ALL_RELATIONSHIP_TYPE_SUCCESS,
        responce: action.data,
      };
    case FETCH_ALL_RELATIONSHIP_TYPE_FAILED:
      return {
        type: FETCH_ALL_RELATIONSHIP_TYPE_FAILED,
        responce: action.data,
      };

    /* SAVE USER CONTACT */
    case SAVE_CONTACT_INFO_SUCCESS:
      return { type: SAVE_CONTACT_INFO_SUCCESS, responce: action.data };
    case SAVE_CONTACT_INFO_FAILED:
      return { type: SAVE_CONTACT_INFO_FAILED, responce: action.data };

    /* GET USER CONTACT LIST */
    case GET_USER_CONTACT_LIST_SUCCESS:
      return { type: GET_USER_CONTACT_LIST_SUCCESS, responce: action.data };
    case GET_USER_CONTACT_LIST_FAILED:
      return { type: GET_USER_CONTACT_LIST_FAILED, responce: action.data };

    /* GET USER EVENT LIST */
    case FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        type: FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS,
        responce: action.data,
      };
    case FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED:
      return {
        type: FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED,
        responce: action.data,
      };

    /* Create new event */
    case CREATE_NEW_EVENT_SUCCESS:
      return { type: CREATE_NEW_EVENT_SUCCESS, responce: action.data };
    case CREATE_NEW_EVENT_FAILED:
      return { type: CREATE_NEW_EVENT_FAILED, responce: action.data };

    /* Update event */
    case UPDATE_EVENT_SUCCESS:
      return { type: UPDATE_EVENT_SUCCESS, responce: action.data };
    case UPDATE_EVENT_FAILED:
      return { type: UPDATE_EVENT_FAILED, responce: action.data };

    /* Update event */
    case DELETE_EVENT_SUCCESS:
      return { type: DELETE_EVENT_SUCCESS, responce: action.data };
    case DELETE_EVENT_FAILED:
      return { type: DELETE_EVENT_FAILED, responce: action.data };

    /* Fetch Single event details */
    case FETCH_SINGLE_EVENT_DETAILS_SUCCESS:
      return {
        type: FETCH_SINGLE_EVENT_DETAILS_SUCCESS,
        responce: action.data,
      };
    case FETCH_SINGLE_EVENT_DETAILS_FAILED:
      return { type: FETCH_SINGLE_EVENT_DETAILS_FAILED, responce: action.data };

    /* FETCH USER MEMORIES BY EVENT */
    case FETCH_MEMORIES_BY_EVENT_SUCCESS:
      return { type: FETCH_MEMORIES_BY_EVENT_SUCCESS, responce: action.data };
    case FETCH_MEMORIES_BY_EVENT_FAILED:
      return { type: FETCH_MEMORIES_BY_EVENT_FAILED, responce: action.data };

    /* CREATE USER MEMORIES BY EVENT */
    case CREATE_USER_MEMORY_SUCCESS:
      return { type: CREATE_USER_MEMORY_SUCCESS, responce: action.data };
    case CREATE_USER_MEMORY_FAILED:
      return { type: CREATE_USER_MEMORY_FAILED, responce: action.data };

    /* UPDATE USER MEMORY BY EVENT */
    case UPDATE_USER_MEMORY_SUCCESS:
      return { type: UPDATE_USER_MEMORY_SUCCESS, responce: action.data };
    case UPDATE_USER_MEMORY_FAILED:
      return { type: UPDATE_USER_MEMORY_FAILED, responce: action.data };
    case FILE_UPLOAD_PROGRESS:
      return { type: FILE_UPLOAD_PROGRESS, responce: action.data };

    /* REMOVE FILE */
    case REMOVE_FILE_SUCCESS:
      return { type: REMOVE_FILE_SUCCESS, responce: action.data };
    case REMOVE_FILE_FAILED:
      return { type: REMOVE_FILE_FAILED, responce: action.data };

    /* DELETE USER MEMORIES BY EVENT*/
    case DELETE_USER_MEMORIES_SUCCESS:
      return { type: DELETE_USER_MEMORIES_SUCCESS, responce: action.data };
    case DELETE_USER_MEMORIES_FAILED:
      return { type: DELETE_USER_MEMORIES_FAILED, responce: action.data };

    /*CANCEL SCHEDULED */
    case CANCEL_SCHEDULE_SUCCESS:
      return { type: CANCEL_SCHEDULE_SUCCESS, responce: action.data };
    case CANCEL_SCHEDULE_FAILED:
      return { type: CANCEL_SCHEDULE_FAILED, responce: action.data };

    /* FETCH EVENT DATES */
    case FETCH_EVENT_DATES_SUCCESS:
      return { type: FETCH_EVENT_DATES_SUCCESS, responce: action.data };
    case FETCH_EVENT_DATES_FAILED:
      return { type: FETCH_EVENT_DATES_FAILED, responce: action.data };

    /* FETCH NOTIFOCATION PRO TIPS */
    case NOTIFICATION_PRO_TIPS_LIST_SUCCESS:
      return {
        type: NOTIFICATION_PRO_TIPS_LIST_SUCCESS,
        responce: action.data,
      };
    case NOTIFICATION_PRO_TIPS_LIST_FAILED:
      return { type: NOTIFICATION_PRO_TIPS_LIST_FAILED, responce: action.data };

    /* SECONDARY USER EVENT SELECTION */
    case SECONDARY_USER_EVENT_SELECTION_SUCCESS:
      return {
        type: SECONDARY_USER_EVENT_SELECTION_SUCCESS,
        responce: action.data,
      };
    case SECONDARY_USER_EVENT_SELECTION_FAILED:
      return {
        type: SECONDARY_USER_EVENT_SELECTION_FAILED,
        responce: action.data,
      };

    /* FETCH SECONDARY USER EVENTS */
    case FETCH_SECONDARY_USER_EVENT_SUCCESS:
      return {
        type: FETCH_SECONDARY_USER_EVENT_SUCCESS,
        responce: action.data,
      };
    case FETCH_SECONDARY_USER_EVENT_FAILED:
      return { type: FETCH_SECONDARY_USER_EVENT_FAILED, responce: action.data };

    /* SECONDARY USER SELECTION LIST */
    case SECONDARY_USER_SELECTION_LIST_SUCCESS:
      return {
        type: SECONDARY_USER_SELECTION_LIST_SUCCESS,
        responce: action.data,
      };
    case SECONDARY_USER_SELECTION_LIST_FAILED:
      return {
        type: SECONDARY_USER_SELECTION_LIST_FAILED,
        responce: action.data,
      };

    /* SECONDARY USER FETCH EVENTS */
    case FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_SUCCESS:
      return {
        type: FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_SUCCESS,
        responce: action.data,
      };
    case FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_FAILED:
      return {
        type: FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_FAILED,
        responce: action.data,
      };

    /* Fetch Primary user's user list using event id */
    case FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_SUCCESS:
      return {
        type: FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_SUCCESS,
        responce: action.data,
      };
    case FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_FAILED:
      return {
        type: FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_FAILED,
        responce: action.data,
      };

    /* User Memories - Primary User Memory Shared List */
    case PRIMARY_USER_MEMORY_SHARED_LIST_SUCCESS:
      return {
        type: PRIMARY_USER_MEMORY_SHARED_LIST_SUCCESS,
        responce: action.data,
      };
    case PRIMARY_USER_MEMORY_SHARED_LIST_FAILED:
      return {
        type: PRIMARY_USER_MEMORY_SHARED_LIST_FAILED,
        responce: action.data,
      };

    /* User Memories - Primary User Memory Shared List */
    case UPDATE_EVENT_TYPES_DATE_SUCCESS:
      return { type: UPDATE_EVENT_TYPES_DATE_SUCCESS, responce: action.data };
    case UPDATE_EVENT_TYPES_DATE_FAILED:
      return { type: UPDATE_EVENT_TYPES_DATE_FAILED, responce: action.data };

    /* USER FORCE LOGIN */
    case FORCE_USER_LOGIN_SUCCESS:
      return { type: FORCE_USER_LOGIN_SUCCESS, responce: action.data };
    case FORCE_USER_LOGIN_FAILED:
      return { type: FORCE_USER_LOGIN_FAILED, responce: action.data };

    /* USER FORCE LOGIN */
    case NEWSLETTER_SUBSCRIPTION_SUCCESS:
      return { type: NEWSLETTER_SUBSCRIPTION_SUCCESS, responce: action.data };
    case NEWSLETTER_SUBSCRIPTION_FAILED:
      return { type: NEWSLETTER_SUBSCRIPTION_FAILED, responce: action.data };

    /* CLEAR THE STATE VALUE */
    case CLEAR_STATE:
      return {};

    default:
      return state;
  }
};
