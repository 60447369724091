import { Button, Dialog, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import PlayBtn from "../assets/images/icon23.svg";
import UploadURLS from "../assets/settings.js";
import LinearProgress from "@material-ui/core/LinearProgress";

import TextField from "@material-ui/core/TextField";
import Image7 from "../assets/images/img7.jpg";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import audioIcon from "../assets/images/audio.png";
import VideoRecorder from "react-video-recorder";
import CloseIcon from "@material-ui/icons/Close";
import "react-voice-recorder/dist/index.css";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
const WAIT_INTERVAL = 1000;

class CreateMemory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      formData: {
        title: "",
        message: "",
        uploadedFiles: [],
      },
      eventDetails: {},
      singleMemoriData: {},
      uploadedVideoFiles: [],
      uploadedImageFiles: [],
      uploadedAudioFiles: [],
      memoryYear: "",
      loading: false,
      isDrafted: false,
      isDisabled: true,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      discardMemoryMessage: "",

      memoriesList: [],
      isFileUpload: false,

      audioOpen: false,
      videoOpen: false,
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      },
      recordState: null,
      recordAudio: null,
      recordAudioData: null,
      isStart: false,
      isStop: false,
      isPause: false,
      isUpload: false,
      audioCount: 0,
      uploadingPer: 0,
      currentBloburl: "",
      currentVideourl: "",
      videocloseClick: false,

      videoCount: 0,
      isVideoStart: false,
      isVideoStop: false,
      isVideoPause: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpen = (event) => {
    this.setState({ setOpen: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  componentDidMount() {
    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    var memory_year =
      this.props.location !== undefined &&
      this.props.location !== null &&
      this.props.location !== "" &&
      this.props.location.state !== undefined &&
      this.props.location.state !== null &&
      this.props.location.state !== "" &&
      this.props.location.state.detail !== undefined &&
      this.props.location.state.detail !== null &&
      this.props.location.state.detail !== "" &&
      this.props.location.state.detail.year !== undefined &&
      this.props.location.state.detail.year !== null &&
      this.props.location.state.detail.year !== ""
        ? this.props.location.state.detail.year
        : "";

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null ||
      memory_year === ""
    ) {
      this.props.history.push("/");
    } else {
      var currentEventId =
        this.props.match !== undefined &&
        this.props.match.params !== undefined &&
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "";
      if (currentEventId !== "") {
        this.props.contactActions.fetchSingleEventDetails(currentEventId);
      }

      //console.log('this.props.location.state.eventDetails',this.props.location.state.eventDetails)

      /*if (this.props.location.state) {

        if (this.props.location.state.detail.memories !== null) {
          let obj = { ...this.state.formData };
          obj.title = this.props.location.state.detail.memories.title;
          obj.message = this.props.location.state.detail.memories.message;
          this.setState({ formData: obj });
        }

        //console.log("this.props.location.state.detail.memories",this.props.location.state.detail.memories)

        this.setState({
            eventDetails: this.props.location.state.eventDetails,
            singleMemoriData: this.props.location.state.detail.memories,
            memoryYear: this.props.location.state.detail.year,
          },
          () => {
            if (
              this.state.singleMemoriData &&
              this.state.singleMemoriData.files.length > 0
            ) {
              let imageArr = this.state.singleMemoriData.files.filter((file) => {
                return (
                  file.includes("jpg") ||
                  file.includes("png") ||
                  file.includes("gif") ||
                  file.includes("jpeg")
                );
              });
              this.setState({ uploadedImageFiles: imageArr });

              let videoArr = this.state.singleMemoriData.files.filter((file) => {
                return (
                  file.includes("mp4") ||
                  file.includes("mov") ||
                  file.includes("wmv") ||
                  file.includes("flv")
                );
              });
              this.setState({ uploadedVideoFiles: videoArr });
            }
          }
        );
      }*/
    }
  }

  componentDidUpdate() {
    if (this.state.apiErrorMessage) {
      toast.error(this.state.apiErrorMessage);
      this.setState({
        apiErrorMessage: "",
      });
      this.setState({ loading: false });
    } else if (this.state.apiSuccessMessage) {
      toast.success(this.state.apiSuccessMessage);
      this.setState({
        apiSuccessMessage: "",
      });
      this.setState({ loading: false });

      if (this.state.isDrafted === true) {
        //toast.success("Memory scheduled Successfully");
        this.setState({
          apiSuccessMessage: "",
        });
        this.props.history.push("/view-event/" + this.state.eventDetails._id);
      }
    } else if (this.state.discardMemoryMessage) {
      // toast.success("Memory discarded successfully");
      this.setState({
        apiSuccessMessage: "",
      });
      this.props.history.replace("/view-event/" + this.state.eventDetails._id);
    } else {
      //toast.success(this.state.apiSuccessMessage);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        case contactActions.UPDATE_USER_MEMORY_SUCCESS:
          props.contactActions.clearState();
          var updateEventSuccess = contactReducerData.responce.data;

          var singleMemoriData = updateEventSuccess.data;
          var formDataObj = state.formData;

          if (formDataObj.uploadedFiles.length > 0) {
            formDataObj.uploadedFiles.forEach(function (
              singleFile,
              singleFileIndx
            ) {
              singleFile["uploadedFileUrl"] =
                singleMemoriData.files[singleFileIndx];
            });
          }

          return {
            apiErrorMessage: "",
            apiSuccessMessage: updateEventSuccess.message,
            isDrafted: updateEventSuccess.data.scheduled,
            loading: false,
          };
          break;
        case contactActions.UPDATE_USER_MEMORY_FAILED:
          props.contactActions.clearState();
          return {
            apiErrorMessage: "Something Went Wrong! please try again",
            apiSuccessMessage: "",
            loading: false,
          };
          break;

        case contactActions.CREATE_USER_MEMORY_SUCCESS:
          props.contactActions.clearState();
          var createEventSuccess = contactReducerData.responce.data;

          return {
            apiErrorMessage: "",
            apiSuccessMessage: createEventSuccess.message,
            singleMemoriData: createEventSuccess.data,
            isDrafted: createEventSuccess.data.scheduled,
            loading: false,
          };
        case contactActions.CREATE_USER_MEMORY_FAILED:
          props.contactActions.clearState();
          return {
            apiErrorMessage: "",
            apiSuccessMessage: "",
            loading: false,
          };
        case contactActions.DELETE_USER_MEMORIES_SUCCESS:
          props.contactActions.clearState();
          var createEventSuccess = contactReducerData.responce.data;
          return {
            apiErrorMessage: "",
            discardMemoryMessage: createEventSuccess.message,
          };
          break;

        case contactActions.FETCH_SINGLE_EVENT_DETAILS_SUCCESS:
          var eventDetails =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";

          if (eventDetails) {
            props.contactActions.fetchUserMemoriesByEvent(
              eventDetails._id,
              eventDetails.user_contact_id._id
            );

            props.contactActions.clearState();
            return {
              eventDetails: eventDetails,
            };
          } else {
            props.contactActions.clearState();
            return {
              eventDetails: {},
            };
          }

          break;
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_FAILED:
          return {
            eventDetails: {},
          };
          break;

        /* Fetch memory */
        case contactActions.FETCH_MEMORIES_BY_EVENT_SUCCESS:
          let memories = contactReducerData.responce.data
            ? contactReducerData.responce.data
            : [];
          props.contactActions.clearState();

          var memory_year = props.location.state.detail.year;
          var singleMemoriData = {};
          var uploadedVideoFiles = [];
          var uploadedImageFiles = [];
          var uploadedAudioFiles = [];
          let obj = state.formData;

          if (
            memories !== undefined &&
            memories.length > 0 &&
            memory_year !== ""
          ) {
            memories.forEach(function (singleMemory, singleMemoryIndx) {
              if (singleMemory.year === memory_year) {
                singleMemoriData = singleMemory.memories;
              }
            });
          }

          if (
            singleMemoriData !== undefined &&
            singleMemoriData !== null &&
            singleMemoriData !== "" &&
            singleMemoriData.files !== undefined &&
            singleMemoriData.files.length > 0
          ) {
            uploadedImageFiles = singleMemoriData.files.filter((file) => {
              return (
                file.includes("jpg") ||
                file.includes("JPG") ||
                file.includes("png") ||
                file.includes("PNG") ||
                file.includes("gif") ||
                file.includes("GIF") ||
                file.includes("jpeg") ||
                file.includes("JPEG")
              );
            });

            uploadedVideoFiles = singleMemoriData.files.filter((file) => {
              return (
                file.includes("mp4") ||
                file.includes("MP4") ||
                file.includes("mov") ||
                file.includes("MOV") ||
                file.includes("wmv") ||
                file.includes("WMV") ||
                file.includes("flv") ||
                file.includes("FLV") ||
                file.includes("video") ||
                file.includes("VIDEO") ||
                file.includes("webm") ||
                file.includes("WEBM")
              );
            });

            uploadedAudioFiles = singleMemoriData.files.filter((file) => {
              return (
                file.includes("mp3") ||
                file.includes("wav") ||
                file.includes("ogg")
              );
            });
          }

          /* store the pre value to the upload file array */
          var uploadedFiles = [];
          var preFiles =
            singleMemoriData !== null &&
            singleMemoriData !== undefined &&
            singleMemoriData !== "" &&
            singleMemoriData.files !== undefined &&
            singleMemoriData.files !== null &&
            singleMemoriData.files !== ""
              ? singleMemoriData.files
              : [];
          if (preFiles.length > 0) {
            preFiles.forEach(function (singleFile, singleFileIndx) {
              var temp = {};
              temp["uploadedFileUrl"] = singleMemoriData.files[singleFileIndx];
              uploadedFiles.push(temp);
            });
          }

          if (singleMemoriData !== null) {
            obj.title =
              singleMemoriData.title !== undefined &&
              singleMemoriData.title !== null &&
              singleMemoriData.title !== ""
                ? singleMemoriData.title
                : "";
            obj.message =
              singleMemoriData.message !== undefined &&
              singleMemoriData.message !== null &&
              singleMemoriData.message !== ""
                ? singleMemoriData.message
                : "";
            obj.uploadedFiles = uploadedFiles;
          }

          return {
            memoriesList: memories,
            singleMemoriData: singleMemoriData,
            uploadedImageFiles: uploadedImageFiles,
            uploadedVideoFiles: uploadedVideoFiles,
            uploadedAudioFiles: uploadedAudioFiles,
            formData: obj,
            memoryYear: memory_year,
          };
          break;
        case contactActions.FILE_UPLOAD_PROGRESS:
          // props.contactActions.clearState();
          let per = contactReducerData.responce;
          return {
            uploadingPer: per,
          };
        default:
          return {};
      }

      return {};
    }
  }

  handleChange = (event) => {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    formData[fieldName] = fieldValue;
    this.setState(
      {
        formData: formData,
        isDisabled: false,
      },
      () => {
        // clearTimeout(this.timer);
        // this.timer = setTimeout(() => {
        //   if (this.state.singleMemoriData !== null) {
        //     var formData = new FormData();
        //     formData.append("title", this.state.formData.title);
        //     formData.append("message", this.state.formData.message);
        //     formData.append("scheduled", false);
        //     formData.append("event_id", this.state.eventDetails._id);
        //     formData.append("memoryId", this.state.singleMemoriData._id);
        //     formData.append(
        //       "user_contact_id",
        //       this.state.eventDetails.user_contact_id._id
        //     );
        //     formData.append("year", this.state.singleMemoriData.year);
        //     this.props.contactActions.updateUserMemory(formData);
        //   } else {
        //     var formData = new FormData();
        //     formData.append("title", this.state.formData.title);
        //     formData.append("message", this.state.formData.message);
        //     formData.append("scheduled", false);
        //     formData.append("event_id", this.state.eventDetails._id);
        //     formData.append(
        //       "user_contact_id",
        //       this.state.eventDetails.user_contact_id._id
        //     );
        //     formData.append("year", this.state.memoryYear);
        //     this.props.contactActions.createUserMemory(formData);
        //   }
        // }, WAIT_INTERVAL);
      }
    );
  };

  handleSubmit = () => {
    if (
      this.state.formData.title === "" ||
      this.state.formData.message === ""
    ) {
      toast.error("Title and message infornation are required");
    } else if (!this.state.formData.title) {
      toast.error("Please enter title.");
    } else if (!this.state.formData.message) {
      toast.error("Please enter message");
    } else {
      if (this.state.singleMemoriData === null) {
        let formData = new FormData();
        formData.append("title", this.state.formData.title);
        formData.append("message", this.state.formData.message);
        formData.append("scheduled", true);
        formData.append("event_id", this.state.eventDetails._id);
        formData.append(
          "user_contact_id",
          //this.state.eventDetails.user_contact_id.user_id
          this.state.eventDetails.user_contact_id._id
        );
        formData.append("year", this.state.memoryYear);

        if (this.state.formData.uploadedFiles.length > 0) {
          this.state.formData.uploadedFiles.forEach(function (singleItm) {
            if (
              singleItm.mainFile !== undefined &&
              singleItm.mainFile !== "" &&
              singleItm.mainFile !== null
            ) {
              formData.append("files", singleItm.mainFile);
            }
          });
        }

        this.setState({ loading: true });
        this.props.contactActions.createUserMemory(formData);
      } else {
        this.state.formData.uploadedFiles.map((item) => {
          delete item.previewImage;
          return item;
        });

        let formData = new FormData();

        formData.append("title", this.state.formData.title);
        formData.append("message", this.state.formData.message);
        formData.append("scheduled", true);
        formData.append("event_id", this.state.eventDetails._id);
        formData.append(
          "user_contact_id",
          this.state.eventDetails.user_contact_id._id
        );
        formData.append("year", this.state.singleMemoriData.year);
        formData.append("memoryId", this.state.singleMemoriData._id);

        if (this.state.formData.uploadedFiles.length > 0) {
          this.state.formData.uploadedFiles.forEach(function (singleItm) {
            if (
              singleItm.mainFile !== undefined &&
              singleItm.mainFile !== "" &&
              singleItm.mainFile !== null
            ) {
              formData.append("files", singleItm.mainFile);
            }
          });
        }

        /* Append old files */
        let oldFileArr = [
          ...this.state.uploadedImageFiles,
          ...this.state.uploadedVideoFiles,
          ...this.state.uploadedAudioFiles,
        ];
        if (oldFileArr.length > 0) {
          oldFileArr.forEach(function (item) {
            formData.append("oldFiles[]", item);
          });
        }

        this.setState({ loading: true });
        this.props.contactActions.updateUserMemory(formData);
      }
    }
  };

  fileUploadChange(e) {
    let memoryFiles = e.target.files;

    let previewImage = "";
    let fileType = "";
    if (memoryFiles.length > 0) {
      memoryFiles = memoryFiles[0];
      previewImage = URL.createObjectURL(memoryFiles);
      fileType = memoryFiles["type"].split("/")[0];
    }

    if (previewImage !== "" && memoryFiles !== "" && fileType !== "") {
      let formData = this.state.formData;
      let preUploadedImage = formData.uploadedFiles; // fetch pre upload files and push new file

      let tempImg = {
        mainFile: memoryFiles,
        previewFile: previewImage,
        fileType: fileType,
      };
      preUploadedImage.push(tempImg);

      console.log("preUploadedImage", preUploadedImage);

      formData["uploadedFiles"] = preUploadedImage;
      this.setState(
        {
          formData: formData,
          isDisabled: false,
          isFileUpload: true,
        },
        () => {
          //var formData = new FormData();
          // formData.append("scheduled", false);
          // formData.append("event_id", this.state.eventDetails._id);
          // formData.append(
          //   "user_contact_id",
          //   //this.state.eventDetails.user_contact_id.user_id
          //   this.state.eventDetails.user_contact_id._id
          // );
          // formData.append("year", this.state.memoryYear);
          // formData.append("memoryId", this.state.singleMemoriData._id);
          // this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //   formData.append("files", singleItm.mainFile);
          // });
          // if (!this.state.singleMemoriData) {
          //   formData.append("scheduled", false);
          //   formData.append("event_id", this.state.eventDetails._id);
          //   formData.append(
          //     "user_contact_id",
          //     this.state.eventDetails.user_contact_id._id
          //   );
          //   formData.append("year", this.state.memoryYear);
          //   this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //     formData.append("files", singleItm.mainFile);
          //   });
          //   this.props.contactActions.createUserMemory(formData);
          // } else {
          //   formData.append("scheduled", false);
          //   formData.append("event_id", this.state.eventDetails._id);
          //   formData.append(
          //     "user_contact_id",
          //     this.state.eventDetails.user_contact_id._id
          //   );
          //   formData.append("year", this.state.memoryYear);
          //   formData.append("memoryId", this.state.singleMemoriData._id);
          //   this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //     if (
          //       singleItm.mainFile !== undefined &&
          //       singleItm.mainFile !== "" &&
          //       singleItm.mainFile !== null
          //     ) {
          //       formData.append("files", singleItm.mainFile);
          //     }
          //   });
          //   /* Append old files */
          //   let oldFileArr = [
          //     ...this.state.uploadedImageFiles,
          //     ...this.state.uploadedVideoFiles,
          //     ...this.state.uploadedAudioFiles,
          //   ];
          //   if (oldFileArr.length > 0) {
          //     oldFileArr.forEach(function (item) {
          //       formData.append("oldFiles[]", item);
          //     });
          //   }
          //   this.props.contactActions.updateUserMemory(formData);
          // }
        }
      );

      e.target.value = ""; // reset input type file
    }
  }

  removeFileConfirm(removeIndx) {
    let formData = this.state.formData;
    let preUploadedImage = formData.uploadedFiles;

    console.log("preUploadedImage[removeIndx]", preUploadedImage[removeIndx]);
    console.log("removeIndx", removeIndx);
    if (
      preUploadedImage[removeIndx] !== undefined &&
      preUploadedImage[removeIndx].uploadedFileUrl !== undefined
    ) {
      let data = {
        fileName: preUploadedImage[removeIndx].uploadedFileUrl,
        memoryId: this.state.singleMemoriData._id,
      };

      this.props.contactActions.removeFile(data);
    }

    preUploadedImage.splice(removeIndx, 1); // remove file form array
    formData["uploadedFiles"] = preUploadedImage;

    this.setState({
      formData: formData,
    });
  }

  removeFile(removeIndx) {
    var THIS = this;
    confirmAlert({
      title: "Confirm to remove!",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => THIS.removeFileConfirm(removeIndx),
        },
        {
          label: "No",
        },
      ],
    });
  }

  removeUploadedFile(file, type) {
    var THIS = this;
    confirmAlert({
      title: "Confirm to remove!",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => THIS.removeUploadedFileConfirm(file, type),
        },
        {
          label: "No",
        },
      ],
    });
  }

  removeUploadedFileConfirm(file, type) {
    //console.log('this.state.singleMemoriData',this.state.singleMemoriData._id)
    let data = {
      fileName: file,
      memoryId: this.state.singleMemoriData._id,
    };
    if (type === "image") {
      let updatedImageArr = this.state.uploadedImageFiles.filter((item) => {
        return item !== file;
      });
      this.props.contactActions.removeFile(data);
      this.setState({ uploadedImageFiles: updatedImageArr });
    } else if (type === "video") {
      let updatedVideoArr = this.state.uploadedVideoFiles.filter((item) => {
        return item !== file;
      });
      this.props.contactActions.removeFile(data);
      this.setState({ uploadedVideoFiles: updatedVideoArr });
    } else if (type === "audio") {
      let updatedAudioArr = this.state.uploadedAudioFiles.filter((item) => {
        return item !== file;
      });
      this.props.contactActions.removeFile(data);
      this.setState({ uploadedAudioFiles: updatedAudioArr });
    }
  }

  resetForm() {
    var resetValues = {
      title: "",
      message: "",
      uploadedFiles: [],
    };

    this.setState(
      {
        formData: resetValues,
        memoryYear: "",
        isDrafted: false,
        uploadedVideoFiles: [],
        uploadedImageFiles: [],
        uploadedAudioFiles: [],
      },
      () => {
        this.props.history.replace(
          "/view-event/" + this.state.eventDetails._id
        );
      }
    );
  }

  componentWillMount() {
    this.timer = null;
  }

  saveDraft() {
    var formData = new FormData();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (
        this.state.formData.title == "" ||
        this.state.formData.message == ""
      ) {
        toast.error("Title and message infornation are required");
      } else {
        if (this.state.singleMemoriData === null) {
          formData.append("title", this.state.formData.title);
          formData.append("message", this.state.formData.message);
          formData.append("scheduled", false);
          formData.append("event_id", this.state.eventDetails._id);
          formData.append(
            "user_contact_id",
            //this.state.eventDetails.user_contact_id.user_id
            this.state.eventDetails.user_contact_id._id
          );
          formData.append("year", this.state.memoryYear);

          this.state.formData.uploadedFiles.forEach(function (singleItm) {
            if (
              singleItm.mainFile !== undefined &&
              singleItm.mainFile !== "" &&
              singleItm.mainFile !== null
            ) {
              formData.append("files", singleItm.mainFile);
            }
          });

          /* Append old files */
          let oldFileArr = [
            ...this.state.uploadedImageFiles,
            ...this.state.uploadedVideoFiles,
            ...this.state.uploadedAudioFiles,
          ];
          if (oldFileArr.length > 0) {
            oldFileArr.forEach(function (item) {
              formData.append("oldFiles[]", item);
            });
          }
          this.setState({ loading: true });
          this.props.contactActions.createUserMemory(formData);
        } else {
          formData.append("title", this.state.formData.title);
          formData.append("message", this.state.formData.message);
          formData.append("scheduled", false);
          formData.append("event_id", this.state.eventDetails._id);
          formData.append("memoryId", this.state.singleMemoriData._id);
          formData.append(
            "user_contact_id",
            //this.state.eventDetails.user_contact_id.user_id
            this.state.eventDetails.user_contact_id._id
          );
          formData.append("year", this.state.singleMemoriData.year);

          this.state.formData.uploadedFiles.forEach(function (singleItm) {
            if (
              singleItm.mainFile !== undefined &&
              singleItm.mainFile !== "" &&
              singleItm.mainFile !== null
            ) {
              formData.append("files", singleItm.mainFile);
            }
          });

          /* Append old files */
          let oldFileArr = [
            ...this.state.uploadedImageFiles,
            ...this.state.uploadedVideoFiles,
            ...this.state.uploadedAudioFiles,
          ];
          if (oldFileArr.length > 0) {
            oldFileArr.forEach(function (item) {
              formData.append("oldFiles[]", item);
            });
          }

          this.setState({ loading: true });
          this.props.contactActions.updateUserMemory(formData);
        }
      }
    }, WAIT_INTERVAL);
  }

  discardMemory = () => {
    //console.log("Discard Memory");
    this.setState({ setOpen: false });
    var THIS = this;
    confirmAlert({
      title: "Confirm to cancel schedule!",
      message: "Are you sure want to cancel this schedule ?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.contactActions.deleteMemories(
              this.state.singleMemoriData._id
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  /*...Video And Audio recording...*/
  openvideorecorder = () => {
    this.setState({ videoOpen: true, currentVideourl: "", currentBloburl: "" });
  };

  handleopenAudiorecorder = () => {
    this.setState({ audioOpen: true });
  };

  handleCloseAudiorecorder = () => {
    this.stop();
    this.setState({
      audioOpen: false,
      recordState: null,
      recordAudio: null,
      recordAudioData: null,
      isStart: false,
      isStop: false,
      isPause: false,
      isUpload: false,
      audioCount: 0,
    });
  };

  handleClosevideorecorder = () => {
    this.stop1();
    this.setState({
      currentVideourl: "",
      currentBloburl: "",
      videoOpen: false,
      videocloseClick: true,
      isVideoStart: false,
      isVideoStop: false,
      isVideoPause: false,
      videoCount: 0,
    });
  };

  handleAudioStop(data) {
    console.log(data);
    this.setState({ audioDetails: data });
  }
  // handleAudioUpload(file) {
  //   const url = URL.createObjectURL(file);
  //   this.updateaudoformdata(file, url);
  //   console.log("videoBlob1233", url);

  //   console.log(file);
  // }

  handleAudioUpload = () => {
    console.log(this.state.recordAudioData);
    //const url = URL.createObjectURL(this.state.recordAudioData);
    // const url = URL.createObjectURL(this.state.recordAudioData);
    this.updateaudoformdata(
      this.state.recordAudioData,
      this.state.recordAudioData.url
    );
    // console.log("videoBlob1233", url);
  };

  handleRest() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      },
    };
    this.setState({ audioDetails: reset });
  }

  //Update fordata after viderecord..
  updateCamvideo(blobfile1, bloburl) {
    var blobfile = new Blob([blobfile1], { type: blobfile1.type });

    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 25; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    let memoryFiles = blobfile;

    var blob = blobfile.slice(0, blobfile.size, "video/*");
    var mimetype = blobfile.type;
    console.log(mimetype);
    var extention = mimetype.substring(6, mimetype.lastIndexOf(";"));
    //console.log(extention);
    var newFile = new File(
      [blobfile],
      `${result}.${extention == "webm" ? "webm" : extention}`,
      { lastModified: new Date().getTime(), type: blobfile.type }
    );

    // let memoryFiles = blobfile;

    let previewImage = "";
    let fileType = "";

    memoryFiles = memoryFiles;
    previewImage = bloburl;
    fileType = "video";

    console.log(memoryFiles);
    console.log(previewImage);
    console.log(fileType);

    if (previewImage !== "" && memoryFiles !== "" && fileType !== "") {
      let formData = this.state.formData;
      let preUploadedImage = formData.uploadedFiles; // fetch pre upload files and push new file

      let tempImg = {
        mainFile: newFile,
        previewFile: previewImage,
        fileType: fileType,
      };
      preUploadedImage.push(tempImg);

      console.log("preUploadedImage", preUploadedImage);

      formData["uploadedFiles"] = preUploadedImage;
      this.setState(
        {
          formData: formData,
          isDisabled: false,
          isFileUpload: true,
        },
        () => {
          //var formData = new FormData();
          // formData.append("scheduled", false);
          // formData.append("event_id", this.state.eventDetails._id);
          // formData.append(
          //   "user_contact_id",
          //   //this.state.eventDetails.user_contact_id.user_id
          //   this.state.eventDetails.user_contact_id._id
          // );
          // formData.append("year", this.state.memoryYear);
          // formData.append("memoryId", this.state.singleMemoriData._id);
          // this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //   formData.append("files", singleItm.mainFile);
          // });
          // if (!this.state.singleMemoriData) {
          //   formData.append("scheduled", false);
          //   formData.append("event_id", this.state.eventDetails._id);
          //   formData.append(
          //     "user_contact_id",
          //     this.state.eventDetails.user_contact_id._id
          //   );
          //   formData.append("year", this.state.memoryYear);
          //   this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //     formData.append("files", singleItm.mainFile);
          //   });
          //   this.props.contactActions.createUserMemory(formData);
          // } else {
          //   formData.append("scheduled", false);
          //   formData.append("event_id", this.state.eventDetails._id);
          //   formData.append(
          //     "user_contact_id",
          //     this.state.eventDetails.user_contact_id._id
          //   );
          //   formData.append("year", this.state.memoryYear);
          //   formData.append("memoryId", this.state.singleMemoriData._id);
          //   this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //     if (
          //       singleItm.mainFile !== undefined &&
          //       singleItm.mainFile !== "" &&
          //       singleItm.mainFile !== null
          //     ) {
          //       formData.append("files", singleItm.mainFile);
          //     }
          //   });
          //   /* Append old files */
          //   let oldFileArr = [
          //     ...this.state.uploadedImageFiles,
          //     ...this.state.uploadedVideoFiles,
          //     ...this.state.uploadedAudioFiles,
          //   ];
          //   if (oldFileArr.length > 0) {
          //     oldFileArr.forEach(function (item) {
          //       formData.append("oldFiles[]", item);
          //     });
          //   }
          //   this.props.contactActions.updateUserMemory(formData);
          // }
        }
      );

      ///this.handleClosevideorecorder();
      this.setState({
        currentVideourl: "",
        currentBloburl: "",
        videoOpen: false,
        videocloseClick: false,
      });

      // e.target.value = ""; // reset input type file
    }
  }
  updateformdata(blobfile1, bloburl) {
    var blobfile = new Blob([blobfile1], { type: blobfile1.type });
    this.setState({
      currentBloburl: blobfile1,
      currentVideourl: bloburl,
      videocloseClick: true,
    });
    // var url = window.URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.style.display = 'none';
    // a.href = url;
    // a.download = 'test.mp4';
    // document.body.appendChild(a);
    // a.click();
    // setTimeout(() => {
    //   document.body.removeChild(a);
    //   window.URL.revokeObjectURL(url);
    // }, 100);

    // console.log(blobfile1)

    // var blobfile = new Blob([blobfile1], {"type" : "video\/mp4"});

    // console.log('Blobfile',blobfile)

    // var result = "";
    // var characters =
    //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    // var charactersLength = characters.length;
    // for (var i = 0; i < 25; i++) {
    //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    // let memoryFiles = blobfile;

    // var blob = blobfile.slice(0, blobfile.size, "video/*");
    // var mimetype = blobfile.type;
    // console.log(mimetype);
    // var extention = mimetype.substring(6, mimetype.lastIndexOf(";"));
    // //console.log(extention);
    // var newFile = new File(
    //   [blobfile],
    //   `${result}.${extention == "webm" ? "webm" : extention}`,
    //   { lastModified: new Date().getTime(), type: blobfile.type }
    // );

    // // let memoryFiles = blobfile;

    // let previewImage = "";
    // let fileType = "";

    // memoryFiles = memoryFiles;
    // previewImage = bloburl;
    // fileType = "video";

    // console.log(memoryFiles);
    // console.log(previewImage);
    // console.log(fileType);

    // if (previewImage !== "" && memoryFiles !== "" && fileType !== "") {
    //   let formData = this.state.formData;
    //   let preUploadedImage = formData.uploadedFiles; // fetch pre upload files and push new file

    //   let tempImg = {
    //     mainFile: newFile,
    //     previewFile: previewImage,
    //     fileType: fileType,
    //   };
    //   preUploadedImage.push(tempImg);

    //   console.log("preUploadedImage", preUploadedImage);

    //   formData["uploadedFiles"] = preUploadedImage;
    //   this.setState(
    //     {
    //       formData: formData,
    //       isDisabled: false,
    //       isFileUpload: true,
    //     },
    //     () => {
    //       var formData = new FormData();
    //       // formData.append("scheduled", false);
    //       // formData.append("event_id", this.state.eventDetails._id);
    //       // formData.append(
    //       //   "user_contact_id",
    //       //   //this.state.eventDetails.user_contact_id.user_id
    //       //   this.state.eventDetails.user_contact_id._id
    //       // );
    //       // formData.append("year", this.state.memoryYear);
    //       // formData.append("memoryId", this.state.singleMemoriData._id);
    //       // this.state.formData.uploadedFiles.forEach(function (singleItm) {
    //       //   formData.append("files", singleItm.mainFile);
    //       // });
    //       if (!this.state.singleMemoriData) {
    //         formData.append("scheduled", false);
    //         formData.append("event_id", this.state.eventDetails._id);
    //         formData.append(
    //           "user_contact_id",

    //           this.state.eventDetails.user_contact_id._id
    //         );
    //         formData.append("year", this.state.memoryYear);

    //         this.state.formData.uploadedFiles.forEach(function (singleItm) {
    //           formData.append("files", singleItm.mainFile);
    //         });
    //         this.props.contactActions.createUserMemory(formData);
    //       } else {
    //         formData.append("scheduled", false);
    //         formData.append("event_id", this.state.eventDetails._id);
    //         formData.append(
    //           "user_contact_id",
    //           this.state.eventDetails.user_contact_id._id
    //         );
    //         formData.append("year", this.state.memoryYear);
    //         formData.append("memoryId", this.state.singleMemoriData._id);

    //         this.state.formData.uploadedFiles.forEach(function (singleItm) {
    //           if (
    //             singleItm.mainFile !== undefined &&
    //             singleItm.mainFile !== "" &&
    //             singleItm.mainFile !== null
    //           ) {
    //             formData.append("files", singleItm.mainFile);
    //           }
    //         });

    //         /* Append old files */
    //         let oldFileArr = [
    //           ...this.state.uploadedImageFiles,
    //           ...this.state.uploadedVideoFiles,
    //           ...this.state.uploadedAudioFiles,
    //         ];
    //         if (oldFileArr.length > 0) {
    //           oldFileArr.forEach(function (item) {
    //             formData.append("oldFiles[]", item);
    //           });
    //         }

    //         //this.props.contactActions.updateUserMemory(formData);
    //       }
    //     }
    //   );

    //   //this.handleClosevideorecorder();

    //   // e.target.value = ""; // reset input type file
    // }
  }
  updateaudoformdata(blobfile1, bloburl) {
    //console.log(blobfile1)
    var blobfile = new Blob([blobfile1.blob], { type: "audio/wav" });
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 25; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    let memoryFiles = blobfile;

    var mimetype = blobfile.type;
    var extention = mimetype.substring(6, mimetype.lastIndexOf(";"));
    console.log(extention);

    //var blob = blobfile.slice(0, blobfile.size, "audio/*");
    var newFile = new File([blobfile], `${result}.wav`, { type: mimetype });

    let previewImage = "";
    let fileType = "";

    memoryFiles = memoryFiles;
    previewImage = bloburl;
    fileType = "audio";

    console.log(memoryFiles);
    console.log(previewImage);
    console.log(fileType);

    if (previewImage !== "" && memoryFiles !== "" && fileType !== "") {
      let formData = this.state.formData;
      let preUploadedImage = formData.uploadedFiles; // fetch pre upload files and push new file

      let tempImg = {
        mainFile: newFile,
        previewFile: previewImage,
        fileType: fileType,
      };
      preUploadedImage.push(tempImg);

      console.log("preUploadedImage", preUploadedImage);

      formData["uploadedFiles"] = preUploadedImage;
      this.setState(
        {
          formData: formData,
          isDisabled: false,
          isFileUpload: true,
        },
        () => {
          //var formData = new FormData();
          // formData.append("scheduled", false);
          // formData.append("event_id", this.state.eventDetails._id);
          // formData.append(
          //   "user_contact_id",
          //   //this.state.eventDetails.user_contact_id.user_id
          //   this.state.eventDetails.user_contact_id._id
          // );
          // formData.append("year", this.state.memoryYear);
          // formData.append("memoryId", this.state.singleMemoriData._id);
          // this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //   formData.append("files", singleItm.mainFile);
          // });
          // if (!this.state.singleMemoriData) {
          //   formData.append("scheduled", false);
          //   formData.append("event_id", this.state.eventDetails._id);
          //   formData.append(
          //     "user_contact_id",
          //     this.state.eventDetails.user_contact_id._id
          //   );
          //   formData.append("year", this.state.memoryYear);
          //   this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //     formData.append("files", singleItm.mainFile);
          //   });
          //   this.props.contactActions.createUserMemory(formData);
          // } else {
          //   formData.append("scheduled", false);
          //   formData.append("event_id", this.state.eventDetails._id);
          //   formData.append(
          //     "user_contact_id",
          //     this.state.eventDetails.user_contact_id._id
          //   );
          //   formData.append("year", this.state.memoryYear);
          //   formData.append("memoryId", this.state.singleMemoriData._id);
          //   this.state.formData.uploadedFiles.forEach(function (singleItm) {
          //     if (
          //       singleItm.mainFile !== undefined &&
          //       singleItm.mainFile !== "" &&
          //       singleItm.mainFile !== null
          //     ) {
          //       formData.append("files", singleItm.mainFile);
          //     }
          //   });
          //   /* Append old files */
          //   let oldFileArr = [
          //     ...this.state.uploadedImageFiles,
          //     ...this.state.uploadedVideoFiles,
          //     ...this.state.uploadedAudioFiles,
          //   ];
          //   if (oldFileArr.length > 0) {
          //     oldFileArr.forEach(function (item) {
          //       formData.append("oldFiles[]", item);
          //     });
          //   }
          //   this.props.contactActions.updateUserMemory(formData);
          // }
        }
      );
      this.handleRest();
      this.handleCloseAudiorecorder();

      // e.target.value = ""; // reset input type file
    }
  }

  triggerInputFile = () => this.fileInput.click();

  /*
  /****************************Audio recording Start ************************ *
  */

  changeTime1 = () => {
    this.setState({ videoCount: this.state.videoCount + 1 });
  };

  start1 = () => {
    // if (!this.state.isVideoStop) {
    //   this.setState({
    //     videoCount: 0,
    //   });
    // }

    const intervalID1 = setInterval(this.changeTime1, 1000);
    this.setState({
      // recordState: RecordState.START,
      // isStop: true,
      // isPause: true,
      // isUpload: false,
      intervalID1,
    });
  };

  stop1 = () => {
    clearInterval(this.state.intervalID1);
    // this.setState({
    //   recordState: RecordState.STOP,
    //   isUpload: true,
    //   isStop: false,
    //   isPause: false,
    // });
  };

  changeTime = () => {
    this.setState({ audioCount: this.state.audioCount + 1 });
  };

  start = () => {
    if (!this.state.isStop) {
      this.setState({
        audioCount: 0,
      });
    }

    const intervalID = setInterval(this.changeTime, 1000);
    this.setState({
      recordState: RecordState.START,
      isStop: true,
      isPause: true,
      isUpload: false,
      intervalID,
    });
  };

  stop = () => {
    clearInterval(this.state.intervalID);
    this.setState({
      recordState: RecordState.STOP,
      isUpload: true,
      isStop: false,
      isPause: false,
    });
  };

  //audioData contains blob and blobUrl
  onStop = (audioData) => {
    this.setState({
      recordAudio: audioData.url,
      recordAudioData: audioData,
    });
    //console.log("audioData", audioData);
  };

  pause = () => {
    clearInterval(this.state.intervalID);
    this.setState({
      recordState: RecordState.PAUSE,
      isUpload: false,
      isPause: false,
    });
  };

  /*
/****************************Audio recording END    ************************ *
*/

  render() {
    let formData = this.state.formData;
    return (
      <>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
            <div className="container">
              <div className="card border-0 border-radius-15 min-vh-75 overflow-hidden">
                <div className="card-header pad-x-20 pad-x-xl-46 pad-y-20 bg-white">
                  <div className="d-lg-flex justify-content-between align-items-center">
                    <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                      <i className="material-icons text-color1 align-middle fs-30">
                        mms
                      </i>
                      <span className="pl-2">
                        {/* {this.state.eventDetails &&
                          this.state.eventDetails.title} */}
                        {this.state.eventDetails.title !== undefined &&
                        this.state.eventDetails.title !== null &&
                        this.state.eventDetails.title !== ""
                          ? this.state.eventDetails.title.includes("Birthday")
                            ? this.state.eventDetails.user_contact_id
                                .firstName !== undefined
                              ? this.state.eventDetails.user_contact_id
                                  .firstName +
                                "'s " +
                                this.state.eventDetails.title
                              : ""
                            : this.state.eventDetails.title
                          : ""}{" "}
                        | {this.state.memoryYear}
                      </span>
                      <span className="pl-5 pl-md-2 fs-19 d-block d-md-inline opa-60 text-black font-weight-Regular">
                        {this.state.singleMemoriData &&
                        this.state.singleMemoriData.scheduled === false
                          ? "Draft saved"
                          : ""}
                      </span>
                    </h3>

                    <div className="pt-3 pt-lg-0 d-flex justify-content-between align-items-center">
                      {this.state.loading === false && (
                        <div>
                          <Button
                            onClick={() => this.saveDraft()}
                            className="text-color1 border text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-15 mar-r-20"
                          >
                            <i className="material-icons fs-17 fs-md-24">
                              drafts
                            </i>{" "}
                            Save as draft
                          </Button>
                        </div>
                      )}
                      {this.state.loading === false && (
                        <div>
                          <Button
                            onClick={() => this.resetForm()}
                            className="text-color1 border text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-15"
                          >
                            <i className="material-icons fs-17 fs-md-24">
                              close
                            </i>{" "}
                            Cancel
                          </Button>
                        </div>
                      )}
                      <div>
                        {!this.state.formData ? (
                          <Button
                            className={
                              "bg-color1 text-white text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-25 mar-l-20 opa-50"
                            }
                            disabled
                          >
                            <i className="material-icons fs-17 fs-md-24">
                              send
                            </i>{" "}
                            Schedule Memory
                          </Button>
                        ) : this.state.loading === true ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            className={
                              "bg-color1 text-white text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-25 mar-l-20 "
                            }
                            onClick={this.handleSubmit}
                          >
                            <i className="material-icons fs-17 fs-md-24">
                              send
                            </i>{" "}
                            Schedule Memory
                          </Button>
                        )}
                      </div>

                      {this.state.singleMemoriData && (
                        <div>
                          <div>
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={this.handleOpen.bind(this)}
                            >
                              <i className="material-icons fs-36">more_vert</i>
                            </IconButton>
                          </div>
                          <Menu
                            anchorEl={this.state.setOpen}
                            keepMounted
                            open={this.state.setOpen}
                            onClose={this.handleClose.bind(this)}
                          >
                            <MenuItem
                              className="fs-18 font-weight-Medium"
                              onClick={() => this.discardMemory()}
                            >
                              <i className="material-icons text-color1 align-middle mr-2">
                                delete
                              </i>{" "}
                              Discard memory
                            </MenuItem>
                          </Menu>
                        </div>
                      )}
                    </div>
                  </div>

                  <div></div>
                </div>

                <div className="card-body">
                  <div className="col-md-11 col-xl-8 mx-auto pt-5">
                    <div className="w-100 mb-4">
                      <TextField
                        //onKeyPress={this.saveDraft.bind(this)}
                        id="demo-simple-select-outlined"
                        label="Add a title"
                        variant="outlined"
                        className="w-100"
                        name="title"
                        onChange={this.handleChange}
                        value={formData.title}
                        required
                      />
                    </div>

                    <div className="w-100 mb-4">
                      <TextField
                        //onFocus={this.saveDraft.bind(this)}
                        id="outlined-multiline-static"
                        label="Write your message here"
                        multiline
                        rows={5}
                        variant="outlined"
                        className="w-100"
                        name="message"
                        onChange={this.handleChange}
                        value={formData.message}
                        required
                      />
                    </div>

                    <div className="w-100 mb-4">
                      {/* {(formData.title == "" ||
                        formData.message == "" ||
                        this.state.singleMemoriData === null) && (
                        <Tooltip title="Please add title and message and save as draft/schedule the memory.">
                          <Button
                            className="text-color1 border  fs-19 pad-y-7 pad-x-15"
                            onClick={() => {
                              confirmAlert({
                                title: "Alert",
                                message:
                                  "Please add title and message and save as draft/schedule the memory.",
                                buttons: [
                                  {
                                    label: "OK",
                                  },
                                ],
                              });
                            }}
                          >
                            <i className="material-icons">insert_photo</i>
                            <span className="pl-1">
                              Add video or Images or Audio
                            </span>
                          </Button>
                        </Tooltip>
                      )} */}

                      <>
                        <input
                          ref={(fileInput) => (this.fileInput = fileInput)}
                          type="file"
                          hidden
                          accept="image/*,video/mp4,video/x-m4v,video/*,audio/*"
                          onChange={(e) => this.fileUploadChange(e)}
                        />

                        <Button
                          className="text-color1 border  fs-19 pad-y-7 pad-x-15 mr-2"
                          onClick={this.triggerInputFile}
                        >
                          <i className="material-icons">insert_photo</i>
                          <span className="pl-1">
                            Add Video or Images or Audio
                          </span>
                        </Button>
                      </>

                      {/* {(formData.title == "" ||
                        formData.message == "" ||
                        this.state.singleMemoriData === null) && (
                        <Tooltip title="Please add title and message and save as draft/schedule the memory.">
                          <Button
                            className="text-color1 border  fs-19 pad-y-7 pad-x-15"
                            onClick={() => {
                              confirmAlert({
                                title: "Alert",
                                message:
                                  "Please add title and message and save as draft/schedule the memory.",
                                buttons: [
                                  {
                                    label: "OK",
                                  },
                                ],
                              });
                            }}
                          >
                            <i className="material-icons">videocam</i>
                            <span className="pl-1">Record Video</span>
                          </Button>
                        </Tooltip>
                      )} */}

                      {/* {formData.title != "" &&
                        formData.message != "" &&
                        this.state.singleMemoriData !== null && ( */}
                      <Button
                        className="text-color1 border  fs-19 pad-y-7 pad-x-15 mr-2"
                        onClick={this.openvideorecorder}
                        // disabled={
                        //   formData.title != "" && formData.message != ""
                        //     ? false
                        //     : true
                        // }
                      >
                        <i className="material-icons">videocam</i>
                        <span className="pl-1">Record Video</span>
                      </Button>
                      {/* )} */}

                      {/* {(formData.title == "" ||
                        formData.message == "" ||
                        this.state.singleMemoriData === null) && (
                        <Tooltip title="Please add title and message and save as draft/schedule the memory.">
                          <Button
                            className="text-color1 border  fs-19 pad-y-7 pad-x-15"
                            onClick={() => {
                              confirmAlert({
                                title: "Alert",
                                message:
                                  "Please add title and message and save as draft/schedule the memory.",
                                buttons: [
                                  {
                                    label: "OK",
                                  },
                                ],
                              });
                            }}
                          >
                            <i className="material-icons">mic</i>
                            <span className="pl-1">Record Audio</span>
                          </Button>
                        </Tooltip>
                      )} */}

                      {/* {formData.title != "" &&
                        formData.message != "" &&
                        this.state.singleMemoriData !== null && ( */}
                      <Button
                        className="text-color1 border  fs-19 pad-y-7 pad-x-15 mr-2"
                        onClick={this.handleopenAudiorecorder}
                        // disabled={
                        //   formData.title != "" && formData.message != ""
                        //     ? false
                        //     : true
                        // }
                      >
                        <i className="material-icons">mic</i>
                        <span className="pl-1">Record Audio</span>
                      </Button>
                      {/* )} */}

                      {this.state.uploadingPer > 0 &&
                        this.state.uploadingPer < 100 && (
                          <div className="my-3">
                            <LinearProgress
                              variant="determinate"
                              value={this.state.uploadingPer}
                            />
                            {this.state.uploadingPer}%
                          </div>
                        )}
                    </div>

                    <div className="row addVideoImage">
                      {formData.uploadedFiles.map((list, index) => (
                        <>
                          {list.fileType !== undefined &&
                            list.fileType !== null &&
                            list.fileType !== "" && (
                              <div className="col-md-4 mb-4" key={index}>
                                {list.fileType === "video" && (
                                  <div>
                                    <figure className="d-block mb-0 position-relative">
                                      <video
                                        id="video"
                                        // src={list.previewFile}
                                        className="w-100"
                                        controls
                                      >
                                        <source
                                          src={list.previewFile}
                                          type='video/webm;codecs="vp8, vorbis"'
                                        />
                                        <source
                                          src={list.previewFile}
                                          type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                                        />
                                      </video>
                                    </figure>
                                    <Button
                                      className="opa-80 fs-16"
                                      onClick={() => this.removeFile(index)}
                                    >
                                      <i className="material-icons">delete</i>{" "}
                                      Remove
                                    </Button>
                                  </div>
                                )}

                                {list.fileType === "audio" && (
                                  <div>
                                    <figure className="d-block mb-0 position-relative">
                                      <audio
                                        id="video"
                                        src={list.previewFile}
                                        className="w-100"
                                        controls
                                        style={{
                                          background: `url(${audioIcon})`,
                                          height: "205px",
                                          backgroundSize: "contain",
                                        }}
                                      ></audio>
                                    </figure>
                                    <Button
                                      className="opa-80 fs-16"
                                      onClick={() => this.removeFile(index)}
                                    >
                                      <i className="material-icons">delete</i>{" "}
                                      Remove
                                    </Button>
                                  </div>
                                )}

                                {list.fileType === "image" && (
                                  <div>
                                    <figure className="d-block mb-0 position-relative">
                                      <a
                                        data-fancybox="gallery"
                                        href={list.previewFile}
                                      >
                                        <img
                                          src={list.previewFile}
                                          alt=""
                                          className="w-100"
                                        />
                                      </a>
                                    </figure>
                                    <Button
                                      className="opa-80 fs-16"
                                      onClick={() => this.removeFile(index)}
                                    >
                                      <i className="material-icons">delete</i>{" "}
                                      Remove
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )}
                        </>
                      ))}

                      {this.state.uploadedVideoFiles &&
                        this.state.uploadedVideoFiles.length > 0 &&
                        this.state.uploadedVideoFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={`${index}video`}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                {/*<img src={Image7} alt="" className="w-100" />
                                <div className="position-absolute play">
                                  <a
                                    data-fancybox="gallery"
                                    href={UploadURLS.user_memories + list}
                                  >
                                    <img src={PlayBtn} alt="" />
                                  </a>
                                </div>*/}

                                {/*<video
                                  id="video"
                                  src={UploadURLS.user_memories + list}
                                  className="w-100"
                                  controls
                                ></video>*/}

                                {/* <video className="w-100" controls playsInline>
                                  <source
                                    src={list}
                                    type='video/webm;codecs="vp8, vorbis"'
                                  />
                                  <source
                                    src={list}
                                    type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                                  />
                                </video> */}

                                <video
                                  id="video"
                                  src={list}
                                  className="w-100"
                                  controls
                                ></video>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() =>
                                  this.removeUploadedFile(list, "video")
                                }
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          </div>
                        ))}

                      {this.state.uploadedAudioFiles &&
                        this.state.uploadedAudioFiles.length > 0 &&
                        this.state.uploadedAudioFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={`${index}audio`}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                {/*<img src={Image7} alt="" className="w-100" />
                                <div className="position-absolute play">
                                  <a
                                    data-fancybox="gallery"
                                    href={UploadURLS.user_memories + list}
                                  >
                                    <img src={PlayBtn} alt="" />
                                  </a>
                                </div>*/}

                                {/*<video
                                  id="video"
                                  src={UploadURLS.user_memories + list}
                                  className="w-100"
                                  controls
                                ></video>*/}

                                {/* <a data-fancybox="gallery" href={list}>
                                  <img src={audioIcon} alt="" className="w-100" />
                                </a> */}

                                <audio
                                  id="video"
                                  src={list}
                                  className="w-100"
                                  controls
                                  style={{
                                    background: `url(${audioIcon})`,
                                    height: "205px",
                                    backgroundSize: "contain",
                                  }}
                                ></audio>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() =>
                                  this.removeUploadedFile(list, "audio")
                                }
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          </div>
                        ))}

                      {this.state.uploadedImageFiles &&
                        this.state.uploadedImageFiles.length > 0 &&
                        this.state.uploadedImageFiles.map((list, index) => (
                          <div className="col-md-4 mb-4" key={`${index}image`}>
                            <div>
                              <figure className="d-block mb-0 position-relative">
                                {/*<a
                                  data-fancybox="gallery"
                                  href={UploadURLS.user_memories + list}
                                >
                                  <img
                                    src={UploadURLS.user_memories + list}
                                    alt=""
                                    className="w-100"
                                  />
                                </a>*/}

                                <a data-fancybox="gallery" href={list}>
                                  <img src={list} alt="" className="w-100" />
                                </a>
                              </figure>
                              <Button
                                className="opa-80 fs-16"
                                onClick={() =>
                                  this.removeUploadedFile(list, "image")
                                }
                              >
                                <i className="material-icons">delete</i> Remove
                              </Button>
                            </div>
                          </div>
                        ))}

                      <Dialog
                        fullScreen
                        open={this.state.videoOpen}
                        style={{
                          background: "#ffffff7a",
                          width: "60%",
                          margin: "auto",
                        }}
                        className={"videorecorderDialouge"}
                      >
                        <IconButton
                          style={{
                            background: "transparent",
                            position: "absolute",
                            zIndex: 5,
                          }}
                        >
                          <CloseIcon
                            className={"crossIcon"}
                            onClick={this.handleClosevideorecorder}
                          />
                        </IconButton>

                        {this.state.videoCount === 0 &&
                          this.state.isVideoStart && (
                            <div className="waitingtext">Wait.....</div>
                          )}

                        {this.state.currentVideourl !== "" &&
                          this.state.videocloseClick && (
                            <div className="w-100 bg-white text-center py-2">
                              <video
                                id="video"
                                // src={list.previewFile}
                                //className="w-100"
                                controls
                              >
                                <source
                                  src={this.state.currentVideourl}
                                  type='video/webm;codecs="vp8, vorbis"'
                                />
                                <source
                                  src={this.state.currentVideourl}
                                  type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                                />
                              </video>
                              <div class="text-center">
                                <Button
                                  className="opa-80 fs-16"
                                  onClick={() =>
                                    this.updateCamvideo(
                                      this.state.currentBloburl,
                                      this.state.currentVideourl
                                    )
                                  }
                                >
                                  <i className="material-icons">upload</i>{" "}
                                  Upload
                                </Button>
                                <Button
                                  className="opa-80 fs-16"
                                  onClick={() =>
                                    this.setState({
                                      currentVideourl: "",
                                      currentBloburl: "",
                                      videoOpen: false,
                                    })
                                  }
                                >
                                  <i className="material-icons">cancel</i>{" "}
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          )}

                        {(this.state.currentVideourl === "" ||
                          !this.state.videocloseClick) && (
                          <VideoRecorder
                            isRecording="true"
                            isReplayingVideo="true"
                            showReplayControls={true}
                            onRecordingComplete={(videoBlob) => {
                              // Do something with the video...
                              const url = URL.createObjectURL(videoBlob);

                              this.setState(
                                {
                                  isVideoPause: false,
                                  isVideoStart: false,
                                  isVideoStop: false,
                                },
                                () => {
                                  this.updateformdata(videoBlob, url);
                                }
                              );

                              //console.log("videoBlob1233", url);
                            }}
                            onPauseRecording={(videoBlob) => {
                              // Do something with the video...
                              // const url = URL.createObjectURL(videoBlob);
                              //alert("videoBlob1233", videoBlob);
                            }}
                            isFlipped={false}
                            style={{
                              margin: "auto",
                              borderRadius: "0px",
                            }}
                            renderActions={({
                              onStartRecording,
                              onStopRecording,
                              isCameraOn,
                              streamIsReady,
                              isRecording,
                              isVideoInputSupported,
                              isInlineRecordingSupported,
                              thereWasAnError,
                              isConnecting,
                              isRunningCountdown,
                              isReplayingVideo,
                              onTurnOnCamera,
                              onPauseRecording,
                              onResumeRecording,
                              countdownTime,
                              timeLimit,
                            }) => {
                              // console.log({ isReplayingVideo });
                              if (
                                (!isInlineRecordingSupported &&
                                  !isVideoInputSupported) ||
                                thereWasAnError ||
                                isConnecting ||
                                isRunningCountdown ||
                                isReplayingVideo
                              ) {
                                return <p>Wait...</p>;
                              }

                              return (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "10%",
                                  }}
                                >
                                  {!isCameraOn && (
                                    <button
                                      onClick={() => {
                                        this.setState(
                                          {
                                            videoCount: 0,
                                          },
                                          () => {
                                            onTurnOnCamera();
                                          }
                                        );
                                      }}
                                      className="btn btn-primary ml-2"
                                    >
                                      Start Camera
                                    </button>
                                  )}
                                  {isRecording && this.state.videoCount > 0 && (
                                    <div className="timerVideoCount">
                                      {Math.round(this.state.videoCount)}
                                    </div>
                                  )}

                                  {!this.state.isVideoStart && isCameraOn && (
                                    <button
                                      onClick={() => {
                                        if (isCameraOn && streamIsReady) {
                                          this.setState(
                                            {
                                              isVideoStart: true,
                                            },
                                            () => {
                                              setTimeout(() => {
                                                this.start1();
                                              }, 3000);

                                              onStartRecording();
                                            }
                                          );
                                        }
                                      }}
                                      className="btn btn-success ml-2"
                                    >
                                      Start Record
                                    </button>
                                  )}

                                  {!this.state.isVideoPause &&
                                    isCameraOn &&
                                    this.state.isVideoStart &&
                                    isRecording && (
                                      <button
                                        onClick={() => {
                                          this.setState(
                                            {
                                              isVideoPause: true,
                                            },
                                            () => {
                                              this.stop1();
                                              onPauseRecording();
                                            }
                                          );
                                        }}
                                        className="btn btn-info ml-2"
                                      >
                                        Pause
                                      </button>
                                    )}

                                  {this.state.isVideoPause &&
                                    isCameraOn &&
                                    this.state.isVideoStart &&
                                    isRecording && (
                                      <button
                                        onClick={() => {
                                          this.setState(
                                            {
                                              isVideoPause: false,
                                            },
                                            () => {
                                              this.start1();
                                              onResumeRecording();
                                            }
                                          );
                                        }}
                                        className="btn btn-info ml-2"
                                      >
                                        Resume
                                      </button>
                                    )}

                                  {this.state.isVideoStart &&
                                    isCameraOn &&
                                    isRecording && (
                                      <button
                                        onClick={() => {
                                          this.setState(
                                            {
                                              videoCount: 0,
                                            },
                                            () => {
                                              this.stop1();
                                              onStopRecording();
                                            }
                                          );
                                        }}
                                        className="btn btn-danger ml-2"
                                      >
                                        Stop
                                      </button>
                                    )}
                                </div>
                              );
                            }}
                          />
                        )}
                      </Dialog>

                      <Dialog
                        open={this.state.audioOpen}
                        style={{ background: "#ffffff7a" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <IconButton style={{ background: "transparent" }}>
                            <CloseIcon
                              className={"crossIcon"}
                              onClick={this.handleCloseAudiorecorder}
                            />
                          </IconButton>
                          {!this.state.isUpload && (
                            <span
                              style={{
                                background: "#eeecec",
                                padding: "0px 30px",
                                fontSize: "2rem",
                              }}
                            >
                              {this.state.audioCount}
                            </span>
                          )}
                        </div>

                        <div style={{ textAlign: "center", padding: "" }}>
                          <div style={{ background: "#c8c8c8" }}>
                            <AudioReactRecorder
                              state={this.state.recordState}
                              onStop={this.onStop}
                              foregroundColor={"#eee"}
                              type="audio/wav"
                            />
                          </div>

                          {this.state.isUpload && !this.state.isPause ? (
                            <audio
                              id="video"
                              src={this.state.recordAudio}
                              classname="w-100"
                              controls
                              style={{ width: "100%", marginTop: "10px" }}
                            ></audio>
                          ) : (
                            ""
                          )}
                          {!this.state.isPause && (
                            <button
                              class="btn btn-primary waves-effect waves-light"
                              type="submit"
                              name="action"
                              onClick={this.start}
                              disabled={this.state.isPause ? true : false}
                              style={{ margin: "5px" }}
                            >
                              Start
                            </button>
                          )}
                          {this.state.isPause && (
                            <button
                              class="btn btn-primary waves-effect waves-light"
                              onClick={this.pause}
                              disabled={this.state.isPause ? false : true}
                              style={{ margin: "5px" }}
                            >
                              Pause
                            </button>
                          )}

                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={this.stop}
                            disabled={this.state.isStop ? false : true}
                            style={{ margin: "5px" }}
                          >
                            Stop
                          </button>
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={this.handleAudioUpload}
                            disabled={this.state.isUpload ? false : true}
                            style={{ margin: "5px" }}
                          >
                            Upload
                          </button>
                        </div>

                        {/* <Recorder
                          record={true}
                          title={"New recording"}
                          audioURL={this.state.audioDetails.url}
                          showUIAudio
                          handleAudioStop={(data) => this.handleAudioStop(data)}
                          handleOnChange={(value) =>
                            this.handleOnChange(value, "firstname")
                          }
                          handleAudioUpload={(data) => {
                            if (data) {
                              this.handleAudioUpload(data);
                            } else if (!this.state.audioDetails.url) {
                              this.setState({
                                audioOpen:false
                              })
                              var THIS = this;
                              confirmAlert({
                                title: "Alert",
                                message: "Please start or stop your recording",
                                buttons: [
                                  {
                                    label: "OK",
                                    onClick: () =>{
                                      THIS.setState({
                                        audioOpen:true
                                      })
                                    }
                                  }
                                ],
                              });
                            } else {
                              alert("Please stop your recording");
                            }
                          }}
                          handleRest={() => this.handleRest()}
                          className={"recorderModal"}
                          style={{ width: "80%", marginBottom: "10px" }}
                        /> */}
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMemory);
