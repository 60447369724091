import {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  DASHBOARD_TESTIMONIALS_SUCCESS,
  DASHBOARD_TESTIMONIALS_FAILED,
  FETCH_EVENT_TYPE_LIST_SUCCESS,
  FETCH_EVENT_TYPE_LIST_FAILD,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILED,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAILED,
  USER_REGISTRATION_FAILED_CARD,
  FETCH_CMS_INFO_SUCCESS,
  FETCH_CMS_INFO_FAILED,
  FETCH_BRAND_LOGO_SUCCESS,
  FETCH_BRAND_LOGO_FAILED,
  FETCH_TESTIMONIALS_SUCCESS,
  FETCH_TESTIMONIALS_FAILED,
  CHECK_USER_EXISTS_SUCCESS,
  CHECK_USER_EXISTS_FAILED,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAILED,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS_FAILED,
  LOGOUT_USER_PROFILE_SUCCESS,
  LOGOUT_USER_PROFILE_FAILED,
  USER_ACCOUNT_DEACTIVATE_SUCCESS,
  USER_ACCOUNT_DEACTIVATE_FAILED,
  FETCH_USER_CARD_SUCCESS,
  FETCH_USER_CARD_FAILED,
  USER_PLAN_UPDATE_SUCCESS,
  USER_PLAN_UPDATE_FAILED,
  USER_PLAN_UPDATE_FAILED_CARD,
  FETCH_CMS_DATA_SUCCESS,
  FETCH_CMS_DATA_FAILED,
  FETCH_FAQ_LIST_SUCCESS,
  FETCH_FAQ_LIST_FAILED,
  FETCH_NOTIFICATION_LIST_SUCCESS,
  FETCH_NOTIFICATION_LIST_FAILED,
  // NOTIFICATION_PRO_TIPS_LIST_SUCCESS,
  // NOTIFICATION_PRO_TIPS_LIST_FAILED,

  /* Read Notification */
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILED,

  /* Get user details by token */
  GET_PROFILE_DETAILS_BY_TOKEN_SUCCESS,
  GET_PROFILE_DETAILS_BY_TOKEN_FAILED,

  /* Apple Pay */
  GET_APPLE_PAYMENT_INTENT_SUCCESS,
  GET_APPLE_PAYMENT_INTENT_FAILED,

  /* Paypal Pay */
  GET_PAYPAL_PAY_REDIRECT_URL_SUCCESS,
  GET_PAYPAL_PAY_REDIRECT_URL_FAILED,

  /* Check paypal already payment or not */
  CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_SUCCESS,
  CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_FAILED,
  /* Change user password */
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILED,

  /* Contact Us save*/
  SAVE_CONTACT_US_SUCCESS,
  SAVE_CONTACT_US_FAILED,

  /* Fetch Signin-Signup CMS*/
  FETCH_SIGN_CMS_SUCCESS,
  FETCH_SIGN_CMS_FAILED,
  CLEAR_STATE,
  UPDATE_REGISTRATION_STEP,
  FETCH_SOCIAL_LINK_SUCCESS,
  FETCH_SOCIAL_LINK_FAILED,
  USER_PROFILE_UPDATE_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILED,
} from "../actions/miscActions";

const intialState = {
  pending: false,
  step: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = intialState, action) => {
  switch (action.type) {
    /* USER LOGIN */
    case USER_LOGIN_SUCCESS:
      return { type: USER_LOGIN_SUCCESS, responce: action.data };
    case USER_LOGIN_FAILED:
      return { type: USER_LOGIN_FAILED, responce: action.data };

    /* FORGOT PASSWORD */
    case FORGOT_PASSWORD_SUCCESS:
      return { type: FORGOT_PASSWORD_SUCCESS, responce: action.data };
    case FORGOT_PASSWORD_FAILED:
      return { type: FORGOT_PASSWORD_FAILED, responce: action.data };

    /* DASHBOARD TESTIMONIAL */
    case DASHBOARD_TESTIMONIALS_SUCCESS:
      return { type: DASHBOARD_TESTIMONIALS_SUCCESS, responce: action.data };
    case DASHBOARD_TESTIMONIALS_FAILED:
      return { type: DASHBOARD_TESTIMONIALS_FAILED, responce: action.data };

    /* ALL EVENT LIST */
    case FETCH_EVENT_TYPE_LIST_SUCCESS:
      return { type: FETCH_EVENT_TYPE_LIST_SUCCESS, responce: action.data };
    case FETCH_EVENT_TYPE_LIST_FAILD:
      return { type: FETCH_EVENT_TYPE_LIST_FAILD, responce: action.data };

    /* ALL PLANS */
    case FETCH_PLANS_SUCCESS:
      return { type: FETCH_PLANS_SUCCESS, responce: action.data };
    case FETCH_PLANS_FAILED:
      return { type: FETCH_PLANS_FAILED, responce: action.data };

    /* USER REGISTRATION */
    case USER_REGISTRATION_SUCCESS:
      return { type: USER_REGISTRATION_SUCCESS, responce: action.data };
    case USER_REGISTRATION_FAILED:
      return { type: USER_REGISTRATION_FAILED, responce: action.data };
    case USER_REGISTRATION_FAILED_CARD:
      return { type: USER_REGISTRATION_FAILED_CARD, responce: action.data };

    /* FETCH ALL CMS DATA */
    case FETCH_CMS_INFO_SUCCESS:
      return { type: FETCH_CMS_INFO_SUCCESS, responce: action.data };
    case FETCH_CMS_INFO_FAILED:
      return { type: FETCH_CMS_INFO_FAILED, responce: action.data };

    /* FETCH BRAND ALL LOGOS */
    case FETCH_BRAND_LOGO_SUCCESS:
      return { type: FETCH_BRAND_LOGO_SUCCESS, responce: action.data };
    case FETCH_BRAND_LOGO_FAILED:
      return { type: FETCH_BRAND_LOGO_FAILED, responce: action.data };

    /* FETCH ALL TESTIMONIIAL  */
    case FETCH_TESTIMONIALS_SUCCESS:
      return { type: FETCH_TESTIMONIALS_SUCCESS, responce: action.data };
    case FETCH_TESTIMONIALS_FAILED:
      return { type: FETCH_TESTIMONIALS_FAILED, responce: action.data };

    /* CHECK USER EXISTS OR NOT   */
    case CHECK_USER_EXISTS_SUCCESS:
      return { type: CHECK_USER_EXISTS_SUCCESS, responce: action.data };
    case CHECK_USER_EXISTS_FAILED:
      return { type: CHECK_USER_EXISTS_FAILED, responce: action.data };

    /* UPDATE USER PROFILE */
    case USER_PROFILE_UPDATE_REQUEST:
      return { type: USER_PROFILE_UPDATE_REQUEST, pending: true };
    case USER_PROFILE_UPDATE_SUCCESS:
      return { type: USER_PROFILE_UPDATE_SUCCESS, responce: action.data, pending: false };
    case USER_PROFILE_UPDATE_FAILED:
      return { type: USER_PROFILE_UPDATE_FAILED, responce: action.data, pending: false };

    /* GET PROFILE DETAILS */
    case GET_PROFILE_DETAILS_SUCCESS:
      return { type: GET_PROFILE_DETAILS_SUCCESS, responce: action.data };
    case GET_PROFILE_DETAILS_FAILED:
      return { type: GET_PROFILE_DETAILS_FAILED, responce: action.data };

    /* PROFILE LOGOUT */
    case LOGOUT_USER_PROFILE_SUCCESS:
      return { type: LOGOUT_USER_PROFILE_SUCCESS, responce: action.data };
    case LOGOUT_USER_PROFILE_FAILED:
      return { type: LOGOUT_USER_PROFILE_FAILED, responce: action.data };

    /* USER ACCOUNT DEACTIVATE */
    case USER_ACCOUNT_DEACTIVATE_SUCCESS:
      return { type: USER_ACCOUNT_DEACTIVATE_SUCCESS, responce: action.data };
    case USER_ACCOUNT_DEACTIVATE_FAILED:
      return { type: USER_ACCOUNT_DEACTIVATE_FAILED, responce: action.data };

    /* FETCHH USER SAVED CARD */
    case FETCH_USER_CARD_SUCCESS:
      return { type: FETCH_USER_CARD_SUCCESS, responce: action.data };
    case FETCH_USER_CARD_FAILED:
      return { type: FETCH_USER_CARD_FAILED, responce: action.data };

    /* USER PLAN UPDATE */
    case USER_PLAN_UPDATE_SUCCESS:
      return { type: USER_PLAN_UPDATE_SUCCESS, responce: action.data };
    case USER_PLAN_UPDATE_FAILED:
      return { type: USER_PLAN_UPDATE_FAILED, responce: action.data };
    case USER_PLAN_UPDATE_FAILED_CARD:
      return { type: USER_PLAN_UPDATE_FAILED_CARD, responce: action.data };

    /* FETCH CMS DATA */
    case FETCH_CMS_DATA_SUCCESS:
      return { type: FETCH_CMS_DATA_SUCCESS, responce: action.data };
    case FETCH_CMS_DATA_FAILED:
      return { type: FETCH_CMS_DATA_FAILED, responce: action.data };

    /* FETCH FAQ LIST */
    case FETCH_FAQ_LIST_SUCCESS:
      return { type: FETCH_FAQ_LIST_SUCCESS, responce: action.data };
    case FETCH_FAQ_LIST_FAILED:
      return { type: FETCH_FAQ_LIST_FAILED, responce: action.data };

    /* FETCH NOTIFICATION LIST */
    case FETCH_NOTIFICATION_LIST_SUCCESS:
      return { type: FETCH_NOTIFICATION_LIST_SUCCESS, responce: action.data };
    case FETCH_NOTIFICATION_LIST_FAILED:
      return { type: FETCH_NOTIFICATION_LIST_FAILED, responce: action.data };

    /* FETCH NOTIFOCATION PRO TIPS */
    // case NOTIFICATION_PRO_TIPS_LIST_SUCCESS:
    //   return {type: NOTIFICATION_PRO_TIPS_LIST_SUCCESS,responce: action.data};
    // case NOTIFICATION_PRO_TIPS_LIST_FAILED:
    //   return {type: NOTIFICATION_PRO_TIPS_LIST_FAILED,responce: action.data};

    /* READ NOTIFICATION */

    case READ_NOTIFICATION_SUCCESS:
      return { type: READ_NOTIFICATION_SUCCESS, responce: action.data };
    case READ_NOTIFICATION_FAILED:
      return { type: READ_NOTIFICATION_FAILED, responce: action.data };

    /* GET USER DETAILS BY TOKEN */
    case GET_PROFILE_DETAILS_BY_TOKEN_SUCCESS:
      return {
        type: GET_PROFILE_DETAILS_BY_TOKEN_SUCCESS,
        responce: action.data,
      };
    case GET_PROFILE_DETAILS_BY_TOKEN_FAILED:
      return {
        type: GET_PROFILE_DETAILS_BY_TOKEN_FAILED,
        responce: action.data,
      };

    /* Apple pay intent */
    case GET_APPLE_PAYMENT_INTENT_SUCCESS:
      return { type: GET_APPLE_PAYMENT_INTENT_SUCCESS, responce: action.data };
    case GET_APPLE_PAYMENT_INTENT_FAILED:
      return { type: GET_APPLE_PAYMENT_INTENT_FAILED, responce: action.data };

    /* Paypal Pay */
    case GET_PAYPAL_PAY_REDIRECT_URL_SUCCESS:
      return {
        type: GET_PAYPAL_PAY_REDIRECT_URL_SUCCESS,
        responce: action.data,
      };
    case GET_PAYPAL_PAY_REDIRECT_URL_FAILED:
      return {
        type: GET_PAYPAL_PAY_REDIRECT_URL_FAILED,
        responce: action.data,
      };

    /* Check paypal already payment or not */
    case CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_SUCCESS:
      return {
        type: CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_SUCCESS,
        responce: action.data,
      };
    case CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_FAILED:
      return {
        type: CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_FAILED,
        responce: action.data,
      };
    /* Update registration step */
    case UPDATE_REGISTRATION_STEP:
      return { type: UPDATE_REGISTRATION_STEP, step: action.step };

    /* Change user password */
    case CHANGE_USER_PASSWORD_SUCCESS:
      return { type: CHANGE_USER_PASSWORD_SUCCESS, responce: action.data };
    case CHANGE_USER_PASSWORD_FAILED:
      return { type: CHANGE_USER_PASSWORD_FAILED, responce: action.data };

    /* Contact Us save*/
    case SAVE_CONTACT_US_SUCCESS:
      return { type: SAVE_CONTACT_US_SUCCESS, responce: action.data };
    case SAVE_CONTACT_US_FAILED:
      return { type: SAVE_CONTACT_US_FAILED, responce: action.data };

    /* Fetch Signin-Signup CMS*/
    case FETCH_SIGN_CMS_SUCCESS:
      return { type: FETCH_SIGN_CMS_SUCCESS, responce: action.data };
    case FETCH_SIGN_CMS_FAILED:
      return { type: FETCH_SIGN_CMS_FAILED, responce: action.data };

    /* Fetch Signin-Signup CMS*/
    case FETCH_SOCIAL_LINK_SUCCESS:
      return { type: FETCH_SOCIAL_LINK_SUCCESS, responce: action.data };
    case FETCH_SOCIAL_LINK_FAILED:
      return { type: FETCH_SOCIAL_LINK_FAILED, responce: action.data };

    // Remove card details
    case DELETE_CARD_SUCCESS:
      return { type: DELETE_CARD_SUCCESS, responce: action.data };
    case DELETE_CARD_FAILED:
      return { type: DELETE_CARD_FAILED, responce: action.data };





    /* CLEAR THE STATE VALUE */
    case CLEAR_STATE:
      return { pending: false };

    default:
      return state;
  }
};
