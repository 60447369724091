import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import UserIcon from "../assets/images/icon21.svg";
import EventIcon from "../assets/images/icon22.svg";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import UploadURLS from "../assets/settings";
import { confirmAlert } from "react-confirm-alert";

class PrimaryUserMemorySharedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      eventDetails: "",
      memoriesList: [],
      isLoading: true,

      isDisabledSubmit: false,
      isSubmitForm: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      isRedirect: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        /* FETCH ALL CONTACT INFO */
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_SUCCESS:
          var eventDetails =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";

          if (eventDetails) {
            props.contactActions.clearState();
            return {
              eventDetails: eventDetails,
            };
          } else {
            props.contactActions.clearState();
            return {
              eventDetails: ""
            };
          }

          break;
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_FAILED:
          return {
            eventDetails: "",
          };
          break;

        /* Fetch memory */
        case contactActions.PRIMARY_USER_MEMORY_SHARED_LIST_SUCCESS:
          var memoriesList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];

          props.contactActions.clearState();
          return {
            memoriesList: memoriesList,
            isLoading: false
          };
          break;

        case contactActions.PRIMARY_USER_MEMORY_SHARED_LIST_FAILED:
          props.contactActions.clearState();
          return {
            memoriesList: [],
            isLoading: false

          };
          break;

        //Update event success..
        case contactActions.UPDATE_EVENT_SUCCESS: {

          var eventDetails =
            contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";
          if (eventDetails) {
            //props.contactActions.fetchSingleEventDetails(eventDetails._id);
            var formData = {
              eventId: eventDetails._id,
              contactId:  eventDetails.user_contact_id,
            };
            setTimeout(() => {
              props.contactActions.primaryUserMemorySharedList(formData);
            }, 1500);
    
           

            return {}
          } else {
            props.contactActions.clearState();
            return {
              eventDetails: "",
              isLoading: false,
            };
          }

          break;

        }



        
      }
    }
    return {};
  }

  componentDidMount() {
    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null
    ) {
      this.props.history.push("/");
    } else {
      var currentEventId =
        this.props.match !== undefined &&
        this.props.match.params !== undefined &&
        this.props.match.params.eventId !== undefined
          ? this.props.match.params.eventId
          : "";

      var contactId =
        this.props.match !== undefined &&
        this.props.match.params !== undefined &&
        this.props.match.params.contactId !== undefined
          ? this.props.match.params.contactId
          : "";

      if (
        currentEventId === "" ||
        currentEventId === null ||
        currentEventId === undefined ||
        contactId === "" ||
        contactId === null ||
        contactId === undefined
      ) {
        this.props.history.push("/");
      } else {
        this.props.contactActions.fetchSingleEventDetails(currentEventId);
        var formData = {
          eventId: currentEventId,
          contactId: contactId,
        };

        this.props.contactActions.primaryUserMemorySharedList(formData);
      }
    }
  }

  handleClick = (data, eventDetails) => {
    if (
      data.memories &&
      Object.keys(data.memories).length > 0 &&
      data.memories.scheduled === true
    ) {
      this.props.history.push({
        pathname: "/secondary-user-view-memory/" + data.memories._id,
        state: {
          detail: data,
          eventDetails: eventDetails,
        },
      });
    } else {
      /*this.props.history.push({
        pathname: "/create-memory",
        state: {
          detail: data,
          eventDetails: eventDetails,
        },
      });*/
    }
  };

  unlock = (memory) => {
    console.log(memory);
    var check = moment();
    var month = check.format('MM');
    var day = check.format('DD');
    var year = check.format('YYYY');


    const formData = {
      eventDay: day,
      eventMonth: month,
      eventYear: year,
      eventId: memory.memories.event_id._id,
      notSure: false,
      dateUnknown: false,
    }
    console.log(formData)
    confirmAlert({
      title: "Sure to continue?",
      message: "You cannot revert back",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.contactActions.updateEvent(formData),
        },
        {
          label: "No",
        },
      ],
    });
  }

  render() {
    var formData = this.state.formData;
    var eventDetails = this.state.eventDetails;
    var isLoading = this.state.isLoading;
    var memoriesList = this.state.memoriesList;

    return (
      <>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          {memoriesList !== undefined &&
            memoriesList !== null &&
            memoriesList !== "" &&
            memoriesList.length > 0 &&
            isLoading === false && (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="card border-0 border-radius-10 min-vh-75 overflow-hidden">
                    <div className="card-header pad-x-20 pad-x-md-46 pad-y-15 bg-white">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                          <i className="material-icons text-color1 align-middle fs-30">
                            event
                          </i>{" "}
                          <span className="pl-2">
                            {eventDetails.title !== undefined &&
                            eventDetails.title !== null &&
                            eventDetails.title !== ""
                              ? eventDetails.title
                              : ""}
                          </span>
                        </h3>

                        <div className="position-relative"></div>
                      </div>
                    </div>
                    <div className="card-body pad-x-20 pad-x-md-46 pad-y-25 ">
                      <div className="row">
                        {this.state.memoriesList &&
                          this.state.memoriesList.length > 0 &&
                          this.state.memoriesList.map((list, index) => (
                            <>
                              {list.locked === false && (
                                <div
                                  className="col-sm-6 col-lg-4 mar-b-30 cursor-pointer"
                                  onClick={() =>
                                    this.handleClick(list, eventDetails)
                                  }
                                >
                                  <div className="shadow-md pad-15 pad-xxxl-30 rounded">
                                    <div className="fs-28 fs-xxxl-36 text-black font-weight-Bold">
                                      <img src={EventIcon} alt="" /> {list.year}
                                    </div>
                                    <div className="">
                                      <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                        Memory is unlocked
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {list.locked === true && (
                                <div className="col-sm-6 col-lg-4 mar-b-30 cursor-pointer">
                                  <div className="shadow-md pad-15 pad-xxxl-30 rounded">
                                    <div className="fs-28 fs-xxxl-36 font-weight-light text-black">
                                      <i className="material-icons align-middle text-color1">
                                        hourglass_full
                                      </i>{" "}
                                      {list.year}
                                    </div>
                                    <div className="">
                                      {list.date != null && (
                                        <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                          Arriving at{" "}
                                          {moment(list.date)
                                            //.tz('America/New_York')
                                            .format("Do MMMM YYYY")}
                                        </span>
                                      )}

                                      {list.date == null &&
                                        list.alertMessage != "" && (
                                          <div
                                            style={{ display: "inline-flex" }}
                                          >
                                            <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                              {list.alertMessage}
                                            </span>
                                            <Button
                                              variant="contained"
                                              color="ternary"
                                              onClick={() => this.unlock(list)}
                                            >
                                              Unlock
                                            </Button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {
          isLoading ? 
          ( <div className="text-center">
          <CircularProgress size={30} />
        </div> ) :

          (memoriesList === undefined ||
            memoriesList === null ||
            memoriesList === "" ||
            memoriesList.length === 0) &&
            (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    Sorry, no data found
                  </div>
                </div>
              </div>
            )
            
            }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrimaryUserMemorySharedList);
