import React, { Component, useState, useEffect } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import Icon from "../assets/images/icon10.svg";
import payIco1 from "../assets/images/payIco1.svg";
import payIco2 from "../assets/images/payIco2.svg";
import payIco3 from "../assets/images/payIco3.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";

/* Stripe */
//import {Elements} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
//import {stripe_publishable_key} from "../api";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import { Button } from "@material-ui/core";

const modal = "d-flex align-items-center";

//const stripePromise = loadStripe(stripe_publishable_key, {apiVersion: "2020-08-27"});

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const applePayInfo = props.applePayInfo;
  const clientSecret =
    applePayInfo !== undefined &&
    applePayInfo !== "" &&
    applePayInfo !== null &&
    applePayInfo.client_secret !== undefined &&
    applePayInfo.client_secret !== null &&
    applePayInfo.client_secret !== ""
      ? applePayInfo.client_secret
      : "";

  useEffect(() => {
    if (stripe) {
      var selected_plan_details = localStorage.getItem("selected_plan_details");
      selected_plan_details = JSON.parse(selected_plan_details);

      var amount =
        selected_plan_details.value !== undefined &&
        selected_plan_details.value !== null &&
        selected_plan_details.value !== ""
          ? selected_plan_details.value
          : 0.0;
      var stripAmount = Math.round(amount * 100);
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label:
            selected_plan_details.header !== undefined &&
            selected_plan_details.header !== null &&
            selected_plan_details.header !== ""
              ? selected_plan_details.header
              : "",
          amount: stripAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        console.log("result", result);
        //if (result) {
        if (
          result &&
          result.applePay !== undefined &&
          result.applePay !== null &&
          result.applePay !== "" &&
          result.applePay !== false
        ) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", async (ev) => {
      const {
        paymentIntent,
        error: confirmError,
      } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        ev.complete("fail");
      } else {
        ev.complete("success");
        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
          } else {
            console.log("succeeded1", paymentIntent);
            props.applePaySubmit(paymentIntent);
            // The payment has succeeded.
          }
        } else {
          console.log("succeeded2", paymentIntent);
          props.applePaySubmit(paymentIntent);
          // The payment has succeeded.
        }
      }
    });

    return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  }

  return "Your browser or device does not support Apple Pay on the web";
};

class RegisterStepFive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      applePayInfo: "",

      isSubmit: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",

      isPaypalPrepaymentSuccess: false,
    };
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";

      /* Redirect to dashboard */
      this.props.history.push("/dashboard");
    }

    if (this.state.isPaypalPrepaymentSuccess === true) {
      let registerData = localStorage.getItem("registration_info");
      let registerObj = JSON.parse(registerData);
      var event_memories_id = registerObj.event_memories_id;
      delete registerObj.event_memories_id;
      delete registerObj.confirm_password;

      var form_data = new FormData();

      for (var key in registerObj) {
        form_data.append(key, registerObj[key]);
      }

      if (event_memories_id.length > 0) {
        event_memories_id.forEach(function (value, indx) {
          form_data.append("event_memories_id[" + indx + "]", value);
        });
      }

      form_data.append("paymentMethod", "paypal");
      this.state.isSubmit = true;

      this.props.miscActions.userRegistration(form_data);

      this.state.isPaypalPrepaymentSuccess = false;
    }
  }

  componentDidMount() {
    var THIS = this;
    var auth_token = localStorage.getItem("auth_token");
    let registration_current_step = localStorage.getItem(
      "registration_current_step"
    );
    if (parseInt(registration_current_step) !== 5) {
      this.props.history.push("/sign-up");
    } else {
      if (
        auth_token === "" ||
        auth_token === undefined ||
        auth_token === null
      ) {
        var registration_info = localStorage.getItem("registration_info");
        if (registration_info !== "") {
          registration_info = JSON.parse(registration_info);
          var plan_id =
            registration_info.plan_id !== undefined &&
            registration_info.plan_id !== "" &&
            registration_info.plan_id !== null
              ? registration_info.plan_id
              : "";
          if (plan_id !== "") {
            var formdata = {
              plan_id: plan_id,
              code: registration_info.coupon_code?registration_info.coupon_code:""
            };
            this.props.miscActions.getApplePayIntent(formdata);

            setTimeout(function () {
              let userInfo = localStorage.getItem("registration_info");
              userInfo = JSON.parse(userInfo);
              //console.log('userInfouserInfouserInfo',userInfo);
              if (
                userInfo !== "" &&
                userInfo !== null &&
                userInfo !== undefined &&
                userInfo.email !== undefined &&
                userInfo.email !== null &&
                userInfo.email !== ""
              ) {
                var formdata = {
                  email: userInfo.email,
                };
                THIS.props.miscActions.checkPaypalAlreadyPaymentOrNot(formdata);
              }
            }, 1000);
          }
        }
      } else {
        this.props.history.push("/");
      }
    }
  }

  selectPaymentMethod(methodName) {
    if (methodName === "") {
      toast.error("Something went wrong! please try again");
    } else {
      if (methodName === "paypal") {
        let user_info = localStorage.getItem("registration_info");
        user_info = JSON.parse(user_info);

        if (
          user_info !== undefined &&
          user_info !== "" &&
          user_info !== null &&
          user_info.email !== "" &&
          user_info.email !== null &&
          user_info.email !== undefined &&
          user_info.plan_id !== "" &&
          user_info.plan_id !== null &&
          user_info.plan_id !== undefined
        ) {
          var formData = {
            plan_id: user_info.plan_id,
            email: user_info.email,
            code: user_info.coupon_code?user_info.coupon_code:""
          };

          this.setState({
            isSubmit: true,
          });
          this.props.miscActions.getPaypalRedirectUrl(formData);
        }

        //toast.error("Sorry, paypal payment not available");
      } else {
        let registerData = localStorage.getItem("registration_info");
        if (
          registerData === "" ||
          registerData === undefined ||
          registerData === null
        ) {
          toast.error("Something went wrong! please try again");
          this.props.history.push("/sign-up");
        } else {
          let registerObj = JSON.parse(registerData);
          registerObj["paymentMethod"] = methodName;
          localStorage.setItem(
            "registration_info",
            JSON.stringify(registerObj)
          );
          localStorage.setItem("registration_current_step", 6);
          this.props.history.push("/sign-up-final-step");
        }
      }
    }
  }

  applePaySubmit = (value) => {
    this.setState({ setOpen: false });
    let registerData = localStorage.getItem("registration_info");
    let registerObj = JSON.parse(registerData);
    var event_memories_id = registerObj.event_memories_id;
    delete registerObj.event_memories_id;
    delete registerObj.confirm_password;

    var form_data = new FormData();

    for (var key in registerObj) {
      form_data.append(key, registerObj[key]);
    }

    if (event_memories_id.length > 0) {
      event_memories_id.forEach(function (value, indx) {
        form_data.append("event_memories_id[" + indx + "]", value);
      });
    }

    form_data.append("paymentDetails", JSON.stringify(value));
    form_data.append("paymentMethod", "apple-pay");
    form_data.append("code",registerObj.coupon_code?registerObj.coupon_code:"")

    this.setState({ isSubmit: true });
    this.props.miscActions.userRegistration(form_data);
  };

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.GET_APPLE_PAYMENT_INTENT_SUCCESS:
          var applePayInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          props.miscActions.clearState();
          return {
            applePayInfo: applePayInfo,
          };
          break;
        case miscActions.GET_APPLE_PAYMENT_INTENT_FAILED:
          return {
            applePayInfo: "",
          };
          break;

        case miscActions.USER_REGISTRATION_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          var token =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.token !== undefined
              ? miscReducerData.responce.data.token
              : "";
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";

          props.miscActions.clearState();

          if (token !== "") {
            localStorage.setItem("auth_token", token);
            localStorage.setItem("user_info", JSON.stringify(userInfo));
            localStorage.removeItem("registration_info");
            localStorage.removeItem("registration_current_step");
            localStorage.removeItem("selected_plan_details");

            return {
              apiErrorMessage: "",
              apiSuccessMessage: message,
              isSubmit: false,
              setOpen: false,
            };
          } else {
            return {
              apiErrorMessage: "Something went wrong",
              apiSuccessMessage: "",
              isSubmit: false,
              setOpen: false,
            };
          }
          return {};
          break;
        case miscActions.USER_REGISTRATION_FAILED:
          props.miscActions.clearState();
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined &&
            miscReducerData.responce.data.message !== ""
              ? miscReducerData.responce.data.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmit: false,
            setOpen: false,
          };
          break;
        case miscActions.USER_REGISTRATION_FAILED_CARD:
          props.miscActions.clearState();
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.message !== undefined &&
            miscReducerData.responce.message !== ""
              ? miscReducerData.responce.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmit: false,
            setOpen: false,
          };
          break;

        /* Paypal Payment get redirect URL */
        case miscActions.GET_PAYPAL_PAY_REDIRECT_URL_SUCCESS:
          var redirectURL =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.redirectUrl !== undefined &&
            miscReducerData.responce.data.redirectUrl !== "" &&
            miscReducerData.responce.data.redirectUrl !== null
              ? miscReducerData.responce.data.redirectUrl
              : "";
          props.miscActions.clearState();
          if (redirectURL !== "") {
            window.location.href = redirectURL;
          }

          return {
            //isSubmit : false
          };
          break;

        case miscActions.GET_PAYPAL_PAY_REDIRECT_URL_FAILED:
          return {
            apiErrorMessage: "Something went wrong ! please try again",
            apiSuccessMessage: "",
            isSubmit: false,
          };
          break;

        case miscActions.CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_SUCCESS:
          props.miscActions.clearState();
          return {
            isPaypalPrepaymentSuccess: true,
          };
          break;
        case miscActions.CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_FAILED:
          props.miscActions.clearState();
          return {
            isPaypalPrepaymentSuccess: false,
          };
          break;
      }
    }

    return {};
  }

  handleOpen = () => {
    this.setState({ setOpen: true });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  render() {
    var selected_plan_details = localStorage.getItem("selected_plan_details");
    selected_plan_details = JSON.parse(selected_plan_details);

    var planName =
      selected_plan_details !== undefined &&
      selected_plan_details !== null &&
      selected_plan_details !== "" &&
      selected_plan_details.header !== undefined &&
      selected_plan_details.header !== "" &&
      selected_plan_details.header !== null
        ? selected_plan_details.header
        : "";
    var subheader =
      selected_plan_details !== undefined &&
      selected_plan_details !== null &&
      selected_plan_details !== "" &&
      selected_plan_details.subheader !== undefined &&
      selected_plan_details.subheader !== "" &&
      selected_plan_details.subheader !== null
        ? selected_plan_details.subheader
        : "";
    var value =
      selected_plan_details !== undefined &&
      selected_plan_details !== null &&
      selected_plan_details !== "" &&
      selected_plan_details.value !== undefined &&
      selected_plan_details.value !== "" &&
      selected_plan_details.value !== null
        ? selected_plan_details.value
        : 0.0;
    var type =
      selected_plan_details !== undefined &&
      selected_plan_details !== null &&
      selected_plan_details !== "" &&
      selected_plan_details.type !== undefined &&
      selected_plan_details.type !== "" &&
      selected_plan_details.type !== null
        ? selected_plan_details.type
        : "";

    return (
      <>
        <div className="card bg-transparent border-0 vh-100 ">
          <PrimaryHeader position={"innerHeader"} />
          <section className=" registerStepWrap h-100 overflow-auto bluebgsection">
            <div className="container">
              <div className="row">
                <div className="card bigCard border-0  mx-auto">
                  <div className="head text-right mr-md-n4">
                    <h5 className="mb-0">Step 5 of 5</h5>
                  </div>

                  <div className=" ">
                    <div className="iconBox mar-b-15 mar-b-md-35">
                      <img src={Icon} alt="" className="w-50px w-md-89px" />
                    </div>
                    <div className="fs-22 fs-md-36 font-weight-Bold ">
                      Select Payment Method
                    </div>
                    <div className="subHead fs-14 fs-md-18 mar-b-30">
                      Set up your payment method.
                    </div>

                    {this.state.isSubmit === true && (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    )}

                    {this.state.isSubmit === false && (
                      <div className="pad-t-30">
                        <div className="row">
                          <div className="col-md-4 mb-5 mb-md-0">
                            <div
                              className="d-flex flex-column p-4 payBox text-center cursor-pointer justify-content-between rounded"
                              onClick={(e) => this.handleOpen()}
                            >
                              <div className=" my-auto ">
                                <img src={payIco1} alt="" />
                              </div>
                              <div className="text-color4">
                                Pay with Apple Pay
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 mb-5 mb-md-0">
                            <div
                              className="d-flex flex-column p-4 payBox text-center cursor-pointer justify-content-between rounded"
                              onClick={(e) =>
                                this.selectPaymentMethod("paypal")
                              }
                            >
                              <div className=" my-auto ">
                                <img src={payIco2} alt="" />
                              </div>
                              <div className="text-color4">Pay with PayPal</div>
                            </div>
                          </div>
                          <div className="col-md-4 mb-5 mb-md-0">
                            <div
                              className="d-flex flex-column p-4 payBox text-center cursor-pointer justify-content-between rounded"
                              onClick={(e) => this.selectPaymentMethod("card")}
                            >
                              <div className=" my-auto ">
                                <img src={payIco3} alt="" />
                              </div>
                              <div className="text-color4">Pay with Card</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="w-100 pad-t-50"
                      style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        type="submit"
                        className="bg-color4 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                        onClick={() => {
                          this.props.history.push("/sign-up-step-four");
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Modal
          className={modal}
          open={this.state.setOpen}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="myCusTomModal d-flex">
            <div className="card">
              <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 ">
                  <i className="material-icons text-color1 align-middle">
                    payment
                  </i>{" "}
                  Payment Now
                </h3>
              </div>
              <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                <h3 className="tsubHead fs-14 fs-md-18 mar-b-30">
                  You have selected the{" "}
                  <span className="font-weight-Bold fs-24">{planName}</span>{" "}
                  plan now for just{" "}
                  <span className="font-weight-Bold fs-24">${value}</span>
                </h3>
              </div>
              <div className="card-footer bg-white text-right pad-x-40 ">
                {/*<Elements stripe={stripePromise}>
                          <CheckoutForm applePayInfo={this.state.applePayInfo} applePaySubmit = {this.applePaySubmit}/>
                        </Elements>*/}
                <CheckoutForm
                  applePayInfo={this.state.applePayInfo}
                  applePaySubmit={this.applePaySubmit}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStepFive);
