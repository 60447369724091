import React, { Component } from "react";
import { Link } from "react-router-dom";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import TextField from "@material-ui/core/TextField";

import fingerIcon from "../assets/images/icon6.svg";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import CircularProgress from "@material-ui/core/CircularProgress";

class SecondaryUserRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegisterCall: false,
      primaryUserInfo: "",
      secondaryUserInfo: "",
      isLoading: true,
      formData: {
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        confirm_password: "",
        newsletterSubscriptions: false,
        accepted_termsCon: false,
      },
      isSubmit: false,
      isShowPassword: false,
      isShowConPassword: false,
      apiSuccessMessage: "",
      apiErrorMessage: "",


      cms_title: "",
      cms_sub_title: "",
      cms_description: "",
    };

    /* Bind the function */
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    })
    this.props.miscActions.fetchSignCms();
    var auth_token = localStorage.getItem("auth_token");
    var secondary_user_token = localStorage.getItem("secondary_user_token");

    if (
      (auth_token === "" || auth_token === undefined || auth_token === null) &&
      secondary_user_token !== "" &&
      secondary_user_token !== null &&
      secondary_user_token !== undefined
    ) {
      var formdata = {
        token: secondary_user_token,
      };
      this.props.miscActions.getUserDetailsByToken(formdata);
    } else {
      this.props.history.push("/");
    }
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";

      /* Redirect to apiSuccessMessage */
      this.props.history.push("/sign-up-secondary-user-step-two");
    }

    if (
      this.state.isRegisterCall === true &&
      this.state.primaryUserInfo !== null &&
      this.state.primaryUserInfo !== "" &&
      this.state.primaryUserInfo !== undefined &&
      this.state.secondaryUserInfo !== null &&
      this.state.secondaryUserInfo !== "" &&
      this.state.secondaryUserInfo !== undefined
    ) {
      var formData = this.state.formData;
      formData["invitedBy"] = this.state.primaryUserInfo._id;
      formData["secondaryUserId"] = this.state.secondaryUserInfo._id;
      delete formData.confirm_password;

      console.log("formData", formData);

      var form_data = new FormData();
      for (var key in formData) {
        form_data.append(key, formData[key]);
      }

      this.props.miscActions.userRegistration(form_data);

      this.state.isRegisterCall = false;
      this.state.isSubmit = true;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.CHECK_USER_EXISTS_FAILED:
          props.miscActions.clearState();
          return {
            apiErrorMessage:
              "Email is already exists! Please try another email",
          };
          break;
        case miscActions.CHECK_USER_EXISTS_SUCCESS:
          props.miscActions.clearState();

          //localStorage.setItem("registration_info", JSON.stringify(state.formData))
          //localStorage.setItem("registration_current_step", 2);
          //props.history.push('/sign-up-step-two')

          return {
            apiErrorMessage: "",
            isRegisterCall: true,
          };
          break;

        case miscActions.USER_REGISTRATION_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";

          var token =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.token !== undefined
              ? miscReducerData.responce.data.token
              : "";

          var message =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";

          props.miscActions.clearState();

          if (token !== "") {
            localStorage.setItem("auth_token", token);
            localStorage.setItem("user_info", JSON.stringify(userInfo));

            return {
              apiErrorMessage: "",
              apiSuccessMessage: message,
              isSubmit: false,
            };
          } else {
            return {
              apiErrorMessage: "Something went wrong",
              apiSuccessMessage: "",
              isSubmit: false,
            };
          }
          return {};
          break;
        case miscActions.USER_REGISTRATION_FAILED:
          props.miscActions.clearState();
          var message =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.message !== undefined &&
              miscReducerData.responce.data.message !== ""
              ? miscReducerData.responce.data.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmit: false,
          };
          break;

        /* Primary User Details */
        case miscActions.GET_PROFILE_DETAILS_BY_TOKEN_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";

          var secondaryUserInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.secondaryUser !== undefined
              ? miscReducerData.responce.data.secondaryUser
              : "";
          console.log("secondaryUserInfo", secondaryUserInfo);

          if (secondaryUserInfo.password !== "") {
            props.history.push("/sign-in");
          }
          props.miscActions.fetchSignCms();

          var formData = {
            email:
              secondaryUserInfo !== undefined &&
                secondaryUserInfo !== null &&
                secondaryUserInfo !== "" &&
                secondaryUserInfo.email !== undefined &&
                secondaryUserInfo.email !== null &&
                secondaryUserInfo.email !== ""
                ? secondaryUserInfo.email
                : "",
            first_name:
              secondaryUserInfo !== undefined &&
                secondaryUserInfo !== null &&
                secondaryUserInfo !== "" &&
                secondaryUserInfo.first_name !== undefined &&
                secondaryUserInfo.first_name !== null &&
                secondaryUserInfo.first_name !== ""
                ? secondaryUserInfo.first_name
                : "",
            last_name:
              secondaryUserInfo !== undefined &&
                secondaryUserInfo !== null &&
                secondaryUserInfo !== "" &&
                secondaryUserInfo.last_name !== undefined &&
                secondaryUserInfo.last_name !== null &&
                secondaryUserInfo.last_name !== ""
                ? secondaryUserInfo.last_name
                : "",
            password: "",
            confirm_password: "",
          };

          props.miscActions.clearState();
          return {
            primaryUserInfo: userInfo,
            secondaryUserInfo: secondaryUserInfo,
            formData: formData,

            isLoading: false,
          };
          break;
        case miscActions.GET_PROFILE_DETAILS_BY_TOKEN_FAILED:
          props.miscActions.clearState();
          return {
            primaryUserInfo: "",
            secondaryUserInfo: "",
            isLoading : false,
          };
          break;
        case miscActions.FETCH_SIGN_CMS_SUCCESS:
          var cmsInfo =
            miscReducerData !== undefined &&
              miscReducerData.responce !== undefined &&
              miscReducerData.responce.data !== undefined &&
              miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          return {
            cms_title: cmsInfo.title,
            cms_sub_title: cmsInfo.sub_title,
            cms_description: cmsInfo.description,
          };
        default:
          return {}
      }
    }
    return {};
  }



  validateEmail(mail) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    var formData = this.state.formData;

    if (
      formData.email === "" ||
      formData.email === undefined ||
      formData.email === null
    ) {
      toast.error("Please enter your email ");
    } else if (this.validateEmail(formData.email) === false) {
      toast.error("Please enter a valid email address");
    } else if (
      formData.first_name === "" ||
      formData.first_name === undefined ||
      formData.first_name === null
    ) {
      toast.error("Please enter your first name");
    } else if (
      formData.last_name === "" ||
      formData.last_name === undefined ||
      formData.last_name === null
    ) {
      toast.error("Please enter your last name");
    } else if (
      formData.password === "" ||
      formData.password === undefined ||
      formData.password === null
    ) {
      toast.error("Please enter your password");
    } else if (formData?.password?.length < 6) {
      toast.error("Password must be atleast 6 characters");
    } else if (
      formData.confirm_password === "" ||
      formData.confirm_password === undefined ||
      formData.confirm_password === null
    ) {
      toast.error("Please enter your confirm password");
    } else if (formData.password !== formData.confirm_password) {
      toast.error("Password and confirm password does not match");
    } else if (
      formData.accepted_termsCon === "" ||
      formData.accepted_termsCon === undefined ||
      formData.accepted_termsCon === null ||
      !formData.accepted_termsCon
    ) {
      toast.error("Please accept the Terms of Use and Privacy Policy");
    } else {
      /* check email exists or not */
      this.props.miscActions.checkUserEmailExists(formData.email);
    }
  }

  handlenewsletterChange = () => {
    let formData = this.state.formData;
    formData["newsletterSubscriptions"] = !this.state.formData
      .newsletterSubscriptions;
    this.setState({
      formData: formData,
    });
  };

  handleaccepted_termsConChanges = () => {
    let formData = this.state.formData;
    formData["accepted_termsCon"] = !this.state.formData.accepted_termsCon;
    this.setState({
      formData: formData,
    });
  };

  render() {
    var formData = this.state.formData;
    var primaryUserInfo = this.state.primaryUserInfo;

    if (this.state.isLoading === true) {
      return (
        <div className="page-center">
          <CircularProgress />
        </div>
      );
    }

    return (
      <>
        <PrimaryHeader
          position={"absoluteHeader"}
          otherMenuNotVisible={"login"}
        />
        <section className="registerWrap">
          <div className="container pb-5">
            <div className="row mx-auto registerInwrap align-items-center ">
              <div className="col-lg-6 bg-white leftBox">
                <div className="card border-0">
                  <h3
                    className="fs-36 font-weight-Bold"
                    style={{ color: "#212121" }}
                  >
                    Accept {primaryUserInfo.first_name}{"\'s "}
                    {/* {primaryUserInfo.last_name} */}
                    Invitation
                  </h3>
                  <h5
                    className="fs-16 fs-lg-18 mar-b-30 font-weight-Regular"
                    style={{ color: "#A3A3A3" }}
                  >
                    Already have an account?{" "}
                    <Link to="/sign-in" className="font-weight-Bold">
                      Sign In
                    </Link>
                  </h5>
                  <form onSubmit={this.handleSubmit} className={'custom_form'}>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <TextField
                          name="email"
                          value={formData.email}
                          label="Email Address"
                          variant="outlined"
                          className="w-100"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <TextField
                          name="first_name"
                          value={formData.first_name}
                          label="First Name"
                          variant="outlined"
                          className="w-100"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <TextField
                          name="last_name"
                          value={formData.last_name}
                          label="Last Name"
                          variant="outlined"
                          className="w-100"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="position-relative passwordWrap">
                          <TextField
                            name="password"
                            value={formData.password}
                            type={
                              this.state.isShowPassword === true
                                ? "text"
                                : "password"
                            }
                            label="Set Password"
                            variant="outlined"
                            className="w-100"
                            onChange={this.handleChange}
                            required
                          />
                          <div
                            className="position-absolute icoSufix"
                            onClick={(e) =>
                              this.setState({
                                isShowPassword: !this.state.isShowPassword,
                              })
                            }
                          >
                            <IconButton>
                              <i className="material-icons">
                                {this.state.isShowPassword === true
                                  ? "visibility"
                                  : "visibility_off"}{" "}
                              </i>
                            </IconButton>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="position-relative passwordWrap">
                          <TextField
                            name="confirm_password"
                            type={
                              this.state.isShowConPassword === true
                                ? "text"
                                : "password"
                            }
                            label="Confirm Password"
                            variant="outlined"
                            className="w-100"
                            onChange={this.handleChange}
                            required
                          />
                          <div
                            className="position-absolute icoSufix"
                            onClick={(e) =>
                              this.setState({
                                isShowConPassword: !this.state
                                  .isShowConPassword,
                              })
                            }
                          >
                            <IconButton>
                              <i className="material-icons">
                                {this.state.isShowConPassword === true
                                  ? "visibility"
                                  : "visibility_off"}{" "}
                              </i>
                            </IconButton>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 pt-3 customCheckbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.formData.newsletterSubscriptions
                              }
                              onChange={this.handlenewsletterChange}
                              name="newsletterSubscriptions"
                            />
                          }
                          label={
                            <p className="fs-14" style={{ margin: "0px" }}>
                              I would like to receive emails regarding platform features and exclusive offers.
                            </p>
                          }
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.formData.accepted_termsCon}
                              onChange={this.handleaccepted_termsConChanges}
                              name="accepted_termsCon"
                            //style={{marginTop:'-20px'}}
                            />
                          }
                          label={
                            <p
                              className="fs-14 fs-14"
                              style={{ margin: "0px" }}
                            >
                              By clicking 'Create Account,' I agree to Forget Me Not’s{" "}
                              <Link to="/terms-of-use" style={{ fontWeight: 'bold' }}>Terms of Service</Link> and acknowledge its {" "}
                              <Link to="/privacy-policy" style={{ fontWeight: 'bold' }}>Privacy Policy</Link>.
                              {/* ”{" "}
                              <Link to="/terms-of-use">Terms of Use</Link> and{" "}
                              <Link to="/privacy-policy">Privacy Policy</Link>. */}
                            </p>
                          }
                        />

                        {/* <p className="fs-14">
                          By clicking Create account, I agree that I have read
                          and accepted the{" "}
                          <Link to="/terms-of-use">Terms of Use</Link> and{" "}
                          <Link to="/privacy-policy">Privacy Policy</Link>.
                        </p> */}
                      </div>
                      <div className="col-md-12 pt-3">
                        {this.state.isSubmit === true && <CircularProgress />}

                        {this.state.isSubmit === false && (
                          <Button
                            type="submit"
                            className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                          >
                            Create Account
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6  ">
                <div className="rightBox">
                  <img
                    src={fingerIcon}
                    alt=""
                    className="w-70px w-xxxl-100px"
                  />
                  <div className="text-white fs-34 fs-xxxl-48 font-weight-Bold pad-t-20 pad-t-xxxl-35 line-height-1" dangerouslySetInnerHTML={{
                    __html: this?.state?.cms_title
                      ? this?.state?.cms_title
                      : "Be there at 2025"
                  }} />

                  <div
                    className="text-white fs-26 fs-xxxl-36"
                    style={{ opacity: "0.6" }}
                    dangerouslySetInnerHTML={{
                      __html: this?.state?.cms_sub_title
                        ? this?.state?.cms_sub_title
                        : "With Never Forget Me"
                    }}
                  />

                  <div className="text-white line-height-normal fs-18 fs-xxxl-24 pad-t-30" dangerouslySetInnerHTML={{ __html: this?.state?.cms_description }} />

                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryUserRegistration);
