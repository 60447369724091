import { baseURL } from "../api";

const UploadURLS = {
  profile_image_path: baseURL + "uploads/user/profile_pic/",
  help_video_path: baseURL + "uploads/cms/video/",
  contact_profile_pic: baseURL + "uploads/user/profile_pic/",
  testimonialsImagePath: baseURL + "uploads/testimonials/",
  user_memories: baseURL + "uploads/user-memories/",
};

export default UploadURLS;
