import { Button } from "@material-ui/core";
import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import moment from "moment-timezone";
/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

import UploadURLS from "../assets/settings.js";
import menuIco4 from "../assets/images/icon15.svg";
import { Helmet } from "react-helmet";

class NotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
    };
  }

  componentDidMount() {
    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null
    ) {
      this.props.history.push("/");
    } else {
      this.props.miscActions.fetchNotifications();
    }
  }

  static getDerivedStateFromProps(props) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.FETCH_NOTIFICATION_LIST_SUCCESS:
          var notificationList = miscReducerData.responce.data
            ? miscReducerData.responce.data
            : [];
          return {
            notifications: notificationList,
          };

        case miscActions.READ_NOTIFICATION_SUCCESS:
          var notiReadData =
            miscReducerData !== undefined &&
            miscReducerData !== null &&
            miscReducerData !== "" &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce !== null &&
            miscReducerData.responce !== "" &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data !== null &&
            miscReducerData.responce.data !== "" &&
            miscReducerData.responce.data.data !== undefined &&
            miscReducerData.responce.data.data !== null &&
            miscReducerData.responce.data.data !== ""
              ? miscReducerData.responce.data.data
              : "";

          var currentUserRole = "";
          var user_info = localStorage.getItem("user_info");
          if (user_info !== "") {
            user_info = JSON.parse(user_info);
          }
          if (
            user_info !== "" &&
            user_info !== undefined &&
            user_info !== null &&
            user_info.role !== "" &&
            user_info.role !== null &&
            user_info.role !== undefined &&
            user_info.role.role !== "" &&
            user_info.role.role !== null &&
            user_info.role.role !== undefined
          ) {
            currentUserRole = user_info.role.role;
          }

          if (
            notiReadData !== "" &&
            currentUserRole === "user" &&
            notiReadData.type === "significant-event-notification" &&
            notiReadData.eventDateRequired === false &&
            notiReadData.event_id !== undefined &&
            notiReadData.event_id._id !== undefined
          ) {
            props.history.push("/view-event/" + notiReadData.event_id._id);
          } else if (
            notiReadData !== "" &&
            currentUserRole === "sub-user" &&
            notiReadData.type === "significant-event-notification" &&
            notiReadData.eventDateRequired === false &&
            notiReadData.event_id !== undefined &&
            notiReadData.event_id._id !== undefined
          ) {
            props.history.push(
              "/secondary-user-memorylist/" + notiReadData.event_id._id
            );
          } else if (
            notiReadData !== "" &&
            notiReadData.type === "significant-event-notification" &&
            notiReadData.eventDateRequired === true
          ) {
            props.history.push("/sign-up-secondary-user-step-two");
          } else if (
            notiReadData !== "" &&
            notiReadData.type === "user-signup-notification" &&
            notiReadData.user_contact_id !== undefined &&
            notiReadData.user_contact_id._id !== undefined
          ) {
            props.history.push(
              "/view-contact/" + notiReadData.user_contact_id._id
            );
          } else if (notiReadData !== "" && notiReadData.type === "dashboard") {
            if (notiReadData.message.includes("Add a profile picture")) {
              props.history.push("/settings");
            } else {
              if (currentUserRole === "user") {
                props.history.push("/dashboard");
              } else {
                props.history.push("/secondary-user-dashboard");
              }
            }
          } else if (
            notiReadData !== "" &&
            notiReadData.type === "memory-shared" &&
            currentUserRole === "sub-user" &&
            notiReadData.event_id !== undefined &&
            notiReadData.event_id._id !== undefined
          ) {
            props.history.push(
              "/secondary-user-memorylist/" + notiReadData.event_id._id
            );
          } else if (
            notiReadData !== "" &&
            notiReadData.type === "memory-shared" &&
            currentUserRole === "user" &&
            notiReadData.event_id !== undefined &&
            notiReadData.event_id._id !== undefined &&
            notiReadData.event_id.user_contact_id !== undefined
          ) {
            props.history.push(
              "/primary-user-memory-shared-list/" +
                notiReadData.event_id._id +
                "/" +
                notiReadData.event_id.user_contact_id
            );
          } else if (
            notiReadData !== "" &&
            notiReadData.type === "user-contact-stored"
          ) {
            props.history.push({
              pathname: "/dashboard",
              state: {
                secondaryUserDetails: notiReadData.secondaryUser,
              },
            });
          }

        default:
          return {};
      }
    }
    return {};
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Forget Me Not - Notifications</title>
        </Helmet>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
            <div className="container">
              <div className="col-md-11 col-lg-10 col-xl-9 col-xxxl-8 mx-auto">
                <div className="card border-0 border-radius-10 min-vh-75 overflow-hidden">
                  <div className="card-header pad-x-20 pad-x-lg-45 pad-y-20 bg-white">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3 className="text-black fs-20 fs-md-24 font-weight-Bold mb-0">
                        <i className="material-icons text-color1 align-middle fs-24 fs-md-30">
                          notifications
                        </i>
                        <span className="pl-2">Notifications</span>
                      </h3>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <ul className="list-unstyled py-4">
                      {this.state.notifications &&
                      this.state.notifications.length > 0
                        ? this.state.notifications.map((list, index) => (
                            <li className="pad-x-20 pad-x-lg-45 " key={index}>
                              <Button
                                onClick={() =>
                                  this.props.miscActions.readNotification(
                                    list._id
                                  )
                                }
                                className={
                                  "d-flex align-items-center justify-content-start border-bottom py-3 py-lg-4 w-100 text-left " +
                                  (list.read === false
                                    ? "disabled-element"
                                    : "")
                                }
                              >
                                <div>
                                  {list.type === "dashboard" && (
                                    <img
                                      src={
                                        list.parentUser !== null &&
                                        list.parentUser !== "" &&
                                        list.parentUser !== undefined &&
                                        list.parentUser.profile_image !==
                                          null &&
                                        list.parentUser.profile_image !== "" &&
                                        list.parentUser.profile_image !==
                                          undefined
                                          ? UploadURLS.profile_image_path +
                                            list.parentUser.profile_image
                                          : menuIco4
                                      }
                                      alt=""
                                      className="w-40px w-md-52px h-40px h-md-52px rounded-circle d-block object-fit-cover"
                                    />
                                  )}

                                  {list.type !== "dashboard" && (
                                    <img
                                      src={
                                        list.user_contact_id !== null &&
                                        list.user_contact_id !== "" &&
                                        list.user_contact_id !== undefined &&
                                        list.user_contact_id.image !== null &&
                                        list.user_contact_id.image !== "" &&
                                        list.user_contact_id.image !== undefined
                                          ? UploadURLS.profile_image_path +
                                            list.user_contact_id.image
                                          : menuIco4
                                      }
                                      alt=""
                                      className="w-40px w-md-52px h-40px h-md-52px rounded-circle d-block object-fit-cover"
                                    />
                                  )}
                                </div>
                                <div className="pl-3 pl-md-4">
                                  <h5
                                    className="mb-1 fs-14 fs-lg-18 opa-80 text-black font-weight-Regular"
                                    dangerouslySetInnerHTML={{
                                      __html: list.message,
                                    }}
                                  />

                                  <h6 className="mb-0 fs-11 fs-lg-14 opa-60 text-black">
                                    {moment(list.createdAt)
                                      .tz("America/New_York")
                                      .format("MMMM Do YYYY")}
                                  </h6>
                                </div>
                              </Button>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
