import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import bg from "../assets/images/image--216.jpg";
import icon24 from "../assets/images/icon24.svg";
import { Button } from "@material-ui/core";
import PlayBtn from "../assets/images/icon23.svg";
import Image from "../assets/images/videobg.png";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import HelpIcon from "@material-ui/icons/Help";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import countryCode from "../assets/countryCode";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import validator from "validator";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";

import UploadURLS from "../assets/settings.js";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const modal = "d-flex align-items-center";

class HelpCenterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "panel0",
      cmsInfo: "",
      faqList: [],
      isLoading: false,
      supModal: false,
      first_name: "",
      last_name: "",
      email: "",
      country_code: "+1",
      phone: "",
      message: "",
      isSubmitForm: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      emailError: "",
      phoneError: "",
      countrycodeError: "",
      isError: false,
      error: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country_code: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    /* fetch help information */
    this.setState({ isLoading: true });
    this.props.miscActions.fetchCmsData("help-centre");
    /* fetch FAQ List */
    this.props.miscActions.fetchFaqList();
  }
  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.FETCH_CMS_DATA_SUCCESS:
          var cmsInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          props.miscActions.clearState();
          return {
            isLoading: false,
            cmsInfo: cmsInfo,
          };
          break;

        case miscActions.FETCH_CMS_DATA_FAILED:
          props.miscActions.clearState();
          return {
            isLoading: false,
            cmsInfo: "",
          };
          break;

        /* FAQ LIST */
        case miscActions.FETCH_FAQ_LIST_SUCCESS:
          var faqList =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";

          props.miscActions.clearState();
          return {
            isLoading: false,
            faqList: faqList,
          };
          break;

        case miscActions.FETCH_FAQ_LIST_FAILED:
          props.miscActions.clearState();
          return {
            isLoading: false,
            faqList: [],
          };
          break;
        case miscActions.SAVE_CONTACT_US_SUCCESS:
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          props.miscActions.clearState();
          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            supModal: false,
            first_name: "",
            last_name: "",
            email: "",
            country_code: "",
            phone: "",
            message: "",
            isSubmitForm: false,
          };
          break;
        case miscActions.SAVE_CONTACT_US_FAILED:
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          props.miscActions.clearState();
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            supModal: false,
            first_name: "",
            last_name: "",
            email: "",
            country_code: "",
            phone: "",
            message: "",
            isSubmitForm: false,
          };
        //break;
        default:
          return {};
      }
    }

    return {};
  }

  validate = () => {
    let isError = false;
    this.setState({ emailError: "", countrycodeError: "" });
    if (this.state.email === "" && this.state.phone === "") {
      isError = true;
      this.setState({
        emailError: "Please provide your email.",
        phoneError: "Please provide your Phone number.",
      });
    }
    if (this.state.country_code === "" && this.state.phone !== "") {
      isError = true;
      this.setState({ countrycodeError: "Please provide proper country code" });
    }
    return isError;
  };
  hasWhiteSpace(s) {
    return /\s/.test(s);
  }

  handleSupSubmit = () => {
    this.setState({ isSubmitForm: true });
    let err = this.validate();
    const errorObj = {};
    const keys = Object.keys(this.state.error);
    for (const key of keys) {
      if (
        (this.state[key] === "" || this.state[key] === "undefined") &&
        key !== "phone" &&
        key !== "email"
      ) {
        errorObj[key] = "This is required field";
      } else if (key === "email") {
        if (this.state[key] === "") {
          if (
            validator.isMobilePhone(this.state.country_code + this.state.phone) && !(/^\s/.test(this.state.phone))
          ) {
            errorObj.email = "";
          } else {
            errorObj.email = "This is required field";
          }
        } else {
          if (
            validator.isEmail(this.state.email) &&
            !this.hasWhiteSpace(this.state.email)
          ) {
            errorObj[key] = "";
          } else {
            errorObj[key] = "Please enter correct email";
          }
        }
      } else if (key === "phone") {
        if (this.state[key] === "") {
          if (
            validator.isEmail(this.state.email) &&
            !this.hasWhiteSpace(this.state.email)
          ) {
            errorObj[key] = "";
          } else {
            errorObj[key] = "This is required field";
          }
        } else {
          if (
            validator.isMobilePhone(this.state.country_code + this.state[key]) && !(/^\s/.test(this.state.phone))
          ) {
            errorObj[key] = "";
          } else {
            errorObj[key] = "Please enter correct phone number";
          }
        }
      } else {
        errorObj[key] = "";
      }
    }
    this.setState(
      {
        error: errorObj,
      },
      () => {
        const error = [];
        const keys = Object.keys(this.state.error);
        console.log(this.state.error);
        for (const key of keys) {
          if (this.state.error[key] !== "") {
            error.push(key);
          }
        }
        if (error.length === 0) {
          let new_data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            country_code: this.state.country_code,
            phone: this.state.phone,
            message: this.state.message,
          };
          this.props.miscActions.saveContactUs(new_data);
        }
      }
    );
  };
  handleSupClose = () => {
    this.setState({
      supModal: false,
      first_name: "",
      last_name: "",
      email: "",
      country_code: "+1",
      phone: "",
      message: "",
      isSubmitForm: false,
      emailError: "",
      phoneError: "",
      countrycodeError: "",
      isError: false,
      error: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country_code: "",
        message: "",
      },
    });
  };

  handleModalChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.isSubmitForm) {
        const errorObj = {};
        const keys = Object.keys(this.state.error);
        for (const key of keys) {
          if (
            (this.state[key] === "" || this.state[key] === "undefined") &&
            key !== "phone" &&
            key !== "email"
          ) {
            errorObj[key] = "This is required field";
          } else if (key === "email") {
            if (this.state[key] === "") {
              if (
                validator.isMobilePhone(
                  this.state.country_code + this.state.phone
                ) && !(/^\s/.test(this.state.phone))
              ) {
                errorObj.email = "";
              } else {
                errorObj.email = "This is required field";
              }
            } else {
              if (
                validator.isEmail(this.state.email) &&
                !this.hasWhiteSpace(this.state.email)
              ) {
                errorObj[key] = "";
              } else {
                errorObj[key] = "Please enter correct email";
              }
            }
          } else if (key === "phone") {
            if (this.state[key] === "") {
              if (
                validator.isEmail(this.state.email) &&
                !this.hasWhiteSpace(this.state.email)
              ) {
                errorObj[key] = "";
              } else {
                errorObj[key] = "This is required field";
              }
            } else {
              if (
                validator.isMobilePhone(
                  this.state.country_code + this.state[key]
                ) && !(/^\s/.test(this.state.phone))
              ) {
                errorObj[key] = "";
              } else {
                errorObj[key] = "Please enter correct phone number";
              }
            }
          } else {
            errorObj[key] = "";
          }
        }
        this.setState({
          error: errorObj,
        });
      }

      // if (e.target.name === "email" && this.state.isSubmitForm) {
      //   if (validator.isEmail(e.target.value)) {
      //     this.setState({
      //       emailError: "",
      //       phoneError: "",
      //     });
      //   } else {
      //     this.setState({
      //       emailError: "Email Address is not correct",
      //     });
      //   }
      // }

      // if (e.target.name === "phone" && this.state.isSubmitForm) {
      //   if (Boolean(this.state.emailError)) {
      //     if (validator.isMobilePhone(e.target.value)) {
      //       this.setState({
      //         emailError: "",
      //         phoneError: "",
      //       });
      //     } else {
      //       this.setState({
      //         phoneError: "Please enter correct phone number",
      //       });
      //     }
      //   }
      // }
    });
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };
  render() {
    var cmsInfo = this.state.cmsInfo;
    var faqList = this.state.faqList;
    if (this.state.isLoading === true) {
      return (
        <div className="page-center">
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <>
          <Helmet>
            <title>Forget Me Not - Contact Us</title>
          </Helmet>
          <Modal
            className={modal}
            open={this.state.supModal}
            onClose={this.handleSupClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="myCusTomModal d-flex">
              <div className="border-radius-15 card">
                <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                  <h3 className="font-weight-Medium fs-24 mb-0 ">
                    <HelpIcon className="material-icons text-color1 align-middle " />{" "}
                    Contact Support
                  </h3>
                </div>
                <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                  <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                    1. Please enter your name.
                  </h3>
                  <div className="row">
                    <div className="mar-b-40 col-md-6">
                      <TextField
                        required
                        placeholder="First Name *"
                        variant="outlined"
                        className="w-100"
                        name="first_name"
                        value={this.state.first_name}
                        onChange={this.handleModalChange}
                        error={Boolean(this.state.error.first_name)}
                        helperText={this.state.error.first_name}
                      />
                    </div>
                    <div className="mar-b-40 col-md-6">
                      <TextField
                        placeholder="Last Name *"
                        variant="outlined"
                        className="w-100"
                        name="last_name"
                        value={this.state.last_name}
                        onChange={this.handleModalChange}
                        error={Boolean(this.state.error.last_name)}
                        helperText={this.state.error.last_name}
                      />
                    </div>
                  </div>

                  <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                    2. Add an email address.
                  </h3>
                  <div className="row">
                    <div className="mar-b-40 col-md-12">
                      <TextField
                        placeholder="Email Address *"
                        //type="email"
                        required={true}
                        name="email"
                        onChange={this.handleModalChange}
                        variant="outlined"
                        className="w-100"
                        value={this.state.email}
                        error={Boolean(this.state.error.email)}
                        helperText={this.state.error.email}
                        //onFocusCapture={()=>{alert('adad')}}
                      />
                    </div>
                  </div>
                  <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                    3. Add a Phone Number.
                  </h3>
                  <div className="row">
                    <div className="mar-b-40 col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <FormControl variant="outlined" className={"w-100"}>
                            {/* <InputLabel id="demo-simple-select-label">
                                                            Country Code
                                                        </InputLabel> */}
                            <Select
                              labelId="demo-simple-select-label"
                              name="country_code"
                              placeholder="Select your limit"
                              onChange={this.handleModalChange}
                              value={this.state.country_code}
                              error={Boolean(this.state.error.country_code)}
                            >
                              {countryCode.map(
                                (singleCode, singleCodeIndex) => (
                                  <MenuItem
                                    value={singleCode.code}
                                    key={singleCodeIndex}
                                  >
                                    {singleCode.code} - {singleCode.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText className="text-danger">
                              {this.state.error.country_code}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <div className="col-md-8 pt-4 pt-md-0">
                          <TextField
                            placeholder="Phone Number *"
                            name="phone"
                            onChange={this.handleModalChange}
                            value={this.state.phone}
                            variant="outlined"
                            className="w-100"
                            error={Boolean(this.state.error.phone)}
                            helperText={this.state.error.phone}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                    4. Please enter your message
                  </h3>
                  <div className="row">
                    <div className="mar-b-40 col-md-12">
                      <TextField
                        multiline
                        rows={4}
                        name="message"
                        onChange={this.handleModalChange}
                        value={this.state.message}
                        placeholder="Write a message *"
                        variant="outlined"
                        className="w-100"
                        inputProps={{
                          maxlength: 250,
                        }}
                        error={Boolean(this.state.error.message)}
                        helperText={this.state.error.message}
                      />
                      <p className="fs-12  text-black opa-60 text-right">
                        {this.state.message.length}/250
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-footer bg-white text-right pad-x-40 ">
                  {this.props.miscReducerData?.pending && <CircularProgress />}

                  {!this.props.miscReducerData?.pending && (
                    <>
                      <Button
                        className="text-color1 fs-19 font-weight-Medium text-capitalize"
                        onClick={this.handleSupClose.bind(this)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={
                          "ml-3 text-color1 fs-19 font-weight-Bold text-capitalize"
                        }
                        onClick={this.handleSupSubmit}
                      >
                        Send
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal>

          <div className="d-flex flex-column helpCenterWrap">
            <div className="w-100">
              <PrimaryHeader position={"innerHeader"} />
            </div>
            <div
              className="h-100 pt-5 overflow-auto"
              style={{
                background: `url(${bg}) no-repeat fixed`,
                backgroundPosition: "center center",
                backgroundSize: "190% 190%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className=" col-11 col-lg-8 col-xxl-6 mx-auto pt-5 mb-5">
                <div className="card border-radius-15">
                  <div className="text-center py-4 my-3">
                    <img src={icon24} className="w-40px w-md-60px" alt="" />
                    <h3 className="fs-24 fs-md-36 text-black m-0 py-3">
                      {cmsInfo.title !== undefined &&
                      cmsInfo.title !== null &&
                      cmsInfo.title !== ""
                        ? cmsInfo.title
                        : "Help Center"}
                    </h3>
                    <Button
                      onClick={(e) => this.setState({ supModal: true })}
                      className="text-color1 font-weight-Medium border px-4 w-md-323px fs-16 fs-md-19 "
                    >
                      Contact Support
                    </Button>
                  </div>

                  <div
                    className="p-3  py-xxxl-4  text-center  "
                    style={{ background: "#F6F6F6" }}
                  >
                    <div
                      className="fs-18 fs-md-20  fs-xxxl-24 opa-60 text-black px-3 px-md-4"
                      dangerouslySetInnerHTML={{
                        __html:
                          cmsInfo.content !== undefined &&
                          cmsInfo.content !== null &&
                          cmsInfo.content !== ""
                            ? cmsInfo.content
                            : "Learn to use the platform easily",
                      }}
                    ></div>
                  </div>

                  <div className="py-4 py-xxxl-5 px-4 px-xxxl-5">
                    <figure className="d-block mb-0 position-relative">
                      <img src={Image} alt="" className="w-100" />
                      <div className="position-absolute play">
                        <a
                          data-fancybox="gallery"
                          href={
                            cmsInfo.video !== undefined &&
                            cmsInfo.video !== "" &&
                            cmsInfo.video !== null
                              ? UploadURLS.help_video_path + cmsInfo.video
                              : "https://www.youtube.com/watch?v=aqz-KE-bpKQ"
                          }
                        >
                          <img src={PlayBtn} alt="" />
                        </a>
                      </div>
                    </figure>
                  </div>

                  <div
                    className="p-3 py-xxxl-4 text-center mt-5 "
                    style={{ background: "#F6F6F6" }}
                  >
                    <span className="fs-20 fs-xxxl-24 opa-60 text-black">
                      Frequently Asked Questions
                    </span>
                  </div>

                  {faqList.length === 0 && (
                    <div className="alert alert-primary" role="alert">
                      Sorry, no data found
                    </div>
                  )}

                  {faqList.length > 0 && (
                    <div className="py-2">
                      {faqList.map((singleList, singleIndex) => (
                        <Accordion
                          square
                          expanded={
                            this.state.expanded === "panel" + singleIndex
                          }
                          onChange={this.handleChange("panel" + singleIndex)}
                          key={singleIndex}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon className="text-color1" />
                            }
                            aria-controls={"panel" + singleIndex + "a-content"}
                            id={"panel" + singleIndex + "a-header"}
                          >
                            <Typography className="fs-16 fs-md-18 text-black font-weight-Medium px-3 px-xxxl-5">
                              {singleList.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="px-3 px-xxxl-5 fs-13 fs-md-18 lh-md-26 opa-60 text-black">
                              {singleList.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpCenterPage);
