import React, { Component } from 'react'
import { NavLink, Link, withRouter } from "react-router-dom";

export default class PrimaryFooter extends Component {
  
    render() {
        return (
            <footer className="border p-3">
                <div className="container text-center">
                    <p className="mb-0">Copyright &copy; 2021  <span className="primary-color">Forget Me Not</span>. All Rights Reserved.</p>
                </div>
            </footer>
        )
    }
}
