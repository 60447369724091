/* Library */
import axiosInstance from "../../api";

/* Actions Types */

/* Fetch relationship list */
export const FETCH_ALL_RELATIONSHIP_TYPE_SUCCESS =
  "FETCH_ALL_RELATIONSHIP_TYPE_SUCCESS";
export const FETCH_ALL_RELATIONSHIP_TYPE_FAILED =
  "FETCH_ALL_RELATIONSHIP_TYPE_FAILED";

/* save contact info */
export const SAVE_CONTACT_INFO_SUCCESS = "SAVE_CONTACT_INFO_SUCCESS";
export const SAVE_CONTACT_INFO_FAILED = "SAVE_CONTACT_INFO_FAILED";

/* Get user contact list */
export const GET_USER_CONTACT_LIST_SUCCESS = "GET_USER_CONTACT_LIST_SUCCESS";
export const GET_USER_CONTACT_LIST_FAILED = "GET_USER_CONTACT_LIST_FAILED";

/* Fetch event list by contact Id */
export const FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS =
  "FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS";
export const FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED =
  "FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED";

/* Create new event */
export const CREATE_NEW_EVENT_SUCCESS = "CREATE_NEW_EVENT_SUCCESS";
export const CREATE_NEW_EVENT_FAILED = "CREATE_NEW_EVENT_FAILED";

/* Update event */
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILED = "UPDATE_EVENT_FAILED";

/* Delete Event */
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";

/* Fetch Single event details */
export const FETCH_SINGLE_EVENT_DETAILS_SUCCESS =
  "FETCH_SINGLE_EVENT_DETAILS_SUCCESS";
export const FETCH_SINGLE_EVENT_DETAILS_FAILED =
  "FETCH_SINGLE_EVENT_DETAILS_FAILED";

/* FETCH MEMORIES BY EVENT*/
export const FETCH_MEMORIES_BY_EVENT_SUCCESS =
  "FETCH_MEMORIES_BY_EVENT_SUCCESS";
export const FETCH_MEMORIES_BY_EVENT_FAILED = "FETCH_MEMORIES_BY_EVENT_FAILED";

/* CREATE USER MEMORIES */

export const CREATE_USER_MEMORY_SUCCESS = "CREATE_USER_MEMORY_SUCCESS";
export const CREATE_USER_MEMORY_FAILED = "CREATE_USER_MEMORY_FAILED";

/* UPDATE USER MEMORIES BY EVENT */

export const UPDATE_USER_MEMORY_SUCCESS = "UPDATE_USER_MEMORY_SUCCESS";
export const UPDATE_USER_MEMORY_FAILED = "UPDATE_USER_MEMORY_FAILED";

export const FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS";

/* REMOVE FILE */
export const REMOVE_FILE_SUCCESS = "REMOVE_FILE_SUCCESS";
export const REMOVE_FILE_FAILED = "REMOVE_FILE_FAILED";

/* DELETE USER MEMORIES */

export const DELETE_USER_MEMORIES_SUCCESS = "DELETE_USER_MEMORIES_SUCCESS";
export const DELETE_USER_MEMORIES_FAILED = "DELETE_USER_MEMORIES_FAILED";

/* CANCEL SCHEDULED */
export const CANCEL_SCHEDULE_FAILED = "CANCEL_SCHEDULE_FAILED";
export const CANCEL_SCHEDULE_SUCCESS = "CANCEL_SCHEDULE_SUCCESS";

/* Event Dates List (Secondary User)*/
export const FETCH_EVENT_DATES_SUCCESS = "FETCH_EVENT_DATES_SUCCESS";
export const FETCH_EVENT_DATES_FAILED = "FETCH_EVENT_DATES_FAILED";

/* DASHBOARD PRO TIPS */
export const NOTIFICATION_PRO_TIPS_LIST_SUCCESS =
  "NOTIFICATION_PRO_TIPS_LIST_SUCCESS";
export const NOTIFICATION_PRO_TIPS_LIST_FAILED =
  "NOTIFICATION_PRO_TIPS_LIST_FAILED";

/* SECONDARY USER EVENT SELECTION  */
export const SECONDARY_USER_EVENT_SELECTION_SUCCESS =
  "SECONDARY_USER_EVENT_SELECTION_SUCCESS";
export const SECONDARY_USER_EVENT_SELECTION_FAILED =
  "SECONDARY_USER_EVENT_SELECTION_FAILED";

/* SECONDARY USER SELECTION LIST */
export const SECONDARY_USER_SELECTION_LIST_SUCCESS =
  "SECONDARY_USER_SELECTION_LIST_SUCCESS";
export const SECONDARY_USER_SELECTION_LIST_FAILED =
  "SECONDARY_USER_SELECTION_LIST_FAILED";

/* Fetch secondary user events */
export const FETCH_SECONDARY_USER_EVENT_SUCCESS =
  "FETCH_SECONDARY_USER_EVENT_SUCCESS";
export const FETCH_SECONDARY_USER_EVENT_FAILED =
  "FETCH_SECONDARY_USER_EVENT_FAILED";

/* Primary User Events List With Memories */
export const FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_SUCCESS =
  "FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_SUCCESS";
export const FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_FAILED =
  "FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_FAILED";

/* Fetch Primary user's user list using event id */
export const FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_SUCCESS =
  "FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_SUCCESS";
export const FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_FAILED =
  "FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_FAILED";

/* Primary User Memory Shared List */
export const PRIMARY_USER_MEMORY_SHARED_LIST_SUCCESS =
  "PRIMARY_USER_MEMORY_SHARED_LIST_SUCCESS";
export const PRIMARY_USER_MEMORY_SHARED_LIST_FAILED =
  "PRIMARY_USER_MEMORY_SHARED_LIST_FAILED";

/* Update event type dates */
export const UPDATE_EVENT_TYPES_DATE_SUCCESS =
  "UPDATE_EVENT_TYPES_DATE_SUCCESS";
export const UPDATE_EVENT_TYPES_DATE_FAILED = "UPDATE_EVENT_TYPES_DATE_FAILED";

/* Fetch Parent User List */
export const FETCH_PARENT_USER_LIST_SUCCESS = "FETCH_PARENT_USER_LIST_SUCCESS";
export const FETCH_PARENT_USER_LIST_FAILED = "FETCH_PARENT_USER_LIST_FAILED";

/* Fetch Parent User List */
export const FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_SUCCESS =
  "FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_SUCCESS";
export const FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_FAILED =
  "FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_FAILED";

/* Fetch User Details */
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILED = "FETCH_USER_DETAILS_FAILED";

/* Force login */
export const FORCE_USER_LOGIN_SUCCESS = "FORCE_USER_LOGIN_SUCCESS";
export const FORCE_USER_LOGIN_FAILED = "FORCE_USER_LOGIN_FAILED";

//Newsletter subscription
export const NEWSLETTER_SUBSCRIPTION_SUCCESS = "NEWSLETTER_SUBSCRIPTION_SUCCESS";
export const NEWSLETTER_SUBSCRIPTION_FAILED = "NEWSLETTER_SUBSCRIPTION_FAILED";


/* CLEAR STATE */
export const CLEAR_STATE = "CLEAR_STATE";

/* Fetch User Details */
export function fetchUserdetails(userid) {
  var formData = new FormData();
  formData.append("user_id", userid);
  return function (dispatch) {
    return axiosInstance
      .post("api/user/details-by-id", formData)
      .then((res) => {
        if (res.data) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchUserdetailsSuccess(res));
        } else {
          dispatch(fetchUserdetailsFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchUserdetailsFailed(data));
      });
  };
}

function fetchUserdetailsSuccess(data) {
  return { type: FETCH_USER_DETAILS_SUCCESS, data: data };
}

function fetchUserdetailsFailed(data) {
  return { type: FETCH_USER_DETAILS_FAILED, data: data };
}

/* Fetch Parent User List  */
export function fetchParentuserlist() {
  return function (dispatch) {
    return axiosInstance
      .get("api/events/user/parent-users-list")
      .then((res) => {
        if (res.data) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchParentuserlistSuccess(res));
        } else {
          dispatch(fetchParentuserlistFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchParentuserlistFailed(data));
      });
  };
}

function fetchParentuserlistSuccess(data) {
  return { type: FETCH_PARENT_USER_LIST_SUCCESS, data: data };
}

function fetchParentuserlistFailed(data) {
  return { type: FETCH_PARENT_USER_LIST_FAILED, data: data };
}

/* Fetch Secondary Dashboard data */
/* FETCH EVENT LIST BY CONTACT ID */
export function fetchSecondaryuserdashboarddataByparentID(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/events/user/list-by-parent", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchSecondaryuserdashboarddataByparentIDSuccess(res));
        } else {
          dispatch(fetchSecondaryuserdashboarddataByparentIDFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(fetchSecondaryuserdashboarddataByparentIDFailed(data));
      });
  };
}

function fetchSecondaryuserdashboarddataByparentIDSuccess(data) {
  return {
    type: FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_SUCCESS,
    data: data,
  };
}

function fetchSecondaryuserdashboarddataByparentIDFailed(data) {
  return {
    type: FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_FAILED,
    data: data,
  };
}

/* Fetch ALL RELATIONSHIP TYPES  */
export function fetchAllRelationshipTypes() {
  return function (dispatch) {
    return axiosInstance
      .get("api/relationship/list")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchAllRelationshipTypesSuccess(res));
        } else {
          dispatch(fetchAllRelationshipTypesFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchAllRelationshipTypesFailed(data));
      });
  };
}

function fetchAllRelationshipTypesSuccess(data) {
  return { type: FETCH_ALL_RELATIONSHIP_TYPE_SUCCESS, data: data };
}

function fetchAllRelationshipTypesFailed(data) {
  return { type: FETCH_ALL_RELATIONSHIP_TYPE_FAILED, data: data };
}

/* Save Contact Info */
export function saveContactInfo(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/savecontact", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(saveContactInfoSuccess(res));
        } else {
          dispatch(saveContactInfoFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(saveContactInfoFailed(data));
      });
  };
}

function saveContactInfoSuccess(data) {
  return { type: SAVE_CONTACT_INFO_SUCCESS, data: data };
}

function saveContactInfoFailed(data) {
  return { type: SAVE_CONTACT_INFO_FAILED, data: data };
}

/* User Fetch contact list */
export function getUserContactList() {
  return function (dispatch) {
    return axiosInstance
      .get("api/user/getcontacts")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(getUserContactListSuccess(res));
        } else {
          dispatch(getUserContactListFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(getUserContactListFailed(data));
      });
  };
}

function getUserContactListSuccess(data) {
  return { type: GET_USER_CONTACT_LIST_SUCCESS, data: data };
}

function getUserContactListFailed(data) {
  return { type: GET_USER_CONTACT_LIST_FAILED, data: data };
}

/* FETCH EVENT LIST BY CONTACT ID */
export function fetchEventListByContactID(contactid) {
  return function (dispatch) {
    return axiosInstance
      .get("api/events/user/list/" + contactid)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchEventListByContactIDSuccess(res));
        } else {
          dispatch(fetchEventListByContactIDFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(fetchEventListByContactIDFailed(data));
      });
  };
}

function fetchEventListByContactIDSuccess(data) {
  return { type: FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS, data: data };
}

function fetchEventListByContactIDFailed(data) {
  return { type: FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED, data: data };
}

/* Fetch Secondary user events */
export function fetchSecondaryUserEvents() {
  return function (dispatch) {
    return axiosInstance
      .get("api/events/user/list")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchSecondaryUserEventsSuccess(res));
        } else {
          dispatch(fetchSecondaryUserEventsFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(fetchSecondaryUserEventsFailed(data));
      });
  };
}

function fetchSecondaryUserEventsSuccess(data) {
  return { type: FETCH_SECONDARY_USER_EVENT_SUCCESS, data: data };
}

function fetchSecondaryUserEventsFailed(data) {
  return { type: FETCH_SECONDARY_USER_EVENT_FAILED, data: data };
}

/* CREATE NEW EVENT */
export function createNewEvent(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/events/create", formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(createNewEventSuccess(res));
        } else {
          dispatch(createNewEventFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(createNewEventFailed(data));
      });
  };
}

function createNewEventSuccess(data) {
  return { type: CREATE_NEW_EVENT_SUCCESS, data: data };
}

function createNewEventFailed(data) {
  return { type: CREATE_NEW_EVENT_FAILED, data: data };
}



/* Primary user request a memory */
export function createPrimaryNewEvent(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/events/request-mem-primary", formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(createPrimaryNewEventSuccess(res));
        } else {
          dispatch(createPrimaryNewEventFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(createPrimaryNewEventFailed(data));
      });
  };
}

function createPrimaryNewEventSuccess(data) {
  return { type: CREATE_NEW_EVENT_SUCCESS, data: data };
}

function createPrimaryNewEventFailed(data) {
  return { type: CREATE_NEW_EVENT_FAILED, data: data };
}

/* UPDATE EVENTS */
export function updateEvent(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/events/update", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(updateEventSuccess(res));
        } else {
          dispatch(updateEventFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(updateEventFailed(data));
      });
  };
}

function updateEventSuccess(data) {
  return { type: UPDATE_EVENT_SUCCESS, data: data };
}

function updateEventFailed(data) {
  return { type: UPDATE_EVENT_FAILED, data: data };
}

/* Delete event */
export function deleteEvent(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/events/delete", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(deleteEventSuccess(res));
        } else {
          dispatch(deleteEventFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(deleteEventFailed(data));
      });
  };
}

function deleteEventSuccess(data) {
  return { type: DELETE_EVENT_SUCCESS, data: data };
}

function deleteEventFailed(data) {
  return { type: DELETE_EVENT_FAILED, data: data };
}

/* Fetch Single Event Details */
export function fetchSingleEventDetails(event_id) {
  return function (dispatch) {
    return axiosInstance
      .get("api/events/detail/" + event_id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchSingleEventDetailsSuccess(res));
        } else {
          dispatch(fetchSingleEventDetailsFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(fetchSingleEventDetailsFailed(data));
      });
  };
}

function fetchSingleEventDetailsSuccess(data) {
  return { type: FETCH_SINGLE_EVENT_DETAILS_SUCCESS, data: data };
}

function fetchSingleEventDetailsFailed(data) {
  return { type: FETCH_SINGLE_EVENT_DETAILS_FAILED, data: data };
}

/* FETCH USER MEMORIES BY EVENT*/
export function fetchUserMemoriesByEvent(eventId, userContactId) {
  return function (dispatch) {
    return axiosInstance
      .get(`api/memories/list/${eventId}/${userContactId}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchUserMemoriesByEventSuccess(res.data));
        } else {
          dispatch(fetchUserMemoriesByEventFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchUserMemoriesByEventFailure(data));
      });
  };
}

function fetchUserMemoriesByEventSuccess(data) {
  return { type: FETCH_MEMORIES_BY_EVENT_SUCCESS, data: data };
}

function fetchUserMemoriesByEventFailure(data) {
  return { type: FETCH_MEMORIES_BY_EVENT_FAILED, data: data };
}
/*CREATE USER MEMORY BY EVENT*/
export function createUserMemory(data) {
  return function (dispatch) {
    return axiosInstance
      .post("api/memories/create", data, {
        onUploadProgress: (progress) => {
          const { loaded, total } = progress;
          // console.log("sam: ", progress);
          const percentageProgress = Math.floor((loaded / total) * 100);
          // console.log(percentageProgress);
          dispatch(setUploadProgress(percentageProgress));
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(createUserMemorySuccess(res));
        } else {
          dispatch(createUserMemoryFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(createUserMemoryFailure(data));
      });
  };
}

function createUserMemorySuccess(data) {
  return { type: CREATE_USER_MEMORY_SUCCESS, data: data };
}

function createUserMemoryFailure(data) {
  return { type: CREATE_USER_MEMORY_FAILED, data: data };
}

/* UPDATE USER MEMORIES BY EVENT */

// export function updateUserMemory(data) {
//   return function (dispatch) {
//     return axiosInstance
//       .post("api/memories/update", data)
//       .then((res) => {
//         if (res.status === 200) {
//           dispatch(updateUserMemorySuccess(res));
//         } else {
//           dispatch(updateUserMemoryFailure(res));
//         }
//       })
//       .catch((error) => {
//         const data = {
//           data: {},
//           status: 503,
//           message: "Something Went Wrong! please try again",
//         };
//         dispatch(updateUserMemoryFailure(data));
//       });
//   };
// }

export function updateUserMemory(data) {
  return function (dispatch) {
    return axiosInstance
      .post("api/memories/update", data, {
        onUploadProgress: (progress) => {
          const { loaded, total } = progress;
          // console.log("sam: ", progress);
          const percentageProgress = Math.floor((loaded / total) * 100);
          // console.log(percentageProgress);
          dispatch(setUploadProgress(percentageProgress));
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(updateUserMemorySuccess(res));
        } else {
          dispatch(updateUserMemoryFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(updateUserMemoryFailure(data));
      });
  };
}

function updateUserMemorySuccess(data) {
  return { type: UPDATE_USER_MEMORY_SUCCESS, data: data };
}

function updateUserMemoryFailure(data) {
  return { type: UPDATE_USER_MEMORY_FAILED, data: data };
}
function setUploadProgress(data) {
  return { type: FILE_UPLOAD_PROGRESS, data: data };
}

/* REMOVE FILE FROM DATABASE */

export function removeFile(data) {
  return function (dispatch) {
    return axiosInstance
      .post("api/memories/remove-file", data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(removeFileSuccess(res));
        } else {
          dispatch(removeFileFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(removeFileFailure(data));
      });
  };
}

function removeFileSuccess(data) {
  return { type: REMOVE_FILE_SUCCESS, data: data };
}

function removeFileFailure(data) {
  return { type: REMOVE_FILE_FAILED, data: data };
}

/* DELETE MEMORIES FROM EVENT */

export function deleteMemories(memoryId) {
  return function (dispatch) {
    return axiosInstance
      .get(`api/memories/delete/${memoryId}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(deleteMemoriesSuccess(res));
        } else {
          dispatch(deleteMemoriesFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(deleteMemoriesFailed(data));
      });
  };
}

function deleteMemoriesSuccess(data) {
  return { type: DELETE_USER_MEMORIES_SUCCESS, data: data };
}

function deleteMemoriesFailed(data) {
  return { type: DELETE_USER_MEMORIES_FAILED, data: data };
}

/* CANCEL SCHEDULED MEMORIES */

export function cancelSchedule(data) {
  return function (dispatch) {
    return axiosInstance
      .get(`api/memories/cancel-schedule/${data}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(cancelScheduleSuccess(res));
        } else {
          dispatch(cancelScheduleFalied(res));
        }
      })
      .catch((error) => {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(cancelScheduleFalied(data));
      });
  };
}

function cancelScheduleSuccess(data) {
  return { type: CANCEL_SCHEDULE_SUCCESS, data: data };
}

function cancelScheduleFalied(data) {
  return { type: CANCEL_SCHEDULE_FAILED, data: data };
}

/* Event Dates List (Secondary User)*/

export function fetchEventDates() {
  return function (dispatch) {
    return axiosInstance
      .get(`api/user/event-dates`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchEventDatesSuccess(res));
        } else {
          dispatch(fetchEventDatesFalied(res));
        }
      })
      .catch((error) => {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchEventDatesFalied(data));
      });
  };
}

function fetchEventDatesSuccess(data) {
  return { type: FETCH_EVENT_DATES_SUCCESS, data: data };
}

function fetchEventDatesFalied(data) {
  return { type: FETCH_EVENT_DATES_FAILED, data: data };
}

/* FETCH DASHBOARD PRO TIPS */

export function fetchDashboardProTips() {
  return function (dispatch) {
    return axiosInstance
      .get("api/notifications/pro-list")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchNotificationProTipsSuccess(res));
        } else {
          dispatch(fetchNotificationProTipsFailed(res));
        }
      })
      .catch((error) => {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchNotificationProTipsFailed(data));
      });
  };
}

function fetchNotificationProTipsSuccess(data) {
  return { type: NOTIFICATION_PRO_TIPS_LIST_SUCCESS, data: data };
}

function fetchNotificationProTipsFailed(data) {
  return { type: NOTIFICATION_PRO_TIPS_LIST_FAILED, data: data };
}

/* SECONDARY USER SELECT EVENTS WHEN REGISTER */

export function selectSecondayUserEvents(data) {
  return function (dispatch) {
    return axiosInstance
      .post("api/events/secondary-selection", data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(selectSecondayUserEventsSuccess(res));
        } else {
          dispatch(selectSecondayUserEventsFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(selectSecondayUserEventsFailure(data));
      });
  };
}

function selectSecondayUserEventsSuccess(data) {
  return { type: SECONDARY_USER_EVENT_SELECTION_SUCCESS, data: data };
}

function selectSecondayUserEventsFailure(data) {
  return { type: SECONDARY_USER_EVENT_SELECTION_FAILED, data: data };
}

/* Secondary user selection list */
export function fetchSecondaryUserSelectionList() {
  return function (dispatch) {
    return axiosInstance
      .get("api/events/secondary-selection-list")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(secondaryUserSelectionListSuccess(res));
        } else {
          dispatch(secondaryUserSelectionListFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(secondaryUserSelectionListFailure(data));
      });
  };
}

function secondaryUserSelectionListSuccess(data) {
  return { type: SECONDARY_USER_SELECTION_LIST_SUCCESS, data: data };
}

function secondaryUserSelectionListFailure(data) {
  return { type: SECONDARY_USER_SELECTION_LIST_FAILED, data: data };
}

/* Fetch Primary User Events List With Memories */
export function fetchPrimaryUserEventsListWithMemories() {
  return function (dispatch) {
    return axiosInstance
      .get("api/events/primary-user/events-list")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchPrimaryUserEventsListWithMemoriesSuccess(res));
        } else {
          dispatch(fetchPrimaryUserEventsListWithMemoriesFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchPrimaryUserEventsListWithMemoriesFailure(data));
      });
  };
}

function fetchPrimaryUserEventsListWithMemoriesSuccess(data) {
  return {
    type: FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_SUCCESS,
    data: data,
  };
}

function fetchPrimaryUserEventsListWithMemoriesFailure(data) {
  return { type: FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_FAILED, data: data };
}

/* Primary User List With Memories from Event ID */
export function fetchPrimaryUserListWithMemoryFromEventId(event_id) {
  return function (dispatch) {
    return axiosInstance
      .get("api/events/primary-user/users-list/" + event_id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(fetchPrimaryUserListWithMemoryFromEventIdSuccess(res));
        } else {
          dispatch(fetchPrimaryUserListWithMemoryFromEventIdFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchPrimaryUserListWithMemoryFromEventIdFailure(data));
      });
  };
}

function fetchPrimaryUserListWithMemoryFromEventIdSuccess(data) {
  return {
    type: FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_SUCCESS,
    data: data,
  };
}

function fetchPrimaryUserListWithMemoryFromEventIdFailure(data) {
  return {
    type: FETCH_PRIMARY_USER_LIST_WITH_MEMORY_FROM_EVENT_ID_FAILED,
    data: data,
  };
}

/* User Memories - Primary User Memory Shared List */
export function primaryUserMemorySharedList(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/memories/primary-user/shared-memories", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(primaryUserMemorySharedListSuccess(res));
        } else {
          dispatch(primaryUserMemorySharedListFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(primaryUserMemorySharedListFailure(data));
      });
  };
}

function primaryUserMemorySharedListSuccess(data) {
  return { type: PRIMARY_USER_MEMORY_SHARED_LIST_SUCCESS, data: data };
}

function primaryUserMemorySharedListFailure(data) {
  return { type: PRIMARY_USER_MEMORY_SHARED_LIST_FAILED, data: data };
}

/* Updates event types dates */
export function updatesEventTypeDates(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/events/update-event-wise-dates", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {
              window.location.href = "/"
            }, 1000)

          }
          dispatch(updatesEventTypeDatesSuccess(res));
        } else {
          dispatch(updatesEventTypeDatesFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(updatesEventTypeDatesFailure(data));
      });
  };
}

function updatesEventTypeDatesSuccess(data) {
  return { type: UPDATE_EVENT_TYPES_DATE_SUCCESS, data: data };
}

function updatesEventTypeDatesFailure(data) {
  return { type: UPDATE_EVENT_TYPES_DATE_FAILED, data: data };
}


// Forcefully login..
/* User Login */
export function forceSignin(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/force-signin-user", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(forceSigninSuccess(res));
        } else {
          dispatch(forceSigninFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(forceSigninFailed(data));
      });
  };
}

function forceSigninSuccess(data) {
  return { type: FORCE_USER_LOGIN_SUCCESS, data: data };
}

function forceSigninFailed(data) {
  return { type: FORCE_USER_LOGIN_FAILED, data: data };
}


//Newsletter subscription..
export function newsletterSubscription(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/newsletter-subscription", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(newsletterSubscriptionSuccess(res));
        } else {
          dispatch(newsletterSubscriptionFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(newsletterSubscriptionFailed(data));
      });
  };
}

function newsletterSubscriptionSuccess(data) {
  return { type: NEWSLETTER_SUBSCRIPTION_SUCCESS, data: data };
}

function newsletterSubscriptionFailed(data) {
  return { type: NEWSLETTER_SUBSCRIPTION_FAILED, data: data };
}


export function clearState() {
  return function (dispatch) {
    dispatch({ type: CLEAR_STATE });
  };
}
