/* Library */
import axiosInstance from "../../api";
/* Actions Types */
/* Fetch relationship list */
export const FETCH_VIEWMEMORY_REQUEST = "FETCH_VIEWMEMORY_REQUEST";
export const FETCH_VIEWMEMORY_SUCCESS = "FETCH_VIEWMEMORY_SUCCESS";
export const FETCH_VIEWMEMORY_FAILED = "FETCH_VIEWMEMORY_FAILED";

/* CLEAR STATE */
export const CLEAR_STATE = "CLEAR_STATE";

/* Fetch User Details */
export function fetchMemorydetails(memoryid) {
    console.log('memoryid', memoryid)
    return function (dispatch) {
        dispatch({ type: FETCH_VIEWMEMORY_REQUEST });
        return axiosInstance
            .get(`api/memories/detail/${memoryid}`)
            .then((res) => {
                if (res.data) {
                    if (res.data.status === 500) {
                        localStorage.clear()
                        setTimeout(() => {
                            window.location.href = "/"
                        }, 1000)

                    }
                    dispatch(fetchMemorydetailsSuccess(res));
                } else {
                    dispatch(fetchMemorydetailsFailed(res));
                }
            })
            .catch(function (error) {
                const data = {
                    status: 503,
                    data: {},
                    message: "Something Went Wrong! please try again",
                };
                dispatch(fetchMemorydetailsFailed(data));
            });
    };
}

function fetchMemorydetailsSuccess(data) {
    console.log(data)
    return { type: FETCH_VIEWMEMORY_SUCCESS, data: data };
}

function fetchMemorydetailsFailed(data) {
    return { type: FETCH_VIEWMEMORY_FAILED, data: data };
}

export function clearState() {
    return function (dispatch) {
        dispatch({ type: CLEAR_STATE });
    };
}
