import { Button, FormHelperText, Typography } from "@material-ui/core";
import React, { Component } from "react";

import Icon from "../assets/images/icon8.svg";
import PrimaryHeader from "../Widgets/PrimaryHeader";

import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";
import moment from "moment";

class SecondaryUserRegStepTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateEventData: [],
      allEventTypes: [],
      formData: {
        eventDay: "",
        eventMonth: "",
        eventYear: "",
        notSure: false,
      },

      isSubmitForm: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      isRedirect: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");
    if (auth_token === "" || auth_token === undefined || auth_token === null) {
      this.props.history.push("/");
    } else {
      this.props.contactActions.fetchEventDates();
    }
  }

  componentDidUpdate() {
    var currentUserRole = "";
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";

      this.props.contactActions.fetchEventDates();
    }

    if (this.state.isRedirect === true) {
      if (currentUserRole === "sub-user") {
        // this.props.history.push("/secondary-user-step-three");
        this.props.history.push("/secondary-user-dashboard");
      } else {
        this.props.history.push("/dashboard");
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        /* Fetch all events */
        case contactActions.FETCH_EVENT_DATES_SUCCESS:
          let events =
            contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];
          console.log("events", events);
          let filterEvent = [];
          let allEventId = [];

          if (events.length > 0) {
            events.forEach(function (singleEventData, singleIndx) {
              var eventData = singleEventData.eventData;
              var eventCreatedUser = singleEventData.requestedBy;

              /* Store user id to event details and remove duplicate events */
              if (
                eventData !== undefined &&
                eventData !== null &&
                eventData !== "" &&
                eventCreatedUser !== undefined &&
                eventCreatedUser !== null &&
                eventCreatedUser !== "" &&
                eventCreatedUser._id !== undefined
              ) {
                eventData["created_by_id"] = eventCreatedUser._id;
                eventData["created_by_first_name"] =
                  eventCreatedUser.first_name;
                eventData["created_by_last_name"] = eventCreatedUser.last_name;
                eventData["created_by_email"] = eventCreatedUser.email;

                /* Show only 1st item initial time */
                if (singleIndx === 0) {
                  eventData["isShow"] = true;
                } else {
                  eventData["isShow"] = false;
                }

                if (allEventId.indexOf(eventData._id) !== -1) {
                  //alert("Value exists!")
                } else {
                  allEventId.push(eventData._id);
                  filterEvent.push(eventData);
                }
              }
            });
          }
          props.contactActions.clearState();

          var allEventTypes = localStorage.getItem(
            "secondary_user_event_types"
          );
          //if(allEventTypes === null || allEventTypes === undefined || allEventTypes === ""){
          localStorage.setItem(
            "secondary_user_event_types",
            JSON.stringify(filterEvent)
          );
          //}

          return {
            allEventTypes: filterEvent,
          };
          break;
        case contactActions.FETCH_EVENT_DATES_FAILED:
          props.miscActions.clearState();
          return {
            allEventTypes: [],
            isRedirect: true,
          };
          break;

        /* UPDATE EVENT */
        case contactActions.UPDATE_EVENT_SUCCESS:
          var message =
            contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.message !== undefined &&
              contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";

          props.contactActions.clearState();

          var formData = {
            eventDay: "",
            eventMonth: "",
            eventYear: "",
          };

          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            isSubmitForm: false,
            formData: formData,
          };
          break;
        case contactActions.UPDATE_EVENT_FAILED:
          var message =
            contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.message !== undefined &&
              contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          props.contactActions.clearState();
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmitForm: false,
          };
          break;

        case contactActions.UPDATE_EVENT_TYPES_DATE_SUCCESS:
          var message =
            contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.message !== undefined &&
              contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";

          props.contactActions.clearState();
          //props.history.push("/secondary-user-step-three");
          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            isRedirect: true,
          };
          break;

        case contactActions.UPDATE_EVENT_TYPES_DATE_FAILED:
          var message =
            contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.message !== undefined &&
              contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          props.contactActions.clearState();
          props.contactActions.fetchEventDates();

          return {
            apiErrorMessage: "Something went wrong! Please try again",
            apiSuccessMessage: "",
            isRedirect: false,
          };
          break;
        default:
          return {}
      }
    }

    return {};
  }

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    
    if (fieldName === "eventMonth") {
      if (fieldValue === "2") {
        if (formData.eventDay > 29) {
          formData["eventDay"] = 29;
        } else if (formData.eventDay < 1) {
          formData["eventDay"] = 1;
        }
      }
    }

    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
    });
  }

  handleSubmit(event_id, currentIndx, notSure) {
    let isValidate = true;
    let formData = this.state.formData;
    let updateEventData = this.state.updateEventData;
    var THIS = this;
    var eventmonth = formData.eventMonth && formData.eventMonth.toString().length === 1 ? `0${formData.eventMonth}` : formData.eventMonth;

    if (notSure === false) {
      if (event_id === "") {
        toast.error("Something went wrong! Please try again");
        isValidate = false;
      } else if (
        formData.eventDay === "" ||
        formData.eventDay === null ||
        formData.eventDay === undefined
      ) {
        toast.error("Event date can not be blank");
        isValidate = false;
      } else if (
        formData.eventMonth === "" ||
        formData.eventMonth === null ||
        formData.eventMonth === undefined
      ) {
        toast.error("Event month can not be blank");
        isValidate = false;
      } else if (
        formData.eventYear === "" ||
        formData.eventYear === null ||
        formData.eventYear === undefined
      ) {
        toast.error("Event year can not be blank");
        isValidate = false;
      } else if (
        !moment(`${formData.eventYear}-${eventmonth}-${formData.eventDay}`).isValid()
      ) {
        toast.error("Please change event day");
      } else {
        formData["eventId"] = event_id;
        formData["dateUnknown"] = false;
        formData["notSure"] = notSure;

        /* Fetch type from local storage */
        var allEventTypes = localStorage.getItem("secondary_user_event_types");
        if (
          allEventTypes !== "" &&
          allEventTypes !== undefined &&
          allEventTypes !== ""
        ) {
          allEventTypes = JSON.parse(allEventTypes);
        } else {
          allEventTypes = [];
        }

        var temp = {
          eventDay: "",
          eventMonth: "",
          eventYear: "",
        };

        var counter = 0;
        if (allEventTypes.length > 0) {
          allEventTypes.forEach(function (itm, indx) {
            if (itm._id === event_id) {
              itm.isShow = false;
              if (
                allEventTypes[indx + 1] !== undefined &&
                allEventTypes[indx + 1] !== null &&
                allEventTypes[indx + 1] !== "" &&
                allEventTypes[indx + 1].isShow !== undefined &&
                allEventTypes[indx + 1].isShow !== null &&
                allEventTypes[indx + 1].isShow !== ""
              ) {
                allEventTypes[indx + 1].isShow = true;

                var postEventId = allEventTypes[indx + 1]._id;
                var stateEventData = THIS.state.updateEventData;
                var nextEventData =
                  stateEventData[postEventId] !== undefined &&
                    stateEventData[postEventId] !== "" &&
                    stateEventData[postEventId] !== null
                    ? stateEventData[postEventId]
                    : "";

                temp.eventDay =
                  nextEventData.eventDay !== undefined &&
                    nextEventData.eventDay !== null &&
                    nextEventData.eventDay !== ""
                    ? nextEventData.eventDay
                    : "";
                temp.eventMonth =
                  nextEventData.eventMonth !== undefined &&
                    nextEventData.eventMonth !== null &&
                    nextEventData.eventMonth !== ""
                    ? nextEventData.eventMonth
                    : "";
                temp.eventYear =
                  nextEventData.eventYear !== undefined &&
                    nextEventData.eventYear !== null &&
                    nextEventData.eventYear !== ""
                    ? nextEventData.eventYear
                    : "";
              }
            }
          });
          console.log(temp);

          localStorage.setItem(
            "secondary_user_event_types",
            JSON.stringify(allEventTypes)
          );
          updateEventData[event_id] = formData;

          if (allEventTypes.length - 1 === currentIndx) {
            var newData = [];
            if (Object.keys(updateEventData).length > 0) {
              for (var key in updateEventData) {
                if (updateEventData.hasOwnProperty(key)) {
                  newData.push(updateEventData[key]);
                }
              }
            }

            var reqData = {
              event: newData,
            };

            this.setState({
              isSubmitForm: true,
            });

            confirmAlert({
              title: "Sure to continue?",
              message: "You cannot revert back",
              buttons: [
                {
                  label: "Yes",
                  onClick: () =>
                    THIS.props.contactActions.updatesEventTypeDates(reqData),
                },
                {
                  label: "No",
                  onClick: () => window.location.reload(),
                },
              ],
              closeOnClickOutside: false,
            });
          } else {
            this.setState({
              updateEventData: updateEventData,
              formData: temp,
            });
          }
        }

        // this.setState({
        //   isSubmitForm: true,
        // });
        //this.props.contactActions.updateEvent(formData);
      }
    } else {
      if (event_id === "") {
        toast.error("Something went wrong! Please try again");
        isValidate = false;
      } else {
        formData["eventId"] = event_id;
        formData["dateUnknown"] = false;
        formData["notSure"] = notSure;

        /* Fetch type from local storage */
        var allEventTypes = localStorage.getItem("secondary_user_event_types");
        if (
          allEventTypes !== "" &&
          allEventTypes !== undefined &&
          allEventTypes !== ""
        ) {
          allEventTypes = JSON.parse(allEventTypes);
        } else {
          allEventTypes = [];
        }

        var temp = {
          eventDay: "",
          eventMonth: "",
          eventYear: "",
        };

        var counter = 0;
        if (allEventTypes.length > 0) {
          allEventTypes.forEach(function (itm, indx) {
            if (itm._id === event_id) {
              itm.isShow = false;
              if (
                allEventTypes[indx + 1] !== undefined &&
                allEventTypes[indx + 1] !== null &&
                allEventTypes[indx + 1] !== "" &&
                allEventTypes[indx + 1].isShow !== undefined &&
                allEventTypes[indx + 1].isShow !== null &&
                allEventTypes[indx + 1].isShow !== ""
              ) {
                allEventTypes[indx + 1].isShow = true;

                var postEventId = allEventTypes[indx + 1]._id;
                var stateEventData = THIS.state.updateEventData;
                var nextEventData =
                  stateEventData[postEventId] !== undefined &&
                    stateEventData[postEventId] !== "" &&
                    stateEventData[postEventId] !== null
                    ? stateEventData[postEventId]
                    : "";

                temp.eventDay =
                  nextEventData.eventDay !== undefined &&
                    nextEventData.eventDay !== null &&
                    nextEventData.eventDay !== ""
                    ? nextEventData.eventDay
                    : "";
                temp.eventMonth =
                  nextEventData.eventMonth !== undefined &&
                    nextEventData.eventMonth !== null &&
                    nextEventData.eventMonth !== ""
                    ? nextEventData.eventMonth
                    : "";
                temp.eventYear =
                  nextEventData.eventYear !== undefined &&
                    nextEventData.eventYear !== null &&
                    nextEventData.eventYear !== ""
                    ? nextEventData.eventYear
                    : "";
              }
            }
          });
          console.log(temp);

          localStorage.setItem(
            "secondary_user_event_types",
            JSON.stringify(allEventTypes)
          );
          updateEventData[event_id] = formData;

          if (allEventTypes.length - 1 === currentIndx) {
            var newData = [];
            if (Object.keys(updateEventData).length > 0) {
              for (var key in updateEventData) {
                if (updateEventData.hasOwnProperty(key)) {
                  newData.push(updateEventData[key]);
                }
              }
            }

            var reqData = {
              event: newData,
            };

            this.setState({
              isSubmitForm: true,
            });

            confirmAlert({
              title: "Sure to continue?",
              message: "You cannot revert back",
              buttons: [
                {
                  label: "Yes",
                  onClick: () =>
                    THIS.props.contactActions.updatesEventTypeDates(reqData),
                },
                {
                  label: "No",
                  onClick: () => {
                    window.location.reload();
                  },
                },
              ],
              closeOnClickOutside: false,
            });
          } else {
            this.setState({
              updateEventData: updateEventData,
              formData: temp,
            });
          }
        }

        // this.setState({
        //   isSubmitForm: true,
        // });
        //this.props.contactActions.updateEvent(formData);
      }
    }
  }

  backStep(event_id) {
    var THIS = this;
    //var allEventTypes = this.state.allEventTypes;
    var allEventTypes = [];
    var localStorageData = localStorage.getItem("secondary_user_event_types");

    if (
      localStorageData !== "" &&
      localStorageData !== undefined &&
      localStorageData !== null
    ) {
      allEventTypes = JSON.parse(localStorageData);
    }

    var temp = {
      eventDay: "",
      eventMonth: "",
      eventYear: "",
    };

    if (allEventTypes.length > 0) {
      allEventTypes.forEach(function (itm, indx) {
        if (itm._id === event_id) {
          itm.isShow = false;
          if (
            allEventTypes[indx - 1] !== undefined &&
            allEventTypes[indx - 1] !== null &&
            allEventTypes[indx - 1] !== "" &&
            allEventTypes[indx - 1].isShow !== undefined &&
            allEventTypes[indx - 1].isShow !== null &&
            allEventTypes[indx - 1].isShow !== ""
          ) {
            allEventTypes[indx - 1].isShow = true;

            var preEventId = allEventTypes[indx - 1]._id;

            var stateEventData = THIS.state.updateEventData;
            var preEventData =
              stateEventData[preEventId] !== undefined &&
                stateEventData[preEventId] !== "" &&
                stateEventData[preEventId] !== null
                ? stateEventData[preEventId]
                : "";

            temp.eventDay =
              preEventData.eventDay !== undefined &&
                preEventData.eventDay !== null &&
                preEventData.eventDay !== "" &&
                !preEventData.notSure
                ? preEventData.eventDay
                : "";
            temp.eventMonth =
              preEventData.eventMonth !== undefined &&
                preEventData.eventMonth !== null &&
                preEventData.eventMonth !== "" &&
                !preEventData.notSure
                ? preEventData.eventMonth
                : "";
            temp.eventYear =
              preEventData.eventYear !== undefined &&
                preEventData.eventYear !== null &&
                preEventData.eventYear !== "" &&
                !preEventData.notSure
                ? preEventData.eventYear
                : "";
          }
        }
      });
      localStorage.setItem(
        "secondary_user_event_types",
        JSON.stringify(allEventTypes)
      );
      this.setState({
        formData: temp,
      });
    }
  }

  render() {
    let formData = this.state.formData;
    let d = new Date();

    var allEventTypes = [];
    var localStorageData = localStorage.getItem("secondary_user_event_types");

    let rows = [];
    for (let i = 1900; i <= d.getFullYear(); i++) {
      rows.push(i);
    }

    if (
      localStorageData !== "" &&
      localStorageData !== undefined &&
      localStorageData !== null
    ) {
      allEventTypes = JSON.parse(localStorageData);
    }

    return (
      <>
        <div className="card bg-transparent border-0 vh-100 overflow-auto">
          <PrimaryHeader position={"innerHeader"} />
          <section className=" registerStepWrap h-100  overflow-auto bluebgsection">
            <div className="container">
              <div className="row">
                <div className="card border-0  mx-auto">
                  {
                    //this.state.allEventTypes.map(
                    allEventTypes.map((singleEventlist, singleEventIndex) => (
                      <div key={singleEventIndex}>
                        {singleEventlist.isShow === true && (
                          <div className="">
                            <div className="iconBox mar-b-15 mar-b-md-35">
                              <img
                                src={Icon}
                                alt=""
                                className="w-50px w-md-70px"
                              />
                            </div>

                            {singleEventlist.title !== undefined &&
                              singleEventlist.title !== null &&
                              singleEventlist.title !== "" && (
                                <div className="fs-22 fs-md-36 font-weight-Bold  ">

                                  {singleEventlist.title.includes('Birthday') ? `Add your ${singleEventlist.title}` : `Add day of ${singleEventlist.title}`}
                                  {/* Add day of {singleEventlist.title} */}
                                </div>
                              )}

                            {singleEventlist.created_by_first_name !==
                              undefined &&
                              singleEventlist.created_by_first_name !== null &&
                              singleEventlist.created_by_first_name !== "" &&
                              singleEventlist.title !== "" && (
                                <div className="subHead fs-14 fs-md-18 mar-b-30">
                                  {singleEventlist.created_by_first_name}{" "}
                                  {singleEventlist.created_by_last_name} wants
                                  to share memory on {singleEventlist.title.includes('Birthday') ? `your ` : `your day of `}
                                  {singleEventlist.title}
                                </div>
                              )}

                            <div className="row">

                              <div className="mar-b-40 col-md-4 pl-custom-sm-0">
                                <FormControl
                                  variant="outlined"
                                  className="w-100 bg-white"
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Month
                                  </InputLabel>

                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Month"
                                    onChange={this.handleChange}
                                    name="eventMonth"
                                    value={
                                      formData.eventMonth !== undefined
                                        ? formData.eventMonth
                                        : ""
                                    }

                                  >
                                    <MenuItem value="1">January</MenuItem>
                                    <MenuItem value="2">February</MenuItem>
                                    <MenuItem value="3">March</MenuItem>
                                    <MenuItem value="4">April</MenuItem>
                                    <MenuItem value="5">May</MenuItem>
                                    <MenuItem value="6">June</MenuItem>
                                    <MenuItem value="7">July</MenuItem>
                                    <MenuItem value="8">August</MenuItem>
                                    <MenuItem value="9">September</MenuItem>
                                    <MenuItem value="10">October</MenuItem>
                                    <MenuItem value="11">November</MenuItem>
                                    <MenuItem value="12">December</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="mar-b-40 col-md-4 pl-custom-sm-0">
                                <TextField
                                  type="number"
                                  label="Day"
                                  name="eventDay"
                                  value={
                                    formData.eventDay !== undefined
                                      ? formData.eventDay
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  className="w-100"
                                  onInput={(e) => {
                                    var value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 2);
                                    if (formData.eventMonth === "2") {
                                      if (value > 29) {
                                        value = 29;
                                      } else if (value < 1) {
                                        value = 1;
                                      }
                                    } else if (value > 31) {
                                      value = 31;
                                    } else if (value < 1) {
                                      value = 1;
                                    }
                                    e.target.value = value;
                                  }}
                                />
                              </div>

                              <div className="mar-b-40 col-md-4 pl-custom-sm-0">

                                <FormControl
                                  variant="outlined"
                                  className="w-100 bg-white"
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Year
                                  </InputLabel>

                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Year"
                                    onChange={this.handleChange}
                                    name="eventYear"
                                    value={
                                      formData.eventYear !== undefined
                                        ? formData.eventYear
                                        : ""
                                    }

                                  >
                                    {rows.map((value, index) => {
                                      return <MenuItem value={value} key={index}>{value}</MenuItem>
                                    })}


                                  </Select>
                                </FormControl>



                                {/* <TextField
                                  type="number"
                                  label="Year"
                                  variant="outlined"
                                  className="w-100"
                                  name="eventYear"
                                  value=""
                                  onChange={this.handleChange}
                                  // eslint-disable-next-line react/jsx-no-duplicate-props
                                  value={
                                    formData.eventYear !== undefined
                                      ? formData.eventYear
                                      : ""
                                  }
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 4);
                                  }}
                                /> */}
                              </div>


                            </div>
                            <div className="w-100 pad-t-50">
                              {this.state.isSubmitForm === true && (
                                <CircularProgress />
                              )}
                              {this.state.isSubmitForm === false && (
                                <div className="row">

                                  <div className={singleEventIndex > 0 ? 'col-12 mb-2' : 'col-6'}>
                                    <Button
                                      style={{ display: "inline-flex", background: '#c7c7c7' }}
                                      onClick={(e) =>
                                        this.handleSubmit(
                                          singleEventlist._id,
                                          singleEventIndex,
                                          true
                                        )
                                      }
                                      color="inherit"
                                      className="pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white"
                                    >
                                      Not sure
                                    </Button>
                                  </div>



                                  {singleEventIndex > 0 && (
                                    <div className="mar-b-40 col-6">
                                      <Button
                                        type="button"
                                        onClick={(e) =>
                                          this.backStep(singleEventlist._id)
                                        }
                                        className="bg-color4 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                                      >
                                        Back
                                      </Button>
                                    </div>
                                  )}

                                  <div
                                    className={
                                      singleEventIndex > 0
                                        ? "mar-b-40 col-6 text-right"
                                        : "mar-b-40 col-6 text-right"
                                    }
                                  >
                                    <Button
                                      type="button"
                                      onClick={(e) =>
                                        this.handleSubmit(
                                          singleEventlist._id,
                                          singleEventIndex,
                                          false
                                        )
                                      }
                                      className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                                    >
                                      Next
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryUserRegStepTwo);
