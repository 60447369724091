import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import UploadIcon from "../assets/images/upload.png";
import AddUser from "../assets/images/icon20.svg";
import UserPhoto from "../assets/images/user1.png";
import UIco from "../assets/images/uIco.svg";
import Tip from "../assets/images/icon19.svg";
import userSmall from "../assets/images/u1.png";
import UserIcon from "../assets/images/icon21.svg";
import EventIcon from "../assets/images/icon22.svg";

import { Button, FormHelperText } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

import countryCode from "../assets/countryCode";
import UploadURLS from "../assets/settings";
import { Helmet } from "react-helmet";
import validator from "validator";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

const modal = "d-flex align-items-center";
const userName = ["Amanda", "Oliver", "George", "Arthur", "Leo", "Jack"];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,

      currentCountryCode: "+1",
      allRelationshipType: [],
      is_show_other_relationship_type: false,
      otherRelationshipType: [
        {
          createdAt: "2020-12-10T09:24:24.443Z",
          isDeleted: false,
          name: "Partner",
          status: "Active",
          type: "Family",
          updatedAt: "2020-12-10T09:24:24.443Z",
          _id: "5fd1e948b7897323e048a5c61",
        },
        {
          createdAt: "2020-12-10T09:24:24.443Z",
          isDeleted: false,
          name: "Grandparents",
          status: "Active",
          type: "Family",
          updatedAt: "2020-12-10T09:24:24.443Z",
          _id: "5fd1e948b7897323e048a5c62",
        },
        {
          createdAt: "2020-12-10T09:24:24.443Z",
          isDeleted: false,
          name: "Grandchildren",
          status: "Active",
          type: "Family",
          updatedAt: "2020-12-10T09:24:24.443Z",
          _id: "5fd1e948b7897323e048a5c63",
        },
        {
          createdAt: "2020-12-10T09:24:24.443Z",
          isDeleted: false,
          name: "Sibling",
          status: "Active",
          type: "Family",
          updatedAt: "2020-12-10T09:24:24.443Z",
          _id: "5fd1e948b7897323e048a5c64",
        },
        {
          createdAt: "2020-12-10T09:24:24.443Z",
          isDeleted: false,
          name: "Spouse",
          status: "Active",
          type: "Family",
          updatedAt: "2020-12-10T09:24:24.443Z",
          _id: "5fd1e948b7897323e048a5c65",
        },
        {
          createdAt: "2020-12-10T09:24:24.443Z",
          isDeleted: false,
          name: "In-laws",
          status: "Active",
          type: "Family",
          updatedAt: "2020-12-10T09:24:24.443Z",
          _id: "5fd1e948b7897323e048a5c66",
        },
        {
          createdAt: "2020-12-10T09:24:24.443Z",
          isDeleted: false,
          name: "Family",
          status: "Active",
          type: "Family",
          updatedAt: "2020-12-10T09:24:24.443Z",
          _id: "5fd1e948b7897323e048a5c67",
        },
      ],
      userAllContactList: [],
      eventListWithMemories: [],
      userDetails: {},
      testimonialData: {},
      profilePreviewImage: "",
      formData: {
        firstName: "",
        lastName: "",
        relationship_id: "",
        other_relationship_id: "",
        nature_of_relationship: "",
        email: "",
        phone: "",
        image: "",
        customInvitation: "",
      },
      selectedRelationlabel: "",
      isAddContactChangeValue: true,
      isSubmitForm: false,
      isSubmitForm1: false,
      isLoading: true,
      activeSlide: 0,
      validEmail: false,
      isDataFetched: false,
      error: {
        firstName: "",
        lastName: "",
        relationship_id: "",
        nature_of_relationship: "",
        email: "",
        phone: "",
      },
      recentMemory: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("authToken");

    if (token != null) {
      /* Forcefully login */
      this.props.contactActions.forceSignin({ token: token });
    } else {
      var currentUserRole = "";
      var auth_token = localStorage.getItem("auth_token");
      var user_info = localStorage.getItem("user_info");
      if (user_info !== "") {
        user_info = JSON.parse(user_info);
      }
      if (
        user_info !== "" &&
        user_info !== undefined &&
        user_info !== null &&
        user_info.role !== "" &&
        user_info.role !== null &&
        user_info.role !== undefined &&
        user_info.role.role !== "" &&
        user_info.role.role !== null &&
        user_info.role.role !== undefined
      ) {
        currentUserRole = user_info.role.role;
      }

      if (
        auth_token === "" ||
        auth_token === undefined ||
        auth_token === null ||
        currentUserRole === "sub-user"
      ) {
        this.props.history.push("/");
      } else {
        /* get secondary user details form notification value */
        var secondaryUserDetails =
          this.props.location.state !== undefined &&
          this.props.location.state !== null &&
          this.props.location.state !== "" &&
          this.props.location.state.secondaryUserDetails !== undefined &&
          this.props.location.state.secondaryUserDetails !== null &&
          this.props.location.state.secondaryUserDetails !== ""
            ? this.props.location.state.secondaryUserDetails
            : "";
        if (secondaryUserDetails !== "") {
          var formData = this.state.formData;
          formData.firstName = secondaryUserDetails.first_name;
          formData.lastName = secondaryUserDetails.last_name;
          formData.email = secondaryUserDetails.email;

          this.setState({
            formData: formData,
            setOpen: true,
            isAddContactChangeValue: false,
          });
        }

        var userInfo = JSON.parse(localStorage.getItem("user_info"));

        this.setState({ userDetails: userInfo });
        /* fetch all relationship */
        this.props.contactActions.fetchAllRelationshipTypes();

        /* Fetch user all contact list */
        this.props.contactActions.getUserContactList();

        /* FETCH TESTIMONIIAL DETAILS */
        this.props.miscActions.getTestimonial();

        /* FETCH NOTIFICATION PRO TIPS LIST */
        this.props.contactActions.fetchDashboardProTips();

        /* FETCH ALL EVENTS */
        this.props.contactActions.fetchPrimaryUserEventsListWithMemories();
      }
    }
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;

      switch (contactReducerData.type) {
        /* Fetch Relationship list */
        case contactActions.FETCH_ALL_RELATIONSHIP_TYPE_SUCCESS:
          var relationshipList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];
          props.contactActions.clearState();
          return {
            allRelationshipType: relationshipList,
          };
          break;
        case contactActions.FETCH_ALL_RELATIONSHIP_TYPE_FAILED:
          props.contactActions.clearState();

          return {
            allRelationshipType: [],
          };
          break;

        /* Save Contact Info */
        case contactActions.SAVE_CONTACT_INFO_SUCCESS:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          props.contactActions.clearState();
          setTimeout(function () {
            props.contactActions.getUserContactList();
          }, 500);

          var formData = {
            firstName: "",
            lastName: "",
            relationship_id: "",
            other_relationship_id: "",
            email: "",
            phone: "",
            image: "",
            customInvitation: "",
          };

          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            setOpen: false,
            isSubmitForm: false,
            profilePreviewImage: "",
            formData: formData,
            validEmail: false,
            isSubmitForm1: false,
          };
        case contactActions.SAVE_CONTACT_INFO_FAILED:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.message !== undefined &&
            contactReducerData.responce.message !== ""
              ? contactReducerData.responce.message
              : ""; // for 500 error
          if (message === "") {
            message =
              contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.message !== undefined &&
              contactReducerData.responce.data.message !== ""
                ? contactReducerData.responce.data.message
                : ""; // for 201 error
          }

          props.contactActions.clearState();
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmitForm: false,
            validEmail: false,
            isSubmitForm1: false,
          };

          break;

        /* FETCH ALL CONTACT INFO */
        case contactActions.GET_USER_CONTACT_LIST_SUCCESS:
          var contactList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];
          props.contactActions.clearState();
          return {
            userAllContactList: contactList,
            isLoading: false,
          };
          break;
        case contactActions.GET_USER_CONTACT_LIST_FAILED:
          return {
            userAllContactList: [],
            isLoading: false,
          };
          break;

        /* FETCH NOTIFICATION PRO LIST */
        case contactActions.NOTIFICATION_PRO_TIPS_LIST_SUCCESS:
          props.contactActions.clearState();
          let proTipsList = contactReducerData.responce.data.data
            ? contactReducerData.responce.data.data
            : [];
          // returnData["notificationProTipsList"] = proTipsList;
          return {
            notificationProTipsList: proTipsList,
          };

        /* FETCH EVENT LIST WITH MEMORIES */
        case contactActions.FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_SUCCESS:
          var eventList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];

          var recentMemory =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.recentMemory !== undefined
              ? contactReducerData.responce.data.recentMemory
              : "";
          props.contactActions.clearState();

          console.log(
            "recent memory",
            contactReducerData.responce.data.recentMemory
          );

          return {
            eventListWithMemories: eventList,
            recentMemory,
            isDataFetched: true,
          };
          break;

        case contactActions.FETCH_PRIMARY_USER_EVENT_LIST_WITH_MEMORY_FAILED:
          return {
            eventListWithMemories: [],
            isDataFetched: true,
          };
          break;

        case contactActions.FORCE_USER_LOGIN_SUCCESS:
          var userInfo =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";
          var token =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.token !== undefined
              ? contactReducerData.responce.data.token
              : "";
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined
              ? contactReducerData.responce.data.message
              : "";

          var role =
            userInfo !== undefined &&
            userInfo !== null &&
            userInfo !== "" &&
            userInfo.role !== undefined &&
            userInfo.role !== "" &&
            userInfo.role !== "" &&
            userInfo.role.role !== undefined &&
            userInfo.role.role !== "" &&
            userInfo.role.role !== null
              ? userInfo.role.role
              : "";

          props.miscActions.clearState();
          if (token !== "") {
            localStorage.setItem("auth_token", token);
            localStorage.setItem("user_info", JSON.stringify(userInfo));
            if (role == "sub-user") {
              window.location.href = "/secondary-user-dashboard";
              //props.history.push("/secondary-user-dashboard");
            } else {
              window.location.href = "/dashboard";
              //props.history.push("/dashboard");
            }

            return {
              apiErrorMessage: "",
              apiSuccessMessage: message,
              loginUserType: role,
            };
          } else {
            return {
              apiErrorMessage: "Something went wrong",
              apiSuccessMessage: "",
            };
          }
        case contactActions.FORCE_USER_LOGIN_FAILED:
          props.miscActions.clearState();
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
          };

        default:
          return {};
      }
    }

    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.DASHBOARD_TESTIMONIALS_SUCCESS:
          let testimonialData = miscReducerData.responce.data
            ? miscReducerData.responce.data
            : {};
          return {
            testimonialData: testimonialData,
          };
        // case miscActions.NOTIFICATION_PRO_TIPS_LIST_SUCCESS:
        //   props.miscActions.clearState();
        //   let proTipsList = miscReducerData.responce.data.data
        //     ? miscReducerData.responce.data.data
        //     : [];

        //   return {
        //     notificationProTipsList: proTipsList,
        //   };

        case miscActions.USER_PROFILE_UPDATE_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          props.miscActions.clearState();
          localStorage.setItem("user_info", JSON.stringify(userInfo));
          props.history.push("/dashboard");
          return {
            userDetails: userInfo,
          };
          break;
        case miscActions.USER_PROFILE_UPDATE_FAILED:
          props.miscActions.clearState();
          return {};

        default:
          return {};
      }
    }

    return {};
  }

  handleOpen = () => {
    this.setState({ setOpen: true });
  };

  handleClose = () => {
    var formData = {
      firstName: "",
      lastName: "",
      relationship_id: "",
      other_relationship_id: "",
      email: "",
      phone: "",
      image: "",
      customInvitation: "",
      previewImage: "",
    };

    this.setState({
      setOpen: false,
      formData: formData,
      isAddContactChangeValue: true,
      profilePreviewImage: "",
    });

    /* if calue comes from notification page and after close modal remove value form props */
    var secondaryUserDetails =
      this.props.location.state !== undefined &&
      this.props.location.state !== null &&
      this.props.location.state !== "" &&
      this.props.location.state.secondaryUserDetails !== undefined &&
      this.props.location.state.secondaryUserDetails !== null &&
      this.props.location.state.secondaryUserDetails !== ""
        ? this.props.location.state.secondaryUserDetails
        : "";

    if (secondaryUserDetails !== "") {
      this.props.history.push("/dashboard");
    }
  };

  triggerInputFile = () => this.fileInput.click();

  setContactPhoto = () => this.contactPhoto.click();

  handleChange(event) {
    let is_show_other_relationship_type =
      this.state.is_show_other_relationship_type;

    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    // if (fieldName == "phone") {
    //   if (fieldValue != "" || this.state.formData.email != "") {
    //     this.setState({
    //       validEmail: true,
    //     });
    //   } else {
    //     this.setState({
    //       validEmail: false,
    //     });
    //   }
    // } else if (fieldName == "email") {
    //   if (this.state.formData.phone != "") {
    //     this.setState({
    //       validEmail: true,
    //     });
    //   } else {
    //     if (this.validateEmail(fieldValue) === false) {
    //       this.setState({
    //         validEmail: false,
    //       });
    //     } else {
    //       this.setState({
    //         validEmail: true,
    //       });
    //     }
    //   }
    // }

    // if (fieldName == "email") {
    //   if (this.validateEmail(fieldValue) === false) {
    //     this.setState({
    //       validEmail: false,
    //     });
    //   } else {
    //     this.setState({
    //       validEmail: true,
    //     });
    //   }
    // }

    // if (
    //   (fieldName === "relationship_id" &&
    //     this.state.selectedRelationlabel == "Other") ||
    //   fieldName === "other_relationship_id"
    // ) {
    //   is_show_other_relationship_type = true;
    // } else if (fieldName === "relationship_id" && fieldValue != "Other") {
    //   is_show_other_relationship_type = false;
    // }

    formData[fieldName] = fieldValue;
    this.setState(
      {
        formData: formData,
        isAddContactChangeValue: false,
        //is_show_other_relationship_type: is_show_other_relationship_type,
      },
      () => {
        if (this.state.isSubmitForm) {
          //checking if error found..
          const errorObj = {};
          const keys = Object.keys(this.state.error);
          for (const key of keys) {
            if (
              (this.state.formData[key] === "" ||
                this.state.formData[key] === "undefined") &&
              key !== "phone" &&
              key !== "email"
            ) {
              errorObj[key] = "This is required field";
            } else if (key === "email") {
              if (this.state.formData[key] === "") {
                if (
                  validator.isMobilePhone(
                    this.state.currentCountryCode.trim() +
                      this.state.formData.phone
                  ) &&
                  !/^\s/.test(this.state.formData.phone)
                ) {
                  errorObj.email = "";
                } else {
                  errorObj.email = "This is required field";
                }
              } else {
                if (
                  validator.isEmail(this.state.formData.email) &&
                  !this.hasWhiteSpace(this.state.formData.email)
                ) {
                  errorObj[key] = "";
                } else {
                  errorObj[key] = "Please enter correct email";
                }
              }
            } else if (key === "phone") {
              if (this.state.formData[key] === "") {
                if (
                  validator.isEmail(this.state.formData.email) &&
                  !this.hasWhiteSpace(this.state.formData.email)
                ) {
                  errorObj[key] = "";
                } else {
                  errorObj[key] = "This is required field";
                }
              } else {
                if (
                  validator.isMobilePhone(
                    this.state.currentCountryCode.trim() +
                      this.state.formData[key]
                  ) &&
                  !/^\s/.test(this.state.formData.phone)
                ) {
                  errorObj[key] = "";
                } else {
                  errorObj[key] = "Please enter correct phone number";
                }
              }
            } else {
              errorObj[key] = "";
            }
          }
          this.setState(
            {
              error: errorObj,
            },
            () => {
              console.log(this.state.error);
            }
          );
        }
      }
    );
  }

  fileUploadChange(e) {
    let profileImage = e.target.files;
    let profilePreviewImage = "";
    let profileMainImage = "";

    if (
      profileImage !== undefined &&
      profileImage !== null &&
      profileImage !== "" &&
      profileImage.length > 0
    ) {
      profileMainImage = profileImage[0];
      profilePreviewImage = URL.createObjectURL(profileMainImage);
    }

    if (profilePreviewImage !== "" && profileMainImage !== "") {
      let formData = this.state.formData;
      formData["image"] = profileMainImage;

      this.setState({
        profilePreviewImage: profilePreviewImage,
        formData: formData,
        isAddContactChangeValue: false,
      });
    }
  }

  validateEmail(mail) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  hasWhiteSpace(s) {
    return /\s/.test(s);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitForm: true });
    let formData = this.state.formData;

    //checking if error found..
    const errorObj = {};
    const keys = Object.keys(this.state.error);
    for (const key of keys) {
      if (
        (this.state.formData[key] === "" ||
          this.state.formData[key] === "undefined") &&
        key !== "phone" &&
        key !== "email" &&
        key !== "nature_of_relationship"
      ) {
        errorObj[key] = "This is required field";
      } else if (key === "email") {
        if (this.state.formData[key] === "") {
          if (
            validator.isMobilePhone(
              this.state.currentCountryCode.trim() + this.state.formData.phone
            ) &&
            !/^\s/.test(this.state.formData.phone)
          ) {
            errorObj.email = "";
          } else {
            errorObj.email = "This is required field";
          }
        } else {
          if (
            validator.isEmail(this.state.formData.email) &&
            !this.hasWhiteSpace(this.state.formData.email)
          ) {
            errorObj[key] = "";
          } else {
            errorObj[key] = "Please enter correct email";
          }
        }
      } else if (key === "phone") {
        if (this.state.formData[key] === "") {
          if (
            validator.isEmail(this.state.formData.email) &&
            !this.hasWhiteSpace(this.state.formData.email)
          ) {
            errorObj[key] = "";
          } else {
            errorObj[key] = "This is required field";
          }
        } else {
          if (
            validator.isMobilePhone(
              this.state.currentCountryCode.trim() + this.state.formData[key]
            ) &&
            !/^\s/.test(this.state.formData.phone)
          ) {
            errorObj[key] = "";
          } else {
            errorObj[key] = "Please enter correct phone number";
          }
        }
      } else if (key === "nature_of_relationship") {
        if (this.state.selectedRelationlabel === "Other") {
          if (this.state.formData.nature_of_relationship === "") {
            errorObj[key] = "This is required field";
          } else {
            errorObj[key] = "";
          }
        } else {
          errorObj[key] = "";
        }
      } else {
        errorObj[key] = "";
      }
    }
    this.setState(
      {
        error: errorObj,
      },
      () => {
        const error = [];
        const keys = Object.keys(this.state.error);
        for (const key of keys) {
          if (this.state.error[key] !== "") {
            error.push(key);
          }
        }
        if (error.length === 0) {
          var relationship_id = formData.relationship_id;
          if (relationship_id === "other") {
            relationship_id = formData.other_relationship_id;
          }
          var newFormData = new FormData();
          newFormData.append("firstName", formData.firstName);
          newFormData.append("lastName", formData.lastName);
          //newFormData.append("relationship_id", formData.relationship_id);
          newFormData.append("relationship_id", relationship_id);
          newFormData.append("email", formData.email);
          newFormData.append("phone", formData.phone);
          newFormData.append(
            "countryCode",
            this.state.currentCountryCode.trim()
          );
          newFormData.append("image", formData.image);
          newFormData.append("customInvitation", formData.customInvitation);
          newFormData.append(
            "nature_of_relationship",
            formData.nature_of_relationship
          );

          this.setState({
            isSubmitForm1: true,
          });

          this.props.contactActions.saveContactInfo(newFormData);
        }
      }
    );

    // if (
    //   formData.firstName === "" ||
    //   (formData.firstName === null && formData.firstName === undefined)
    // ) {
    //   toast.error("First name can not be blank");
    // } else if (
    //   formData.lastName === "" ||
    //   formData.lastName === null ||
    //   formData.lastName === undefined
    // ) {
    //   toast.error("Last name can not be blank");
    // } else if (
    //   formData.relationship_id === "" ||
    //   formData.relationship_id === null ||
    //   formData.relationship_id === undefined
    // ) {
    //   toast.error("Please select relationship type");
    // } else if (
    //   this.state.selectedRelationlabel == "Other" &&
    //   (formData.nature_of_relationship === "" ||
    //     formData.nature_of_relationship === null ||
    //     formData.nature_of_relationship === undefined)
    // ) {
    //   toast.error("Other can not be blank");
    // } else if (
    //   formData.relationship_id === "other" &&
    //   (formData.other_relationship_id === "" ||
    //     formData.other_relationship_id === null ||
    //     formData.other_relationship_id === undefined)
    // ) {
    //   toast.error("Please select relationship type");
    // } else if (
    //   (formData.email === "" ||
    //     formData.email === null ||
    //     formData.email === undefined) &&
    //   (formData.phone === "" ||
    //     formData.phone === null ||
    //     formData.phone === undefined)
    // ) {
    //   toast.error("Email or Phone can not be blank");
    // } else if (
    //   formData.email !== "" &&
    //   formData.email !== undefined &&
    //   formData.email !== null &&
    //   this.validateEmail(formData.email) === false
    // ) {
    //   toast.error("Please enter a valid email address");
    // } else if (
    //   formData.phone !== "" &&
    //   formData.phone !== undefined &&
    //   formData.phone !== null &&
    //   (this.state.currentCountryCode === "" ||
    //     this.state.currentCountryCode === null ||
    //     this.state.currentCountryCode === undefined)
    // ) {
    //   toast.error("Please select a country code");
    // } else {
    //   var phone = formData.phone;
    //   if (phone !== null && phone !== undefined && phone !== "") {
    //     phone = this.state.currentCountryCode.trim() + phone;
    //   }

    //   var relationship_id = formData.relationship_id;
    //   if (relationship_id === "other") {
    //     relationship_id = formData.other_relationship_id;
    //   }
    //   var newFormData = new FormData();
    //   newFormData.append("firstName", formData.firstName);
    //   newFormData.append("lastName", formData.lastName);
    //   //newFormData.append("relationship_id", formData.relationship_id);
    //   newFormData.append("relationship_id", relationship_id);
    //   newFormData.append("email", formData.email);
    //   newFormData.append("phone", phone);
    //   newFormData.append("image", formData.image);
    //   newFormData.append("customInvitation", formData.customInvitation);
    //   newFormData.append(
    //     "nature_of_relationship",
    //     formData.nature_of_relationship
    //   );

    //   this.setState({
    //     isSubmitForm: true,
    //   });

    //   this.props.contactActions.saveContactInfo(newFormData);
    // }
  }

  gotoMemoryPage(singleContactInfo) {
    this.props.history.push("/view-contact/" + singleContactInfo._id);
  }

  fileUploadDashboardProTips(e) {
    // console.log("Sam",e?.target?.files[0]?.type?.includes("image/"))
    if (e?.target?.files[0]?.type?.includes("image/")) {
      let previewImage = URL.createObjectURL(e.target.files[0]);
      this.setState({ previewImage: previewImage });
      var formData = new FormData();
      formData.append("profile_image", e.target.files[0]);
      this.props.miscActions.userProfileUpdate(formData);
    } else {
      toast.error("Please choose an image");
    }
  }

  createMemoryPageRedirect() {
    var protipsList = this.state.notificationProTipsList;
    var currentSliderIndex = this.state.activeSlide;
    if (
      protipsList.length > 0 &&
      currentSliderIndex !== "" &&
      protipsList[currentSliderIndex] !== undefined &&
      protipsList[currentSliderIndex] !== null &&
      protipsList[currentSliderIndex] !== ""
    ) {
      var currentProList = protipsList[currentSliderIndex];

      if (
        currentProList.type === "significant-event-notification" &&
        currentProList.event_id !== undefined &&
        currentProList.event_id !== null &&
        currentProList.event_id !== "" &&
        currentProList.event_id._id !== undefined &&
        currentProList.event_id._id !== null &&
        currentProList.event_id._id !== ""
      ) {
        this.props.history.push("/view-event/" + currentProList.event_id._id);
      } else if (currentProList.type === "dashboard") {
        this.fileInput.click();
      }
    }
  }

  eventClick(singleEvent) {
    if (singleEvent.singleUserEvent === false) {
      this.props.history.push(
        "/primary-userlist-from-event/" + singleEvent._id
      );
    } else {
      this.props.history.push(
        "/primary-user-memory-shared-list/" +
          singleEvent._id +
          "/" +
          singleEvent.user_contact_id
      );
    }
  }

  handleUpdatelabel = (label) => {
    let formData = this.state.formData;
    if (label == "Other") {
      this.setState({
        is_show_other_relationship_type: true,
      });
    } else {
      formData["nature_of_relationship"] = "";
      this.setState({
        formData: formData,
        is_show_other_relationship_type: false,
      });
    }
    this.setState({ selectedRelationlabel: label });
  };

  viewFullMemory() {
    /* Recent memory details */
    let recentMemory = this.state.recentMemory;
    console.log("recentMemory", this.state.recentMemory);
    if (
      recentMemory !== undefined &&
      recentMemory !== null &&
      recentMemory !== "" &&
      recentMemory.event_id !== undefined &&
      recentMemory.year !== undefined &&
      recentMemory._id !== undefined
    ) {
      var data = {
        year: recentMemory.year,
        memories: recentMemory,
      };
      var eventDetails = recentMemory.event_id;

      this.props.history.push({
        pathname: "/secondary-user-view-memory/" + recentMemory._id,
        state: {
          detail: data,
          eventDetails: eventDetails,
        },
      });

      // this.props.history.push({
      //   pathname: "/view-memory/" + recentMemory._id,
      //   state: {
      //     detail: data,
      //     eventDetails: eventDetails,
      //   },
      // });
    }
  }

  render() {
    var settings = {
      fade: true,
      dots: true,
      arrows: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (current) => this.setState({ activeSlide: current }),
    };

    var allRelationshipType = this.state.allRelationshipType;
    var otherRelationshipType = this.state.otherRelationshipType;
    var userAllContactList = this.state.userAllContactList;
    var eventListWithMemories = this.state.eventListWithMemories;
    //console.log(this.state.validEmail);

    /* Recent memory details */
    let recentMemory = this.state.recentMemory;

    return (
      <>
        <Helmet>
          <title>Forget Me Not - Dashboard</title>
        </Helmet>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap dashboardWrapsub">
            <div className="container">
              <div className="row justify-content-lg-center">
                <div className="col-lg-10 col-xxxl-9">
                  <div className="userSlider">
                    <Slider {...settings}>
                      {this.state.notificationProTipsList &&
                        this.state.notificationProTipsList.length > 0 &&
                        this.state.notificationProTipsList.map(
                          (list, index) => (
                            <div className="items" key={index}>
                              <h3 className="fs-24 font-weight-bold mar-b-22">
                                {localStorage.getItem("firstLogin") == 1 && (
                                  <span>Hi, </span>
                                )}
                                {localStorage.getItem("firstLogin") != 1 && (
                                  <span>Welcome back, </span>
                                )}
                                {this.state.userDetails &&
                                  this.state.userDetails.first_name}
                                !
                              </h3>
                              <div className="border-radius-10  bg-white d-md-flex pad-y-30  pad-y-md-40 pad-x-20 pad-x-md-50">
                                <div className="uploadImage mb-4 mb-md-0">
                                  {list.type === "dashboard" &&
                                    this.state.previewImage && (
                                      <>
                                        <AddCircleIcon
                                          style={{
                                            color: "#71c4c8",
                                            cursor: "pointer",
                                          }}
                                          className="AddIcon"
                                          onClick={(e) =>
                                            this.triggerInputFile()
                                          }
                                        />
                                        <img
                                          src={this.state.previewImage}
                                          alt=""
                                          className="w-72px h-72px d-block object-fit-cover rounded-circle userIcon"
                                          onClick={(e) =>
                                            this.triggerInputFile()
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      </>
                                    )}

                                  {list.type === "dashboard" &&
                                    !this.state.previewImage && (
                                      <>
                                        {this.state.userDetails
                                          .profile_image === "" && (
                                          <img
                                            src={UploadIcon}
                                            alt=""
                                            className=""
                                            onClick={(e) =>
                                              this.triggerInputFile()
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        )}
                                        {this.state.userDetails
                                          .profile_image !== "" && (
                                          <>
                                            <AddCircleIcon
                                              style={{
                                                color: "#71c4c8",
                                                cursor: "pointer",
                                              }}
                                              className="AddIcon"
                                              onClick={(e) =>
                                                this.triggerInputFile()
                                              }
                                            />
                                            <img
                                              src={
                                                UploadURLS.profile_image_path +
                                                this.state.userDetails
                                                  .profile_image
                                              }
                                              alt=""
                                              className="w-72px h-72px d-block object-fit-cover rounded-circle userIcon "
                                              onClick={(e) =>
                                                this.triggerInputFile()
                                              }
                                              style={{
                                                cursor: "pointer",
                                                width: "114px !important",
                                                height: "114px !important",
                                              }}
                                            />
                                          </>
                                        )}
                                      </>
                                    )}

                                  {list.type ===
                                    "significant-event-notification" &&
                                    list.secondaryUser !== undefined &&
                                    list.secondaryUser !== null &&
                                    list.secondaryUser !== "" &&
                                    list.secondaryUser.profile_image !==
                                      undefined &&
                                    list.secondaryUser.profile_image !== null &&
                                    list.secondaryUser.profile_image !== "" && (
                                      <img
                                        src={
                                          UploadURLS.profile_image_path +
                                          list.secondaryUser.profile_image
                                        }
                                        alt=""
                                        className="w-72px h-72px d-block object-fit-cover rounded-circle"
                                      />
                                    )}

                                  {list.type ===
                                    "significant-event-notification" &&
                                    (list.secondaryUser === undefined ||
                                      list.secondaryUser === null ||
                                      list.secondaryUser === "" ||
                                      list.secondaryUser.profile_image ===
                                        undefined ||
                                      list.secondaryUser.profile_image ===
                                        null ||
                                      list.secondaryUser.profile_image ===
                                        "") && (
                                      <img
                                        src={UploadIcon}
                                        alt=""
                                        className=""
                                      />
                                    )}
                                </div>
                                <div className="pad-l-md-40">
                                  <h6 className="text-uppercase font-weight-bold fs-14">
                                    <img
                                      src={Tip}
                                      alt=""
                                      className="d-inline-flex"
                                    />{" "}
                                    pro tips
                                  </h6>
                                  <h4 className="text-black fs-18 fs-xl-24 ">
                                    <b className="font-weight-Bold"></b>{" "}
                                    <span
                                      className="opa-80 font-weight-Regular"
                                      dangerouslySetInnerHTML={{
                                        __html: list.message,
                                      }}
                                    />
                                  </h4>

                                  <div className="pt-2">
                                    {list.type ===
                                      "significant-event-notification" &&
                                      list.event_id !== undefined &&
                                      list.event_id._id !== undefined && (
                                        <Button
                                          data-eventid={list.event_id._id}
                                          name={list.event_id._id}
                                          onClick={(e) =>
                                            this.createMemoryPageRedirect()
                                          }
                                          className="bg-color1 text-white pad-y-8 pad-x-20 text-capitalize fs-16 fs-xl-19"
                                        >
                                          <i className="material-icons">
                                            add_circle
                                          </i>{" "}
                                          {""}
                                          {list.secondaryUser !== undefined &&
                                            list.secondaryUser !== null &&
                                            list.secondaryUser !== "" && (
                                              <span
                                                className="pl-2"
                                                name={list.event_id._id}
                                              >
                                                Create memory for{" "}
                                                {list.secondaryUser.first_name}{" "}
                                                {list.secondaryUser.last_name}
                                              </span>
                                            )}
                                        </Button>
                                      )}

                                    {list.type === "dashboard" && (
                                      <>
                                        <input
                                          style={{ display: "none" }}
                                          type="file"
                                          ref={(fileInput) =>
                                            (this.fileInput = fileInput)
                                          }
                                          onChange={(e) =>
                                            this.fileUploadDashboardProTips(e)
                                          }
                                        />
                                        <Button
                                          data-eventid={list._id}
                                          name={list._id}
                                          onClick={(e) =>
                                            this.createMemoryPageRedirect()
                                          }
                                          className="bg-color1 text-white pad-y-8 pad-x-20 text-capitalize fs-16 fs-xl-19"
                                        >
                                          <i className="material-icons">
                                            add_circle
                                          </i>{" "}
                                          <span className="pl-2">
                                            Upload a Photo
                                          </span>
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </Slider>
                  </div>

                  {recentMemory && (
                    <div className=" text-center bg-white border-radius-15 my-2">
                      <div className="col-md-11 px-0 px-md-3 mx-auto pt-4 pt-md-5 pb-4">
                        <div className="shadow border-radius-10">
                          <div className="w-100 mb-4 border-bottom p-4">
                            <div className="row align-items-center justify-content-between">
                              <div className="col-md-auto">
                                <h4 className="pl-2 mb-0 opa-60 font-weight-Regular">
                                  <span
                                    className="w-10px h-10px d-inline-block rounded-circle align-middle mr-2"
                                    style={{ background: "#8FFA24" }}
                                  ></span>{" "}
                                  Recent memory from{" "}
                                  {recentMemory &&
                                    recentMemory.user_id &&
                                    recentMemory.user_id.first_name}
                                </h4>
                              </div>
                              <div className="col-md-auto pt-2 pt-md-0">
                                <h5 className="mb-0 fs-18 opa-60 font-weight-Regular">
                                  {recentMemory !== undefined &&
                                    recentMemory !== null &&
                                    recentMemory !== "" &&
                                    recentMemory.unlockedOn !== undefined &&
                                    recentMemory.unlockedOn !== "" &&
                                    recentMemory.unlockedOn !== null && (
                                      <>
                                        {" "}
                                        Arrived on{" "}
                                        {moment(recentMemory.unlockedOn)
                                          //.tz('America/New_York')
                                          .format("Do MMMM YYYY")}
                                      </>
                                    )}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="p-4 text-left">
                            {recentMemory?.title !== "" &&
                              recentMemory?.message !== "" && (
                                <>
                                  <h3 className="mb-0 fs-24 fs-md-36 fontsmall">
                                    {recentMemory?.title}
                                  </h3>
                                  <div className="fs-16 fs-md-24 lh-28 lh-md-36 font-weight-Light text-black mb-5 fontsmalltext">
                                    {recentMemory?.message}
                                  </div>
                                </>
                              )}

                            {recentMemory?.title !== "" &&
                              recentMemory?.message !== "" && (
                                <div>
                                  <Button
                                    className={
                                      "bg-color1 text-white text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-25 mar-l-20 "
                                    }
                                    onClick={(e) => this.viewFullMemory()}
                                  >
                                    <i className="material-icons fs-17 fs-md-24 mr-2">
                                      mms
                                    </i>{" "}
                                    View full memory
                                  </Button>
                                </div>
                              )}

                            {/* 

                        {(recentMemoryTitle === "" ||
                          recentMemoryDescription === "") &&
                          (this.state.isLoading === true ? (
                            <div className="text-center">
                              <CircularProgress />
                            </div>
                          ) : (
                            <h4 className="pl-2 mb-0 opa-60 font-weight-Regular">
                              Sorry, no memory information found.
                            </h4>
                          ))}

                         */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {userAllContactList.length === 0 &&
                    (this.state.isDataFetched === false ? (
                      <div className="text-center mt-5">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className=" text-center bg-white border-radius-15 pad-y-20 pad-y-md-60 pad-y-xxl-100 mt-5">
                        <div className="pad-y-30">
                          <div>
                            <img src={AddUser} alt="" />
                          </div>
                          <div className="text-black opa-87 font-weight-Light fs-26 fs-md-36">
                            <i>Add your First Contact</i>
                          </div>
                          {/* <div className="text-black opa-60 font-weight-Regular fs-14 fs-md-18">
                            You can start creating memories afterwards
                          </div> */}
                          <div className="pad-t-18">
                            <Button
                              className="bg-color1 text-white pad-y-7 pad-x-20 text-capitalize fs-19"
                              onClick={this.handleOpen.bind(this)}
                            >
                              <i className="material-icons">person_add</i>{" "}
                              <span className="pl-2">Add Contact</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}

                  {userAllContactList.length > 0 &&
                    (this.state.isDataFetched === false ? (
                      <div className="text-center mt-5">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className=" text-center bg-white border-radius-15  ">
                        <div className="card-header border-0 pad-x-15 pad-x-md-46 pad-t-20 bg-white border-radius-15">
                          <div className="d-flex justify-content-between align-items-center">
                            <h3 className="text-black fs-16 fs-md-24 font-weight-Bold mb-0 text-left">
                              <i className="material-icons text-color1 align-middle fs-30">
                                supervisor_account
                              </i>
                              <span className="pl-2">Your Contacts</span>
                            </h3>

                            <div>
                              <Button
                                className="bg-color1 text-white text-capitalize fs-12 fs-md-16 pad-y-7 pad-x-15 pad-x-md-25 mar-l-20  "
                                onClick={this.handleOpen.bind(this)}
                              >
                                <i className="material-icons pr-1 fs-18 fs-md-24">
                                  person_add
                                </i>{" "}
                                Add Contact
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="card-body pad-x-15 pad-x-md-46">
                          <div className="row">
                            {userAllContactList.map((list, index) => (
                              <div className="col-md-6 mb-4" key={index}>
                                <div className="shadow-sm px-4 py-3 border rounded">
                                  <div className="d-flex align-items-center  justify-content-between mb-3">
                                    <div>
                                      <img
                                        src={
                                          list.image !== undefined &&
                                          list.image !== null &&
                                          list.image !== ""
                                            ? UploadURLS.contact_profile_pic +
                                              list.image
                                            : UserIcon
                                        }
                                        className="object-fit-cover w-55px h-55px  rounded-circle"
                                        alt=""
                                      />
                                    </div>
                                    <div className="border rounded-pill fs-14 text-secondary py-1 px-2">
                                      {list.relationship !== undefined &&
                                      list.relationship.name !== undefined
                                        ? list.relationship.name
                                        : "NA"}
                                    </div>
                                  </div>
                                  <div className="fs-35 fs-xl-26 fs-xxxl-36 text-black opa-87 text-left">
                                    {list.firstName} {list.lastName}
                                  </div>
                                  <div className="fs-14 fs-xl-18 text-black opa-60 text-left mb-4">
                                    {list.numberOfMemories} Memories created
                                  </div>
                                  <div className="text-left">
                                    <Button
                                      className="border text-color1"
                                      onClick={(e) => this.gotoMemoryPage(list)}
                                    >
                                      <i className="material-icons mr-1">mms</i>{" "}
                                      Manage Memories
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}

                  {eventListWithMemories.length > 0 && (
                    <div className=" text-center bg-white border-radius-15">
                      <div className="card-header border-0 pad-x-15 pad-x-md-46 pad-t-20 bg-white">
                        <div className="d-flex justify-content-between align-items-center">
                          <h3 className="text-black fs-16 fs-md-24 font-weight-Bold mb-0 text-left">
                            <i className="material-icons text-color1 align-middle">
                              event
                            </i>
                            <span className="pl-2">Shared Events</span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body pad-x-15 pad-x-md-46">
                        <div className="row">
                          {eventListWithMemories.map(
                            (singleEventlist, eventindex) => (
                              <div
                                className=" col-lg-6 mar-b-30 text-left"
                                key={eventindex}
                                onClick={(e) =>
                                  this.eventClick(singleEventlist)
                                }
                              >
                                <div className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded">
                                  <div className="fs-28 fs-xxxl-36 font-weight-light text-black" style={{overflowWrap:'anywhere'}}>
                                    {singleEventlist.title !== undefined &&
                                    singleEventlist.title !== null &&
                                    singleEventlist.title !== ""
                                      ? singleEventlist.title
                                      : ""}
                                  </div>
                                  <div className="">
                                    <img src={EventIcon} alt="" />{" "}
                                    <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                      {singleEventlist.eventMemories !==
                                        undefined &&
                                      singleEventlist.eventMemories !== null &&
                                      singleEventlist.eventMemories !== "" &&
                                      singleEventlist.eventMemories.length > 0
                                        ? singleEventlist.eventMemories.length +
                                          " Memories"
                                        : "No Memories"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          )}

                          {/*<div className=" col-lg-6 mar-b-30 text-left">
                            <div className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded">
                              <div className="fs-28 fs-xxxl-36 font-weight-light text-black">Test</div>
                              <div className="">
                                <img src={EventIcon} alt="" />{" "}
                                <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                  Add memories to this event
                                </span>
                              </div>
                            </div>
                          </div>*/}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="col-lg-4 col-xxxl-3 pt-5 pt-lg-0">
                  <div className="h-100 pad-y-34 pad-x-15 pad-x-md-55 border-radius-10 text-center bg-white">
                    <div className="userImg mar-b-22">
                      <img
                        src={
                          UploadURLS.testimonialsImagePath +
                          this.state.testimonialData.image
                        }
                        alt=""
                        className="mx-auto w-157px h-157px object-fit-cover rounded-circle"
                        alt=""
                      />
                    </div>
                    <h4 className="fs-22 font-weight-Regular lh-36 px-xxxl-5 text-black">
                      {this.state.testimonialData
                        ? this.state.testimonialData.content
                        : null}
                    </h4>
                    <h4 className="fs-22 font-weight-Bold mar-b-20">
                      -
                      {this.state.testimonialData.name
                        ? this.state.testimonialData.name
                        : ""}
                    </h4>
                    <p className="fs-14 text-black opa-40 font-weight-Regular">
                      {this.state.testimonialData.subContent
                        ? this.state.testimonialData.subContent
                        : ""}{" "}
                      {this.state.testimonialData.subContent2
                        ? this.state.testimonialData.subContent2
                        : ""}
                    </p>

                    <p className="fs-14 text-black opa-40 font-weight-Regular mb-0">
                      Left 43 memories for 4 of his family members
                      </p>
                    
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Modal
          className={modal}
          open={this.state.setOpen}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="myCusTomModal d-flex">
            <div className="card">
              <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 ">
                  <i className="material-icons text-color1 align-middle">
                    person_add
                  </i>{" "}
                  Create a Contact
                </h3>
              </div>
              <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  1. What is the name of this person?
                </h3>
                <div className="row">
                  <div className="mar-b-40 col-md-6">
                    <TextField
                      label="First Name"
                      variant="outlined"
                      className="w-100"
                      name="firstName"
                      value={this.state.formData.firstName}
                      onChange={this.handleChange}
                      required
                      error={Boolean(this.state.error.firstName)}
                      helperText={this.state.error.firstName}
                    />
                  </div>
                  <div className="mar-b-40 col-md-6">
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      className="w-100"
                      name="lastName"
                      value={this.state.formData.lastName}
                      onChange={this.handleChange}
                      required
                      error={Boolean(this.state.error.lastName)}
                      helperText={this.state.error.lastName}
                    />
                  </div>
                </div>

                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  2. What is your relationship with this person?
                </h3>
                <div className="row">
                  <div className="mar-b-40 col-md-12">
                    <FormControl variant="outlined" className={"w-100"}>
                      <InputLabel id="demo-simple-select-label">
                        Relationship *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="relationship_id"
                        label="Select your limit"
                        value={this.state.formData.relationship_id}
                        onChange={this.handleChange}
                        error={Boolean(this.state.error.relationship_id)}
                        //helperText={this.state.error.relationship_id}
                      >
                        {allRelationshipType.map(
                          (singleRel, singleRelIndex) => (
                            <MenuItem
                              value={
                                singleRel.name === "Other" ||
                                singleRel.name === "other"
                                  ? singleRel._id
                                  : singleRel._id
                              }
                              key={singleRelIndex}
                              data-label={singleRel.name}
                              onClick={() =>
                                this.handleUpdatelabel(singleRel.name)
                              }
                            >
                              {singleRel.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {Boolean(this.state.error.relationship_id) && (
                        <FormHelperText className="Mui-error Mui-error">
                          {this.state.error.relationship_id}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>

                {this.state.is_show_other_relationship_type === true && (
                  <>
                    <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                      Other relationship type
                    </h3>
                    <div className="row">
                      <div className="mar-b-40 col-md-12">
                        <TextField
                          label=""
                          variant="outlined"
                          className="w-100"
                          name="nature_of_relationship"
                          value={this.state.formData.nature_of_relationship}
                          onChange={this.handleChange}
                          placeholder="Other"
                          error={Boolean(
                            this.state.error.nature_of_relationship
                          )}
                          helperText={this.state.error.nature_of_relationship}
                        />
                        {/* <FormControl variant="outlined" className={"w-100"}>
                          <InputLabel id="demo-simple-select-label">
                            Other Relationship
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            name="other_relationship_id"
                            label="Select your limit"
                            value={this.state.formData.other_relationship_id}
                            onChange={this.handleChange}
                          >
                            {otherRelationshipType.map(
                              (singleRel, singleRelIndex) => (
                                <MenuItem
                                  value={singleRel._id}
                                  key={singleRelIndex}
                                >
                                  {singleRel.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl> */}
                      </div>
                    </div>
                  </>
                )}

                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  3. Contact details of the person
                </h3>
                <div className="row">
                  <div className=" mar-b-40 col-md-12">
                    <TextField
                      required
                      label="Email Address"
                      name="email"
                      onChange={this.handleChange}
                      variant="outlined"
                      className="w-100"
                      value={this.state.formData.email}
                      error={Boolean(this.state.error.email)}
                      helperText={this.state.error.email}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "-15px" }}>
                  <h3
                    className="mar-b-22 fs-18 font-weight-Regular text-center"
                    style={{ color: "grey" }}
                  >
                    OR
                  </h3>
                </div>

                {/* <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  4. Add a phone number -
                </h3> */}
                <div className="row">
                  <div className=" mar-b-40 col-md-4">
                    <FormControl variant="outlined" className={"w-100"}>
                      <InputLabel id="demo-simple-select-label">
                        Country Code
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="country_code"
                        label="Select your limit"
                        onChange={(e) =>
                          this.setState({
                            currentCountryCode: e.target.value,
                          })
                        }
                        value={this.state.currentCountryCode}
                      >
                        {countryCode.map((singleCode, singleCodeIndex) => (
                          <MenuItem
                            value={singleCode.code}
                            key={singleCodeIndex}
                          >
                            {singleCode.code} - {singleCode.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-8 pt-md-0">
                    <TextField
                      label="Phone Number"
                      name="phone"
                      onChange={this.handleChange}
                      variant="outlined"
                      className="w-100"
                      value={this.state.formData.phone}
                      error={Boolean(this.state.error.phone)}
                      helperText={this.state.error.phone}
                    />
                  </div>
                </div>

                <h3 className="text-black mar-b-22 margin-top-30 fs-18 font-weight-Regular">
                  4. Create a customized invitation{" "}
                  <span style={{ fontWeight: "300", fontStyle: "italic" }}>
                    (optional)
                  </span>{" "}
                  -
                </h3>
                <div className="row">
                  <div className="mar-b-40 col-md-12">
                    <TextField
                      multiline
                      rows={4}
                      name="customInvitation"
                      onChange={this.handleChange}
                      label="Write a message"
                      variant="outlined"
                      className="w-100"
                      value={this.state.formData.customInvitation}
                      inputProps={{
                        maxlength: 250,
                      }}
                    />
                    <p className="fs-12  text-black opa-60 text-right">
                      {this.state.formData.customInvitation.length}/250
                    </p>
                  </div>
                </div>

                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  5. Set contact photo{" "}
                  <span style={{ fontWeight: "300", fontStyle: "italic" }}>
                    (optional)
                  </span>
                  -
                </h3>
                <div className="row">
                  <div className="mar-b-40 col-md-12">
                    <input
                      hidden
                      ref={(contactPhoto) => (this.contactPhoto = contactPhoto)}
                      type="file"
                      accept="image/*"
                      onChange={(e) => this.fileUploadChange(e)}
                    />
                    <div
                      className="d-inline-flex cp align-items-center fs-19"
                      onClick={this.setContactPhoto}
                    >
                      <div>
                        <img
                          src={
                            this.state.profilePreviewImage !== "" &&
                            this.state.profilePreviewImage !== null &&
                            this.state.profilePreviewImage !== undefined
                              ? this.state.profilePreviewImage
                              : UIco
                          }
                          alt=""
                          className="w-60px h-60px object-fit-cover rounded-circle"
                        />
                      </div>
                      <div className="pl-2 text-primary font-weight-Medium">
                        Upload photo
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white text-right pad-x-40 ">
                {this.state.isSubmitForm1 === true && <CircularProgress />}

                {this.state.isSubmitForm1 === false && (
                  <>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize"
                      onClick={this.handleClose.bind(this)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={
                        "text-color1 fs-19 font-weight-Medium text-capitalize background_color"
                      }
                      //disabled={!this.state.validEmail}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
