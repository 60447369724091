import { Button, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";
import Icon from "../assets/images/icon9.svg";
import PrimaryHeader from "../Widgets/PrimaryHeader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as paymentActions from "../redux/actions/paymentActions";

const anualPlanPrice = [
  { plan: "Free", price: 0, contact: 1, space: 0.5 },
  { plan: "Basic", price: 30.99, contact: 5, space: 3 },
  { plan: "Advanced", price: 70.99, contact: 15, space: 30 },
];

class RegisterStepFour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planType: "annual",

      isanualPlanListFetched: false,
      anualPlanList: [],
      monthlyPlanList: [],
      couponcode: "",
      isCouponcodeapplied: false,
      couponCodepercentage: 0,
      appliedCouponcode: "",
    };
  }

  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");
    let registration_current_step = localStorage.getItem(
      "registration_current_step"
    );
    let registration_info = localStorage.getItem("registration_info");
    let reginfo_obj = JSON.parse(registration_info);
    if (this.props.miscReducerData.step != "") {
    } else {
      if (parseInt(registration_current_step) !== 4) {
        this.props.history.push("/sign-up");
      } else {
        if (
          auth_token === "" ||
          auth_token === undefined ||
          auth_token === null
        ) {
        } else {
          this.props.history.push("/");
        }
      }
    }

    /*fetch plans list */
    if (reginfo_obj?.no_of_contacts && reginfo_obj?.no_of_contacts >= 1) {
      this.props.miscActions.fetchFlans({
        no_of_contacts: reginfo_obj?.no_of_contacts,
        type: "Monthly",
      });

      // this.props.miscActions.fetchFlans({
      //   no_of_contacts: reginfo_obj?.no_of_contacts,
      //   type: "Annual",
      // });

    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.FETCH_PLANS_SUCCESS:
          let planList =
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : [];

          if (state.isanualPlanListFetched === false) {
            setTimeout(function () {
              let registration_info = localStorage.getItem("registration_info");
              let reginfo_obj = JSON.parse(registration_info);
              if (
                reginfo_obj?.no_of_contacts &&
                reginfo_obj?.no_of_contacts >= 1
              ) {
                props.miscActions.fetchFlans({
                  no_of_contacts: reginfo_obj?.no_of_contacts,
                  type: "Annual",
                });
              }
            }, 2000);
            return {
              monthlyPlanList: planList,
              isanualPlanListFetched: true,
            };
          } else {
            props.miscActions.clearState();
            return {
              anualPlanList: planList,
            };
          }

          break;

        case miscActions.FETCH_PLANS_FAILED:
          props.miscActions.clearState();
          return {};
          break;
      }
    }

    if (Object.keys(props.paymentReducerData).length > 0) {
      let { paymentReducerData } = props;
      switch (paymentReducerData.type) {
        case paymentActions.FETCH_COUPONCODE_SUCCESS:
          props.miscActions.clearState();
          let BlankArray = [];
          let BlankArray1 = [];
          let couponDetails =
            paymentReducerData.responce !== undefined &&
            paymentReducerData.responce.data !== undefined &&
            paymentReducerData.responce.data.data !== undefined
              ? paymentReducerData.responce.data.data
              : [];

          state.anualPlanList.forEach((value) => {
            var newarr = value;
            let DiscountPercentage = couponDetails.value;
            newarr["discountValue"] = (value.value * DiscountPercentage) / 100;
            newarr["updatedvalue"] =
              value.value == 0
                ? 0
                : (
                    value.value -
                    (value.value * DiscountPercentage) / 100
                  ).toFixed(2);
            BlankArray.push(newarr);
            //BlankArray.push({updatedValue:250})
          });

          state.monthlyPlanList.forEach((value) => {
            var newarr = value;
            let DiscountPercentage = couponDetails.value;
            newarr["discountValue"] = (value.value * DiscountPercentage) / 100;
            newarr["updatedvalue"] =
              value.value == 0
                ? 0
                : (
                    value.value -
                    (value.value * DiscountPercentage) / 100
                  ).toFixed(2);
            BlankArray1.push(newarr);
            //BlankArray.push({updatedValue:250})
          });
          var appliedCouponcode = couponDetails.code;
          props.paymentActions.clearState();

          return {
            couponCodepercentage: couponDetails.value,
            anualPlanList: BlankArray,
            monthlyPlanList: BlankArray1,
            isCouponcodeapplied: true,
            appliedCouponcode: appliedCouponcode,
          };

          break;

        case paymentActions.FETCH_COUPONCODE_FAILED:
          props.paymentActions.clearState();
          let errorMessage =
            paymentReducerData.responce !== undefined &&
            paymentReducerData.responce.data.message !== undefined
              ? paymentReducerData.responce.data.message
              : "";
          // if (errorMessage != "") {
          //   toast.error(errorMessage);
          // }

          //props.paymentActions.clearState();
          return {};
          break;
      }
    }

    return {};
  }

  changePlan(data) {
    this.setState({ planType: data });
  }

  selectPlan(plan) {
    var plan_id = plan._id;
    if (plan_id === "") {
      toast.error("Something went wrong! please try again");
    } else {
      let registerData = localStorage.getItem("registration_info");
      if (
        registerData === "" ||
        registerData === undefined ||
        registerData === null
      ) {
        toast.error("Something went wrong! please try again");
        this.props.history.push("/sign-up");
      } else {
        let registerObj = JSON.parse(registerData);
        registerObj["plan_id"] = plan_id;
        if (
          this.state.isCouponcodeapplied &&
          this.state.appliedCouponcode != ""
        ) {
          registerObj["coupon_code"] = this.state.appliedCouponcode;
          registerObj["code"] = this.state.appliedCouponcode;
        } else {
          delete registerObj["coupon_code"];
          delete registerObj["code"];
        }
        registerObj["plan_id"] = plan_id;
        localStorage.setItem("registration_info", JSON.stringify(registerObj));
        localStorage.setItem("registration_current_step", 5);
        localStorage.setItem("selected_plan_details", JSON.stringify(plan));

        this.props.history.push("/sign-up-step-five");
      }
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleUpdatecoupon = () => {
    if (this.state.couponcode == "") {
      toast.error("Please add coupon code");
    } else {
      let BlankArray = [];
      const formData = new FormData();
      formData.append("code", this.state.couponcode);
      this.props.paymentActions.fetchCouponcode(formData);
    }
  };

  handleClearcoupon = () => {
    let BlankArray = [];
    let BlankArray1 = [];
    this.state.anualPlanList.forEach((value) => {
      var newarr = value;
      delete newarr["discountValue"];
      delete newarr["updatedvalue"];
      BlankArray.push(newarr);
      //BlankArray.push({updatedValue:250})
    });

    this.state.monthlyPlanList.forEach((value) => {
      var newarr = value;
      delete newarr["discountValue"];
      delete newarr["updatedvalue"];
      BlankArray1.push(newarr);
      //BlankArray.push({updatedValue:250})
    });

    this.setState(
      {
        anualPlanList: BlankArray,
        monthlyPlanList: BlankArray1,
        couponcode: "",
        isCouponcodeapplied: false,
      },
      () => {
        console.log(this.state.isCouponcodeapplied);
      }
    );
  };

  render() {
    var allPlans = this.state.anualPlanList;
    var monthlyPlans = this.state.monthlyPlanList;

    //console.log(monthlyPlans);
    const SearchButton = () => (
      <Button
        variant="contained"
        onClick={this.handleUpdatecoupon}
        style={{background:'#71c4c8',color:'#fff'}}
      >
        Apply
      </Button>
    );

    const ClearButton = () => (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleClearcoupon}
      >
        Clear
      </Button>
    );
    //console.log("PLan:", allPlans);
    return (
      <>
        <div className="card bg-transparent border-0 vh-100 overflow-auto">
          <PrimaryHeader position={"innerHeader"} />
          <section className=" registerStepWrap h-100 overflow-auto bluebgsection">
            <div className="container">
              <div className="row">
                <div className="card bigCard border-0  mx-auto">
                  <div className="head text-right mr-md-n4">
                    <h5 className="mb-0">Step 4 of 5</h5>
                  </div>

                  <div className=" ">
                    <div className="iconBox mar-b-15 mar-b-md-35">
                      <img src={Icon} alt="" className="w-50px w-md-72px" />
                    </div>
                    <div className="fs-22 fs-md-36 font-weight-Bold ">
                    Select Plan
                    </div>
                    <div
                      className="fs-14 fs-md-18 coupon_code_block"
                      style={{ float: "right", marginBottom: "-16px" }}
                    >
                      <p style={{marginBottom:'1px',color:'#a3a3a3'}}>Apply your coupon</p>
                      <TextField
                        id="standard-name"
                        placeholder="Coupon code"
                        value={this.state.couponcode}
                        variant="outlined"
                        InputProps={{
                          endAdornment: this.state.isCouponcodeapplied ? (
                            <ClearButton />
                          ) : (
                            <SearchButton />
                          ),
                        }}
                        onChange={this.handleChange}
                        name="couponcode"
                        className="counpon_input"
                      />
                    </div>
                    <div className="subHead fs-14 fs-md-18 mar-b-18">
                      Choose the plan that suits you.
                    </div>

                    <div className="w-100">
                      <div className="border-bottom mb-4 ">
                        <div className="">
                          <div className="d-inline-block mr-2 mar-r-sm-70">
                            <Button
                              className={
                                "btn px-0 fs-14 fs-xs-18 shadow-none  " +
                                (this.state.planType === "annual"
                                  ? "text-primary font-weight-Bold"
                                  : "text-secondary")
                              }
                              onClick={() => this.changePlan("annual")}
                              style={{ cursor: "default" }}
                            >
                              Annual (Save 10%)
                            </Button>
                            <div
                              className={
                                "h-7px " +
                                (this.state.planType === "annual"
                                  ? "bg-primary"
                                  : "")
                              }
                              style={{ borderRadius: "5px 5px 0px 0px" }}
                            ></div>
                          </div>
                          <div className="d-inline-block">
                            <Typography
                              className={
                                "btn px-0 fs-14 fs-xs-18 shadow-none  " +
                                (this.state.planType === "Monthly"
                                  ? "text-primary font-weight-Bold"
                                  : "text-secondary")
                              }
                              onClick={() => this.changePlan("Monthly")}
                              style={{cursor:'inherit'}}
                            >
                              Monthly
                            </Typography>
                            <div
                              className={
                                "h-7px " +
                                (this.state.planType === "Monthly"
                                  ? "bg-primary"
                                  : "")
                              }
                              style={{ borderRadius: "5px 5px 0px 0px" }}
                            ></div>
                          </div>
                        </div>
                      </div>

                      {/* <div className={'tableBlock'}>
                        {this.state.planType === "annual" &&
                          allPlans.length > 0 && (
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col min-w-150">Package</th>
                                  <th scope="col">Price </th>
                                  <th scope="col min-w-150">Feature</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allPlans.map((list, index) => (
                                  <tr key={index}>
                                    <td className="min-w-150">
                                      <span className="rounded-pill border d-inline-block px-4 py-1">
                                        {list.header}
                                      </span>
                                      {list.value == 0 && (
                                        <p
                                          style={{
                                            marginLeft: "6px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          Duration: {list.duration}
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      <h4 className="line-height-1">
                                        {list.discountValue && (
                                          <span>US$ {list.updatedvalue} </span>
                                        )}
                                        {!list.discountValue &&
                                          list.discountValue != "" && (
                                            <span>US$ {list.value} </span>
                                          )}
                                        <sub className="fs-14">
                                          /{" "}
                                          {list?.type === "Annual"
                                            ? "Year"
                                            : "Month"}
                                        </sub>
                                      </h4>
                                    </td>

                                    <td className="min-w-150">
                                      <ul className="list-unstyled text-color5">
                                        {list.line_items.map(
                                          (
                                            single_line_itm,
                                            single_line_indx
                                          ) => (
                                            <li key={single_line_indx}>
                                              ✓ {single_line_itm}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </td>
                                    <td>
                                      <button
                                        onClick={(e) => this.selectPlan(list)}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text bg-primary pad-y-10 text-white w-100"
                                        tabindex="0"
                                        type="button"
                                      >
                                        <span className="MuiButton-label">
                                          Select Plan
                                        </span>
                                        <span className="MuiTouchRipple-root"></span>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}

                        {!this.state.isanualPlanListFetched ? (
                          <div className="text-center">
                            <CircularProgress size={30} />
                          </div>
                        ) : (
                          this.state.planType === "annual" &&
                          allPlans.length === 0 && (
                            <div className="alert alert-info" role="alert">
                              Sorry, No plans found
                            </div>
                          )
                        )}
                        {this.state.planType === "Monthly" &&
                          monthlyPlans.length > 0 && (
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col min-w-150">Package</th>
                                  <th scope="col">Price </th>
                                  <th scope="col min-w-150">Feature</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {monthlyPlans.map((list, index) => (
                                  <>
                                  <tr><td class="min-w-150"></td></tr>
                                    <tr key={index} className="TABLEtR">
                                      <td className="min-w-150">
                                        <span className="rounded-pill border d-inline-block px-4 py-1">
                                          {list.header}
                                        </span>
                                        {list.value == 0 && (
                                          <p
                                            style={{
                                              marginLeft: "6px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            Duration: {list.duration}
                                          </p>
                                        )}
                                      </td>
                                      <td>
                                        <h4 className="line-height-1">
                                          {list.discountValue && (
                                            <span>
                                              US${" "}
                                              {list.updatedvalue == 0
                                                ? 0
                                                : list.updatedvalue}{" "}
                                            </span>
                                          )}
                                          {!list.discountValue &&
                                            list.discountValue != "" && (
                                              <span>US$ {list.value} </span>
                                            )}
                                          <sub className="fs-14">/ Month</sub>
                                        </h4>
                                      </td>

                                      <td className="min-w-150">
                                        <ul className="list-unstyled text-color5">
                                          {list.line_items.map(
                                            (
                                              single_line_itm,
                                              single_line_indx
                                            ) => (
                                              <li key={single_line_indx}>
                                                ✓ {single_line_itm}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                      <td>
                                        <button
                                          onClick={(e) => this.selectPlan(list)}
                                          className="MuiButtonBase-root MuiButton-root MuiButton-text bg-primary pad-y-10 text-white w-100"
                                          tabindex="0"
                                          type="button"
                                        >
                                          <span className="MuiButton-label">
                                            Select Plan
                                          </span>
                                          <span className="MuiTouchRipple-root"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    {list.closest_plan != "" &&
                                      list.closest_plan != undefined &&
                                      list.closest_plan != null && (
                                        <tr
                                          key={list.closest_plan.id}
                                          className={"fold open"}
                                        >
                                          <td className="min-w-150">
                                            <span className="rounded-pill border d-inline-block px-4 py-1">
                                              {list.closest_plan.header}
                                            </span>
                                            {list.value == 0 && (
                                              <p
                                                style={{
                                                  marginLeft: "6px",
                                                  marginTop: "10px",
                                                }}
                                              >
                                                Duration:{" "}
                                                {list.closest_plan.duration}
                                              </p>
                                            )}
                                          </td>
                                          <td>
                                            <h4 className="line-height-1">
                                              {list.closest_plan
                                                .discountValue && (
                                                <span>
                                                  US${" "}
                                                  {list.closest_plan
                                                    .updatedvalue == 0
                                                    ? 0
                                                    : list.closest_plan
                                                        .updatedvalue}{" "}
                                                </span>
                                              )}
                                              {!list.closest_plan
                                                .discountValue &&
                                                list.closest_plan
                                                  .discountValue != "" && (
                                                  <span>
                                                    US${" "}
                                                    {list.closest_plan.value}{" "}
                                                  </span>
                                                )}
                                              <sub className="fs-14">
                                                / Year
                                              </sub>
                                            </h4>
                                          </td>

                                          <td className="min-w-150">
                                            <ul className="list-unstyled text-color5">
                                              {list.closest_plan.line_items.map(
                                                (
                                                  single_line_itm,
                                                  single_line_indx
                                                ) => (
                                                  <li key={single_line_indx}>
                                                    ✓ {single_line_itm}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </td>
                                          <td>
                                            <button
                                              onClick={(e) =>
                                                this.selectPlan(
                                                  list.closest_plan
                                                )
                                              }
                                              className="MuiButtonBase-root MuiButton-root MuiButton-text bg-primary pad-y-10 text-white w-100"
                                              tabindex="0"
                                              type="button"
                                            >
                                              <span className="MuiButton-label">
                                                Select Plan
                                              </span>
                                              <span className="MuiTouchRipple-root"></span>
                                            </button>
                                          </td>
                                        </tr>
                                      )}
                                  </>
                                ))}
                              </tbody>
                            </table>
                          )}

                        {this.state.planType === "Monthly" &&
                          monthlyPlans.length === 0 && (
                            <div className="alert alert-info" role="alert">
                              Sorry, No monthly plans found
                            </div>
                          )}
                      </div> */}

                      {this.state.planType === 'annual' &&
                                                <div className="row animate__animated animate__fadeIn">
                                                    {allPlans.map((list, index) =>
                                                        <div className="col-md-4 mb-4" key={index}>
                                                            <div className="d-flex flex-column p-4 planBox">
                                                                <div className="mb-3">
                                                                    <span className="rounded-pill border d-inline-block px-4 py-1">{list.header}</span>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <h4 className="line-height-1">US$ {list.value} <sub className="fs-14" style={{ bottom: '0' }}>/ Year</sub></h4>
                                                                    <h5 className="text-color4 fs-18 font-weight-Regular">{list.subheader}</h5>
                                                                </div>
                                                                <div>
                                                                    <ul className="list-unstyled text-color5">
                                                                        {list.line_items.map((single_line_itm, single_line_indx) =>
                                                                            <li>✓ {single_line_itm}</li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        className="bg-primary pad-y-10 text-white w-100"
                                                                        onClick={(e) => this.selectPlan(list)}
                                                                    >Select Plan</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {this.state.planType === 'Monthly' &&
                                                <div className="row animate__animated animate__fadeIn">
                                                    {monthlyPlans.map((list, index) =>
                                                        <div className="col-md-4 mb-4" key={index}>
                                                            <div className="d-flex flex-column p-4 planBox">
                                                                <div className="mb-3">
                                                                    <span className="rounded-pill border d-inline-block px-4 py-1">{list.header}</span>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <h4 className="line-height-1">US$ {list.value} <sub className="fs-14" style={{ bottom: '0' }}>/ Month</sub></h4>
                                                                    <h5 className="text-color4 fs-18 font-weight-Regular">{list.subheader}</h5>
                                                                </div>
                                                                <div>
                                                                    <ul className="list-unstyled text-color5">
                                                                        {list.line_items.map((single_line_itm, single_line_indx) =>
                                                                            <li>✓ {single_line_itm}</li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        className="bg-primary pad-y-10 text-white w-100"
                                                                        onClick={(e) => this.selectPlan(list)}
                                                                    >Select Plan</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                    </div>

                    <div className="info">
                      We’ll be billing from your account only. Your contacts wont have to pay.
                    </div>

                    <div
                      className="w-100 pad-t-50"
                      style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        type="submit"
                        className="bg-color4 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                        onClick={() => {
                          this.props.history.push("/sign-up-step-three");
                        }}
                      >
                        Back
                      </Button>
                    </div>

                    <div className="w-100 pad-t-50">
                      {/* <Button
                                                className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                                                onClick={() => window.location.href = "/sign-up-step-three"}
                                            >Next</Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    paymentReducerData: state.paymentReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  paymentActions: bindActionCreators(paymentActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStepFour);
