import { Button } from "@material-ui/core";
import React, { Component } from "react";

import Icon from "../assets/images/icon8.svg";
import PrimaryHeader from "../Widgets/PrimaryHeader";

import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

import {  toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-toastify/dist/ReactToastify.css";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";

class RegisterStepThreePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allEventTypes: [],
      loading : true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");
    let registration_current_step = localStorage.getItem(
      "registration_current_step"
    );
    if (this.props.miscReducerData.step != "") {
      // this.setState({
      //   allEventTypes: JSON.parse(localStorage.getItem("registration_info"))
      //     .event_memories_id,
      // });
    } else {
      if (parseInt(registration_current_step) !== 3) {
        this.props.history.push("/sign-up");
      } else {
        if (
          auth_token === "" ||
          auth_token === undefined ||
          auth_token === null
        ) {
        } else {
          this.props.history.push("/");
        }
      }
    }

    /*fetch event type list */
    this.props.miscActions.fetchEventTypeList();
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.FETCH_EVENT_TYPE_LIST_SUCCESS:
          let eventTypes =
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : [];

          let allEventTypes = [];
          if (eventTypes.length > 0) {
            eventTypes.forEach(function (singleEvent, singleIndx) {
              if (props.miscReducerData.step != "") {
                if (JSON.parse(
                  localStorage.getItem("registration_info")
                ).event_memories_id && 
                  JSON.parse(
                    localStorage.getItem("registration_info")
                  ).event_memories_id.includes(singleEvent._id)
                ) {

                  var temp = {
                    id: singleEvent._id,
                    name: singleEvent.title,
                    isSelected: true,
                  };
                  allEventTypes.push(temp);


                } else {
                  if (singleEvent.isDeleted === false) {
                    var temp = {
                      id: singleEvent._id,
                      name: singleEvent.title,
                      isSelected: singleEvent.selectByDefault,
                    };
                    allEventTypes.push(temp);
                  }
                }
              } else {
                if (singleEvent.isDeleted === false) {
                  var temp = {
                    id: singleEvent._id,
                    name: singleEvent.title,
                    isSelected: singleEvent.selectByDefault,
                  };
                  allEventTypes.push(temp);
                }
              }
            });
          }

          props.miscActions.clearState();

          return {
            allEventTypes: allEventTypes,
            loading : false
          };
          break;

        case miscActions.FETCH_EVENT_TYPE_LIST_FAILD:
          props.miscActions.clearState();
          return {};
          break;
      }
    }

    return {};
  }

  selectEventType(index) {
    let allEventTypes = this.state.allEventTypes;

    if (allEventTypes.length > 0) {
      allEventTypes.forEach(function (itm, indx) {
        if (index === indx) {
          itm.isSelected = !itm.isSelected;
        }
      });
    }

    this.setState({
      allEventTypes: allEventTypes,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    let isValidate = false;
    let selectedEvents = [];
    let allEventTypes = this.state.allEventTypes;
    if (allEventTypes.length > 0) {
      allEventTypes.forEach(function (itm, indx) {
        if (itm.isSelected === true) {
          isValidate = true;
          selectedEvents.push(itm.id);
        }
      });
    }

    if (isValidate === false) {
      toast.error("Please select an event type");
    } else {
      //console.log('selectedEvents',selectedEvents)

      let registerData = localStorage.getItem("registration_info");
      if (
        registerData === "" ||
        registerData === undefined ||
        registerData === null
      ) {
        toast.error("Something went wrong! please try again");
        this.props.history.push("/sign-up");
      } else {
        let registerObj = JSON.parse(registerData);
        registerObj["event_memories_id"] = selectedEvents;
        localStorage.setItem("registration_info", JSON.stringify(registerObj));
        localStorage.setItem("registration_current_step", 4);
        this.props.miscActions.Updatestep(4);

        this.props.history.push("/sign-up-step-four");
      }
    }
  }

  render() {
    console.log(this.state.allEventTypes);
    return (
      <>
        <div className="card bg-transparent border-0 vh-100 overflow-auto">
          <PrimaryHeader position={"innerHeader"} />
          <section className=" registerStepWrap h-100  overflow-auto bluebgsection">
            <div className="container">
              <div className="row">
                <div className="card border-0  mx-auto">
                  <div className="head text-right  mr-md-n4">
                    <h5 className="mb-0">Step 3 of 5</h5>
                  </div>

                  <div className=" ">
                    <div className="iconBox mar-b-15 mar-b-md-35">
                      <img src={Icon} alt="" className="w-50px w-md-70px" />
                    </div>
                    <div className="fs-22 fs-md-36 font-weight-Bold  ">
                      Events
                    </div>
                    <div className="subHead fs-14 fs-md-18 mar-b-30">
                      Select the desired events and occasions.
                    </div>
                                           
                    <form onSubmit={this.handleSubmit}>
                      {
                      this.state.loading === true ? 
                          
                          <div className="text-center mb-3">
                            <CircularProgress />
                          </div> 
                          :
                      <div className="">
                        <ul className="list-unstyled d-flex flex-wrap">
                          {this.state.allEventTypes.map((list, index) => (
                            <li className="p-2" key={index}>
                              {list.isSelected === true && (
                                <Chip
                                  icon={<DoneIcon  style={{color:'#fff'}} />}
                                  label={list.name}
                                  clickable
                                  onClick={(e) => this.selectEventType(index)}
                                  style={{background:'#71c4c8',color:'#fff'}}
                                />
                              )}

                              {list.isSelected === false && (
                                <Chip
                                  label={list.name}
                                  clickable
                                  onClick={(e) => this.selectEventType(index)}
                                />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                      }
                      <div className="info">
                       That's not all! You can add more personalized <br />
                       events later.
                      </div>
                      <div
                        className="w-100 pad-t-50"
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          type="submit"
                          className="bg-color4 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                          onClick={() => {
                            this.props.history.push("/sign-up-step-two");
                          }}
                        >
                          Back
                        </Button>
                        {/*<Button
                                                    className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                                                    onClick={() => window.location.href = "/sign-up-step-four"}
                                                >Next</Button>*/}

                        <Button
                          type="submit"
                          className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                        >
                          Next
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStepThreePage);
