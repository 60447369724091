import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import UserIcon from "../assets/images/icon21.svg";
import EventIcon from "../assets/images/icon22.svg";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadURLS from "../assets/settings";
import { Link, NavLink } from "react-router-dom";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import moment from "moment";

const modal = "d-flex align-items-center";

class ViewContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      eventList: [],
      currentContactInfo: "",
      isLoading: true,

      formData: {
        title: "",
        recuringEvent: "",
        eventDay: "",
        eventMonth: "",
        eventYear: "",
        dateUnknown: false,
      },

      isDisabledSubmit: false,

      isSubmitForm: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleOpen = () => {
    this.setState({ setOpen: true });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    if (fieldName === "eventMonth") {
      if (fieldValue === "02") {
        if (formData.eventDay > 29) {
          formData['eventDay'] =29;
        } else if (formData.eventDay < 1) {
          formData['eventDay'] =1;
        }
      }
    }

    if (fieldName === "dateUnknown") {
      fieldValue = event.target.checked;
    }

    if (fieldName === "recuringEvent" && fieldValue === "true") {
      fieldValue = true;
    } else if (fieldName === "recuringEvent" && fieldValue === "false") {
      fieldValue = false;
    }

    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
      isDisabledSubmit: false,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    var formData = this.state.formData;

    if (
      formData.title === "" ||
      formData.title === undefined ||
      formData.title === null
    ) {
      toast.error("Event name can not be blank");
    } else if (
      formData.recuringEvent === "" ||
      formData.recuringEvent === undefined ||
      formData.recuringEvent === null
    ) {
      toast.error("Please select 'Is this a recurring event or not'");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventDay === "" ||
        formData.eventDay === null ||
        formData.eventDay === undefined)
    ) {
      toast.error("Please enter event day");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventMonth === "" ||
        formData.eventMonth === null ||
        formData.eventMonth === undefined)
    ) {
      toast.error("Please select an event month");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventYear === "" ||
        formData.eventYear === null ||
        formData.eventYear === undefined)
    ) {
      toast.error("Please enter event year");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      !moment(
        `${formData.eventYear}-${formData.eventMonth}-${formData.eventDay}`
      ).isValid()
    ) {
      toast.error("Please change event day");
    } else {
      if (formData.dateUnknown === "") {
        formData.dateUnknown = false;
      }

      var currentContactId =
        this.props.match !== undefined &&
        this.props.match.params !== undefined &&
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "";
      formData["user_contact_id"] = currentContactId;

      this.setState({
        isSubmitForm: true,
      });
      this.props.contactActions.createNewEvent(formData);
    }
  }

  componentDidMount() {
    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null
    ) {
      this.props.history.push("/");
    } else {
      /* Fetch user all contact list */
      this.props.contactActions.getUserContactList();
    }
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        /* FETCH ALL CONTACT INFO */
        case contactActions.GET_USER_CONTACT_LIST_SUCCESS:
          var currentContactInfo = "";
          var currentContactId =
            props.match !== undefined &&
            props.match.params !== undefined &&
            props.match.params.id !== undefined
              ? props.match.params.id
              : "";
          var contactList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];

          if (contactList.length > 0 && currentContactId !== "") {
            contactList.forEach(function (
              singleContactInfo,
              singleContactIndx
            ) {
              if (currentContactId === singleContactInfo._id) {
                currentContactInfo = singleContactInfo;
              }
            });
          }
          props.contactActions.clearState();

          if (currentContactInfo !== "") {
            props.contactActions.fetchEventListByContactID(currentContactId);
          }

          return {
            currentContactInfo: currentContactInfo,
          };
          break;
        case contactActions.GET_USER_CONTACT_LIST_FAILED:
          return {
            currentContactInfo: "",
          };
          break;

        /* Fetch all event list */
        case contactActions.FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS:
          var eventList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];
          props.contactActions.clearState();
          return {
            eventList: eventList,
            isLoading: false,
          };
          break;
        case contactActions.FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED:
          return {
            eventList: [],
            isLoading: false,
          };
          break;

        /* Create New event */
        case contactActions.CREATE_NEW_EVENT_SUCCESS:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          var data =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined &&
            contactReducerData.responce.data.data !== ""
              ? contactReducerData.responce.data.data
              : "";
          props.contactActions.clearState();

          var currentContactId =
            props.match !== undefined &&
            props.match.params !== undefined &&
            props.match.params.id !== undefined
              ? props.match.params.id
              : "";
          if (currentContactId !== "") {
            props.contactActions.fetchEventListByContactID(currentContactId);
          }

          var formData = {
            title: "",
            recuringEvent: "",
            eventDay: "",
            eventMonth: "",
            eventYear: "",
            dateUnknown: "",
          };

          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            formData: formData,
            setOpen: false,
            isSubmitForm: false,
          };
          break;

        case contactActions.CREATE_NEW_EVENT_FAILED:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          props.contactActions.clearState();
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmitForm: false,
          };
        default:
          return {};
      }
    }

    return {};
  }

  render() {
    var currentContactInfo = this.state.currentContactInfo;
    var isLoading = this.state.isLoading;
    var eventList = this.state.eventList;

    var formData = this.state.formData;

    //Year for loop
    let d = new Date();
    let rows = [];
    for (let i = 1900; i <= d.getFullYear(); i++) {
      rows.push(i);
    }

    return (
      <>
        <Helmet>
          <title>Forget Me Not - View Contact</title>
        </Helmet>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>

          {currentContactInfo !== undefined &&
            currentContactInfo !== null &&
            currentContactInfo !== "" &&
            (isLoading === true ? (
              <div className="text-center mt-5">
                <CircularProgress />
              </div>
            ) : (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="card border-0 border-radius-15 overflow-hidden min-vh-75">
                    <div className="card-header pad-x-20 pad-x-md-46 pad-y-25 bg-white">
                      <div className="d-md-flex justify-content-between">
                        <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                          <img
                            className="object-fit-cover w-55px h-55px  rounded-circle"
                            src={
                              currentContactInfo.image !== undefined &&
                              currentContactInfo.image !== null &&
                              currentContactInfo.image !== ""
                                ? UploadURLS.contact_profile_pic +
                                  currentContactInfo.image
                                : UserIcon
                            }
                            alt=""
                          />
                          <span className="pl-2">
                            Memories with{" "}
                            {currentContactInfo.firstName !== undefined &&
                            currentContactInfo.firstName !== "" &&
                            currentContactInfo.firstName !== null
                              ? currentContactInfo.firstName
                              : ""}
                          </span>
                        </h3>

                        <div className="text-center pt-4 pt-md-0">
                          <Button
                            className="bg-color1 text-white fs-19 px-4 py-2 text-capitalize"
                            onClick={this.handleOpen.bind(this)}
                          >
                            <i className="material-icons mr-2">event</i> Create
                            New Event
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pad-x-20 pad-x-md-46 pad-y-25 ">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb bg-white p-0">
                          <li className="breadcrumb-item text-color1">
                            <Link to={"/dashboard"}>
                              {/* <i className="material-icons">home_outlined</i> */}
                              <HomeOutlinedIcon
                                style={{ fontSize: "22px", marginTop: "-4px" }}
                              />
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item text-color1 active"
                            aria-current="page"
                          >
                            {currentContactInfo.firstName !== undefined &&
                            currentContactInfo.firstName !== "" &&
                            currentContactInfo.firstName !== null
                              ? currentContactInfo.firstName
                              : ""}{" "}
                            {currentContactInfo.lastName !== undefined &&
                            currentContactInfo.lastName !== "" &&
                            currentContactInfo.lastName !== null
                              ? currentContactInfo.lastName
                              : ""}
                          </li>
                        </ol>
                      </nav>
                      <div className="row">
                        {eventList.length === 0 && (
                          <div
                            className="alert alert-primary w-100"
                            role="alert"
                          >
                            Sorry, no event found
                          </div>
                        )}

                        {eventList.length > 0 && (
                          <>
                            {eventList.map((singleEventlist, eventIndex) => (
                              <div
                                className=" col-lg-4 mar-b-30"
                                key={eventIndex}
                              >
                                <div
                                  onClick={() =>
                                    this.props.history.push(
                                      "/view-event/" + singleEventlist._id
                                    )
                                  }
                                  className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded"
                                >
                                  <div className="fs-28 fs-xxxl-36 font-weight-light text-black">
                                    {singleEventlist.title !== undefined &&
                                    singleEventlist.title !== null &&
                                    singleEventlist.title !== ""
                                      ? singleEventlist.title
                                      : ""}
                                  </div>
                                  <div className="">
                                    <img src={EventIcon} alt="" />{" "}
                                    <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                      {singleEventlist.eventMemories !==
                                        undefined &&
                                      singleEventlist.eventMemories !== null &&
                                      singleEventlist.eventMemories !== "" &&
                                      singleEventlist.eventMemories.length > 0
                                        ? singleEventlist.eventMemories.length +
                                          " Memories"
                                        : "Add Memories"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}

                        {/*<div className=" col-lg-4 mar-b-30">
                                                <div onClick={()=> window.location.href="/view-event"} className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded">
                                                    <div className="fs-28 fs-xxxl-36 font-weight-light text-black">New Year</div>
                                                    <div className=""><img src={EventIcon} alt="" /> <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">Add memories to this event</span></div>
                                                </div>
                                            </div>
                                            <div className=" col-lg-4 mar-b-30">
                                                <div onClick={()=> window.location.href="/view-event"} className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded">
                                                    <div className="fs-28 fs-xxxl-36 font-weight-light text-black">Christmas</div>
                                                    <div className=""><img src={EventIcon} alt="" /> <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">Add memories to this event</span></div>
                                                </div>
                                            </div>
                                            <div className=" col-lg-4 mar-b-30">
                                                <div onClick={()=> window.location.href="/view-event"} className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded">
                                                    <div className="fs-28 fs-xxxl-36 font-weight-light text-black">Anniversary</div>
                                                    <div className=""><img src={EventIcon} alt="" /> <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">Add memories to this event</span></div>
                                                </div>
                                            </div>
                                            <div className=" col-lg-4 mar-b-30">
                                                <div onClick={()=> window.location.href="/view-event"} className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded">
                                                    <div className="fs-28 fs-xxxl-36 font-weight-light text-black">First meet</div>
                                                    <div className=""><img src={EventIcon} alt="" /> <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">Add memories to this event</span></div>
                                                </div>
                                            </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {(currentContactInfo === undefined ||
            currentContactInfo === null ||
            currentContactInfo === "") &&
            isLoading === false && (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    Sorry, no data found
                  </div>
                </div>
              </div>
            )}
        </div>

        <Modal
          className={modal}
          open={this.state.setOpen}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="myCusTomModal d-flex">
            <div className="card   ">
              <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 ">
                  <i className="material-icons text-color1 align-middle">
                    event
                  </i>{" "}
                  {formData.dateUnknown && !formData.recuringEvent && (
                    <>Add event details</>
                  )}
                  {formData.dateUnknown && formData.recuringEvent && (
                    <>Event details</>
                  )}
                  {!formData.dateUnknown && <>Event details</>}
                </h3>
              </div>
              <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                {/* {formData.dateUnknown} */}
                {!formData.dateUnknown && (
                  <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                    1. For which occasion are you creating this event?
                  </h3>
                )}
                {formData.dateUnknown && formData.recuringEvent && (
                  <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                    1. For which occasion are you creating this event?
                  </h3>
                )}
                {formData.dateUnknown && !formData.recuringEvent && (
                  <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                    1. For which event you’re requesting this memory?
                  </h3>
                )}
                <div className="row">
                  <div className="mar-b-40 col-md-12">
                    <TextField
                      inputProps={{ maxLength: 25 }}
                      label="Event name"
                      variant="outlined"
                      className="w-100"
                      name="title"
                      value={formData.title !== undefined ? formData.title : ""}
                      onChange={this.handleChange}
                      required
                    />
                    <div className="fs-12 opa-60 text-black text-right">
                      {this.state.formData.title.length}/25
                    </div>
                  </div>
                </div>
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  2. Is this a recurring event? *
                </h3>
                <div className="mar-b-40 ">
                  <RadioGroup
                    name="recuringEvent"
                    onChange={this.handleChange}
                    value={
                      formData.recuringEvent !== undefined
                        ? formData.recuringEvent
                        : ""
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes, this event recurs every year"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No, this event occurs for once"
                    />
                  </RadioGroup>
                </div>

                <div className="d-md-flex justify-content-between mar-b-22 align-items-center">
                  <h3 className="text-black mb-0 fs-18 font-weight-Regular">
                    3. Event date *
                  </h3>
                  <div className="checkLabelCustom ml-n2 ml-md-auto">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="dateUnknown"
                          value={
                            formData.dateUnknown !== undefined
                              ? formData.dateUnknown
                              : false
                          }
                          onChange={this.handleChange}
                        />
                      }
                      label="This event’s date is unknown"
                    />
                  </div>
                </div>

                {formData.dateUnknown !== true && (
                  <div className="row">
                    <div className="mar-b-40 col-md-4">
                      <FormControl
                        variant="outlined"
                        className="w-100 bg-white"
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Month
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Month"
                          onChange={this.handleChange}
                          name="eventMonth"
                          value={
                            formData.eventMonth !== undefined
                              ? formData.eventMonth
                              : ""
                          }
                        >
                          <MenuItem value="01">January</MenuItem>
                          <MenuItem value="02">February</MenuItem>
                          <MenuItem value="03">March</MenuItem>
                          <MenuItem value="04">April</MenuItem>
                          <MenuItem value="05">May</MenuItem>
                          <MenuItem value="06">June</MenuItem>
                          <MenuItem value="07">July</MenuItem>
                          <MenuItem value="08">August</MenuItem>
                          <MenuItem value="09">September</MenuItem>
                          <MenuItem value="10">October</MenuItem>
                          <MenuItem value="11">November</MenuItem>
                          <MenuItem value="12">December</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mar-b-40 col-md-4">
                      <TextField
                        type="number"
                        label="Day"
                        name="eventDay"
                        value={
                          formData.eventDay !== undefined
                            ? formData.eventDay
                            : ""
                        }
                        onChange={this.handleChange}
                        variant="outlined"
                        className="w-100"
                        onInput={(e) => {
                          var value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 2);

                          if (formData.eventMonth === "02") {
                            if (value > 29) {
                              value = 29;
                            } else if (value < 1) {
                              value = 1;
                            }
                          } else if (value > 31) {
                            value = 31;
                          } else if (value < 1) {
                            value = 1;
                          }
                          e.target.value = value;
                        }}
                      />
                    </div>

                    <div className="mar-b-40 col-md-4">
                      <FormControl
                        variant="outlined"
                        className="w-100 bg-white"
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Year
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Year"
                          onChange={this.handleChange}
                          name="eventYear"
                          value={
                            formData.eventYear !== undefined
                              ? formData.eventYear
                              : ""
                          }
                        >
                          {rows.map((value, index) => {
                            return (
                              <MenuItem value={value} key={index}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {/* <TextField
                        type="number"
                        label="Year"
                        variant="outlined"
                        className="w-100"
                        name="eventYear"
                        value={
                          formData.eventYear !== undefined
                            ? formData.eventYear
                            : ""
                        }
                        onChange={this.handleChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 4);
                        }}
                      /> */}
                    </div>
                  </div>
                )}

                {formData.dateUnknown === true && (
                  <div className="bg-color3 py-3 px-4 border-radius-10 fs-18 ">
                    <div className="opa-60 text-black">
                      Create memories for future events without a certain date.{" "}
                      {currentContactInfo.firstName !== undefined &&
                      currentContactInfo.firstName !== "" &&
                      currentContactInfo.firstName !== null
                        ? currentContactInfo.firstName
                        : ""}{" "}
                      will be able to see this memory anytime when it’s
                      appropriate by the event name.
                    </div>
                  </div>
                )}
              </div>
              <div className="card-footer bg-white text-right pad-x-40 ">
                {this.state.isSubmitForm === true && <CircularProgress />}

                {this.state.isSubmitForm === false && (
                  <>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize"
                      onClick={this.handleClose.bind(this)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize background_color"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewContact);

//export default ViewContact;
