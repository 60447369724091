import { FETCH_COUPONCODE_SUCCESS, FETCH_COUPONCODE_FAILED, CLEAR_STATE } from "../actions/paymentActions";
const intialState = {}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = intialState, action) => {
    switch (action.type) {
        /* FETCH COUPON CODE DETAILS */
        case FETCH_COUPONCODE_SUCCESS:
            return { type: FETCH_COUPONCODE_SUCCESS, responce: action.data };
        case FETCH_COUPONCODE_FAILED:
            return { type: FETCH_COUPONCODE_FAILED, responce: action.data };
        
        /* CLEAR THE STATE VALUE */
        case CLEAR_STATE:
            return {};

        default:
            return state;
    }
}