import React, { Component, useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";

class PaypalPaymentConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      apiErrorMessage: "",
      apiSuccessMessage: "",
    };
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
      //this.props.history.push('/');
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";

      /* Redirect to dashboard */
      //this.props.history.push('/dashboard');
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.USER_REGISTRATION_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          var token =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.token !== undefined
              ? miscReducerData.responce.data.token
              : "";
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";

          props.miscActions.clearState();

          if (token !== "") {
            localStorage.setItem("auth_token", token);
            localStorage.setItem("user_info", JSON.stringify(userInfo));
            localStorage.removeItem("registration_info");
            localStorage.removeItem("registration_current_step");
            localStorage.removeItem("selected_plan_details");

            return {
              apiErrorMessage: "",
              apiSuccessMessage: message,
              isLoading: false,
            };
          } else {
            return {
              apiErrorMessage: "Something went wrong",
              apiSuccessMessage: "",
              isLoading: false,
            };
          }
          return {};
          break;
        case miscActions.USER_REGISTRATION_FAILED:
          props.miscActions.clearState();
          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined &&
            miscReducerData.responce.data.message !== ""
              ? miscReducerData.responce.data.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isLoading: false,
          };
          break;

        /* Plan Update */

        case miscActions.USER_PLAN_UPDATE_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          localStorage.setItem("user_info", JSON.stringify(userInfo));
          localStorage.removeItem("selected_plan_details");

          var message =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.message !== undefined
              ? miscReducerData.responce.data.message
              : "";
          props.miscActions.clearState();
          props.history.push('/settings')
          return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            isLoading: false,
          };
          break;
        case miscActions.USER_PLAN_UPDATE_FAILED:
          props.miscActions.clearState();
          props.history.push('/settings')
          return {
            apiErrorMessage: "Something went wrong! Please try again",
            apiSuccessMessage: "",
            isLoading: false,
          };
          break;

        default:
          return {};
      }
    }

    return {};
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const successStatus = urlParams.get("success");
    const planId = urlParams.get("planId");
    let auth_token = localStorage.getItem("auth_token");

    if (
      planId === "" ||
      planId === null ||
      planId === undefined ||
      successStatus === "" ||
      successStatus === null ||
      successStatus === undefined
    ) {
      toast.error("You have canceled your payment.");
      this.props.history.push("/");
    } else {
      if (
        auth_token === "" ||
        auth_token === null ||
        auth_token === undefined
      ) {
        // register new user
        if (successStatus === "false") {
          toast.error("Something went wrong ! Please try again");
          this.props.history.push("/sign-up-step-five");
        } else if (successStatus === "true") {
          let registerData = localStorage.getItem("registration_info");

          if (
            registerData !== "" &&
            registerData !== undefined &&
            registerData !== null
          ) {
            let registerObj = JSON.parse(registerData);
            var event_memories_id = registerObj.event_memories_id;

            delete registerObj.event_memories_id;
            delete registerObj.confirm_password;

            var form_data = new FormData();

            for (var key in registerObj) {
              form_data.append(key, registerObj[key]);
            }

            if (event_memories_id.length > 0) {
              event_memories_id.forEach(function (value, indx) {
                form_data.append("event_memories_id[" + indx + "]", value);
              });
            }

            form_data.append("paymentMethod", "paypal");

            this.setState({
              isLoading: true,
            });

            this.props.miscActions.userRegistration(form_data);
          } else {
            toast.error("Something went wrong ! Please try again");
            this.props.history.push("/sign-up-step-five");
          }
        }
      } else {
        // update user plan
        if (successStatus === "false") {
          toast.error("Something went wrong ! Please try again");
          this.props.history.push("/user-plan");
        } else if (successStatus === "true") {
          var userInfo = localStorage.getItem("user_info");
          userInfo = JSON.parse(userInfo);

          if (userInfo !== "" && userInfo !== null && userInfo !== undefined) {
            var form_data = new FormData();
            form_data.append("plan_id", planId);
            form_data.append("paymentMethod", "paypal");
            this.props.miscActions.userPlanUpdate(form_data);
          } else {
            toast.error("Something went wrong ! Please try again");
            this.props.history.push("/user-plan");
          }
        }
      }
    }
  }

  render() {
    return (
      <div className="page-center">
        <CircularProgress />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaypalPaymentConfirmation);
