import React from 'react'
import Modal from "@material-ui/core/Modal";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Button } from "@material-ui/core";
import moment from "moment";



export default function BillingInfoComponent(props) {
    console.log(props.userInfo)
    return (
        <Modal
            className={'d-flex align-items-center'}
            open={props.isinfomdal}
            onClose={props.closeInfoModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="myCusTomModal d-flex">
                <div className="card">
                    <div className="card-header border-bottom bg-white pad-x-20 pad-y-25">
                        <h3 className="font-weight-Medium fs-24 mb-0 ">
                            <span
                                className="float-left mr-2"
                                style={{ marginTop: "-2px " }}
                            >
                                <LockOpenIcon className="material-icons text-color1 align-middle " />{" "}
                            </span>
                            Billing Information
                        </h3>
                    </div>
                    <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                        <div className="row">
                            <div className="col-md-6">
                                <h5>Plan Name:</h5>
                                <p>{props.userInfo.plan_id?.header}</p>
                            </div>
                            <div className="col-md-6">
                                <h5>Plan Type:</h5>
                                <p>{props.userInfo.plan_id?.type === 'Annual' ? 'Yearly' : 'Monthly'}</p>
                            </div>
                            <div className="col-md-6">
                                <h5>Start Date:</h5>
                                <p>{moment(props.userInfo.subscriptionData?.startDate)
                                    .tz("America/New_York")
                                    .format("MMMM Do YYYY")}</p>
                            </div>
                            <div className="col-md-6">
                                <h5>End Date:</h5>
                                <p>{moment(props.userInfo.subscriptionData?.endDate)
                                    .tz("America/New_York")
                                    .format("MMMM Do YYYY")}</p>
                            </div>
                            <div className="col-md-6">
                                <h5>Subscription Amount:</h5>
                                <p>${props.userInfo.plan_id?.value.toFixed(2)}</p>
                            </div>
                            <div className="col-md-6">
                                <h5>Status:</h5>
                                <p>{props.userInfo.plan_id?.status}</p>
                            </div>
                            <div className="col-md-12">
                                <h5>Plan Features:</h5>
                                <ul style={{ background: '#eee', padding: '15px 25px' }}>
                                    {props.userInfo.plan_id?.line_items.map((value) => {
                                        return <li>{value}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer bg-white text-right pad-x-40 ">
                        <Button className="text-color fs-19 font-weight-Medium" variant="contained" onClick={() => props.closeInfoModal()}>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
