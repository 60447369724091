import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import HeroImg from "../assets/images/img1.png";
import HeroImgBg from "../assets/images/heroImgBg.svg";
import ShareBG from "../assets/images/shareBg.svg";
import ShareImg from "../assets/images/img2.png";
import AnyOneImg from "../assets/images/img3.png";
import whatIsBg from "../assets/images/whaIsLike.svg";
import userImg1 from "../assets/images/img4.png";
import tourImgVideo from "../assets/images/img5.png";
import PlayBtn from "../assets/images/icon5.svg";
import { Button, Typography } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { baseURL } from "../api";
import howitwork1 from "../assets/images/imgstep1.png";
import howitwork2 from "../assets/images/imgstep2.png";
import howitwork3 from "../assets/images/imgstep3.png";
import send from "../assets/images/sw.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StayConnectComponent from "../Components/Homepage/StayConnect/StayConnectComponent";
import HomeFooterComponent from "../Components/Homepage/HomeFooter/HomeFooterComponent";
import Slider from "react-slick";
import { createMuiTheme } from "@material-ui/core/styles";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";

import Brand1 from "../assets/images/brand1.png";
import Brand2 from "../assets/images/brand2.png";
import Brand3 from "../assets/images/brand3.png";
import Brand4 from "../assets/images/brand4.png";
import Brand5 from "../assets/images/brand5.png";

// We can use inline-style
const CustomBtnStyle = {
  background: "#71c4c8",
  borderRadius: 4,
  border: 0,
  color: "white",
  padding: "10px 30px",
};

let imagePath = baseURL + "uploads/homepagecms/image/";
let brandImagePath = baseURL + "uploads/brand/";
let videoPath = baseURL + "uploads/homepagecms/video/";
let testimonialsImagePath = baseURL + "uploads/testimonials/";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageDetails: {},
      brandLogos: [],
      allTestimonials: [],
      isLoading: true,
      isAnualPlanListFetched: false,
      anualPlanList: [],
      monthlyPlanList: [],
      planType: "annual",
      socailLink: [],
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("authToken");

    if (token != null) {
      /* Forcefully login */
      this.props.contactActions.forceSignin({ token: token });
    } else {
      var currentUserRole = "";
      var auth_token = localStorage.getItem("auth_token");
      var user_info = localStorage.getItem("user_info");
      if (user_info !== "") {
        user_info = JSON.parse(user_info);
      }
      if (
        user_info !== "" &&
        user_info !== undefined &&
        user_info !== null &&
        user_info.role !== "" &&
        user_info.role !== null &&
        user_info.role !== undefined &&
        user_info.role.role !== "" &&
        user_info.role.role !== null &&
        user_info.role.role !== undefined
      ) {
        currentUserRole = user_info.role.role;
      }

      //alert(auth_token)

      if (
        auth_token !== "" &&
        auth_token !== undefined &&
        auth_token !== null &&
        currentUserRole === "sub-user"
      ) {
        //alert(currentUserRole)
        this.props.history.push("/secondary-user-dashboard");
      }
      if (
        auth_token !== "" &&
        auth_token !== undefined &&
        auth_token !== null &&
        currentUserRole === "user"
      ) {
        this.props.history.push("/dashboard");
      }

      if (
        auth_token === "" ||
        auth_token === undefined ||
        auth_token === null ||
        currentUserRole === "sub-user"
      ) {
        //this.props.history.push("/");
      } else {
        /* get secondary user details form notification value */
        var secondaryUserDetails =
          this.props.location.state !== undefined &&
          this.props.location.state !== null &&
          this.props.location.state !== "" &&
          this.props.location.state.secondaryUserDetails !== undefined &&
          this.props.location.state.secondaryUserDetails !== null &&
          this.props.location.state.secondaryUserDetails !== ""
            ? this.props.location.state.secondaryUserDetails
            : "";
        if (secondaryUserDetails !== "") {
          var formData = this.state.formData;
          formData.firstName = secondaryUserDetails.first_name;
          formData.lastName = secondaryUserDetails.last_name;
          formData.email = secondaryUserDetails.email;

          this.setState({
            formData: formData,
            setOpen: true,
            isAddContactChangeValue: false,
          });
        }
      }
    }

    this.props.miscActions.fetchCmsInfo("homepage");
    this.props.miscActions.fetchBrandLogo();
    this.props.miscActions.fetchTestimonials();
    var planFormdat = new FormData();
    planFormdat.append("type", "Annual");
    planFormdat.append("no_of_contacts", 0);
    this.props.miscActions.fetchFlans(planFormdat);
    this.props.miscActions.fetchFlans("Monthly");
    this.props.miscActions.fetchSociallink();
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.FETCH_CMS_INFO_SUCCESS:
          var pageInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : {};

          props.miscActions.clearState();
          return {
            pageDetails: pageInfo,
            isLoading: false,
          };
          break;
        case miscActions.FETCH_CMS_INFO_FAILED:
          props.miscActions.clearState();
          return {
            isLoading: false,
          };
          break;

        case miscActions.FETCH_BRAND_LOGO_SUCCESS:
          var logoInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : [];

          props.miscActions.clearState();

          return {
            brandLogos: logoInfo,
          };
          break;
        case miscActions.FETCH_BRAND_LOGO_FAILED:
          props.miscActions.clearState();
          return {};
          break;

        case miscActions.FETCH_TESTIMONIALS_SUCCESS:
          var testimonialInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : [];
          props.miscActions.clearState();
          return {
            allTestimonials: testimonialInfo,
          };
          break;
        case miscActions.FETCH_TESTIMONIALS_FAILED:
          props.miscActions.clearState();
          return {};
        case miscActions.FETCH_PLANS_SUCCESS:
          let planList =
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : [];
          if (state.isAnualPlanListFetched === false) {
            setTimeout(function () {
              var planFormdat1 = new FormData();
              planFormdat1.append("type", "Monthly");
              planFormdat1.append("no_of_contacts", 0);
              props.miscActions.fetchFlans(planFormdat1);
            }, 2000);
            return {
              anualPlanList: planList,
              isAnualPlanListFetched: true,
            };
          } else {
            props.miscActions.clearState();
            return {
              monthlyPlanList: planList,
            };
          }
        case miscActions.FETCH_PLANS_FAILED:
          props.miscActions.clearState();
          return {};

        case miscActions.FETCH_SOCIAL_LINK_SUCCESS:
          var socialLink =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : [];
          //props.miscActions.clearState();
          return {
            socailLink: socialLink,
          };
          break;
        case miscActions.FETCH_SOCIAL_LINK_FAILED:
          props.miscActions.clearState();
          return {};

        default: {
        }
      }
    }

    return {};
  }
  changePlan(data) {
    this.setState({ planType: data });
  }

  render() {
    //console.log(this.state.socailLink)
    var settings = {
      dots: false,
      infinite: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            dots: true,
            arrows: false,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    };

    var isLoading = this.state.isLoading;
    var pageDetails = this.state.pageDetails;
    var brandLogos = this.state.brandLogos;
    var allTestimonials = this.state.allTestimonials;
    var socialLink = this.state.socailLink;

    if (isLoading === true) {
      return <></>;
    }

    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }
    console.log("info", pageDetails);
    var anualPlans = this.state.anualPlanList;
    var monthlyPlans = this.state.monthlyPlanList;

    //console.log(anualPlans);
    return (
      <>
        <PrimaryHeader />

        <section className="heroWraper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="imgBox p-2">
                  <div className="bannerImgWrapper">
                    <div className="bannerImageInner">
                      <img
                        src={
                          pageDetails.part1 !== undefined &&
                          pageDetails.part1 !== null &&
                          pageDetails.part1 !== "" &&
                          pageDetails.part1.image !== undefined &&
                          pageDetails.part1.image !== null &&
                          pageDetails.part1.image !== ""
                            ? imagePath + pageDetails.part1.image
                            : HeroImg
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="imgBox">
                  <div className="bannerImgWrapper">
                    <img
                      src="https://beta.getforgetmenot.com/static/media/registerBg.9f65945b.png"
                      alt=""
                    />
                  </div>
                </div> */}

                {/* <div className="imgBox">
                  <img
                    src={
                      pageDetails.part1 !== undefined &&
                      pageDetails.part1 !== null &&
                      pageDetails.part1 !== "" &&
                      pageDetails.part1.image !== undefined &&
                      pageDetails.part1.image !== null &&
                      pageDetails.part1.image !== ""
                        ? imagePath + pageDetails.part1.image
                        : HeroImg
                    }
                    alt=""
                    className="w-100"
                  />
                </div> */}
              </div>
              <div className="col-md-6 pl-md-5">
                <h1
                  className="fs-md-30 fs-lg-40 fs-xl-50 fs-xxxl-64"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageDetails.part1 !== undefined &&
                      pageDetails.part1 !== null &&
                      pageDetails.part1 !== "" &&
                      pageDetails.part1.title !== undefined &&
                      pageDetails.part1.title !== null &&
                      pageDetails.part1.title !== ""
                        ? pageDetails.part1.title
                        : "<b>Emotional</b> Life <br /> Insurance",
                  }}
                ></h1>
                <p
                  className=" fs-xl-20 fs-xxxl-24 mt-4 mb-4"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageDetails.part1 !== undefined &&
                      pageDetails.part1 !== null &&
                      pageDetails.part1 !== "" &&
                      pageDetails.part1.description !== undefined &&
                      pageDetails.part1.description !== null &&
                      pageDetails.part1.description !== ""
                        ? pageDetails.part1.description
                        : "With Forget me not, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut",
                  }}
                ></p>
                {/* <button className="btn btn-color1">Start For Free</button> */}
                <div className="pad-t-10">
                  {(auth_token === "" ||
                    auth_token === undefined ||
                    auth_token === null ||
                    currentUserRole === "" ||
                    currentUserRole === undefined ||
                    currentUserRole === null) && (
                    <Button
                      style={CustomBtnStyle}
                      onClick={(e) => this.props.history.push("/sign-in")}
                      className={"customButton"}
                    >
                      <span
                        className="button-text-span"
                        dangerouslySetInnerHTML={{
                          __html:
                            pageDetails.part1 !== undefined &&
                            pageDetails.part1 !== null &&
                            pageDetails.part1 !== "" &&
                            pageDetails.part1.btn_txt !== undefined &&
                            pageDetails.part1.btn_txt !== null &&
                            pageDetails.part1.btn_txt !== ""
                              ? pageDetails.part1.btn_txt
                              : "Start For Free",
                        }}
                      />
                      {/* {pageDetails.part1 !== undefined &&
                      pageDetails.part1 !== null &&
                      pageDetails.part1 !== "" &&
                      pageDetails.part1.btn_txt !== undefined &&
                      pageDetails.part1.btn_txt !== null &&
                      pageDetails.part1.btn_txt !== ""
                        ? pageDetails.part1.btn_txt
                        : "Start For Free"} */}
                      {/* Get Started for Free */}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="shareTHeFutureWraper position-relative">
          <div className="bgImg">
            <img src={ShareBG} alt="" className="w-100" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h3
                  className="fs-30 fs-md-40 fs-xl-50 fs-xxxl-64"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageDetails.part2 !== undefined &&
                      pageDetails.part2 !== null &&
                      pageDetails.part2 !== "" &&
                      pageDetails.part2.title !== undefined &&
                      pageDetails.part2.title !== null &&
                      pageDetails.part2.title !== ""
                        ? pageDetails.part2.title.replace(
                            /(<p[^>]+?>|<p>|<\/p>)/gim,
                            ""
                          )
                        : "Share the future",
                  }}
                ></h3>

                <div
                  className="fs-xl-20 fs-xxxl-24 contentBox"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageDetails.part2 !== undefined &&
                      pageDetails.part2 !== null &&
                      pageDetails.part2 !== "" &&
                      pageDetails.part2.description !== undefined &&
                      pageDetails.part2.description !== null &&
                      pageDetails.part2.description !== ""
                        ? pageDetails.part2.description
                        : "Lorem ipsum dolor sit amet, consectetur adipiscing <br /> elit, sed do eiusmod tempor incididunt ut",
                  }}
                ></div>
                <div>
                  {/* <Link to="/share-the-future">Learn more</Link> */}
                </div>
              </div>
              <div className="col-md-6">
                {/*<img src={ShareImg} alt="" className="w-100" />*/}
                <img
                  src={
                    pageDetails.part2 !== undefined &&
                    pageDetails.part2 !== null &&
                    pageDetails.part2 !== "" &&
                    pageDetails.part2.image !== undefined &&
                    pageDetails.part2.image !== null &&
                    pageDetails.part2.image !== ""
                      ? imagePath + pageDetails.part2.image
                      : ShareImg
                  }
                  alt=""
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="anyOneWraper position-relative">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5">
                {/*<img src={AnyOneImg} alt="" className="w-100" />*/}
                <div className="oval-block"></div>
                <img
                  src={
                    pageDetails.part3 !== undefined &&
                    pageDetails.part3 !== null &&
                    pageDetails.part3 !== "" &&
                    pageDetails.part3.image !== undefined &&
                    pageDetails.part3.image !== null &&
                    pageDetails.part3.image !== ""
                      ? imagePath + pageDetails.part3.image
                      : AnyOneImg
                  }
                  alt=""
                  className="w-100"
                  style={{ position: "relative" }}
                />
              </div>

              <div className="col-md-6">
                <h3
                  className="fs-30 fs-md-40 fs-xl-50 fs-xxxl-64"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageDetails.part3 !== undefined &&
                      pageDetails.part3 !== null &&
                      pageDetails.part3 !== "" &&
                      pageDetails.part3.title !== undefined &&
                      pageDetails.part3.title !== null &&
                      pageDetails.part3.title !== ""
                        ? pageDetails.part3.title.replace(
                            /(<p[^>]+?>|<p>|<\/p>)/gim,
                            ""
                          )
                        : "Anyone, anytime",
                  }}
                ></h3>
                <p
                  className="fs-xl-20 fs-xxxl-24"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageDetails.part3 !== undefined &&
                      pageDetails.part3 !== null &&
                      pageDetails.part3 !== "" &&
                      pageDetails.part3.description !== undefined &&
                      pageDetails.part3.description !== null &&
                      pageDetails.part3.description !== ""
                        ? pageDetails.part3.description
                        : "Lorem ipsum dolor sit amet, consectetur adipiscing <br /> elit, sed do eiusmod tempor incididunt ut",
                  }}
                ></p>
                <div>{/* <Link to="/anyone-anytime" >Learn more</Link> */}</div>
              </div>
            </div>
          </div>
        </section>
        {/* 
                <section className="whatIsItLinkWrap">
                    <img src={whatIsBg} alt="" className="w-100 bgImg" />

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="secHead text-center">
                                    <h3 dangerouslySetInnerHTML={{ __html: pageDetails.part4 !== undefined && pageDetails.part4 !== null && pageDetails.part4 !== "" && pageDetails.part4.title !== undefined && pageDetails.part4.title !== null && pageDetails.part4.title !== "" ? pageDetails.part4.title.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "") : "What is it like?" }}></h3>
                                    <p dangerouslySetInnerHTML={{ __html: pageDetails.part4 !== undefined && pageDetails.part4 !== null && pageDetails.part4 !== "" && pageDetails.part4.description !== undefined && pageDetails.part4.description !== null && pageDetails.part4.description !== "" ? pageDetails.part4.description : "Lorem ipsum dolor sit amet, consectetur adipiscing" }}></p>
                                </div>

                                <div className="sliderBox">
                                    <Slider {...settings}>
                                        {allTestimonials.map((list, index) =>
                                            <div className="text-center" key={index}>
                                                <div className="mar-b-20"> <img src={list.image !== undefined && list.image !== null && list.image !== "" ? testimonialsImagePath + list.image : userImg1} alt="" className="d-inline-block" /></div>
                                                <h4 dangerouslySetInnerHTML={{ __html: list.content !== undefined && list.content !== null && list.content !== '' ? list.content : '' }}>
                                                </h4>
                                                <h2>-{list.name !== undefined && list.name !== "" && list.name !== null ? list.name : "No name"}</h2>
                                                <h6>
                                                    {list.subContent !== undefined && list.subContent !== "" && list.subContent !== null ? list.subContent : ""} <br />
                                                    {list.subContent2 !== undefined && list.subContent2 !== "" && list.subContent2 !== null ? list.subContent2 : ""}
                                                </h6>
                                            </div>
                                        )}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

        <section className="shareTHeFutureWraper position-relative howworks">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-6 text-center">
                <h3 className="fs-30 fs-md-40 fs-xl-50 fs-xxxl-64">
                  How it Works
                </h3>
                <p>Easy and Simple</p>
              </div>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4 col-sm-6 text-center">
                    <span className="imagesection">
                      <img src={howitwork1} alt="" height="108" />
                    </span>
                    <p>Create Your Profile</p>
                  </div>

                  <div className="col-lg-4 col-sm-6 text-center">
                    <span className="imagesection">
                      <img src={howitwork2} alt="" height="108" />
                    </span>
                    <p>
                      Upload And Schedule <br /> Content
                    </p>
                  </div>

                  <div className="col-lg-4 col-sm-6 text-center">
                    <span className="imagesection">
                      {" "}
                      <img src={howitwork3} alt="" height="108" />
                    </span>
                    <p>
                      Invite Your Loved Ones To <br /> Enjoy Endless Memories
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="letsGiveYouTourWrap">
          <div className="container">
            <div className="head text-center">
              {}
              <h3
                dangerouslySetInnerHTML={{
                  __html:
                    pageDetails.part5 !== undefined &&
                    pageDetails.part5 !== null &&
                    pageDetails.part5 !== "" &&
                    pageDetails.part5.title !== undefined &&
                    pageDetails.part5.title !== null &&
                    pageDetails.part5.title !== ""
                      ? pageDetails.part5.title.replace(
                          /(<p[^>]+?>|<p>|<\/p>)/gim,
                          ""
                        )
                      : "Let’s give you a tour",
                }}
              ></h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    pageDetails.part5 !== undefined &&
                    pageDetails.part5 !== null &&
                    pageDetails.part5 !== "" &&
                    pageDetails.part5.description !== undefined &&
                    pageDetails.part5.description !== null &&
                    pageDetails.part5.description !== ""
                      ? pageDetails.part5.description
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
                }}
              ></p>
            </div>

            <div className="col-md-11 mx-auto">
              <a
                data-fancybox="gallery"
                className="d-block position-relative"
                href={
                  pageDetails.part5 !== undefined &&
                  pageDetails.part5 !== null &&
                  pageDetails.part5 !== "" &&
                  pageDetails.part5.video !== undefined &&
                  pageDetails.part5.video !== null &&
                  pageDetails.part5.video !== ""
                    ? videoPath + pageDetails.part5.video
                    : "https://www.youtube.com/watch?v=YE7VzlLtp-4"
                }
              >
                <img
                  src={tourImgVideo}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
                <div className="btnBox">
                  <img src={PlayBtn} alt="" className="  d-block" />
                </div>
              </a>
            </div>
          </div>
        </section> */}

        <section className="pricingWrap">
          <div className="container text-left mt-5">
            {/*<h2>The opportunity is invaluable</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>*/}

            <div className="row justify-content-lg-center">
              <div className="col-lg-7 mb-4 pr-0 greybg overflow-hidden shadow-md-price">
                <div className="">
                  <div className="fs-22 fs-md-36 font-weight-Bold padding-custom">
                    Pricing
                  </div>
                  <p className="padding-custom text-color5">
                    {" "}
                    Choose a plan that's right for you
                  </p>
                  <div className="d-inline-block mr-2 mar-r-sm-70  padding-custom">
                    <Button
                      className={
                        "btn px-0 fs-14 fs-xs-18 shadow-none  " +
                        (this.state.planType === "annual"
                          ? "text-primary font-weight-Bold"
                          : "text-secondary")
                      }
                      onClick={() => this.changePlan("annual")}
                    >
                      Annual (Save 10%)
                    </Button>
                    <div
                      className={
                        "h-7px " +
                        (this.state.planType === "annual" ? "bg-primary" : "")
                      }
                      style={{ borderRadius: "5px 5px 0px 0px" }}
                    ></div>
                  </div>
                  <div className="d-inline-block">
                    <Button
                      className={
                        "btn px-0 fs-14 fs-xs-18 shadow-none  " +
                        (this.state.planType === "monthly"
                          ? "text-primary font-weight-Bold"
                          : "text-secondary")
                      }
                      onClick={() => this.changePlan("monthly")}
                    >
                      Monthly
                    </Button>
                    <div
                      className={
                        "h-7px " +
                        (this.state.planType === "monthly" ? "bg-primary" : "")
                      }
                      style={{ borderRadius: "5px 5px 0px 0px" }}
                    ></div>
                  </div>

                  {this.state.planType === "annual" && (
                    <div className="row animate__animated animate__fadeIn border-top pt-4 padding-custom">
                      {anualPlans.map((list, index) => (
                        <div className="col-md-4 mb-4" key={index}>
                          <div
                            className={`d-flex flex-column p-4 planBox shadow-md border-radius-10`}
                          >
                            <div className="mb-3">
                              <span className="rounded-pill border d-inline-block px-4 py-1">
                                {list.header}
                              </span>
                            </div>
                            <div className="mb-1">
                              <h4 className="line-height-1">
                                ${list.value}{" "}
                                <sub className="fs-14" style={{ bottom: "0" }}>
                                  / Year
                                </sub>
                              </h4>
                              <h5 className="text-color4 fs-18 font-weight-Regular">
                                {list.subheader}
                              </h5>
                              <p className={"text-color4"}>Billed Annualy</p>
                            </div>

                            <div>
                              <ul
                                className="list-unstyled text-color5"
                                style={{ marginBottom: "0px" }}
                              >
                                {list.line_items.map(
                                  (single_line_itm, single_line_indx) => (
                                    <li key={single_line_indx}>
                                      ✓ {single_line_itm}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                            <div>
                              {/*<Button
                                                                            className="bg-primary pad-y-10 text-white w-100"
                                                                            onClick={() => window.location.href = "/sign-up-step-five"}
                                                                        >Select Plan</Button>*/}
                              <NavLink to="/sign-up">
                                <Button className="bg-primary pad-y-10 text-white w-100">
                                  Select Plan
                                </Button>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {this.state.planType === "monthly" && (
                    <div className="row animate__animated animate__fadeIn border-top pt-4 padding-custom">
                      {monthlyPlans.map((list, index) => (
                        <div className="col-md-4 mb-4" key={index}>
                          <div
                            className={`d-flex flex-column p-4 planBox shadow-md border-radius-10`}
                          >
                            <div className="mb-3">
                              <span className="rounded-pill border d-inline-block px-4 py-1">
                                {list.header}
                              </span>
                            </div>
                            <div className="mb-1">
                              <h4 className="line-height-1">
                                ${list.value}{" "}
                                <sub className="fs-14" style={{ bottom: "0" }}>
                                  / Month
                                </sub>
                              </h4>
                              <h5 className="text-color4 fs-18 font-weight-Regular">
                                {list.subheader}
                              </h5>
                              <p className={"text-color4"}>Billed Monthly</p>
                            </div>
                            <div>
                              <ul className="list-unstyled text-color5">
                                {list.line_items.map(
                                  (single_line_itm, single_line_indx) => (
                                    <li key={single_line_indx}>
                                      ✓ {single_line_itm}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                            <div>
                              <NavLink to="/sign-up">
                                <Button className="bg-primary pad-y-10 text-white w-100">
                                  Select Plan
                                </Button>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <section className="getStartWrap">
            <div className="container text-center">
              {/*<h2>The opportunity is invaluable</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>*/}
              <div className="d-flex justify-content-center">
                <h3
                  dangerouslySetInnerHTML={{
                    __html:
                      pageDetails.part6 !== undefined &&
                      pageDetails.part6 !== null &&
                      pageDetails.part6 !== "" &&
                      pageDetails.part6.title !== undefined &&
                      pageDetails.part6.title !== null &&
                      pageDetails.part6.title !== ""
                        ? pageDetails.part6.title.replace(
                            /(<p[^>]+?>|<p>|<\/p>)/gim,
                            ""
                          )
                        : "Time Flies",
                  }}
                ></h3>
                <span className="ml-2">
                  <img src={send} alt="" />
                </span>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    pageDetails.part6 !== undefined &&
                    pageDetails.part6 !== null &&
                    pageDetails.part6 !== "" &&
                    pageDetails.part6.description !== undefined &&
                    pageDetails.part6.description !== null &&
                    pageDetails.part6.description !== ""
                      ? pageDetails.part6.description
                      : "Make every moment count ",
                }}
                style={{ fontStyle: "italic" }}
              ></p>

              <div className="pad-t-44">
                {/*<a className="btn btn-outline-color1">Get started now</a>*/}

                {(auth_token === "" ||
                  auth_token === undefined ||
                  auth_token === null ||
                  currentUserRole === "" ||
                  currentUserRole === undefined ||
                  currentUserRole === null) && (
                  <a
                    className="btn btn-outline-color1 footerButtonColor"
                    onClick={(e) => this.props.history.push("/sign-up")}
                  >
                    {pageDetails.part6 !== undefined &&
                    pageDetails.part6 !== null &&
                    pageDetails.part6 !== "" &&
                    pageDetails.part6.btn_txt !== undefined &&
                    pageDetails.part6.btn_txt !== null &&
                    pageDetails.part6.btn_txt !== ""
                      ? pageDetails.part6.btn_txt
                      : "Start Your Free Trial"}
                  </a>
                )}
              </div>
            </div>
          </section>
        </section>

        {/* <section className="brandWrap">
          <div className="container">
            <div className="row align-items-center mx-0 justify-content-center text-center ">
              {brandLogos.map((list, index) => (
                <div className="col-md-auto" key={index}>
                  {list.image !== undefined &&
                    list.image !== "" &&
                    list.image !== null && (
                      <img
                        src={brandImagePath + list.image}
                        alt=""
                        className=" mx-auto d-block"
                      />
                    )}
                </div>
              ))}
            </div>
          </div>
        </section> */}

        <HomeFooterComponent socialLink={socialLink} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
