/* Library */
import axiosInstance, { baseURL } from "../../api";
import axios from "axios";
import { toast } from "react-toastify";

/* Actions Types */
export const FETCH_COUPONCODE_SUCCESS = "FETCH_COUPONCODE_SUCCESS";
export const FETCH_COUPONCODE_FAILED = "FETCH_COUPONCODE_FAILED";



export const CLEAR_STATE = "CLEAR_STATE";

/* Fetch CMS info */
export function fetchCouponcode(formData) {
  return async function (dispatch) {
    return await axiosInstance
      .post("api/coupons/validation", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchCouponcodeSuccess(res));
        } else {
          dispatch(fetchCouponcodeFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchCouponcodeFailed(data));
      });
  };
}

function fetchCouponcodeSuccess(data) {
  return { type: FETCH_COUPONCODE_SUCCESS, data: data };
}

function fetchCouponcodeFailed(data) {
  //console.log(data)
  toast.error(data.data.message);
  return { type: FETCH_COUPONCODE_FAILED, data: data };
}








export function clearState() {
  return function (dispatch) {
    dispatch({ type: CLEAR_STATE });
  };
}

