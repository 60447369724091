import { Button } from "@material-ui/core";
import React, { Component } from "react";
import PrimaryHeader from "../Widgets/PrimaryHeader";

import { toast } from "react-toastify";

import TextField from "@material-ui/core/TextField";
import AddUser from "../assets/images/icon20.svg";
import UIco from "../assets/images/uIco.svg";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import EventIcon from "../assets/images/icon22.svg";
import Modal from "@material-ui/core/Modal";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import UserIcon from "../assets/images/icon21.svg";
import UploadURLS from "../assets/settings.js";
import moment from "moment-timezone";

import countryCode from "../assets/countryCode";

const modal = "d-flex align-items-center";

class SecondaryUserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: null,
      setModalOpen: false,
      isSubmitForm: false,
      userDetails: {},
      is_show_other_relationship_type: false,
      isLoading: true,
      pageLoading: true,

      formData: {
        title: "",
        recuringEvent: "",
        eventDay: "",
        eventMonth: "",
        eventYear: "",
        requestMemory: true,
        dateUnknown: false,
        parent_user_id: "",
      },

      createContactFormData: {
        firstName: "",
        lastName: "",
        relationship_id: "",
        email: "",
        nature_of_relationship: "",
        //phone: "",
        //image: "",
        //customInvitation: "",
      },

      eventDetails: {},
      eventList: [],
      filteredEvents: [],
      recentMemory: "",
      loading: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",

      /* Create contact */
      modalType: "request_memory",
      allRelationshipType: [],
      profilePreviewImage: "",
      isAddContactChangeValue: true,
      currentCountryCode: "",

      userAllContactList: [],

      /* Parent user list */
      parent_user_list: [],
      current_primary_user: "",
      contactAdded: false,

      isModelOpen: false,
      canUpdateEvent: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChangeCreateContact = this.handleChangeCreateContact.bind(this);
    this.handleSubmitCreateContact = this.handleSubmitCreateContact.bind(this);
    this.wrapperRef = React.createRef();
    this.buttonRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  triggerInputFile = () => this.fileInput.click();
  setContactPhoto = () => this.contactPhoto.click();

  handleOpen = (event) => {
    this.setState({ setOpen: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("authToken");

    if (token != null) {
      /* Forcefully login */
      this.props.contactActions.forceSignin({ token: token });
    } else {
      var THIS = this;
      var currentUserRole = "";
      var auth_token = localStorage.getItem("auth_token");
      var user_info = localStorage.getItem("user_info");
      if (user_info !== "") {
        user_info = JSON.parse(user_info);
      }
      if (
        user_info !== "" &&
        user_info !== undefined &&
        user_info !== null &&
        user_info.role !== "" &&
        user_info.role !== null &&
        user_info.role !== undefined &&
        user_info.role.role !== "" &&
        user_info.role.role !== null &&
        user_info.role.role !== undefined
      ) {
        currentUserRole = user_info.role.role;
      }

      if (
        auth_token === "" ||
        auth_token === undefined ||
        auth_token === null ||
        currentUserRole === "user"
      ) {
        this.props.history.push("/");
      } else {
        /*GET PROFILE  DETAILS*/

        //Update if current parent id present in the localstorage
        if (localStorage.getItem("secondary_current_parent_user_id")) {
          // this.setState({
          //   isLoading: true,
          // });
          let formData = new FormData();
          formData.append(
            "user_id",
            localStorage.getItem("secondary_current_parent_user_id")
          );
          this.props.contactActions.fetchSecondaryuserdashboarddataByparentID(
            formData
          );

          /* fetch all relationship */
          setTimeout(function () {
            THIS.props.contactActions.fetchAllRelationshipTypes();
            // this.setState({
            //   isLoading: false,
            // });
          }, 1000);
        } else {
          this.props.miscActions.getProfileDetails();
          /* FETCH EVENT LIST BY CONTACT ID */
          setTimeout(function () {
            THIS.props.contactActions.fetchEventListByContactID();
          }, 500);

          /* fetch all relationship */
          setTimeout(function () {
            THIS.props.contactActions.fetchAllRelationshipTypes();
          }, 1000);
          /* Fetch user all contact list */
          setTimeout(function () {
            THIS.props.contactActions.getUserContactList();
          }, 1000);
        }

        setTimeout(function () {
          THIS.setState({
            pageLoading: false,
          });
        }, 1500);
      }
    }

    // document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  componentDidUpdate() {
    if (this.state.apiErrorMessage) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
    } else if (this.state.apiSuccessMessage) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiSuccessMessage = "";
    }
  }

  viewFullMemory() {
    /* Recent memory details */
    let recentMemory = this.state.recentMemory;
    console.log("recentMemory", this.state.recentMemory);
    if (
      recentMemory !== undefined &&
      recentMemory !== null &&
      recentMemory !== "" &&
      recentMemory.event_id !== undefined &&
      recentMemory.year !== undefined &&
      recentMemory._id !== undefined
    ) {
      var data = {
        year: recentMemory.year,
        memories: recentMemory,
      };
      var eventDetails = recentMemory.event_id;
      this.props.history.push({
        pathname: "/view-memory/" + recentMemory._id,
        state: {
          detail: data,
          eventDetails: eventDetails,
        },
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    var returnData = state;

    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        /* Fetch parent user list */
        case contactActions.FETCH_PARENT_USER_LIST_SUCCESS:
          /* Fetch Parent user List */
          var parentUserList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];

          props.contactActions.clearState();
          returnData["parent_user_list"] = parentUserList;
          returnData["current_primary_user"] = localStorage.getItem(
            "secondary_current_parent_user_id"
          )
            ? localStorage.getItem("secondary_current_parent_user_id")
            : parentUserList[0]
            ? parentUserList[0].user_id._id
            : "";
          returnData["contactAdded"] = localStorage.getItem(
            "secondary_current_parent_id"
          )
            ? parentUserList[
                localStorage.getItem("secondary_current_parent_id")
              ].user_id.contactAdded
            : parentUserList[0]
            ? parentUserList[0].user_id.contactAdded
            : false;
          returnData["userDetails"] = localStorage.getItem(
            "secondary_current_parent_id"
          )
            ? {
                invitedBy:
                  parentUserList[
                    localStorage.getItem("secondary_current_parent_id")
                  ].user_id,
              }
            : parentUserList[0]
            ? { invitedBy: parentUserList[0].user_id }
            : {};
          returnData["isLoading"] = false;

          break;
        case contactActions.FETCH_PARENT_USER_LIST_FAILED:
          props.contactActions.clearState();
          returnData["parent_user_list"] = [];
          break;

        /* Fetch all event list */
        case contactActions.FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_SUCCESS:
          /* Fetch Event List */
          var eventList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];

          /* Fetch Recent memory details */

          var recentMemory =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.recentMemory !== undefined
              ? contactReducerData.responce.data.recentMemory
              : "";

          /* Filter event */
          var filteredEvents =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.filteredEvents !== undefined
              ? contactReducerData.responce.data.filteredEvents
              : [];

          props.contactActions.clearState();

          /*return {
            eventList: eventList,
            recentMemory: recentMemory,
            filteredEvents: filteredEvents,
          };*/

          returnData["eventList"] = eventList;
          returnData["recentMemory"] = recentMemory;
          returnData["filteredEvents"] = filteredEvents;

          break;
        case contactActions.FETCH_SECONDARY_DASHBOARD_DATA_BY_PARENT_ID_FAILED:
          props.contactActions.clearState();

          /*return {
            eventList: [],
            recentMemory: "",
            filteredEvents: [],
          };*/

          returnData["eventList"] = [];
          returnData["recentMemory"] = "";
          returnData["filteredEvents"] = [];

          break;

        /* Fetch all event list */
        case contactActions.FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS:
          /* Fetch Event List */
          var eventList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];

          /* Fetch Recent memory details */

          var recentMemory =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.recentMemory !== undefined
              ? contactReducerData.responce.data.recentMemory
              : "";

          /* Filter event */
          var filteredEvents =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.filteredEvents !== undefined
              ? contactReducerData.responce.data.filteredEvents
              : [];

          props.contactActions.clearState();

          /*return {
            eventList: eventList,
            recentMemory: recentMemory,
            filteredEvents: filteredEvents,
          };*/

          returnData["eventList"] = eventList;
          returnData["recentMemory"] = recentMemory;
          returnData["filteredEvents"] = filteredEvents;

          break;
        case contactActions.FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED:
          props.contactActions.clearState();

          /*return {
            eventList: [],
            recentMemory: "",
            filteredEvents: [],
          };*/

          returnData["eventList"] = [];
          returnData["recentMemory"] = "";
          returnData["filteredEvents"] = [];

          break;

        /* Create New event */
        case contactActions.CREATE_NEW_EVENT_SUCCESS:
          props.contactActions.clearState();
          var message = contactReducerData
            ? contactReducerData.responce.data.message
            : "";

          var formData = {
            title: "",
            recuringEvent: "",
            eventDay: "",
            eventMonth: "",
            eventYear: "",
            requestMemory: true,
            dateUnknown: false,
          };

          var formData1 = new FormData();
          formData1.append(
            "user_id",
            localStorage.getItem("secondary_current_parent_user_id")
          );
          props.contactActions.fetchSecondaryuserdashboarddataByparentID(
            formData1
          );
          props.contactActions.fetchEventListByContactID();

          //props.contactActions.fetchEventListByContactID();

          /*return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            formData: formData,
            loading: false,
            setModalOpen: false,
          };*/

          returnData["apiErrorMessage"] = "";
          returnData["apiSuccessMessage"] = message;
          returnData["formData"] = formData;
          returnData["loading"] = false;
          returnData["setModalOpen"] = false;
          returnData["canUpdateEvent"] = true;
          returnData["isSubmitForm"] = false;

          break;
        case contactActions.CREATE_NEW_EVENT_FAILED:
          props.contactActions.clearState();
          var message = contactReducerData
            ? contactReducerData.responce.data.message
            : "";

          /*return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
          };*/

          returnData["apiErrorMessage"] = message;
          returnData["apiSuccessMessage"] = "";

          break;

        /* Fetch Relationship list */
        case contactActions.FETCH_ALL_RELATIONSHIP_TYPE_SUCCESS:
          var relationshipList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];
          props.contactActions.clearState();

          /*return {
            allRelationshipType: relationshipList,
          };*/

          returnData["allRelationshipType"] = relationshipList;

          break;
        case contactActions.FETCH_ALL_RELATIONSHIP_TYPE_FAILED:
          props.contactActions.clearState();
          //return{};
          break;

        /* Save Contact Info */

        /* Save Contact */
        case contactActions.SAVE_CONTACT_INFO_SUCCESS:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          props.contactActions.clearState();
          props.contactActions.getUserContactList();
          /*var formData = {
            firstName: "",
            lastName: "",
            relationship_id: "",
            email: "",
            phone: "",
            image: "",
            customInvitation: "",
          };*/

          /*return {
            apiErrorMessage: "",
            apiSuccessMessage: message,
            setOpen: false,
            isSubmitForm: false,
            profilePreviewImage: "",
            setModalOpen: false,
            //createContactFormData: formData,
          };*/

          returnData["apiErrorMessage"] = "";
          returnData["apiSuccessMessage"] = message;
          returnData["setOpen"] = false;
          returnData["isSubmitForm"] = false;
          returnData["profilePreviewImage"] = "";
          returnData["setModalOpen"] = false;

          break;
        case contactActions.SAVE_CONTACT_INFO_FAILED:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.message !== undefined &&
            contactReducerData.responce.message !== ""
              ? contactReducerData.responce.message
              : ""; // for 500 error
          if (message === "") {
            message =
              contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.message !== undefined &&
              contactReducerData.responce.data.message !== ""
                ? contactReducerData.responce.data.message
                : ""; // for 201 error
          }

          props.contactActions.clearState();

          /*return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
            isSubmitForm: false,
          };*/

          returnData["apiErrorMessage"] = message;
          returnData["apiSuccessMessage"] = "";
          returnData["isSubmitForm"] = false;

          break;

        /* FETCH ALL CONTACT INFO */
        case contactActions.GET_USER_CONTACT_LIST_SUCCESS:
          var contactList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];
          props.contactActions.fetchParentuserlist();
          props.contactActions.clearState();

          /*return {
            userAllContactList: contactList,
          };*/

          returnData["userAllContactList"] = contactList;

          break;
        case contactActions.GET_USER_CONTACT_LIST_FAILED:
          /*return {
            userAllContactList: [],
          };*/

          returnData["userAllContactList"] = [];

          break;

        case contactActions.FORCE_USER_LOGIN_SUCCESS:
          var userInfo =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";
          var token =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.token !== undefined
              ? contactReducerData.responce.data.token
              : "";
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined
              ? contactReducerData.responce.data.message
              : "";

          var role =
            userInfo !== undefined &&
            userInfo !== null &&
            userInfo !== "" &&
            userInfo.role !== undefined &&
            userInfo.role !== "" &&
            userInfo.role !== "" &&
            userInfo.role.role !== undefined &&
            userInfo.role.role !== "" &&
            userInfo.role.role !== null
              ? userInfo.role.role
              : "";

          props.miscActions.clearState();
          if (token !== "") {
            localStorage.setItem("auth_token", token);
            localStorage.setItem("user_info", JSON.stringify(userInfo));
            if (role == "sub-user") {
              window.location.href = "/secondary-user-dashboard";
              //props.history.push("/secondary-user-dashboard");
            } else {
              window.location.href = "/dashboard";
              //props.history.push("/dashboard");
            }

            return {
              apiErrorMessage: "",
              apiSuccessMessage: message,
              loginUserType: role,
            };
          } else {
            return {
              apiErrorMessage: "Something went wrong",
              apiSuccessMessage: "",
            };
          }
        case contactActions.FORCE_USER_LOGIN_FAILED:
          props.miscActions.clearState();
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined &&
            contactReducerData.responce.data.message !== ""
              ? contactReducerData.responce.data.message
              : "";
          return {
            apiErrorMessage: message,
            apiSuccessMessage: "",
          };
        default:
          return {};
      }
      return {};
    }
    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        /* Fetch User Profile */
        case miscActions.GET_PROFILE_DETAILS_SUCCESS:
          var userInfo = miscReducerData.responce.data.data
            ? miscReducerData.responce.data.data
            : "";

          var invitedBy =
            userInfo.invitedBy !== undefined &&
            userInfo.invitedBy !== null &&
            userInfo.invitedBy !== ""
              ? userInfo.invitedBy
              : "";

          var temp = {
            firstName: "",
            lastName: "",
            relationship_id: "",
            email: "",
          };

          if (invitedBy !== "") {
            temp.firstName = invitedBy.first_name;
            temp.lastName = invitedBy.last_name;
            temp.email = invitedBy.email;
          }
          props.contactActions.fetchParentuserlist();

          props.miscActions.clearState();

          /*return {
            userDetails: userInfo,
            createContactFormData : temp,
          };*/

          returnData["userDetails"] = userInfo;
          returnData["createContactFormData"] = temp;

          break;
        case miscActions.GET_PROFILE_DETAILS_FAILED:
          props.miscActions.clearState();

          /*return {
            userDetails: "",
          };*/

          returnData["userDetails"] = "";
          break;
        default:
          return {};
      }
    }

    //return {};
    return returnData;
  }

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    if (fieldName === "eventMonth") {
      if (fieldValue === "2") {
        if (formData.eventDay > 29) {
          formData["eventDay"] = 29;
        } else if (formData.eventDay < 1) {
          formData["eventDay"] = 1;
        }
      }
    }

    if (fieldName === "recuringEvent" && fieldValue === "true") {
      fieldValue = true;
    } else if (fieldName === "recuringEvent" && fieldValue === "false") {
      fieldValue = false;
    }

    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
      isDisabledSubmit: false,
    });
  }

  handleSubmit = () => {
    let formData = this.state.formData;
    formData["parent_user_id"] = this.state.userDetails.invitedBy._id;
    this.setState({
      formData: formData,
    });

    if (
      this.state.formData.title === "" ||
      this.state.formData.title === null ||
      this.state.formData.title === undefined
    ) {
      toast.error("Please select an event");
    } else if (
      (this.state.canUpdateEvent && this.state.formData.recuringEvent === "") ||
      this.state.formData.recuringEvent === undefined ||
      this.state.formData.recuringEvent === null
    ) {
      toast.error("Please select 'Is this a recurring event or not'");
    } else if (
      (this.state.canUpdateEvent && this.state.formData.eventDay === "") ||
      this.state.formData.eventDay === null ||
      this.state.formData.eventDay === undefined
    ) {
      toast.error("Please enter event date");
    } else if (
      (this.state.canUpdateEvent && this.state.formData.eventMonth === "") ||
      this.state.formData.eventMonth === null ||
      this.state.formData.eventMonth === undefined
    ) {
      toast.error("Please select an event month");
    } else if (
      (this.state.canUpdateEvent && this.state.formData.eventYear === "") ||
      this.state.formData.eventYear === null ||
      this.state.formData.eventYear === undefined
    ) {
      toast.error("Please enter event year");
    } else if (
      !moment(
        `${this.state.formData.eventYear}-${this.state.formData.eventMonth}-${this.state.formData.eventDay}`
      ).isValid()
    ) {
      toast.error("Please change event day");
    } else {
      let formData = this.state.formData;
      formData.requestMemory = true;
      formData.dateUnknown = false;
      this.setState({
        formData: formData,
        isSubmitForm: true,
      });

      //console.log(this.state.formData);

      this.props.contactActions.createNewEvent(this.state.formData);
    }
  };

  handleChangeCreateContact(event) {
    let formData = this.state.createContactFormData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    formData[fieldName] = fieldValue;
    this.setState({
      createContactFormData: formData,
      isAddContactChangeValue: false,
    });
  }

  fileUploadChange(e) {
    let profileImage = e.target.files;
    let profilePreviewImage = "";
    let profileMainImage = "";

    if (
      profileImage !== undefined &&
      profileImage !== null &&
      profileImage !== "" &&
      profileImage.length > 0
    ) {
      profileMainImage = profileImage[0];
      profilePreviewImage = URL.createObjectURL(profileMainImage);
    }

    if (profilePreviewImage !== "" && profileMainImage !== "") {
      let formData = this.state.createContactFormData;
      formData["image"] = profileMainImage;

      this.setState({
        profilePreviewImage: profilePreviewImage,
        createContactFormData: formData,
        isAddContactChangeValue: false,
      });
    }
  }

  validateEmail(mail) {
    if (
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleSubmitCreateContact = (event) => {
    event.preventDefault();
    var formData = this.state.createContactFormData;
    var userDetails = this.state.userDetails;
    //var parentUserId =userDetails._id;
    var parentUserId =
      userDetails !== undefined &&
      userDetails !== null &&
      userDetails !== "" &&
      userDetails.invitedBy !== undefined &&
      userDetails.invitedBy !== null &&
      userDetails.invitedBy !== "" &&
      userDetails.invitedBy._id !== undefined &&
      userDetails.invitedBy._id !== null &&
      userDetails.invitedBy._id !== ""
        ? userDetails.invitedBy._id
        : "";

    //console.log(this.state.userDetails);

    if (parentUserId === "") {
      toast.error("Something went wrong! Please try again");
    } else if (
      formData.firstName === "" ||
      formData.firstName === null ||
      formData.firstName === undefined
    ) {
      toast.error("First name can not be blank");
    } else if (
      formData.lastName === "" ||
      formData.lastName === null ||
      formData.lastName === undefined
    ) {
      toast.error("Last name can not be blank");
    } else if (
      formData.relationship_id === "" ||
      formData.relationship_id === null ||
      formData.relationship_id === undefined
    ) {
      toast.error("Please select relationship type");
    } else if (
      formData.email === "" ||
      formData.email === null ||
      formData.email === undefined
    ) {
      toast.error("Email can not be blank");
    } else if (
      this.state.selectedRelationlabel == "Other" &&
      (formData.nature_of_relationship === "" ||
        formData.nature_of_relationship === null ||
        formData.nature_of_relationship === undefined)
    ) {
      toast.error("Other can not be blank");
    } else if (this.validateEmail(formData.email) === false) {
      toast.error("Please enter a valid email address");
    } else {
      /*else if ((formData.email === "" || formData.email === null || formData.email === undefined) && (formData.phone === "" || formData.phone === null || formData.phone === undefined)) {
      toast.error("Email or Phone can not be blank");
    } else if (formData.email !== "" && formData.email !== undefined && formData.email !== null && this.validateEmail(formData.email) === false) {
      toast.error("Please enter a valid email address");
    } else if (formData.phone !== "" && formData.phone !== undefined && formData.phone !== null && (this.state.currentCountryCode === "" || this.state.currentCountryCode === null || this.state.currentCountryCode === undefined)) {
      toast.error("Please select a country code");
    } */
      /*var phone = formData.phone;
      if (phone !== null && phone !== undefined && phone !== "") {
        phone = this.state.currentCountryCode + phone;
      }*/

      var newFormData = new FormData();
      newFormData.append("relationship_id", formData.relationship_id);
      newFormData.append("userId", parentUserId);
      newFormData.append(
        "nature_of_relationship",
        formData.nature_of_relationship
      );

      //newFormData.append("firstName", formData.firstName);
      //newFormData.append("lastName", formData.lastName);
      //newFormData.append("email", formData.email);
      //newFormData.append("phone", phone);
      //newFormData.append("image", formData.image);
      //newFormData.append("customInvitation", formData.customInvitation);

      this.setState({
        isSubmitForm: true,
      });

      //alert(formData.nature_of_relationship)

      // console.log(newFormData.get('nature_of_relationship'))

      this.props.contactActions.saveContactInfo(newFormData);
    }
  };

  handleCloseModal = () => {
    this.setState({
      setModalOpen: false,
      formData: {
        title: "",
        recuringEvent: "",
        eventDay: "",
        eventMonth: "",
        eventYear: "",
      },
      canUpdateEvent: true,
      isSubmitForm: false,

      /*createContactFormData: {
        firstName: "",
        lastName: "",
        relationship_id: "",
        email: "",
        phone: "",
        image: "",
        customInvitation: "",
      },*/
    });
  };

  triggerInputFile = () => this.fileInput.click();

  // userDropBox() {
  //   console.log("Sam",document.querySelector(".dropBoxWrap").classList.contains("d-none"));
  //   if(document.querySelector(".dropBoxWrap").classList.contains("d-none")){
  //     document.querySelector(".dropBoxWrap").classList.toggle("d-none");
  //   }
  // }

  //Parent User Chnage update Data..
  handleparentChange = (userid, contactAdded, userdetails, index) => {
    let formData = new FormData();
    formData.append("user_id", userid);
    this.props.contactActions.fetchSecondaryuserdashboarddataByparentID(
      formData
    );

    var temp = {
      firstName: "",
      lastName: "",
      relationship_id: "",
      email: "",
    };

    if (contactAdded == false) {
      temp.firstName = userdetails.first_name;
      temp.lastName = userdetails.last_name;
      temp.email = userdetails.email;
      this.setState({
        createContactFormData: temp,
      });
    } else {
      this.setState({
        createContactFormData: temp,
        userDetails: {},
      });
    }

    this.setState({
      current_primary_user: userid,
      contactAdded: contactAdded,
      userDetails: { invitedBy: userdetails },
    });
    localStorage.setItem("secondary_current_parent_id", index);
    localStorage.setItem("secondary_current_parent_user_id", userid);
    //console.log(userid);
  };

  handleUpdatelabel = (label) => {
    let formData = this.state.createContactFormData;
    if (label === "Other") {
      this.setState({
        is_show_other_relationship_type: true,
      });
    } else {
      formData["nature_of_relationship"] = "";
      this.setState({
        formData: formData,
        is_show_other_relationship_type: false,
      });
    }
    this.setState({ selectedRelationlabel: label });
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    var specifiedElement = document?.getElementById("drop_button");
    var isClickInside = specifiedElement?.contains(event?.target);
    if (isClickInside) {
      this.setState({ isModelOpen: !this.state.isModelOpen });
      // document.querySelector(".dropBoxWrap").classList.toggle("d-none");
    } else {
      this.setState({ isModelOpen: false });
      // document.querySelector(".dropBoxWrap").removeClass("d-none");
    }
  }

  handleDatevalue = (list) => {
    console.log(list);
    if (
      list.eventDay !== null &&
      list.eventDay !== undefined &&
      list.eventDay !== "" &&
      list.eventMonth !== undefined &&
      list.eventMonth !== null &&
      list.eventMonth !== "" &&
      list.eventYear !== "" &&
      list.eventYear !== undefined &&
      list.eventYear !== null
    ) {
      let formData = this.state.formData;
      formData["eventDay"] = list.eventDay;
      formData["eventMonth"] = list.eventMonth;
      formData["eventYear"] = list.eventYear;
      formData["recuringEvent"] = list.recuringEvent;
      this.setState({
        formData: formData,
        isDisabledSubmit: false,
        canUpdateEvent: false,
      });
    } else {
      let formData = this.state.formData;
      formData["eventDay"] = "";
      formData["eventMonth"] = "";
      formData["eventYear"] = "";
      this.setState({
        formData: formData,
        isDisabledSubmit: true,
        canUpdateEvent: true,
      });
    }
  };

  render() {
    let formData = this.state.formData;
    let eventList = this.state.eventList;
    let userDetails = this.state.userDetails;
    let filteredEvents = this.state.filteredEvents;
    let parent_user_list = this.state.parent_user_list;
    let current_primary_user = this.state.current_primary_user;
    // console.log(this.state.contactAdded);

    /* Recent memory details */
    let recentMemory = this.state.recentMemory;

    let recentMemoryTitle =
      recentMemory !== null &&
      recentMemory !== "" &&
      recentMemory !== undefined &&
      recentMemory.title !== undefined &&
      recentMemory.title !== null &&
      recentMemory.title !== ""
        ? recentMemory.title
        : "";

    let recentMemoryDescription =
      recentMemory !== null &&
      recentMemory !== "" &&
      recentMemory !== undefined &&
      recentMemory.message !== undefined &&
      recentMemory.message !== null &&
      recentMemory.message !== ""
        ? recentMemory.message
        : "";

    if (recentMemoryDescription.length > 100) {
      recentMemoryDescription =
        recentMemoryDescription.substring(0, 97) + "...";
    }

    /* fetch all relationship */
    var allRelationshipType = this.state.allRelationshipType;
    var createContactFormData = this.state.createContactFormData;

    /* Get user Contact list */
    var userAllContactList = this.state.userAllContactList;
    //console.log("userAllContactList",userAllContactList);

    //Year for loop
    let d = new Date();
    let rows = [];
    for (let i = 1900; i <= d.getFullYear(); i++) {
      rows.push(i);
    }

    if (this.state.pageLoading === true) {
      return (
        <div className="page-center">
          <CircularProgress />
        </div>
      );
    }

    return (
      <>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap dashboardWrapsub">
            <div className="container">
              <div className="card border-0 border-radius-15 min-vh-75 overflow-hidden">
                <div className="card-header pad-x-20 pad-x-xl-46 pad-y-20 bg-white">
                  <div className="d-lg-flex justify-content-between align-items-center">
                    <h3 className="text-black fs-22 fs-md-24 fs-xl-36 font-weight-Regular mb-0 position-relative">
                      <img
                        className="object-fit-cover w-55px h-55px  rounded-circle"
                        src={
                          userDetails &&
                          userDetails.invitedBy &&
                          userDetails.invitedBy.profile_image
                            ? UploadURLS.profile_image_path +
                              userDetails.invitedBy.profile_image
                            : UserIcon
                        }
                        alt=""
                      />
                      <span className="pl-2 mar-r-20">
                        {userDetails &&
                          userDetails.invitedBy &&
                          userDetails.invitedBy.first_name}{" "}
                        {userDetails &&
                          userDetails.invitedBy &&
                          userDetails.invitedBy.last_name}
                      </span>

                      <span className="cp" id="drop_button">
                        <svg
                          width="31"
                          height="31"
                          viewBox="0 0 31 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.5 3.83333C21.9312 3.83333 27.1667 9.06875 27.1667 15.5C27.1667 21.9313 21.9312 27.1667 15.5 27.1667C9.06874 27.1667 3.83332 21.9313 3.83332 15.5C3.83332 9.06875 9.06874 3.83333 15.5 3.83333ZM15.5 0.916668C7.44999 0.916668 0.916656 7.45 0.916656 15.5C0.916656 23.55 7.44999 30.0833 15.5 30.0833C23.55 30.0833 30.0833 23.55 30.0833 15.5C30.0833 7.45 23.55 0.916668 15.5 0.916668ZM15.5 19.875L9.66666 14.0417H21.3333L15.5 19.875Z"
                            fill="#71c4c8"
                          />
                        </svg>
                      </span>
                      {this.state.isModelOpen && (
                        <div
                          className="dropBoxWrap customlist position-absolute bg-white"
                          ref={this.wrapperRef}
                        >
                          {/* <h3 className="text-black p-3 p-md-4 fs-20 fs-md-22 fs-xl-24 font-weight-Light mb-0 position-relative d-flex align-items-center">
                          <img
                            className="object-fit-cover w-36px h-36px  rounded-circle"
                            src={
                              userDetails &&
                                userDetails.invitedBy &&
                                userDetails.invitedBy.profile_image
                                ? UploadURLS.profile_image_path +
                                userDetails.invitedBy.profile_image
                                : UserIcon
                            }
                          />
                          <span className="pl-2 mr-auto">
                            {userDetails &&
                              userDetails.invitedBy &&
                              userDetails.invitedBy.first_name}{" "}
                            {userDetails &&
                              userDetails.invitedBy &&
                              userDetails.invitedBy.last_name}
                          </span>

                          {userAllContactList.length > 0 &&
                            <span >
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#71c4c8" />
                              </svg>
                            </span>
                          }


                        </h3>
                         */}
                          <ul>
                            {parent_user_list.length > 0 &&
                              parent_user_list.map((userInfo, index) => {
                                return (
                                  <li key={userInfo._id}>
                                    <span>
                                      <img
                                        className="object-fit-cover w-36px h-36px  rounded-circle"
                                        src={
                                          userInfo.user_id &&
                                          userInfo.user_id.profile_image &&
                                          userInfo.user_id.profile_image != ""
                                            ? UploadURLS.profile_image_path +
                                              userInfo.user_id.profile_image
                                            : UserIcon
                                        }
                                        alt=""
                                      />
                                    </span>

                                    <span className="customname">
                                      {userInfo.user_id.first_name}{" "}
                                      {userInfo.user_id.last_name}
                                    </span>
                                    <span className="customcheck">
                                      <input
                                        type="radio"
                                        name="radio"
                                        value={userInfo.user_id._id}
                                        checked={
                                          current_primary_user ==
                                          userInfo.user_id._id
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          this.handleparentChange(
                                            userInfo.user_id._id,
                                            userInfo.user_id.contactAdded,
                                            userInfo.user_id,
                                            index
                                          )
                                        }
                                      />
                                      <label htmlFor=""></label>
                                    </span>
                                  </li>
                                );
                              })}
                            {/* <li>
                            <span>
                              <img
                                className="object-fit-cover w-36px h-36px  rounded-circle"
                                src={
                                  userDetails &&
                                  userDetails.invitedBy &&
                                  userDetails.invitedBy.profile_image
                                    ? UploadURLS.profile_image_path +
                                      userDetails.invitedBy.profile_image
                                    : UserIcon
                                }
                              />
                            </span>

                            <span className="customname">Admin Name</span>
                            <span className="customcheck">
                              <input type="radio" name="radio" value="604a476bc5cb9022ce40e85b" onChange={this.handleparentChange} />
                              <label htmlFor=""></label>
                            </span>
                          </li> */}
                          </ul>

                          {/* <hr className="my-0" />

                        <h3 className="text-black p-3 p-md-4 fs-20 fs-md-22 fs-xl-24 font-weight-Light mb-0 position-relative d-flex align-items-center">
                          <img
                            className="object-fit-cover w-36px h-36px  rounded-circle"
                            src={
                              userDetails &&
                              userDetails.invitedBy &&
                              userDetails.invitedBy.profile_image
                                ? UploadURLS.profile_image_path +
                                  userDetails.invitedBy.profile_image
                                : UserIcon
                            }
                          />
                          <span className="pl-2 mr-auto">
                            {userDetails &&
                              userDetails.invitedBy &&
                              userDetails.invitedBy.first_name}{" "}
                            {userDetails &&
                              userDetails.invitedBy &&
                              userDetails.invitedBy.last_name}
                          </span>

                          {userAllContactList.length > 0 && (
                            <span>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z"
                                  fill="#3772FF"
                                />
                              </svg>
                            </span>
                          )}
                        </h3>
                        <hr className="my-0" /> */}

                          <div className=" text-center bg-white border-radius-10 pad-y-20 pad-y-md-30">
                            <div className="pad-y-30">
                              <div>
                                <img src={AddUser} alt="" />
                              </div>

                              {!this.state.contactAdded && (
                                <div>
                                  <div className="text-black  py-2    fs-18">
                                    Invite & Stay Connected
                                  </div>
                                  <div className="text-black opa-60 font-weight-Regular fs-14">
                                    Is there someone you want to hear from{" "}
                                    <br /> even when they are not around?
                                  </div>
                                  <div className="pad-t-18">
                                    <Button
                                      className="bg-color1 text-white pad-y-7 pad-x-20 text-capitalize fs-19"
                                      onClick={() =>
                                        this.setState({
                                          setModalOpen: true,
                                          modalType: "create_contact",
                                        })
                                      }
                                    >
                                      <i className="material-icons">
                                        person_add
                                      </i>{" "}
                                      <span className="pl-2">Add contact</span>
                                    </Button>
                                  </div>
                                </div>
                              )}

                              {/* {userAllContactList.length > 0 ||
                              (this.state.contactAdded && (
                                <div className="text-black  py-2    fs-18">
                                  Contact already added
                                </div>
                              ))} */}

                              {this.state.contactAdded && (
                                <div className="text-black  py-2    fs-18">
                                  Contact already added
                                </div>
                              )}

                              {/* {userAllContactList.length === 0 ||
                              (this.state.contactAdded && (
                                <div className="text-black  py-2    fs-18">
                                  Contact already added
                                </div>
                              ))} */}
                            </div>
                          </div>
                        </div>
                      )}
                    </h3>

                    <div className="pt-3 pt-lg-0 d-flex justify-content-between align-items-center">
                      <div>
                        <Button
                          className={
                            "bg-color1 text-white text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-25 mar-l-20 "
                          }
                          onClick={() =>
                            this.setState({
                              setModalOpen: true,
                              modalType: "request_memory",
                            })
                          }
                        >
                          <i className="material-icons fs-17 fs-md-24 mr-2">
                            person_add
                          </i>{" "}
                          Request a memory
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="col-md-11 px-0 px-md-3 mx-auto pt-4 pt-md-5">
                    <div className="shadow border-radius-10">
                      <div className="w-100 mb-4 border-bottom p-4">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-auto">
                            <h4 className="pl-2 mb-0 opa-60 font-weight-Regular">
                              <span
                                className="w-10px h-10px d-inline-block rounded-circle align-middle mr-2"
                                style={{ background: "#8FFA24" }}
                              ></span>{" "}
                              Recent memory from{" "}
                              {userDetails &&
                                userDetails.invitedBy &&
                                userDetails.invitedBy.first_name}
                            </h4>
                          </div>
                          <div className="col-md-auto pt-2 pt-md-0">
                            <h5 className="mb-0 fs-18 opa-60 font-weight-Regular">
                              {recentMemory !== undefined &&
                                recentMemory !== null &&
                                recentMemory !== "" &&
                                recentMemory.unlockedOn !== undefined &&
                                recentMemory.unlockedOn !== "" &&
                                recentMemory.unlockedOn !== null && (
                                  <>
                                    {" "}
                                    Arrived on{" "}
                                    {moment(recentMemory.unlockedOn)
                                      //.tz('America/New_York')
                                      .format("Do MMMM YYYY")}
                                  </>
                                )}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="p-4">
                        {recentMemoryTitle !== "" &&
                          recentMemoryDescription !== "" && (
                            <>
                              <h3 className="mb-0 fs-24 fs-md-36 fontsmall">
                                {recentMemoryTitle}
                              </h3>
                              <div className="fs-16 fs-md-24 lh-28 lh-md-36 font-weight-Light text-black mb-5 fontsmalltext">
                                {recentMemoryDescription}
                              </div>
                            </>
                          )}

                        {(recentMemoryTitle === "" ||
                          recentMemoryDescription === "") &&
                          (this.state.isLoading === true ? (
                            <div className="text-center">
                              <CircularProgress />
                            </div>
                          ) : (
                            <h4 className="pl-2 mb-0 opa-60 font-weight-Regular">
                              Sorry, no memory information found.
                            </h4>
                          ))}

                        {recentMemoryTitle !== "" &&
                          recentMemoryDescription !== "" && (
                            <div>
                              <Button
                                className={
                                  "bg-color1 text-white text-capitalize fs-14 fs-md-19 pad-y-7 pad-x-5 pad-x-md-25 mar-l-20 "
                                }
                                onClick={(e) => this.viewFullMemory()}
                              >
                                <i className="material-icons fs-17 fs-md-24 mr-2">
                                  mms
                                </i>{" "}
                                View full memory
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}

                  <div className="col-md-11 px-0 px-md-3 mx-auto pt-4 pt-md-5  ">
                    <div className="container--">
                      <div className="card border-0 border-radius-10   min-vh-75">
                        <div className="card-header bg-white px-0">
                          <div className="d-md-flex justify-content-between">
                            <h4 className="fs-18 opa-60 lh-28 lh-md-36 font-weight-Regular text-black mb-0 ">
                              <span className="pl-2">All events</span>
                            </h4>
                          </div>
                        </div>
                        <div className="card-body px-0 pad-y-25 ">
                          <div className="row pt-3">
                            <>
                              {filteredEvents.map(
                                (singleEventlist, eventIndex) => (
                                  <div
                                    className=" col-lg-4 mar-b-30"
                                    key={eventIndex}
                                  >
                                    {singleEventlist.eventMemories !==
                                      undefined &&
                                      singleEventlist.eventMemories.length >
                                        0 && (
                                        <div
                                          onClick={() =>
                                            this.props.history.push(
                                              "/secondary-user-memorylist/" +
                                                singleEventlist._id
                                            )
                                          }
                                          className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded"
                                        >
                                          <div className="fs-28 fs-xxxl-36 font-weight-light text-black">
                                            {singleEventlist.title}
                                          </div>
                                          <div className="">
                                            <img src={EventIcon} alt="" />{" "}
                                            <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                              {
                                                singleEventlist.eventMemories
                                                  .length
                                              }{" "}
                                              Memories
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    {singleEventlist.eventMemories !==
                                      undefined &&
                                      singleEventlist.eventMemories.length ===
                                        0 && (
                                        <div className="cursor-pointer shadow-md pad-15 pad-xxxl-30 rounded">
                                          <div className="fs-28 fs-xxxl-36 font-weight-light text-black">
                                            {singleEventlist.title}
                                          </div>
                                          <div className="">
                                            <img src={EventIcon} alt="" />{" "}
                                            <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                              No Memories
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className={modal}
          open={this.state.setModalOpen}
          onClose={this.handleCloseModal.bind(this)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <>
            {this.state.modalType === "request_memory" && (
              <div className="myCusTomModal d-flex">
                <div className="card   ">
                  <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                    <h3 className="font-weight-Medium fs-24 mb-0 ">
                      <i className="material-icons text-color1 align-middle">
                        event
                      </i>{" "}
                      Request a memory
                    </h3>
                  </div>
                  <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                    <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                      1. For which event are you requesting this memory?
                    </h3>
                    <div className="row">
                      <div className="mar-b-40 col-md-12">
                        <FormControl
                          variant="outlined"
                          className="w-100 bg-white"
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Event Name
                          </InputLabel>

                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Event Name"
                            onChange={this.handleChange}
                            name="title"
                            value={formData.title ? formData.title : ""}
                          >
                            {eventList &&
                              eventList.length > 0 &&
                              eventList.map((list, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={list.title}
                                    onClick={() => this.handleDatevalue(list)}
                                  >
                                    {list.title}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.canUpdateEvent && (
                      <>
                        <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                          2. Is this a recurring event?
                        </h3>
                        <div className="mar-b-40 ">
                          <RadioGroup
                            name="recuringEvent"
                            onChange={this.handleChange}
                            value={
                              formData.recuringEvent !== undefined
                                ? formData.recuringEvent
                                : ""
                            }
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes, this event recurs every year"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No, this event occurs for once"
                            />
                          </RadioGroup>
                        </div>
                      </>
                    )}
                    {this.state.canUpdateEvent && (
                      <>
                        <div className="d-md-flex justify-content-between mar-b-22 align-items-center">
                          <h3 className="text-black mb-0 fs-18 font-weight-Regular">
                            3. Event date
                          </h3>
                        </div>

                        <div className="row">
                          <div className="mar-b-40 col-md-4">
                            <FormControl
                              variant="outlined"
                              className="w-100 bg-white"
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Month
                              </InputLabel>

                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Month"
                                onChange={this.handleChange}
                                name="eventMonth"
                                value={
                                  formData.eventMonth !== undefined
                                    ? formData.eventMonth
                                    : ""
                                }
                              >
                                <MenuItem value="1">January</MenuItem>
                                <MenuItem value="2">February</MenuItem>
                                <MenuItem value="3">March</MenuItem>
                                <MenuItem value="4">April</MenuItem>
                                <MenuItem value="5">May</MenuItem>
                                <MenuItem value="6">June</MenuItem>
                                <MenuItem value="7">July</MenuItem>
                                <MenuItem value="8">August</MenuItem>
                                <MenuItem value="9">September</MenuItem>
                                <MenuItem value="10">October</MenuItem>
                                <MenuItem value="11">November</MenuItem>
                                <MenuItem value="12">December</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="mar-b-40 col-md-4">
                            <TextField
                              type="number"
                              label="Day"
                              name="eventDay"
                              value={
                                formData.eventDay !== undefined
                                  ? formData.eventDay
                                  : ""
                              }
                              onChange={this.handleChange}
                              variant="outlined"
                              className="w-100"
                              onInput={(e) => {
                                var value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 2);
                                if (formData.eventMonth === "2") {
                                  if (value > 29) {
                                    value = 29;
                                  } else if (value < 1) {
                                    value = 1;
                                  }
                                } else if (value > 31) {
                                  value = 31;
                                } else if (value < 1) {
                                  value = 1;
                                }
                                e.target.value = value;
                              }}
                            />
                          </div>
                          <div className="mar-b-40 col-md-4">
                            <FormControl
                              variant="outlined"
                              className="w-100 bg-white"
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Year
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Year"
                                onChange={this.handleChange}
                                name="eventYear"
                                value={
                                  formData.eventYear !== undefined
                                    ? formData.eventYear
                                    : ""
                                }
                              >
                                {rows.map((value, index) => {
                                  return (
                                    <MenuItem value={value} key={index}>
                                      {value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>

                            {/* <TextField
                              type="number"
                              label="Year"
                              variant="outlined"
                              className="w-100"
                              name="eventYear"
                              value={
                                formData.eventYear !== undefined
                                  ? formData.eventYear
                                  : ""
                              }
                              onChange={this.handleChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                            /> */}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="card-footer bg-white text-right pad-x-40 ">
                    {this.state.isSubmitForm === true && <CircularProgress />}

                    {this.state.isSubmitForm === false && (
                      <>
                        <Button
                          className="text-color1 fs-19 font-weight-Medium text-capitalize"
                          onClick={this.handleCloseModal.bind(this)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="text-color1 fs-19 font-weight-Medium text-capitalize background_color"
                          onClick={this.handleSubmit}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            {this.state.modalType === "create_contact" && (
              <div className="myCusTomModal d-flex">
                <div className="card">
                  <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                    <h3 className="font-weight-Medium fs-24 mb-0 ">
                      <i className="material-icons text-color1 align-middle">
                        person_add
                      </i>{" "}
                      Create a contact
                    </h3>
                  </div>
                  <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                    <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                      1. What is the name of this person?
                    </h3>
                    <div className="row">
                      <div className="mar-b-40 col-md-6">
                        <TextField
                          label="First Name"
                          variant="outlined"
                          className="w-100"
                          name="firstName"
                          value={createContactFormData.firstName}
                          InputProps={{
                            readOnly: true,
                          }}
                          //onChange={this.handleChangeCreateContact}
                        />
                      </div>
                      <div className="mar-b-40 col-md-6">
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          className="w-100"
                          name="lastName"
                          value={createContactFormData.lastName}
                          InputProps={{
                            readOnly: true,
                          }}
                          //onChange={this.handleChangeCreateContact}
                        />
                      </div>
                    </div>

                    <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                      2. What is your relationship with this person?{" "}
                      <span style={{ fontWeight: "300", fontStyle: "italic" }}>
                        (Wife, Brother etc)
                      </span>
                    </h3>
                    <div className="row">
                      <div className="mar-b-40 col-md-12">
                        <FormControl variant="outlined" className={"w-100"}>
                          <InputLabel id="demo-simple-select-label">
                            Relationship
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            name="relationship_id"
                            label="Select your limit"
                            onChange={this.handleChangeCreateContact}
                            value={createContactFormData.relationship_id}
                          >
                            {allRelationshipType.map(
                              (singleRel, singleRelIndex) => (
                                <MenuItem
                                  value={singleRel._id}
                                  key={singleRelIndex}
                                  onClick={() =>
                                    this.handleUpdatelabel(singleRel.name)
                                  }
                                >
                                  {singleRel.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    {this.state.is_show_other_relationship_type === true && (
                      <>
                        <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                          Other relationship type
                        </h3>
                        <div className="row">
                          <div className="mar-b-40 col-md-12">
                            <TextField
                              label=""
                              variant="outlined"
                              className="w-100"
                              name="nature_of_relationship"
                              value={
                                createContactFormData.nature_of_relationship
                              }
                              onChange={this.handleChangeCreateContact}
                              placeholder="Other"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                      3. Add an email address -{/* or phone number - */}
                    </h3>
                    <div className="row">
                      <div className="  col-md-12">
                        <TextField
                          label="Email Address"
                          name="email"
                          //onChange={this.handleChangeCreateContact}
                          variant="outlined"
                          className="w-100"
                          value={createContactFormData.email}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>
                      {/*<div className=" col-md-12 py-2">or</div>
                      <div className="mar-b-40 col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <FormControl variant="outlined" className={"w-100"}>
                              <InputLabel id="demo-simple-select-label">
                                Country Code
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="country_code"
                                label="Select your limit"
                                onChange={(e) =>
                                  this.setState({
                                    currentCountryCode: e.target.value,
                                  })
                                }
                              >
                                {countryCode.map((singleCode, singleCodeIndex) => (
                                  <MenuItem
                                    value={singleCode.code}
                                    key={singleCodeIndex}
                                  >
                                    {singleCode.code} - {singleCode.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-md-8 pt-4 pt-md-0">
                            <TextField
                              label="Phone Number"
                              name="phone"
                              onChange={this.handleChangeCreateContact}
                              variant="outlined"
                              className="w-100"
                            />
                          </div>
                        </div>
                      </div>*/}
                    </div>

                    {/*<h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                      4. Create a customised invitation (optional) -
                    </h3>
                    <div className="row">
                      <div className="mar-b-40 col-md-12">
                        <TextField
                          multiline
                          rows={4}
                          name="customInvitation"
                          onChange={this.handleChangeCreateContact}
                          label="Write a message"
                          variant="outlined"
                          className="w-100"
                        />
                        <p className="fs-12  text-black opa-60 text-right">0/250</p>
                      </div>
                    </div>

                    <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                      5. Set contact photo(optional)-
                    </h3>
                    <div className="row">
                      <div className="mar-b-40 col-md-12">
                        <input
                          hidden
                          ref={(contactPhoto) => (this.contactPhoto = contactPhoto)}
                          type="file"
                          accept="image/*"
                          onChange={(e) => this.fileUploadChange(e)}
                        />
                        <div
                          className="d-inline-flex cp align-items-center fs-19"
                          onClick={this.setContactPhoto}
                        >
                          <div>
                            <img
                              src={
                                this.state.profilePreviewImage !== "" &&
                                this.state.profilePreviewImage !== null &&
                                this.state.profilePreviewImage !== undefined
                                  ? this.state.profilePreviewImage
                                  : UIco
                              }
                              alt=""
                              className="w-60px h-60px object-fit-cover rounded-circle"
                            />
                          </div>
                          <div className="pl-2 text-primary font-weight-Medium">
                            Upload photo
                          </div>
                        </div>
                      </div>
                    </div>*/}
                  </div>
                  <div className="card-footer bg-white text-right pad-x-40 ">
                    {this.state.isSubmitForm === true && <CircularProgress />}

                    {this.state.isSubmitForm === false && (
                      <>
                        <Button
                          className="text-color1 fs-19 font-weight-Medium text-capitalize"
                          onClick={this.handleCloseModal.bind(this)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="{text-color1 fs-19 font-weight-Medium text-capitalize opa-70 background_color"
                          disabled={this.state.isAddContactChangeValue}
                          onClick={this.handleSubmitCreateContact}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryUserDashboard);
