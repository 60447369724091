import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";

// import ImagePath from '../assets/ImagePath';

import logo from "../assets/images/logo.svg";
import WLogo from "../assets/images/whiteLogo.svg";
import InnerLogo from "../assets/images/Logo2.svg";
import menuIco1 from "../assets/images/icon12.svg";
import menuIco2 from "../assets/images/icon13.svg";
import menuIco3 from "../assets/images/icon14.svg";
import menuIco4 from "../assets/images/icon15.svg";
import UploadURLS from "../assets/settings.js";
import pusherChannel from "../assets/pusherSettings.js";
import HelpOutlineOutlined from "@material-ui/icons/HelpOutlineOutlined";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

import Pusher from "pusher-js";
import { Avatar, Button, CircularProgress } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";

class PrimaryHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},

      notificationCounter: 0,
      isSecondStepRedirect: undefined,
      isThreeStepRedirect: undefined,
      isCallingFetchSecondaryUserEvents: false,
      isHomePage: false,
      isLoading: true,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  isMobileMenuOpen = () => {
    if (!this.wrapperRef.current.classList.contains("active")) {
      this.wrapperRef.current.classList.add("active");
    } else {
      this.wrapperRef.current.classList.remove("active");
    }
  };

  logout(e) {
    e.preventDefault();
    /*var THIS = this;
        localStorage.removeItem("auth_token");
        localStorage.removeItem("user_info");
        setTimeout(function(){
            THIS.props.history.push('/');
        })*/

    this.props.miscActions.logoutUserProfile();
  }

  componentDidUpdate() {
    var currentUserRole = "";
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (this.state.isSecondStepRedirect === true) {
      this.props.history.push("/sign-up-secondary-user-step-two");
      this.setState({
        isSecondStepRedirect: false,
      });
    }

    //console.log("currentUserRole",currentUserRole);
    //console.log("this.state.isThreeStepRedirect",this.state.isThreeStepRedirect);

    if (
      this.state.isThreeStepRedirect === true &&
      currentUserRole === "sub-user"
    ) {
      this.props.history.push("/secondary-user-step-three");
      this.setState({ isThreeStepRedirect: false });
    }
  }

  static getDerivedStateFromProps(props, state) {
    var returnData = state;

    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        case miscActions.GET_PROFILE_DETAILS_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          //console.log("userInfo",userInfo);

          props.miscActions.clearState();
          localStorage.setItem("user_info", JSON.stringify(userInfo));
          return {};
          break;
        case miscActions.GET_PROFILE_DETAILS_FAILED:
          props.miscActions.clearState();
          return {};
          break;

        case miscActions.LOGOUT_USER_PROFILE_SUCCESS:
          localStorage.removeItem("auth_token");
          localStorage.removeItem("user_info");
          localStorage.clear();
          //props.history.push("/");
          window.location.href = "/";
          //return {};
          break;
        case miscActions.LOGOUT_USER_PROFILE_FAILED:
          //return {};
          break;

        case miscActions.FETCH_NOTIFICATION_LIST_SUCCESS:
          var notificationList = miscReducerData.responce.data
            ? miscReducerData.responce.data
            : [];

          var notificationLength = 0;
          if (notificationList.length > 0) {
            notificationList.forEach(function (singleitm, singleIndx) {
              if (singleitm.read === false) {
                notificationLength++;
              }
            });
          }

          returnData["notificationCounter"] = notificationLength;
          break;

        default:
        //return {};
      }
    }

    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        /* Fetch all events */
        case contactActions.FETCH_EVENT_DATES_SUCCESS:
          props.contactActions.clearState();
          returnData["isSecondStepRedirect"] = true;
          break;

        case contactActions.FETCH_EVENT_DATES_FAILED:
          props.miscActions.clearState();
          props.contactActions.fetchSecondaryUserEvents();
          returnData["isSecondStepRedirect"] = false;
          break;

        case contactActions.FETCH_SECONDARY_USER_EVENT_SUCCESS:
          /* Filter event */
          var filteredEvents =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.filteredEvents !== undefined
              ? contactReducerData.responce.data.filteredEvents
              : [];
          props.contactActions.clearState();

          if (filteredEvents.length === 0) {
            returnData["isThreeStepRedirect"] = true;
          }

          break;

        case contactActions.FETCH_SECONDARY_USER_EVENT_FAILED:
          returnData["isThreeStepRedirect"] = false;
          props.contactActions.clearState();
          break;

        default:
          break;
      }
    }

    //return {};
    return returnData;
  }

  componentDidMount() {
    var THIS = this;

    var currentUserRole = "";
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (currentUserRole === "sub-user" || currentUserRole === "user") {
      this.props.contactActions.fetchEventDates();
      THIS = this;
      setTimeout(function () {
        THIS.props.miscActions.getProfileDetails();
      }, 2000);
    }

    if (user_info !== null && user_info !== "" && user_info !== undefined) {
      this.setState({ userDetails: user_info });
      /* Fetch notification initial time */
      THIS.props.miscActions.fetchNotifications();
    }

    // Forcefully logout if the user deleted from the admin
    pusherChannel.bind("session-expired", (data) => {
      if (data?.data?.user_id === user_info?._id) {
        localStorage.clear();

        confirmAlert({
          title: "Session expired!",
          message: "",
          closeOnClickOutside: false,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.props.history.push("/");
              },
            },
          ],
        });
      }
    });


   

    /* Dashboard Notification */
    pusherChannel.bind("dashboard", (data) => {
      THIS.props.miscActions.fetchNotifications();
    });

    /* Signup notification */
    pusherChannel.bind("user-signup-notification", (data) => {
      THIS.props.miscActions.fetchNotifications();
    });

    /* Event Notification */
    pusherChannel.bind("significant-event-notification", (data) => {
      THIS.props.miscActions.fetchNotifications();
    });
    document.addEventListener("click", this.handleClickOutside);

    //  console.log(this.props.history.location.pathname)
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    var om1Element = document?.getElementById("om1");
    var isClickom1 = om1Element?.contains(event?.target);
    if (
      !isClickom1 &&
      document?.querySelector(".homeHeader nav")?.classList?.contains("active")
    ) {
      document.querySelector(".homeHeader nav").classList.toggle("active");
      //   //alert("You clicked outside of me!");
    }
  }

  render() {
    var currentUserRole = "";
    var is_login = false;
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }

    if (auth_token !== "" && auth_token !== undefined && auth_token !== null) {
      is_login = true;
    }

    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }
    if (currentUserRole === "user") {
      var link_src = "/dashboard";
    } else {
      link_src = "/secondary-user-dashboard";
    }

    // if (this.state.isLoading === true) {
    //   return (
    //     <div className="page-center">
    //       <CircularProgress />
    //     </div>
    //   );
    // }

    return (
      <>
        <header
          className={"pad-y-20 homeHeader border-bottom" + this.props.position}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-auto webLogo">
                {this.props.position == "absoluteHeader" ? (
                  <Link to="">
                    {/* <img src={WLogo} alt="Logo" /> */}
                    <img src={InnerLogo} alt="Logo12" />
                  </Link>
                ) : this.props.position == "innerHeader" ? (
                  <Link to={link_src}>
                    <img src={InnerLogo} alt="Logo" />
                  </Link>
                ) : (
                  <Link to="">
                    {/* <img src={logo} alt="Logo" /> */}
                    <img src={logo} alt="Logo12" />
                  </Link>
                )}
              </div>
              <div className="col-auto">
                <nav onClick={this.isMobileMenuOpen} ref={this.wrapperRef}>
                  <div className="d-flex d-lg-none justify-content-between align-items-center bg-white p-2">
                    <div className="m-Logo">
                      <Link to="">
                        <img src={logo} alt="Logo" />
                      </Link>
                    </div>
                    <div>
                      <button
                        className="btn shadow-none  closeBtn"
                        // onClick={this.isMobileMenuOpen}
                      >
                        <i className="material-icons align-middle">close</i>
                      </button>
                    </div>
                  </div>
                  <ul className="list-unstyled d-lg-flex mb-0 align-items-center">
                    {is_login === true && (
                      <>
                        {this.props.history.location.pathname !==
                        "/sign-up-secondary-user-step-two" ? (
                          <li>
                            {currentUserRole === "sub-user" && (
                              <NavLink
                                to="/secondary-user-dashboard"
                                className="d-flex align-items-center rounded"
                              >
                                <img
                                  src={menuIco1}
                                  alt=""
                                  style={{ marginRight: "3px" }}
                                />{" "}
                                <span className="pl-1">Dashboard</span>
                              </NavLink>
                            )}

                            {currentUserRole === "user" && (
                              <NavLink
                                to="/dashboard"
                                className="d-flex align-items-center rounded"
                              >
                                <img
                                  src={menuIco1}
                                  alt=""
                                  style={{ marginRight: "3px" }}
                                />{" "}
                                <span className="pl-1">Dashboard</span>
                              </NavLink>
                            )}
                          </li>
                        ) : (
                          <li>
                            {currentUserRole === "sub-user" && (
                              <span className="d-flex align-items-center rounded">
                                <img
                                  src={menuIco1}
                                  alt=""
                                  style={{ marginRight: "3px" }}
                                />{" "}
                                <span className="pl-1">Dashboard</span>
                              </span>
                            )}

                            {currentUserRole === "user" && (
                              <NavLink
                                to="/dashboard"
                                className="d-flex align-items-center rounded"
                              >
                                <img
                                  src={menuIco1}
                                  alt=""
                                  style={{ marginRight: "3px" }}
                                />{" "}
                                <span className="pl-1">Dashboard</span>
                              </NavLink>
                            )}
                          </li>
                        )}
                      </>
                    )}

                    {is_login === false && (
                      <>
                        <li>
                          <NavLink
                            to="/help"
                            className="d-flex align-items-center rounded"
                          >
                            {this.props.history.location.pathname === "/" &&
                              this.props.history.location.pathname !==
                                "/sign-up" &&
                              this.props.history.location.pathname !==
                                "/sign-in" && <>FAQs</>}
                            {this.props.history.location.pathname !== "/" && (
                              <>
                                <HelpOutlineOutlined
                                  style={{ marginRight: "5px", color: "#000" }}
                                />
                                Help
                              </>
                            )}
                          </NavLink>
                        </li>
                        <li>
                          {this.props.history.location.pathname !== "/" &&
                            this.props.history.location.pathname !==
                              "/sign-up" &&
                            this.props.history.location.pathname !==
                              "/sign-in" &&
                            this.props.history.location.pathname !==
                              "/sign-up-secondary-user" &&
                            this.props.history.location.pathname !==
                              "/sign-up-step-two" &&
                            this.props.history.location.pathname !==
                              "/sign-up-step-three" &&
                            this.props.history.location.pathname !==
                              "/sign-up-step-four" &&
                            this.props.history.location.pathname !==
                              "/sign-up-step-five" &&
                            this.props.history.location.pathname !==
                              "/sign-up-final-step" && (
                              <NavLink
                                to="/sign-in"
                                className="d-flex align-items-center rounded"
                              >
                                Sign In
                              </NavLink>
                            )}

                          {this.props.history.location.pathname === "/" &&
                            this.props.history.location.pathname !==
                              "/sign-up" &&
                            this.props.history.location.pathname !==
                              "/sign-in" && (
                              <NavLink
                                to="/sign-in"
                                className="d-flex align-items-center rounded"
                              >
                                Sign In
                              </NavLink>
                            )}
                        </li>

                        <li>
                          {this.props.history.location.pathname === "/" &&
                            this.props.history.location.pathname !==
                              "/sign-up" &&
                            this.props.history.location.pathname !==
                              "/sign-in" &&
                            this.props.history.location.pathname !==
                              "/sign-up-secondary-user" && (
                              <NavLink
                                to="/sign-up"
                                className="d-flex align-items-center rounded"
                              >
                                <Button className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize">
                                  Sign Up
                                </Button>
                              </NavLink>
                            )}

                          {this.props.history.location.pathname !== "/" &&
                            this.props.history.location.pathname !==
                              "/sign-up" &&
                            this.props.history.location.pathname !==
                              "/sign-in" &&
                            this.props.history.location.pathname !==
                              "/sign-up-secondary-user" &&
                            this.props.history.location.pathname !==
                              "/sign-up-step-two" &&
                            this.props.history.location.pathname !==
                              "/sign-up-step-three" &&
                            this.props.history.location.pathname !==
                              "/sign-up-step-four" &&
                            this.props.history.location.pathname !==
                              "/sign-up-step-five" &&
                            this.props.history.location.pathname !==
                              "/sign-up-final-step" && (
                              <NavLink
                                to="/sign-up"
                                className="d-flex align-items-center rounded"
                              >
                                Sign Up
                              </NavLink>
                            )}
                        </li>

                        {/* <li>
                          <NavLink
                            to="/sign-in"
                            className="d-flex align-items-center border rounded"
                          >
                            Sign in
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/sign-up"
                            className="d-flex align-items-center border rounded"
                          >
                            Sign up
                          </NavLink>
                        </li> */}
                      </>
                    )}

                    {is_login === true && (
                      <>
                        <li>
                          {this.props.history.location.pathname !==
                          "/sign-up-secondary-user-step-two" ? (
                            <NavLink
                              to="/help"
                              className="d-flex align-items-center rounded"
                            >
                              <HelpOutlineOutlined
                                style={{ marginRight: "5px", color: "#000" }}
                              />
                              <span className="pl-1">Help</span>
                            </NavLink>
                          ) : (
                            <span className="d-flex align-items-center rounded ml-3">
                              <HelpOutlineOutlined
                                style={{ marginRight: "5px", color: "#000" }}
                              />
                              <span className="pl-1">Help</span>
                            </span>
                          )}
                        </li>
                        <li>
                          {this.props.history.location.pathname !==
                          "/sign-up-secondary-user-step-two" ? (
                            <NavLink
                              to="/notification"
                              className="d-flex align-items-center rounded"
                            >
                              <img
                                src={menuIco3}
                                alt=""
                                style={{ marginRight: "3px" }}
                              />{" "}
                              <span className="pl-1">Notification</span>
                              {this.state.notificationCounter > 0 && (
                                <i
                                  className="w-10px h-10px rounded-circle  d-inline-flex ml-2"
                                  style={{ background: "#FF1469" }}
                                ></i>
                              )}
                            </NavLink>
                          ) : (
                            <span className="d-flex align-items-center rounded ml-3">
                              <img
                                src={menuIco3}
                                alt=""
                                style={{ marginRight: "3px" }}
                              />{" "}
                              <span className="pl-1">Notification</span>
                              {this.state.notificationCounter > 0 && (
                                <i
                                  className="w-10px h-10px rounded-circle  d-inline-flex ml-2"
                                  style={{ background: "#FF1469" }}
                                ></i>
                              )}
                            </span>
                          )}
                        </li>
                        <li>
                          {this.props.history.location.pathname !==
                          "/sign-up-secondary-user-step-two" ? (
                            <NavLink
                              to={
                                this.props.history.location.pathname ===
                                "/sign-up-secondary-user-step-two"
                                  ? "/sign-up-secondary-user-step-two"
                                  : "/settings"
                              }
                              className="d-flex align-items-center rounded"
                            >
                              {user_info?.profile_image !== "" &&
                                user_info?.profile_image !== null &&
                                user_info?.profile_image !== undefined && (
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={
                                      UploadURLS?.profile_image_path +
                                      user_info?.profile_image
                                    }
                                    className="w-30px w-xl-40px h-30px h-xl-40px rounded-circle object-fite-cover"
                                  />
                                )}

                              {(user_info?.profile_image === "" ||
                                user_info?.profile_image === null ||
                                user_info?.profile_image === undefined) && (
                                <img
                                  src={menuIco4}
                                  alt=""
                                  className="w-30px w-xl-40px h-30px h-xl-40px rounded-circle object-fite-cover"
                                />
                              )}
                              <span className="pl-2">My Settings</span>
                            </NavLink>
                          ) : (
                            <span className="d-flex align-items-center rounded ml-3">
                              {user_info?.profile_image !== "" &&
                                user_info?.profile_image !== null &&
                                user_info?.profile_image !== undefined && (
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={
                                      UploadURLS?.profile_image_path +
                                      user_info?.profile_image
                                    }
                                    className="w-30px w-xl-40px h-30px h-xl-40px rounded-circle object-fite-cover"
                                  />
                                )}

                              {(user_info?.profile_image === "" ||
                                user_info?.profile_image === null ||
                                user_info?.profile_image === undefined) && (
                                <img
                                  src={menuIco4}
                                  alt=""
                                  className="w-30px w-xl-40px h-30px h-xl-40px rounded-circle object-fite-cover"
                                />
                              )}
                              <span className="pl-2">My Settings</span>
                            </span>
                          )}
                        </li>
                        {/* <li>
                          <a
                            href=""
                            onClick={(e) => this.logout(e)}
                            className="d-flex align-items-center border rounded"
                          >
                            <span className="pl-2">Help</span>
                          </a>
                        </li> */}
                      </>
                    )}

                    {/* <li>
                      <NavLink
                        to="/help"
                        className="d-flex align-items-center border rounded"
                      >
                        <img src={menuIco2} alt="" />{" "}
                        <span className="pl-1">Contact Us</span>
                      </NavLink>
                    </li> */}
                  </ul>
                </nav>

                <div className="d-block d-lg-none " id="om1">
                  <button
                    className="btn shadow-none text-color1"
                    onClick={this.isMobileMenuOpen}
                  >
                    <i className="material-icons align-middle">menu</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrimaryHeader));

//export default withRouter(PrimaryHeader);
