import Pusher from 'pusher-js';
/*const pusher = new Pusher('APP_KEY', {
  cluster: 'APP_CLUSTER',
  encrypted: true
});
const channel = pusher.subscribe('chat');
*/


// Dev: b2615272d7e7d093315c
//Live: f077ebc51dbf89640ad8

const pusher = new Pusher('f077ebc51dbf89640ad8', { //PUSHER_KEY
  cluster: 'us2', //PUSHER_CLUSTER
  encrypted: true
});
const pusherChannel = pusher.subscribe('forget_me_not');


export default pusherChannel;