import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PrimaryHeader from "../Widgets/PrimaryHeader";
import UserIcon from "../assets/images/icon21.svg";
import EventIcon from "../assets/images/icon22.svg";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";

const modal = "d-flex align-items-center";

class SecondaryUserMemoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      setModalOpen: false,
      eventDetails: "",
      memoriesList: [],
      userDetails: "",
      isLoading: true,

      isDisabledSubmit: false,
      isSubmitForm: false,
      apiErrorMessage: "",
      apiSuccessMessage: "",
      isRedirect: false,
    };
  }

  componentDidUpdate() {
    if (
      this.state.apiErrorMessage !== "" &&
      this.state.apiErrorMessage !== null &&
      this.state.apiErrorMessage !== undefined
    ) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    } else if (
      this.state.apiSuccessMessage !== "" &&
      this.state.apiSuccessMessage !== null &&
      this.state.apiSuccessMessage !== undefined
    ) {
      toast.success(this.state.apiSuccessMessage);
      this.state.apiErrorMessage = "";
      this.state.apiSuccessMessage = "";
    }

    var eventDetails = this.state.eventDetails;
    var user_contact_id =
      eventDetails !== undefined &&
        eventDetails !== "" &&
        eventDetails !== null &&
        eventDetails.user_contact_id !== undefined &&
        eventDetails.user_contact_id !== null &&
        eventDetails.user_contact_id !== "" &&
        eventDetails.user_contact_id._id !== undefined &&
        eventDetails.user_contact_id._id !== null &&
        eventDetails.user_contact_id._id !== ""
        ? eventDetails.user_contact_id._id
        : "";

    if (this.state.isRedirect === true && user_contact_id !== "") {
      this.state.isRedirect = false;
      this.props.history.push("/view-contact/" + user_contact_id);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;

      switch (contactReducerData.type) {
        /* FETCH ALL CONTACT INFO */
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_SUCCESS:
          var eventDetails =
            contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";
          if (eventDetails) {
            props.contactActions.fetchUserMemoriesByEvent(
              eventDetails._id,
              eventDetails.user_id
            );
            props.contactActions.fetchUserdetails(eventDetails.user_id);
            props.contactActions.fetchUserMemoriesByEvent(
              eventDetails._id,
              eventDetails.user_id
            );
            props.contactActions.clearState();
            return {
              eventDetails: eventDetails,
              isLoading: false,
            };
          } else {
            props.contactActions.clearState();
            return {
              eventDetails: "",
              isLoading: false,
            };
          }

          break;
        case contactActions.FETCH_SINGLE_EVENT_DETAILS_FAILED:
          return {
            eventDetails: "",
            isLoading: false,
          };
          break;

        /* Fetch memory */
        case contactActions.FETCH_MEMORIES_BY_EVENT_SUCCESS:
          let memories = contactReducerData.responce.data
            ? contactReducerData.responce.data
            : [];
          // console.log("mmmm0", memories);
          props.contactActions.clearState();
          return {
            memoriesList: memories,
          };
          break;

        //Fetch user details
        case contactActions.FETCH_USER_DETAILS_SUCCESS:
          let userdetails = contactReducerData.responce.data
            ? contactReducerData.responce.data.data
            : [];
          // console.log("Userdetails", userdetails);

          props.contactActions.clearState();
          return {
            userDetails: { invitedBy: userdetails },
          };
          break;

        //Update event success..
        case contactActions.UPDATE_EVENT_SUCCESS: {
          var eventDetails =
            contactReducerData !== undefined &&
              contactReducerData.responce !== undefined &&
              contactReducerData.responce.data !== undefined &&
              contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : "";
          if (eventDetails) {
            props.contactActions.fetchUserMemoriesByEvent(
              eventDetails._id,
              eventDetails.user_id
            );
            props.contactActions.fetchUserdetails(eventDetails.user_id);
            props.contactActions.fetchUserMemoriesByEvent(
              eventDetails._id,
              eventDetails.user_id
            );
            props.contactActions.clearState();
            return {
              eventDetails: eventDetails,
              isLoading: false,
            };
          } else {
            props.contactActions.clearState();
            return {
              eventDetails: "",
              isLoading: false,
            };
          }

          break;
        }
      }
    }

    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        /* Fetch User Profile */
        case miscActions.GET_PROFILE_DETAILS_SUCCESS:
          var userInfo = miscReducerData.responce.data.data
            ? miscReducerData.responce.data.data
            : "";
          props.miscActions.clearState();
          return {
            //userDetails: userInfo,
          };
          break;
        case miscActions.GET_PROFILE_DETAILS_FAILED:
          props.miscActions.clearState();
          return {
            userDetails: "",
          };
          break;
      }
    }

    return {};
  }

  componentDidMount() {
    var currentUserRole = "";
    var auth_token = localStorage.getItem("auth_token");
    var user_info = localStorage.getItem("user_info");
    if (user_info !== "") {
      user_info = JSON.parse(user_info);
    }
    if (
      user_info !== "" &&
      user_info !== undefined &&
      user_info !== null &&
      user_info.role !== "" &&
      user_info.role !== null &&
      user_info.role !== undefined &&
      user_info.role.role !== "" &&
      user_info.role.role !== null &&
      user_info.role.role !== undefined
    ) {
      currentUserRole = user_info.role.role;
    }

    if (
      auth_token === "" ||
      auth_token === undefined ||
      auth_token === null ||
      currentUserRole === "" ||
      currentUserRole === undefined ||
      currentUserRole === null
    ) {
      this.props.history.push("/");
    } else {
      var currentEventId =
        this.props.match !== undefined &&
          this.props.match.params !== undefined &&
          this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "";
      if (
        currentEventId === "" ||
        currentEventId === null ||
        currentEventId === undefined
      ) {
        this.props.history.push("/");
      } else {
        this.props.contactActions.fetchSingleEventDetails(currentEventId);
        /*GET PROFILE  DETAILS*/
        this.props.miscActions.getProfileDetails();
      }
    }
  }

  handleClick = (data, eventDetails) => {
    if (
      data.memories &&
      Object.keys(data.memories).length > 0 &&
      data.memories.scheduled === true
    ) {
      this.props.history.push({
        pathname: "/view-memory/" + data.memories._id,
        state: {
          detail: data,
          eventDetails: eventDetails,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/create-memory/" + eventDetails._id,
        state: {
          detail: data,
          eventDetails: eventDetails,
        },
      });
    }
  };

  unlock = (memory) => {
    var check = moment();
    var month = check.format("MM");
    var day = check.format("DD");
    var year = check.format("YYYY");

    const formData = {
      eventDay: day,
      eventMonth: month,
      eventYear: year,
      eventId: memory.memories.event_id,
      parent_user: memory.memories.user_id,
      notSure: false,
      dateUnknown: false,
    };
    console.log(formData);
    confirmAlert({
      title: "Sure to continue?",
      message: "You cannot revert back",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.contactActions.updateEvent(formData),
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    var formData = this.state.formData;
    var eventDetails = this.state.eventDetails;
    var isLoading = this.state.isLoading;

    var parentUserName =
      this.state.userDetails !== undefined &&
        this.state.userDetails !== null &&
        this.state.userDetails !== "" &&
        this.state.userDetails.invitedBy !== undefined &&
        this.state.userDetails.invitedBy !== null &&
        this.state.userDetails.invitedBy !== ""
        ? this.state.userDetails.invitedBy.first_name +
        " " +
        this.state.userDetails.invitedBy.last_name
        : "";
    var parentUserName =
      this.state.userDetails !== undefined &&
        this.state.userDetails !== null &&
        this.state.userDetails !== "" &&
        this.state.userDetails.invitedBy !== undefined &&
        this.state.userDetails.invitedBy !== null &&
        this.state.userDetails.invitedBy !== ""
        ? this.state.userDetails.invitedBy.first_name +
        " " +
        this.state.userDetails.invitedBy.last_name
        : "";

    return (
      <>
        <div className="d-flex vh-100 flex-column ">
          <div>
            <PrimaryHeader position={"innerHeader"} />
          </div>
          {eventDetails !== undefined &&
            eventDetails !== null &&
            eventDetails !== "" &&
            isLoading === false && (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="card border-0 border-radius-10 min-vh-75 overflow-hidden">
                    <div className="card-header pad-x-20 pad-x-md-46 pad-y-15 bg-white">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-black fs-22 fs-md-24 font-weight-Bold mb-0">
                          <i className="material-icons text-color1 align-middle fs-30">
                            event
                          </i>{" "}
                          <span className="pl-2">
                            {/* {eventDetails.title !== undefined &&
                              eventDetails.title !== null &&
                              eventDetails.title !== ""
                              ? eventDetails.title
                              : ""}  */}

                            {eventDetails.title !== undefined &&
                              eventDetails.title !== null &&
                              eventDetails.title !== ""
                              ? eventDetails.title.includes("Birthday")
                                ? eventDetails.user_contact_id.firstName !==
                                  undefined
                                  ? "Your " +
                                  eventDetails.title + " "
                                  : ""
                                : eventDetails.title + ' '
                              : " "}

                              | {eventDetails.eventDate !== undefined &&
                              eventDetails.eventDate !== null &&
                              eventDetails.eventDate !== ""
                              ? moment(eventDetails.eventDate).format("Do MMMM")
                              : ""}
                          </span>
                        </h3>

                        <div className="position-relative"></div>
                      </div>
                    </div>
                    <div className="card-body pad-x-20 pad-x-md-46 pad-y-25 ">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb bg-white text-nowrap flex-nowrap overflow-auto p-0">
                          <li className="breadcrumb-item text-color1">
                            <Link to={"/secondary-user-dashboard"}>
                              {/* <i className="material-icons">home</i> */}
                              <HomeOutlinedIcon
                                style={{ fontSize: "22px", marginTop: "-4px" }}
                              />
                            </Link>
                          </li>
                          {parentUserName !== "" && (
                            <li className="breadcrumb-item text-color1">
                              <Link to={"/secondary-user-dashboard/"}>
                                {parentUserName}
                              </Link>
                            </li>
                          )}
                          <li
                            className="breadcrumb-item text-color1 active"
                            aria-current="page"
                          >
                            {eventDetails.title !== undefined &&
                              eventDetails.title !== null &&
                              eventDetails.title !== ""
                              ? eventDetails.title.includes("Birthday")
                                ? eventDetails.user_contact_id.firstName !==
                                  undefined
                                  ? "Your " +
                                  eventDetails.title + " "
                                  : ""
                                : eventDetails.title + ' '
                              : " "}






                          </li>
                        </ol>
                      </nav>
                      <div className="row">
                        {this.state.memoriesList &&
                          this.state.memoriesList.length > 0 &&
                          this.state.memoriesList.map((list, index) => (
                            <>
                              {list.locked === false && (
                                <div
                                  className="col-sm-6 col-lg-4 mar-b-30 cursor-pointer"
                                  onClick={() =>
                                    this.handleClick(list, eventDetails)
                                  }
                                >
                                  <div className="shadow-md pad-15 pad-xxxl-30 rounded">
                                    <div className="fs-28 fs-xxxl-36 text-black font-weight-Bold">
                                      <img src={EventIcon} alt="" /> {list.year}
                                    </div>
                                    <div className="">
                                      <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                        Memory is unlocked
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {list.locked === true && (
                                <div className="col-sm-6 col-lg-4 mar-b-30">
                                  <div className="shadow-md pad-15 pad-xxxl-30 rounded" style={{ border: '1px solid #e8e2e2', background: '#eeeeee59' }}>
                                    <div className="fs-28 fs-xxxl-36 font-weight-light text-black">
                                      <i className="material-icons align-middle text-color1">
                                        hourglass_full
                                      </i>{" "}
                                      {list.year}
                                    </div>
                                    <div className="">
                                      {list.date != null && (
                                        <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                          Arriving at{" "}
                                          {moment(list.date)
                                            //.tz('America/New_York')
                                            .format("Do MMMM YYYY")}
                                        </span>
                                      )}
                                      {list.date == null &&
                                        list.alertMessage != "" && (
                                          <div
                                            style={{ display: "inline-flex" }}
                                          >
                                            <span className="fs-14 fs-xxxl-18 font-weight-Regular text-black opa-60 pl-1">
                                              {list.alertMessage}
                                            </span>
                                            <Button
                                              variant="contained"
                                              // color="ternary"
                                              onClick={() => this.unlock(list)}
                                            >
                                              Unlock
                                            </Button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {(eventDetails === undefined ||
            eventDetails === null ||
            eventDetails === "") &&
            isLoading === false && (
              <div className="h-100 overflow-auto pad-y-36 bg-color6 dashboardWrap">
                <div className="container">
                  <div className="alert alert-primary" role="alert">
                    Sorry, no data found
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryUserMemoryList);
