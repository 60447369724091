/* Library */
import axiosInstance, { baseURL } from "../../api";
import axios from "axios";
import { toast } from "react-toastify";

/* Actions Types */
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const DASHBOARD_TESTIMONIALS_SUCCESS = "DASHBOARD_TESTIMONIALS_SUCCESS";
export const DASHBOARD_TESTIMONIALS_FAILED = "DASHBOARD_TESTIMONIALS_FAILED";

export const FETCH_EVENT_TYPE_LIST_SUCCESS = "FETCH_EVENT_TYPE_LIST_SUCCESS";
export const FETCH_EVENT_TYPE_LIST_FAILD = "FETCH_EVENT_TYPE_LIST_FAILD";

export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAILED = "FETCH_PLANS_FAILED";

export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_FAILED = "USER_REGISTRATION_FAILED";
export const USER_REGISTRATION_FAILED_CARD = "USER_REGISTRATION_FAILED_CARD";

export const FETCH_CMS_INFO_SUCCESS = "FETCH_CMS_INFO_SUCCESS";
export const FETCH_CMS_INFO_FAILED = "FETCH_CMS_INFO_FAILED";

export const FETCH_BRAND_LOGO_SUCCESS = "FETCH_BRAND_LOGO_SUCCESS";
export const FETCH_BRAND_LOGO_FAILED = "FETCH_BRAND_LOGO_FAILED";

export const FETCH_TESTIMONIALS_SUCCESS = "FETCH_TESTIMONIALS_SUCCESS";
export const FETCH_TESTIMONIALS_FAILED = "FETCH_TESTIMONIALS_FAILED";

export const CHECK_USER_EXISTS_SUCCESS = "CHECK_USER_EXISTS_SUCCESS";
export const CHECK_USER_EXISTS_FAILED = "CHECK_USER_EXISTS_FAILED";

export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAILED = "USER_PROFILE_UPDATE_FAILED";

export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";
export const GET_PROFILE_DETAILS_FAILED = "GET_PROFILE_DETAILS_FAILED";

export const LOGOUT_USER_PROFILE_SUCCESS = "LOGOUT_USER_PROFILE_SUCCESS";
export const LOGOUT_USER_PROFILE_FAILED = "LOGOUT_USER_PROFILE_FAILED";

export const USER_ACCOUNT_DEACTIVATE_SUCCESS =
  "USER_ACCOUNT_DEACTIVATE_SUCCESS";
export const USER_ACCOUNT_DEACTIVATE_FAILED = "USER_ACCOUNT_DEACTIVATE_FAILED";

export const FETCH_USER_CARD_SUCCESS = "FETCH_USER_CARD_SUCCESS";
export const FETCH_USER_CARD_FAILED = "FETCH_USER_CARD_FAILED";

export const USER_PLAN_UPDATE_SUCCESS = "USER_PLAN_UPDATE_SUCCESS";
export const USER_PLAN_UPDATE_FAILED = "USER_PLAN_UPDATE_FAILED";
export const USER_PLAN_UPDATE_FAILED_CARD = "USER_PLAN_UPDATE_FAILED_CARD";

export const FETCH_CMS_DATA_SUCCESS = "FETCH_CMS_DATA_SUCCESS";
export const FETCH_CMS_DATA_FAILED = "FETCH_CMS_DATA_FAILED";

export const FETCH_FAQ_LIST_SUCCESS = "FETCH_FAQ_LIST_SUCCESS";
export const FETCH_FAQ_LIST_FAILED = "FETCH_FAQ_LIST_FAILED";

export const FETCH_NOTIFICATION_LIST_SUCCESS =
  "FETCH_NOTIFICATION_LIST_SUCCESS";
export const FETCH_NOTIFICATION_LIST_FAILED = "FETCH_NOTIFICATION_LIST_FAILED";

// export const NOTIFICATION_PRO_TIPS_LIST_SUCCESS =
//   "NOTIFICATION_PRO_TIPS_LIST_SUCCESS";
// export const NOTIFICATION_PRO_TIPS_LIST_FAILED =
//   "NOTIFICATION_PRO_TIPS_LIST_FAILED";

export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAILED = "READ_NOTIFICATION_FAILED";

export const GET_PROFILE_DETAILS_BY_TOKEN_SUCCESS =
  "GET_PROFILE_DETAILS_BY_TOKEN_SUCCESS";
export const GET_PROFILE_DETAILS_BY_TOKEN_FAILED =
  "GET_PROFILE_DETAILS_BY_TOKEN_FAILED";

/* Get Apple Payment Instant */
export const GET_APPLE_PAYMENT_INTENT_SUCCESS =
  "GET_APPLE_PAYMENT_INTENT_SUCCESS";
export const GET_APPLE_PAYMENT_INTENT_FAILED =
  "GET_APPLE_PAYMENT_INTENT_FAILED";

/* Paypal Pay */
export const GET_PAYPAL_PAY_REDIRECT_URL_SUCCESS =
  "GET_PAYPAL_PAY_REDIRECT_URL_SUCCESS";
export const GET_PAYPAL_PAY_REDIRECT_URL_FAILED =
  "GET_PAYPAL_PAY_REDIRECT_URL_FAILED";

/* Check paypal already payment or not */
export const CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_SUCCESS =
  "CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_SUCCESS";
export const CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_FAILED =
  "CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_FAILED";

/* Change user password */
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAILED = "CHANGE_USER_PASSWORD_FAILED";
/* Contact Us save*/
export const SAVE_CONTACT_US_SUCCESS = "SAVE_CONTACT_US_SUCCESS";
export const SAVE_CONTACT_US_FAILED = "SAVE_CONTACT_US_FAILED";

/* Fetch Signin-Signup CMS*/
export const FETCH_SIGN_CMS_SUCCESS = "FETCH_SIGN_CMS_SUCCESS";
export const FETCH_SIGN_CMS_FAILED = "FETCH_SIGN_CMS_FAILED";

export const FETCH_SOCIAL_LINK_SUCCESS = "FETCH_SOCIAL_LINK_SUCCESS";
export const FETCH_SOCIAL_LINK_FAILED = "FETCH_SOCIAL_LINK_FAILED";

/* Actions Types */
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILED = "DELETE_CARD_FAILED";

export const CLEAR_STATE = "CLEAR_STATE";

export const UPDATE_REGISTRATION_STEP = "UPDATE_REGISTRATION_STEP";

/* Fetch CMS info */
export function fetchCmsInfo(cms_name) {
  return function (dispatch) {
    return axiosInstance
      .get("api/" + cms_name + "/cms")
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchCmsInfoSuccess(res));
        } else {
          dispatch(fetchCmsInfoFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchCmsInfoFailed(data));
      });
  };
}

function fetchCmsInfoSuccess(data) {
  return { type: FETCH_CMS_INFO_SUCCESS, data: data };
}

function fetchCmsInfoFailed(data) {
  return { type: FETCH_CMS_INFO_SUCCESS, data: data };
}

/* Fetch Brand Logos */
export function fetchBrandLogo() {
  return function (dispatch) {
    return axiosInstance
      .get("api/brand-logo/list")
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchBrandLogoSuccess(res));
        } else {
          dispatch(fetchBrandLogoFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchBrandLogoFailed(data));
      });
  };
}

function fetchBrandLogoSuccess(data) {
  return { type: FETCH_BRAND_LOGO_SUCCESS, data: data };
}

function fetchBrandLogoFailed(data) {
  return { type: FETCH_BRAND_LOGO_FAILED, data: data };
}

/* Fetch Testimonials */
export function fetchTestimonials() {
  return function (dispatch) {
    return axiosInstance
      .get("api/testimonials/list")
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchTestimonialsSuccess(res));
        } else {
          dispatch(fetchTestimonialsFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchTestimonialsFailed(data));
      });
  };
}

function fetchTestimonialsSuccess(data) {
  return { type: FETCH_TESTIMONIALS_SUCCESS, data: data };
}

function fetchTestimonialsFailed(data) {
  return { type: FETCH_TESTIMONIALS_FAILED, data: data };
}

/* User Login */
export function userLogin(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/signin", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(userLoginSuccess(res));
        } else {
          dispatch(userLoginFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(userLoginFailed(data));
      });
  };
}

function userLoginSuccess(data) {
  return { type: USER_LOGIN_SUCCESS, data: data };
}

function userLoginFailed(data) {
  return { type: USER_LOGIN_FAILED, data: data };
}

export function forgotPassword(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/reset-password", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(forgotPasswordSuccess(res));
        } else {
          dispatch(forgotPasswordFailure(res));
        }
      })
      .catch((error) => {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(forgotPasswordFailure(data));
      });
  };
}

function forgotPasswordSuccess(data) {
  return { type: FORGOT_PASSWORD_SUCCESS, data: data };
}
function forgotPasswordFailure(data) {
  return { type: FORGOT_PASSWORD_FAILED, data: data };
}

export function getTestimonial() {
  return function (dispatch) {
    return axiosInstance
      .get("api/testimonials/dashboard-content")
      .then((res) => {
        if (res.status === 200) {
          dispatch(getTestimonialSuccess(res.data));
        } else {
          dispatch(getTestimonialFailure(res));
        }
      })
      .catch((err) => {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(forgotPasswordFailure(data));
      });
  };
}

function getTestimonialSuccess(data) {
  return { type: DASHBOARD_TESTIMONIALS_SUCCESS, data: data };
}
function getTestimonialFailure(data) {
  return { type: DASHBOARD_TESTIMONIALS_FAILED, data: data };
}

/* Fetch Event List */
export function fetchEventTypeList(formData) {
  return function (dispatch) {
    return axiosInstance
      .get("api/events/list")
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchEventTypeListSuccess(res));
        } else {
          dispatch(fetchEventTypeListFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchEventTypeListFailed(data));
      });
  };
}

function fetchEventTypeListSuccess(data) {
  return { type: FETCH_EVENT_TYPE_LIST_SUCCESS, data: data };
}

function fetchEventTypeListFailed(data) {
  return { type: FETCH_EVENT_TYPE_LIST_FAILD, data: data };
}

/* Fetch Plan List */

export function fetchFlans(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/plan/list", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchFlansSuccess(res));
        } else {
          dispatch(fetchFlansFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchFlansFailed(data));
      });
  };
}

export function fetchUserFlans(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/plan/list-based-on-contacts", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchFlansSuccess(res));
        } else {
          dispatch(fetchFlansFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchFlansFailed(data));
      });
  };
}

function fetchFlansSuccess(data) {
  return { type: FETCH_PLANS_SUCCESS, data: data };
}

function fetchFlansFailed(data) {
  return { type: FETCH_PLANS_FAILED, data: data };
}

/* User Registration */
export function userRegistration(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/signup", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(userRegistrationSuccess(res));
        } else {
          dispatch(userRegistrationFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(userRegistrationFailedCard(data));
      });
  };
}

function userRegistrationSuccess(data) {
  return { type: USER_REGISTRATION_SUCCESS, data: data };
}

function userRegistrationFailed(data) {
  return { type: USER_REGISTRATION_FAILED, data: data };
}

function userRegistrationFailedCard(data) {
  return { type: USER_REGISTRATION_FAILED_CARD, data: data };
}

/* Check user email is exists or not */

export function checkUserEmailExists(email) {
  return function (dispatch) {
    return axiosInstance
      .get("api/user/check-email/" + email)
      .then((res) => {
        if (res.status === 200) {
          dispatch(checkUserEmailExistsSuccess(res));
        } else {
          dispatch(checkUserEmailExistsFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(checkUserEmailExistsFailed(data));
      });
  };
}

function checkUserEmailExistsSuccess(data) {
  return { type: CHECK_USER_EXISTS_SUCCESS, data: data };
}

function checkUserEmailExistsFailed(data) {
  return { type: CHECK_USER_EXISTS_FAILED, data: data };
}

/* USER PROFILE UPDATE */
export function userProfileUpdate(formData) {
  return function (dispatch) {
    dispatch({ type: USER_PROFILE_UPDATE_REQUEST });
    return axiosInstance
      .post("api/user/update/profile", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {

            }, 1000)

          }
          dispatch(userPrifileUpdateSuccess(res));
        } else {
          dispatch(userPrifileUpdateFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(userPrifileUpdateFailed(data));
      });
  };
}

function userPrifileUpdateSuccess(data) {
  return { type: USER_PROFILE_UPDATE_SUCCESS, data: data };
}

function userPrifileUpdateFailed(data) {
  return { type: USER_PROFILE_UPDATE_FAILED, data: data };
}

/* GET USER PROFILE */
export function getProfileDetails() {
  return function (dispatch) {
    return axiosInstance
      .get("api/user/details")
      .then((res) => {

        //console.log("res :>> ", res.data.data);
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {

            }, 1000)

          }
          dispatch(getProfileDetailsSuccess(res));
        } else {
          console.log('dhhdhddh', res.data.data.length)
          if (res.data.data.length === 0) {
            localStorage.clear()
            setTimeout(() => {

            }, 1000)

          }
          dispatch(getProfileDetailsFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(getProfileDetailsFailed(data));
      });
  };
}

function getProfileDetailsSuccess(data) {
  return { type: GET_PROFILE_DETAILS_SUCCESS, data: data };
}

function getProfileDetailsFailed(data) {
  return { type: GET_PROFILE_DETAILS_FAILED, data: data };
}

/* Logout user profile */
export function logoutUserProfile() {
  return function (dispatch) {
    return axiosInstance
      .get("api/user/logout")
      .then((res) => {
        if (res.status === 200) {
          dispatch(logoutUserProfileSuccess(res));
        } else {
          dispatch(logoutUserProfileFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(logoutUserProfileFailed(data));
      });
  };
}

function logoutUserProfileSuccess(data) {
  return { type: LOGOUT_USER_PROFILE_SUCCESS, data: data };
}

function logoutUserProfileFailed(data) {
  return { type: LOGOUT_USER_PROFILE_FAILED, data: data };
}

/* USER ACCOUNT DEACTIVATE */
export function userAccDeactivate() {
  return function (dispatch) {
    return axiosInstance
      .get("api/user/deactivate")
      .then((res) => {
        if (res.status === 200) {
          dispatch(userAccDeactivateSuccess(res));
        } else {
          dispatch(userAccDeactivateFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(userAccDeactivateFailed(data));
      });
  };
}

function userAccDeactivateSuccess(data) {
  return { type: USER_ACCOUNT_DEACTIVATE_SUCCESS, data: data };
}

function userAccDeactivateFailed(data) {
  return { type: USER_ACCOUNT_DEACTIVATE_FAILED, data: data };
}

/* FETCH USER CARD */
export function fetchUserSavedCard() {
  return function (dispatch) {
    return axiosInstance
      .get("api/user/getcards")
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchUserSavedCardSuccess(res));
        } else {
          dispatch(fetchUserSavedCardFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchUserSavedCardFailed(data));
      });
  };
}

function fetchUserSavedCardSuccess(data) {
  return { type: FETCH_USER_CARD_SUCCESS, data: data };
}

function fetchUserSavedCardFailed(data) {
  return { type: FETCH_USER_CARD_FAILED, data: data };
}

/* USER UPGRADE PLAN */
export function userPlanUpdate(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/upgrade-plan", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {

            }, 1000)

          }
          dispatch(userPlanUpdateSuccess(res));
        } else {
          dispatch(userPlanUpdateFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status:
            error.response !== undefined && error.response.status !== undefined
              ? error.response.status
              : 503,
          data: {},
          message:
            error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.message !== undefined
              ? error.response.data.message
              : "Something Went Wrong! please try again",
        };
        dispatch(userPlanUpdateFailedCard(data));
      });
  };
}

function userPlanUpdateSuccess(data) {
  return { type: USER_PLAN_UPDATE_SUCCESS, data: data };
}

function userPlanUpdateFailed(data) {
  return { type: USER_PLAN_UPDATE_FAILED, data: data };
}

function userPlanUpdateFailedCard(data) {
  return { type: USER_PLAN_UPDATE_FAILED_CARD, data: data };
}

/* FETCH CMS DATA */
export function fetchCmsData(cms_type) {
  return function (dispatch) {
    return axiosInstance
      .get("api/cms/data/" + cms_type)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchCmsDataSuccess(res));
        } else {
          dispatch(fetchCmsDataFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchCmsDataFailed(data));
      });
  };
}

function fetchCmsDataSuccess(data) {
  return { type: FETCH_CMS_DATA_SUCCESS, data: data };
}

function fetchCmsDataFailed(data) {
  return { type: FETCH_CMS_DATA_FAILED, data: data };
}

/* FETCH FAQ LIST */
export function fetchFaqList() {
  return function (dispatch) {
    return axiosInstance
      .get("api/faq/list")
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchFaqListSuccess(res));
        } else {
          dispatch(fetchFaqListFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchFaqListFailed(data));
      });
  };
}

function fetchFaqListSuccess(data) {
  return { type: FETCH_FAQ_LIST_SUCCESS, data: data };
}

function fetchFaqListFailed(data) {
  return { type: FETCH_FAQ_LIST_FAILED, data: data };
}

/* FETCH USER NOTIFICATION */

export function fetchNotifications() {
  return function (dispatch) {
    return axiosInstance
      .get("api/notifications/list")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {

            }, 1000)

          }
          dispatch(fetchNotificationListSuccess(res.data));
        } else {
          dispatch(fetchNotificationListFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchNotificationListFailed(data));
      });
  };
}

function fetchNotificationListSuccess(data) {
  return { type: FETCH_NOTIFICATION_LIST_SUCCESS, data: data };
}

function fetchNotificationListFailed(data) {
  return { type: FETCH_NOTIFICATION_LIST_FAILED, data: data };
}

/* FETCH DASHBOARD PRO TIPS */

// export function fetchDashboardProTips() {
//   return function (dispatch) {
//     return axiosInstance
//       .get("api/notifications/pro-list")
//       .then((res) => {
//         if (res.status === 200) {
//           dispatch(fetchNotificationProTipsSuccess(res));
//         } else {
//           dispatch(fetchNotificationProTipsFailed(res));
//         }
//       })
//       .catch((error) => {
//         const data = {
//           data: {},
//           status: 503,
//           message: "Something Went Wrong! please try again",
//         };
//         dispatch(fetchNotificationProTipsFailed(data));
//       });
//   };
// }

// function fetchNotificationProTipsSuccess(data) {
//   return { type: NOTIFICATION_PRO_TIPS_LIST_SUCCESS, data: data };
// }

// function fetchNotificationProTipsFailed(data) {
//   return { type: NOTIFICATION_PRO_TIPS_LIST_FAILED, data: data };
// }

/* Read Notification */
export function readNotification(notiId) {
  return function (dispatch) {
    return axiosInstance
      .get("api/notifications/read/" + notiId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {

            }, 1000)

          }
          dispatch(readNotificationSuccess(res));
        } else {
          dispatch(readNotificationFailed(res));
        }
      })
      .catch((error) => {
        const data = {
          data: {},
          status: 503,
          message: "Something Went Wrong! please try again",
        };
        dispatch(readNotificationFailed(data));
      });
  };
}

function readNotificationSuccess(data) {
  return { type: READ_NOTIFICATION_SUCCESS, data: data };
}

function readNotificationFailed(data) {
  return { type: READ_NOTIFICATION_FAILED, data: data };
}

/* Get User Details by Token */
export function getUserDetailsByToken(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/fetch-user", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUserDetailsByTokenSuccess(res));
        } else {
          dispatch(getUserDetailsByTokenFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(getUserDetailsByTokenFailed(data));
      });
  };
}

function getUserDetailsByTokenSuccess(data) {
  return { type: GET_PROFILE_DETAILS_BY_TOKEN_SUCCESS, data: data };
}

function getUserDetailsByTokenFailed(data) {
  return { type: GET_PROFILE_DETAILS_BY_TOKEN_FAILED, data: data };
}

/* Get apple payment intent */
export function getApplePayIntent(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/apple-pay", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getApplePayIntentSuccess(res));
        } else {
          dispatch(getApplePayIntentFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(getApplePayIntentFailed(data));
      });
  };
}

function getApplePayIntentSuccess(data) {
  return { type: GET_APPLE_PAYMENT_INTENT_SUCCESS, data: data };
}

function getApplePayIntentFailed(data) {
  return { type: GET_APPLE_PAYMENT_INTENT_FAILED, data: data };
}

/* Paypal Payment */
export function getPaypalRedirectUrl(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/paypal", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getPaypalRedirectUrlSuccess(res));
        } else {
          dispatch(getPaypalRedirectUrlFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(getPaypalRedirectUrlFailed(data));
      });
  };
}

function getPaypalRedirectUrlSuccess(data) {
  return { type: GET_PAYPAL_PAY_REDIRECT_URL_SUCCESS, data: data };
}

function getPaypalRedirectUrlFailed(data) {
  return { type: GET_PAYPAL_PAY_REDIRECT_URL_FAILED, data: data };
}

/* Check Paypal payment already success or not */
export function checkPaypalAlreadyPaymentOrNot(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/paypal-transaction-performed", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(checkPaypalAlreadyPaymentOrNotSuccess(res));
        } else {
          dispatch(checkPaypalAlreadyPaymentOrNotFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(checkPaypalAlreadyPaymentOrNotFailed(data));
      });
  };
}

function checkPaypalAlreadyPaymentOrNotSuccess(data) {
  return { type: CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_SUCCESS, data: data };
}

function checkPaypalAlreadyPaymentOrNotFailed(data) {
  return { type: CHECK_PAYPAL_ALREADY_PAYMENT_OR_NOT_FAILED, data: data };
}

/* Change user password */

export function changeUserPassword(formData) {
  return function (dispatch) {
    return axiosInstance
      .post("api/user/change/password", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 500) {
            localStorage.clear()
            setTimeout(() => {

            }, 1000)

          }
          dispatch(changeUserPasswordSuccess(res));
        } else {
          dispatch(changeUserPasswordFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(changeUserPasswordFailed(data));
      });
  };
}

function changeUserPasswordSuccess(data) {
  return { type: CHANGE_USER_PASSWORD_SUCCESS, data: data };
}

function changeUserPasswordFailed(data) {
  return { type: CHANGE_USER_PASSWORD_FAILED, data: data };
}

//Update registration step...
export function Updatestep(step) {
  return function (dispatch) {
    dispatch({ type: UPDATE_REGISTRATION_STEP, step: step });
  };
}

/* Contact Us save*/
export function saveContactUs(formData) {
  return function (dispatch) {
    dispatch({ type: USER_PROFILE_UPDATE_REQUEST });
    return axiosInstance
      .post("api/contactus/store", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(saveContactUsSuccess(res));
        } else {
          dispatch(saveContactUsFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(saveContactUsFailed(data));
      });
  };
}

function saveContactUsSuccess(data) {
  return { type: SAVE_CONTACT_US_SUCCESS, data: data };
}

function saveContactUsFailed(data) {
  return { type: SAVE_CONTACT_US_FAILED, data: data };
}

/* Contact Us save*/
export function fetchSignCms() {
  return function (dispatch) {
    return axiosInstance
      .get("api/signup-signin/cms")
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSignCmsSuccess(res));
        } else {
          dispatch(fetchSignCmsFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchSignCmsFailed(data));
      });
  };
}

function fetchSignCmsSuccess(data) {
  return { type: FETCH_SIGN_CMS_SUCCESS, data: data };
}

function fetchSignCmsFailed(data) {
  return { type: FETCH_SIGN_CMS_FAILED, data: data };
}


/* Social link Us save*/
export function fetchSociallink() {
  return function (dispatch) {
    return axiosInstance
      .get("api/cms/data/social-links")
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSociallinkSuccess(res));
        } else {
          dispatch(fetchSociallinkFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(fetchSociallinkFailed(data));
      });
  };
}

function fetchSociallinkSuccess(data) {
  return { type: FETCH_SOCIAL_LINK_SUCCESS, data: data };
}

function fetchSociallinkFailed(data) {
  return { type: FETCH_SOCIAL_LINK_FAILED, data: data };
}

//Delete card
export function deleteCard(formData) {
  return async function (dispatch) {
    return await axiosInstance
      .post("api/user/delete-cards", formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(deleteCardSuccess(res));
        } else {
          dispatch(deleteCardFailed(res));
        }
      })
      .catch(function (error) {
        const data = {
          status: 503,
          data: {},
          message: "Something Went Wrong! please try again",
        };
        dispatch(deleteCardFailed(data));
      });
  };
}

function deleteCardSuccess(data) {
  return { type: DELETE_CARD_SUCCESS, data: data };
}

function deleteCardFailed(data) {
  return { type: DELETE_CARD_FAILED, data: data };
}







export function clearState() {
  return function (dispatch) {
    dispatch({ type: CLEAR_STATE });
  };
}
