import { Button } from "@material-ui/core";
import React, { Component } from "react";

import Icon from "../assets/images/icon8.svg";
import PrimaryHeader from "../Widgets/PrimaryHeader";

import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

import "react-toastify/dist/ReactToastify.css";

/* Redux */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as miscActions from "../redux/actions/miscActions";
import * as contactActions from "../redux/actions/contactActions";

const modal = "d-flex align-items-center";

class SecondaryUserStepThree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allEventTypes: [],
      setOpen: false,
      setModalOpen: false,
      isSubmitForm: false,
      userInfo: "",
      formData: {
        title: "",
        recuringEvent: "",
        eventDay: "",
        eventMonth: "",
        eventYear: "",
        dateUnknown: "",
      },
      apiErrorMessage: "",
      apiSuccessMessage: "",
      isRedirect: false,

      selectedEventList: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var auth_token = localStorage.getItem("auth_token");

    if (auth_token) {
      this.props.miscActions.getProfileDetails();
      this.props.contactActions.fetchEventListByContactID();
    } else {
      this.props.history.push("/");
    }
  }

  componentDidUpdate() {
    if (this.state.apiErrorMessage) {
      toast.error(this.state.apiErrorMessage);
      this.state.apiErrorMessage = "";
    } else if (this.state.apiSuccessMessage) {
      toast.success("Event created successfully");
      this.state.apiSuccessMessage = "";
    }

    if (this.state.isRedirect === true) {
      this.props.history.replace("/secondary-user-dashboard");
    }
  }

  static getDerivedStateFromProps(props, state) {
    var returnData = state;
    if (Object.keys(props.contactReducerData).length > 0) {
      let { contactReducerData } = props;
      switch (contactReducerData.type) {
        /* Fetch all event list */
        case contactActions.FETCH_EVENT_LIST_BY_CONTACT_ID_SUCCESS:
          var eventList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];
          let allEventTypes = [];
          if (eventList.length > 0) {
            eventList.forEach(function (singleEvent, singleIndx) {
              if (singleEvent.isDeleted === false) {
                var temp = {
                  id: singleEvent._id,
                  name: singleEvent.title,
                  isSelected: false,
                };
                allEventTypes.push(temp);
              }
            });
          }
          props.miscActions.clearState();
          props.contactActions.fetchSecondaryUserSelectionList();
          returnData["allEventTypes"] = allEventTypes;
          break;
        case contactActions.FETCH_EVENT_LIST_BY_CONTACT_ID_FAILED:
          returnData["allEventTypes"] = [];
          break;

        /* Create New event */
        case contactActions.CREATE_NEW_EVENT_SUCCESS:
          var message = contactReducerData
            ? contactReducerData.responce.data.message
            : "";
          props.miscActions.clearState();
          props.contactActions.fetchEventListByContactID();

          returnData["loading"] = false;
          returnData["setModalOpen"] = false;
          break;
        case contactActions.CREATE_NEW_EVENT_FAILED:
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined
              ? contactReducerData.responce.data.message
              : "";
          returnData["apiErrorMessage"] = message;
          break;

        /* SELECT SECONDARY USER EVENTS */
        case contactActions.SECONDARY_USER_EVENT_SELECTION_SUCCESS:
          returnData["apiErrorMessage"] = "";
          returnData["apiSuccessMessage"] = "";
          returnData["isRedirect"] = true;
          props.miscActions.clearState();
          break;
        case contactActions.SECONDARY_USER_EVENT_SELECTION_FAILED:
          props.miscActions.clearState();
          var message =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.message !== undefined
              ? contactReducerData.responce.data.message
              : "";
          returnData["apiErrorMessage"] = message;
          break;

        /* Secondary user selection list */
        case contactActions.SECONDARY_USER_SELECTION_LIST_SUCCESS:
          var selectedEventList =
            contactReducerData !== undefined &&
            contactReducerData.responce !== undefined &&
            contactReducerData.responce.data !== undefined &&
            contactReducerData.responce.data.data !== undefined
              ? contactReducerData.responce.data.data
              : [];

          var allEventTypesList = state.allEventTypes;

          if (allEventTypesList.length > 0 && selectedEventList.length > 0) {
            allEventTypesList.forEach(function (itm, indx) {
              selectedEventList.forEach(function (selectedItm, selectedIndx) {
                var selectedEventId = "";

                if (
                  selectedItm.event_id !== undefined &&
                  selectedItm.event_id !== null &&
                  selectedItm.event_id !== "" &&
                  selectedItm.event_id._id !== undefined &&
                  selectedItm.event_id._id !== null &&
                  selectedItm.event_id._id !== ""
                ) {
                  selectedEventId = selectedItm.event_id._id;
                  if (itm.id === selectedEventId) {
                    itm.isSelected = true;
                  }
                }
              });
            });
          }

          returnData["allEventTypes"] = allEventTypesList;
          break;

        case contactActions.SECONDARY_USER_SELECTION_LIST_FAILED:
          //returnData["selectedEventList"] = [];
          break;
      }
    }

    if (Object.keys(props.miscReducerData).length > 0) {
      let { miscReducerData } = props;
      switch (miscReducerData.type) {
        /* Fetch User Profile */
        case miscActions.GET_PROFILE_DETAILS_SUCCESS:
          var userInfo =
            miscReducerData !== undefined &&
            miscReducerData.responce !== undefined &&
            miscReducerData.responce.data !== undefined &&
            miscReducerData.responce.data.data !== undefined
              ? miscReducerData.responce.data.data
              : "";
          props.miscActions.clearState();
          returnData["userInfo"] = userInfo;
          break;
        case miscActions.GET_PROFILE_DETAILS_FAILED:
          props.miscActions.clearState();
          returnData["userInfo"] = "";
          break;
      }
    }

    return returnData;
  }

  selectEventType(index) {
    let allEventTypes = this.state.allEventTypes;

    if (allEventTypes.length > 0) {
      allEventTypes.forEach(function (itm, indx) {
        if (index === indx) {
          itm.isSelected = !itm.isSelected;
        }
      });
    }

    this.setState({
      allEventTypes: allEventTypes,
    });
  }

  handleCloseModal = () => {
    this.setState({ setModalOpen: false });
  };

  handleSubmit(event) {
    event.preventDefault();

    var allEventTypes = this.state.allEventTypes;
    var selectedEventIds = [];
    if (allEventTypes.length > 0) {
      allEventTypes.forEach(function (itm, indx) {
        if (itm.isSelected === true) {
          selectedEventIds.push(itm.id);
        }
      });
    }

    if (selectedEventIds.length <= 0) {
      toast.error("Please select an event type");
    } else {
      var formData = {
        events: selectedEventIds,
      };

      this.props.contactActions.selectSecondayUserEvents(formData);
    }
  }

  handleChange(event) {
    let formData = this.state.formData;
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    if (fieldName === "eventMonth") {
      if (fieldValue === "2") {
        if (formData.eventDay > 29) {
          formData["eventDay"] = 29;
        } else if (formData.eventDay < 1) {
          formData["eventDay"] = 1;
        }
      }
    }

    if (fieldName === "dateUnknown") {
      fieldValue = event.target.checked;
    }

    if (fieldName === "recuringEvent" && fieldValue === "true") {
      fieldValue = true;
    } else if (fieldName === "recuringEvent" && fieldValue === "false") {
      fieldValue = false;
    }

    formData[fieldName] = fieldValue;
    this.setState({
      formData: formData,
      isDisabledSubmit: false,
    });
  }

  handleCreateEvents(event) {
    event.preventDefault();
    var formData = this.state.formData;

    if (
      formData.title === "" ||
      formData.title === undefined ||
      formData.title === null
    ) {
      toast.error("Event name can not be blank");
    } else if (
      formData.recuringEvent === "" ||
      formData.recuringEvent === undefined ||
      formData.recuringEvent === null
    ) {
      toast.error("Please select 'Is this a recurring event or not'");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventDay === "" ||
        formData.eventDay === null ||
        formData.eventDay === undefined)
    ) {
      toast.error("Please enter event date");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventMonth === "" ||
        formData.eventMonth === null ||
        formData.eventMonth === undefined)
    ) {
      toast.error("Please select an event month");
    } else if (
      (formData.dateUnknown === "" ||
        formData.dateUnknown === undefined ||
        formData.dateUnknown === null ||
        formData.dateUnknown === false) &&
      (formData.eventYear === "" ||
        formData.eventYear === null ||
        formData.eventYear === undefined)
    ) {
      toast.error("Please enter event year");
    } else {
      if (formData.dateUnknown === "") {
        formData.dateUnknown = false;
      }

      var currentContactId =
        this.props.match !== undefined &&
        this.props.match.params !== undefined &&
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "";
      formData["user_contact_id"] = currentContactId;

      this.setState({
        isSubmitForm: true,
      });
      this.props.contactActions.createNewEvent(formData);
    }
  }

  render() {
    var formData = this.state.formData;

    var parentUserFirstName =
      this.state.userInfo !== "" &&
      this.state.userInfo !== null &&
      this.state.userInfo !== undefined &&
      this.state.userInfo.invitedBy !== null &&
      this.state.userInfo.invitedBy !== "" &&
      this.state.userInfo.invitedBy !== undefined &&
      this.state.userInfo.invitedBy.first_name !== null &&
      this.state.userInfo.invitedBy.first_name !== "" &&
      this.state.userInfo.invitedBy.first_name !== undefined
        ? this.state.userInfo.invitedBy.first_name
        : "";

    var parentUserLastName =
      this.state.userInfo !== "" &&
      this.state.userInfo !== null &&
      this.state.userInfo !== undefined &&
      this.state.userInfo.invitedBy !== null &&
      this.state.userInfo.invitedBy !== "" &&
      this.state.userInfo.invitedBy !== undefined &&
      this.state.userInfo.invitedBy.last_name !== null &&
      this.state.userInfo.invitedBy.last_name !== "" &&
      this.state.userInfo.invitedBy.last_name !== undefined
        ? this.state.userInfo.invitedBy.last_name
        : "";

    return (
      <>
        <div className="card bg-transparent border-0 vh-100 overflow-auto">
          <PrimaryHeader position={"innerHeader"} />
          <section className=" registerStepWrap h-100  overflow-auto">
            <div className="container">
              <div className="row">
                <div className="card border-0  mx-auto">
                  <div className=" ">
                    <div className="iconBox mar-b-15 mar-b-md-35">
                      <img src={Icon} alt="" className="w-50px w-md-70px" />
                    </div>
                    <div className="fs-22 fs-md-36 font-weight-Bold  ">
                      More Events you care about
                    </div>
                    <div className="subHead fs-14 fs-md-18 mar-b-30">
                      Select events when you wish to hear{" "}
                      {/* from  {parentUserFirstName} {parentUserLastName}  */}
                      so that nothing gets missed.
                    </div>

                    <form onSubmit={this.handleSubmit}>
                      <div className="">
                        <ul className="list-unstyled d-flex flex-wrap">
                          {this.state.allEventTypes.map((list, index) => (
                            <li className="p-2" key={index}>
                              {list.isSelected === true && (
                                <Chip
                                  icon={<DoneIcon />}
                                  label={list.name}
                                  clickable
                                  onClick={(e) => this.selectEventType(index)}
                                  color="primary"
                                />
                              )}

                              {list.isSelected === false && (
                                <Chip
                                  label={list.name}
                                  clickable
                                  onClick={(e) => this.selectEventType(index)}
                                />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <Button
                        className="text-color1 font-weight-Mediaum p-0 fs-16"
                        onClick={() => this.setState({ setModalOpen: true })}
                      >
                        Add custom events
                      </Button>
                      <div className="w-100 pad-t-50">
                        <Button
                          type="submit"
                          className="bg-color1 pad-y-7 font-weight-Medium pad-x-20 fs-19 text-white text-capitalize"
                        >
                          Next
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Modal
          className={modal}
          open={this.state.setModalOpen}
          onClose={this.handleCloseModal.bind(this)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="myCusTomModal d-flex">
            <div className="card   ">
              <div className="card-header border-bottom bg-white pad-x-40 pad-y-25">
                <h3 className="font-weight-Medium fs-24 mb-0 ">
                  <i className="material-icons text-color1 align-middle">
                    event
                  </i>{" "}
                  Add Event details
                </h3>
              </div>
              <div className="card-body h-100 overflow-auto pad-x-40 pad-y-36">
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  1. For which occasion are you creating this event?
                </h3>
                <div className="row">
                  <div className="mar-b-40 col-md-12">
                    <TextField
                      inputProps={{ maxLength: 25 }}
                      label="Event name"
                      variant="outlined"
                      className="w-100"
                      name="title"
                      value={formData.title !== undefined ? formData.title : ""}
                      onChange={this.handleChange}
                    />
                    <div className="fs-12 opa-60 text-black text-right">
                      {formData.title.length}/25
                    </div>
                  </div>
                </div>
                <h3 className="text-black mar-b-22 fs-18 font-weight-Regular">
                  2. Is this a recurring event?
                </h3>
                <div className="mar-b-40 ">
                  <RadioGroup
                    name="recuringEvent"
                    onChange={this.handleChange}
                    value={
                      formData.recuringEvent !== undefined
                        ? formData.recuringEvent
                        : ""
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes, this event recurs every year"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No, this event occurs for once"
                    />
                  </RadioGroup>
                </div>

                <div className="d-md-flex justify-content-between mar-b-22 align-items-center">
                  <h3 className="text-black mb-0 fs-18 font-weight-Regular">
                    3. Event date
                  </h3>
                  <div className="checkLabelCustom ml-n2 ml-md-auto">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="dateUnknown"
                          checked={
                            formData.dateUnknown !== undefined
                              ? formData.dateUnknown
                              : false
                          }
                          //   checked={true}
                          value={
                            formData.dateUnknown !== undefined
                              ? formData.dateUnknown
                              : ""
                          }
                          onChange={this.handleChange}
                        />
                      }
                      label="This event’s date is unknown"
                    />
                  </div>
                </div>

                {formData.dateUnknown !== true && (
                  <div className="row">
                    <div className="mar-b-40 col-md-4">
                      <FormControl
                        variant="outlined"
                        className="w-100 bg-white"
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Month
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Month"
                          onChange={this.handleChange}
                          name="eventMonth"
                          value={
                            formData.eventMonth !== undefined
                              ? formData.eventMonth
                              : ""
                          }
                        >
                          <MenuItem value="1">January</MenuItem>
                          <MenuItem value="2">February</MenuItem>
                          <MenuItem value="3">March</MenuItem>
                          <MenuItem value="4">April</MenuItem>
                          <MenuItem value="5">May</MenuItem>
                          <MenuItem value="6">June</MenuItem>
                          <MenuItem value="7">July</MenuItem>
                          <MenuItem value="8">August</MenuItem>
                          <MenuItem value="9">September</MenuItem>
                          <MenuItem value="10">October</MenuItem>
                          <MenuItem value="11">November</MenuItem>
                          <MenuItem value="12">December</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mar-b-40 col-md-4">
                      <TextField
                        type="number"
                        label="Day"
                        name="eventDay"
                        value={
                          formData.eventDay !== undefined
                            ? formData.eventDay
                            : ""
                        }
                        onChange={this.handleChange}
                        variant="outlined"
                        className="w-100"
                        onInput={(e) => {
                          var value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 2);
                          if (formData.eventMonth === "2") {
                            if (value > 29) {
                              value = 29;
                            } else if (value < 1) {
                              value = 1;
                            }
                          } else if (value > 31) {
                            value = 31;
                          } else if (value < 1) {
                            value = 1;
                          }
                          e.target.value = value;
                        }}
                      />
                    </div>
                    <div className="mar-b-40 col-md-4">
                      <TextField
                        type="number"
                        label="Year"
                        variant="outlined"
                        className="w-100"
                        name="eventYear"
                        value={
                          formData.eventYear !== undefined
                            ? formData.eventYear
                            : ""
                        }
                        onChange={this.handleChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 4);
                        }}
                      />
                    </div>
                  </div>
                )}

                {formData.dateUnknown === true && (
                  <div className="bg-color3 py-3 px-4 border-radius-10 fs-18 ">
                    <div className="opa-60 text-black">
                      Create memories for future events without a certain date.
                      Tracey will be able to see this memory anytime she thinks
                      it’s appropriate by the event name.
                    </div>
                  </div>
                )}
              </div>
              <div className="card-footer bg-white text-right pad-x-40 ">
                {this.state.isSubmitForm === true && <CircularProgress />}

                {this.state.isSubmitForm === false && (
                  <>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize"
                      onClick={this.handleCloseModal.bind(this)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="text-color1 fs-19 font-weight-Medium text-capitalize"
                      onClick={this.handleCreateEvents.bind(this)}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miscReducerData: state.miscReducer,
    contactReducerData: state.contactReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  miscActions: bindActionCreators(miscActions, dispatch),
  contactActions: bindActionCreators(contactActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryUserStepThree);
